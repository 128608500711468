import * as React from 'react';
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, withStyles, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from '@material-ui/icons';

const styles = (theme: any) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    headingFailed: {
        color: theme.palette.error.main
    },
    linkButton: {
        position: 'absolute' as any,
        marginTop: '-11px' as any,
        marginLeft: '5px' as any
    }
});

const isLinkReg = /https?:\/\//;
const isLink = (txt: string) =>{
    return isLinkReg.test(txt);
}

export interface Props{
    report: {
        label?: string,
        key: string,
        groups: Array<{
            key: string,
            results: Array<{
                key: string;
                label: string|null;
                value: any;
                qualitative: 'good'|'average'|'bad'|null,
                valueType: string, //boolean, percentual...
                fail: boolean,
                threshold?: any,
                ignore?: boolean
            }>
        }>
    }
}

const wordBreakAllStyle: any = { wordBreak: "break-all"};

const Result = (props: {
    label: string,
    value: any,
    threshold?: any,
    fail: boolean,
    ignore?: boolean
})=>{
    
    return (
    <div style={{marginBottom: 16, opacity: props.ignore===true? 0.3:1}}>
        <Typography variant="caption" style={wordBreakAllStyle} color={props.fail?"error":"textSecondary"}>{props.label}</Typography>
        <Typography variant="body1" component="p">
            {/* { isLink(props.label) && <IconButton><Link fontSize="small" /></IconButton> } */}
            <Typography component="span" style={wordBreakAllStyle} display="inline" color={props.value!=null?"textPrimary":"textSecondary"}>{props.value.toString()}</Typography>
            { props.threshold!=null && <Typography style={wordBreakAllStyle} display="inline" component="span" color={"textSecondary"}> ({props.threshold.toString()})</Typography> }
        </Typography>        
    </div>
    )
}

export class SimpleReportUnstyled extends React.PureComponent<Props & {classes: any}>{

    handleLinkClick = (e: any) => {
        e.stopPropagation();
        window.open(e.target.dataset.url, '_blank');
    }

    render(){
        const { report, classes } = this.props;
        const reportKeySplitted = report.key.replace(/:/,'__:__').split('__:__');

        return <React.Fragment>
            <Typography component="div" variant="h6">{`${report.label!=null?`[${report.label}] `:''} ${reportKeySplitted[0]}`}</Typography>
            <Typography component="div" variant="caption" style={{wordWrap: 'break-word'}}>{reportKeySplitted[1]}</Typography>
            <br />
            { report.groups.map(group =>{
                const groupContainsError = group.results.findIndex(x => x.fail && x.ignore !== true ) !== -1;
                return (<ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={`${classes.heading} ${groupContainsError ? classes.headingFailed : '' }`}>{group.key}</Typography>
                    { isLink(group.key) && <div><IconButton data-url={group.key} onClick={this.handleLinkClick} className={this.props.classes.linkButton}><Link fontSize="small" /></IconButton></div> }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ minWidth:'200px', maxWidth: '80vw', display: 'block' }}>
                    { group.results.map(result => (
                        <Result label={result.key} value={result.value} fail={result.fail} ignore={result.ignore} threshold={result.threshold} />
                    )) }
                </ExpansionPanelDetails>
            </ExpansionPanel>)
            }) }
        </React.Fragment>
    }
}

export const SimpleReport = withStyles(styles)(SimpleReportUnstyled);
import * as React from "react";
import {
  Paper,
  Button,
  IconButton,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import {
  ErrorOutline as ErroIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

const styles: (theme: any) => any = theme => {
  return {
    floatingControlsPaper: {
      position: "fixed",
      bottom: "0",
      right: "0",
      padding: 6,
      zIndex: 100,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0
    },
    errorIcon: {
      margin: "0 5px",
      position: "relative",
      top: "0.3em"
    }
  };
};

interface FormActionsProps {
  templateKey: string;
  websiteKey: string;
  classes: any;
  formIsValid: boolean;
  onBuildClick: (e: any) => void|Promise<void>;
  onSaveClick: (e: any) => void|Promise<void>;
}

interface FormActionsState {
  menuOpen: boolean;
  menuAnchor: any | null;
}

export class FormActionsUnstyled extends React.Component<FormActionsProps> {
  
  state: FormActionsState = {
    menuOpen: false,
    menuAnchor: null
  };

  private handleMoreActionClick = (e: any) => {
    this.setState({ menuOpen: true, menuAnchor: e.target });
  };

  private hideMenu = (e: any) => {
    this.setState({ menuOpen: false });
  };

  render() {
    const {
      classes,
      formIsValid,
      onBuildClick,
      onSaveClick,
      templateKey,
      websiteKey
    } = this.props;

    return (
      <Paper className={classes.floatingControlsPaper}>
        <Button style={{ margin: 6 }} onClick={onSaveClick} >
          Save
        </Button>

        {!formIsValid && (
          <ErroIcon color="error" className={this.props.classes.errorIcon} />
        )}

        <Button style={{ margin: 6 }} variant="contained" color="primary" disabled={!formIsValid} onClick={onBuildClick} >
          Build
        </Button>

        <IconButton onClick={this.handleMoreActionClick}>
          <MoreVertIcon />
        </IconButton>

        <Menu
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchor}
          onClose={this.hideMenu}
        >
          <MenuItem
            onClick={this.hideMenu}
            component="a"
            href={`http://${templateKey}--${this.props.websiteKey}.hugosites.webdrvn.com`}
            target="_blank"
          >
            Preview
          </MenuItem>
          <MenuItem
            component={React.forwardRef((itemProps: any, ref: any) => (
              <Link ref={ref} to={`/websites/${templateKey}/${websiteKey}/deploy`} {...itemProps} />
            ))}
          >
            Deploy
          </MenuItem>
        </Menu>
      </Paper>
    );
  }
}

export const FormActions = withStyles(styles)(FormActionsUnstyled);

import * as React from 'react';
import { Formik, FastField } from 'formik';
import { FormControl, Button } from '@material-ui/core';
import * as Yup from 'yup';
import { FormikTextField } from '../../../components/FormikTextField';

interface CreateFormProps {
    agency: string,
    submitForm: (data: any) => void,
    onFormSubmited: () => void;
}

export class CreateInfraCredentialForm extends React.Component<CreateFormProps,any>{
    
    handleOnFormikSubmit = async (values: any, { setSubmitting }: { setSubmitting: (value: boolean)=>void }) =>{
        try{
            setSubmitting(true);
            await this.props.submitForm(values);
            this.props.onFormSubmited();
        }
        catch(e){
            alert('An error has ocurred.');
            setSubmitting(false);
        }              
    }    

    public render(){

        const { agency } = this.props;
        const agencyIsSelected = agency!=null&&agency!=='';

        const validationScheme = Yup.object().shape({
            agency: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            accessKey: Yup.string().required('Required'),
            secretAccessKey: Yup.string().required('Required')
        });       

        return (<Formik
            initialValues={{ name: '', accessKey:'', secretAccessKey:'', agency:this.props.agency }}
            onSubmit={this.handleOnFormikSubmit}
            validationSchema={validationScheme}
        >
        {props => {
            const { isSubmitting, handleSubmit } = props;

            return (<form onSubmit={handleSubmit}>
                { agencyIsSelected ? (<React.Fragment>
                    <FastField name="agency" label="* Agency" InputProps={{ readOnly: true }} component={FormikTextField} />
                    <FastField name="name" label="* Name" component={FormikTextField} />
                    <FastField name="accessKey" label="* AWS Access Key" component={FormikTextField} />
                    <FastField name="secretAccessKey" label="* AWS Secret Access Key" component={FormikTextField} />
                </React.Fragment>) : (<p>To create a credential you must select an agency.</p>) }
                
                <FormControl margin={'normal'} fullWidth={true}>
                    <Button type="submit" disabled={isSubmitting||!agencyIsSelected} variant="contained" color="primary" fullWidth={true}>Create</Button>
                </FormControl>
            </form>)
        }}
        </Formik>);
    }
    
}
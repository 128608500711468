import * as React from 'react';
import * as Yup from 'yup';
import { Divider, List, Paper, ListSubheader, ListItem, ListItemSecondaryAction, ListItemText, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Formik, Field } from 'formik';
import { serviceLocator } from '../../services';
import { FormikSelect } from '../../components/FormikSelect';

const userService = serviceLocator.getUserService();

interface Props{
    users: Array<any>;
    addUser: (user: any)=>void;
    deleteUser: (username: any)=>void;
    usersOptions: Array<{value: string}>;
    onCreatedDialogOpen?: ()=>any
}

interface State{
    menuAnchor: any;
    userMenuOpen: boolean;
    userMenuKey: string;
    openDialog: string|null;
}

const dialogContentStyle: any = {
    minWidth: '300px'
}


export class UsersList extends React.Component<Props, State>{

    state: State = {
        menuAnchor: null,
        userMenuOpen: false,
        userMenuKey: '',
        openDialog: null,
    }

    private restoreScroll(){
        // fix
        setTimeout(()=>{
            document.body.style.overflow = "";
        },100);
    }

    private handleNewClick = async (e: any) => {
        this.setState({ openDialog: 'new' });
        if(this.props.onCreatedDialogOpen){
            this.props.onCreatedDialogOpen();
        }
    }   

    handleUserDelete = async (e: any) => {
        this.setState({userMenuOpen: false});
        await this.props.deleteUser(this.state.userMenuKey);
    }

    private handleUserMoreClick = (e: any) => {
        const target = e.currentTarget;
        this.setState({ userMenuOpen: true, menuAnchor: target, userMenuKey: target.dataset.key });
    }

    private handleDialogClose = (e: any) => {
        this.setState({ openDialog: null });
    }

    handlePutUserFormSubmit = async (values: any, { setSubmitting, resetForm }: any) =>{
        try{
            await this.props.addUser({ username: values.username, role: values.role });
            setSubmitting(false);
            resetForm();
            this.setState({ openDialog: null });
        }
        catch{
            setSubmitting(false);
            alert('An error has ocurred');
        }
    }

    private handleMenuClose = (e: any) => {
        this.setState({ userMenuOpen: false });
    }

    renderDialog(){
        return (
            <Formik
                initialValues={{ username: '', role: "designer" }}
                onSubmit={this.handlePutUserFormSubmit}
                validationSchema={
                    Yup.object().shape({
                        username: Yup.string().required('Required.'),
                        role: Yup.string().required('Required.')
                    }).required()
                }>
                { props => {
                    const { handleSubmit, isSubmitting, submitForm } = props;
    
                    return (
                        <Dialog
                            onClose={this.handleDialogClose}
                            onExited={this.restoreScroll}
                            open={this.state.openDialog==='new'}>
                            <DialogTitle style={dialogContentStyle}>Add New User</DialogTitle>
                            <DialogContent style={dialogContentStyle}>
                                <form onSubmit={handleSubmit}>
                                    <Field
                                        name="username" label="User"
                                        component={FormikSelect} options={this.props.usersOptions} />
                                    <Field
                                        name="role" 
                                        label="Role"
                                        component={FormikSelect}
                                        default
                                        options={[{value: 'designer'}, {value: 'agent'}]}
                                    />
                                    <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{width:1, height:1, visibility:'hidden'}}>Create</button>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    disabled={isSubmitting}
                                    color="primary"
                                    type="submit"
                                    value="create"
                                    onClick={submitForm}>Add</Button>
                            </DialogActions>
                        </Dialog>
                                     
                    )
                }}
            </Formik>
        );
    }

    renderMenu() {
        return (
            <Menu
                key="user-menu"
                open={this.state.userMenuOpen}
                anchorEl={this.state.menuAnchor}
                onClose={this.handleMenuClose}
            >
                {/* <MenuItem component={this.currentJsonSchemaLink} >Open</MenuItem> */}
                <MenuItem onClick={this.handleUserDelete}>Delete</MenuItem>
            </Menu>
        );
    }

    render(){
        console.log('users', this.props.users)
        return (
            <React.Fragment>
                <Paper>
                    <List component="nav" subheader={<ListSubheader>Users</ListSubheader>}>
                        <Divider />
                        {this.props.users.map((user, i) => {
                            return (
                                <ListItem key={user.username}
                                    // button={true}
                                    // component={this.getJsonSchemaLink(item)}
                                    divider={true}>
                                    <ListItemText primary={user.username} secondary={user.AgencyUser?.role} />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={this.handleUserMoreClick} data-key={user.username}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                </Paper>
                <div style={{ padding: '16px 0' }}>
                    <span> </span><Button onClick={this.handleNewClick}>Add User</Button>
                </div>
                {this.renderDialog()}
                {this.renderMenu()}
            </React.Fragment>
        )
    }
}
import { FormStateBuilder, FieldBase } from "../HoForm";

export default {
    normalizeStateForGroupedObject: (state: any, field: FieldBase, stateBuilder: FormStateBuilder)=>{
        let key = field.key;
        if(state[key]==null){
            state[key] = {};
        }
        if(typeof state[key] !== 'object' && state[key] !== null){
            let newData = {};
            stateBuilder.reportDataReplacement(field, state[key], newData);
            state[key] = newData;
        }
        stateBuilder.setLevelState(state[key], field.fields);
    },
    normalizeStateForArrayOfObject: (state: any, field: FieldBase, stateBuilder: FormStateBuilder)=>{
        let key = field.key;
        if(state[key]==null){
            state[key] = field.default || [];
        }
        else if(!Array.isArray(state[key])){
            let newData = field.default || [];
            stateBuilder.reportDataReplacement(field, state[key], newData);
            state[key] = newData;
        }
        for(let j = 0; j < state[key].length; j++){
            stateBuilder.setLevelState(state[key][j], field.fields);
        }
    }
}
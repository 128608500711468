import * as React from 'react';
import { pth } from '../utils/pth';
import { DebouncedTextField } from './DebouncedTextField';

export class FormikTextField extends React.Component<any,any>{
    public render(){
        const {
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            ...fProps
        } = this.props;
        const isTouched = pth(field.name,touched);
        const error = pth(field.name,errors);
        
        // if(error){
        //     console.log(field, error);
        // }
        return (
            <DebouncedTextField
            {...field}
            {...fProps}
            margin="dense"
            fullWidth={true}
            helperText={isTouched ? `${fProps.helperText||''} ${error||''}`.trim() : fProps.helperText} error={isTouched ? (error!=null) : false} />
        )
    }
};
import * as React from 'react';
import { TextField } from '@material-ui/core';
import { Debounce } from '../utils/debounce';

interface Props {
    initialValue: string;
    save: (value: string)=>Promise<void>;
}

interface State {
    isSaving: boolean,
    value: string | null
}

export class AutoSaveTextField extends React.Component<Props|any,State> {
    
    saveDebounce:Debounce = new Debounce(1000);
    savedValue: string;

    constructor(props: Props){
        super(props);
        this.savedValue = props.initialValue;
        this.state = { isSaving: false, value: props.initialValue };
    }

    private handleChange = (e: any)=>{
        
        const value = e.currentTarget.value;
        this.setState({value});
        
        if(!this.state.isSaving){
            this.setState({isSaving: true});
        }

        this.save(value);
    }

    private save(value: string){
        
        this.saveDebounce.run(()=>{
            console.log('Saving.');
            this.savedValue = value;
            this.props.save(value)
                .then(this.saveHandler)
                .catch(this.saveErrorHandler);
        })
    }

    static getDerivedStateFromProps(props: Props, state: State){        
        if(state.value==null){
            return { value: props.initialValue };
        }
        return null;
    }

    private saveHandler = () =>{
        // console.log('Saved.');
        if(this.savedValue!==this.state.value){
            // console.log('Resaving.');
            this.save(this.state.value||'');
        }
        else{
            // console.log('Resting...');
            this.setState({isSaving: false});
        }
    }

    private saveErrorHandler = () =>{
        // console.log('Save error.');
        this.setState({isSaving: false});
    }

    public render(){
        const { isSaving, value } = this.state;
        const { save, load, initialValue, ...rest } = this.props;
        return <TextField
            key={value==null?'disabled':'enabled'}
            {...rest}
            value={value||''}
            disabled={value==null}
            onChange={this.handleChange}
            helperText={ isSaving?'Saving...':(rest.helperText||'Changes will be saved automatically.')
        } />
    }
}
import * as React from 'react';
import { serviceLocator } from '../../../../services';
import { Paper, List, ListSubheader, Divider, ListItem, ListItemText, Button, Drawer, Switch, FormControlLabel, FormGroup } from '@material-ui/core';
import { SimpleReport } from '../../../QualityReports/components/SimpleReport';
import { MainPadding } from '../../../../components/MainPadding';
import { SetupDialog } from './SetupDialog';
import { ToggleOffOutlined, ToggleOnOutlined, CheckBoxOutlined, CheckBoxOutlineBlank } from '@material-ui/icons';

const qualityCheckService = serviceLocator.getQualityCheckService()

interface Props {
    websiteKey: string;
    agency: string;
    dailyCheckEnabled: boolean;
    onEnableDailyCheckToggle: ()=>Promise<void>;
}

interface State {
    reports: any[];
    openReport: boolean;
    selectedReport: any;
    running: boolean;
    setupOpen: boolean;
    setupRequested: boolean;
}

export class QualityReportsList extends React.Component<Props,State>{
    
    state: State = {
        reports: [],
        openReport: false,
        selectedReport: null,
        running: false,
        setupOpen: false,
        setupRequested: false
    }

    load = async () => {
        const { reports } = await qualityCheckService.getReportsByName(this.props.websiteKey);
        this.setState({reports});
    }

    componentDidMount = async () => {
        this.load();
    }

    handleRunChecksClick = async () =>{
        this.setState({running: true});
        try{
            await qualityCheckService.runForOneWebsite(this.props.websiteKey);
            await this.load();
        }
        catch(e){

        }
        this.setState({running: false});
    }

    handleEnableDailyCheckToggle = (e: any) => {
        this.props.onEnableDailyCheckToggle();
    }

    render(){
        return (
            <React.Fragment>
                <Paper>
                    <List component="nav" subheader={<ListSubheader>Quality Reports</ListSubheader>}>
                        <Divider />
                        {this.state.reports.map((report, i) => {
                            return (
                                <ListItem key={report.key}
                                    onClick={()=>this.setState({openReport: true, selectedReport: report})}
                                    button={true}
                                    divider={true}>
                                    <ListItemText                                        
                                        primary={report.key.replace(':',' ')}
                                        primaryTypographyProps={{
                                            color:report.fail?'error':undefined,
                                            style:{ overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: 'block'
                                            }
                                        }}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Paper>
                <div style={{ padding: '16px 0' }}>
                    <Button size="small" disabled={this.state.running} onClick={this.handleRunChecksClick}>Run</Button>
                    <span> </span><Button size="small" onClick={()=>this.setState({setupOpen: true, setupRequested: true})}>Setup</Button>
                    <span> </span><Button size="small" onClick={this.handleEnableDailyCheckToggle}>Daily Check&nbsp;{this.props.dailyCheckEnabled?<CheckBoxOutlined fontSize="small" />:<CheckBoxOutlineBlank fontSize="small" style={{opacity: 0.3}} />}</Button>
                </div>
                <Drawer anchor="right" open={this.state.openReport && this.state.selectedReport} onClose={()=>this.setState({openReport: false})}>
                    <MainPadding>
                        { this.state.selectedReport!=null ? <SimpleReport report={this.state.selectedReport} /> : null }
                    </MainPadding>
                </Drawer>
                {this.state.setupRequested && <SetupDialog agency={this.props.agency} websiteKey={this.props.websiteKey} open={this.state.setupOpen} onClose={()=>this.setState({setupOpen: false})} />}
            </React.Fragment>
        )
    }
}
import * as React from 'react';
import { Paper } from '@material-ui/core';

export class PreviewThumbnail extends React.PureComponent<any>{
    public render(){
        const { url, style, ...rest } = this.props;
        return (
            <Paper
                style={Object.assign({}, style, {
                    width:60,
                    height: 40,
                    backgroundImage:`url(${url})`,
                    backgroundSize:'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    position:'relative',
                    zIndex: 2
                })}
                { ...rest }
            />
        );
    }
}
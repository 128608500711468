import * as React from 'react';
import { Typography } from '@material-ui/core';

export class ErrorBoundary extends React.Component<any,{hasError: boolean}> {

    state = {hasError: false};

    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: any) {
      // Atualiza o state para que a próxima renderização mostre a UI alternativa.
      return { hasError: true };
    }
  
    componentDidCatch(error: any, info: any) {
      // Você também pode registrar o erro em um serviço de relatórios de erro
      console.log(error, info);
    }
  
    render() {
      if (this.state.hasError) {
        // Você pode renderizar qualquer UI alternativa
        return <Typography color="error" display="inline">Something went wrong.</Typography>;
      }
  
      return this.props.children; 
    }
}
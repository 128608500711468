import * as React from 'react';
import { Tabs, Tab } from '@material-ui/core';

export interface TabSimpleProps {
    labels:string[];
    index: number;
    onChange: (e:any, value:number)=>void;
}

export class TabsSimple extends React.Component<TabSimpleProps,{}>{
    public shouldComponentUpdate(nextProps: TabSimpleProps, nextState: {}){
        return this.props.index !== nextProps.index
        || this.props.onChange !== nextProps.onChange
        || this.props.labels.join('') !== nextProps.labels.join('');
    }

    

    public render(){
        return <Tabs
            style={{maxWidth: '100%'}}
            value={Math.min(this.props.index)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={this.props.onChange}
        >
            { this.props.labels.map((x: any,i: number) =><Tab value={i} data-index={i} key={i} label={this.emptyToDash(x)} />) }
        </Tabs>
    }

    private emptyToDash(str: string) {
        if(str==null||str.length===0||/^\s+$/.test(str)){ return '-';}
        return str;
    }

}
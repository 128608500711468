import Axios from 'axios';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

interface Props { path: string; }
interface State { md?: string }

const renderers = {
  code: ({language, value}:{language: string, value: string}) => {
    return <pre style={{padding: 3, border: 'solid 1px #eee'}}><code className={`language-${language}`}>{value}</code></pre>
  }
}

export class MarkdownContent extends React.Component<Props, State>{

  state: State = {

  };

  setFail() {
    this.setState({ md: `~~~
CONTENT NOT FOUND: ${this.props.path}
~~~` });
  }

  componentDidMount() {
    Axios.get(this.props.path).then((response) => {
      if (response.status != 200)
        this.setFail();
      else
        this.setState({ md: response.data });
    }, (reason) => {
      this.setFail();
    })
  }

  render() {
    if (!this.state.md) {
      return <p>...</p>;
    }
    return (<ReactMarkdown className="rich-content" renderers={renderers}>{this.state.md}</ReactMarkdown>);
  }
}
import * as React from 'react';
import { ComponentProps } from '../HoForm';
import { BaseDynamic } from '../HoForm';
import { FileCopy } from '@material-ui/icons';
import { IconButton, MenuItem, Menu } from '@material-ui/core';

type CopyAndPasteDynamicField = {
    type: string,
    key: string, 
    groupKey: string,
    componentKey: string,
    target: string,
    exceptRootKeys: string[],
}

type CopyAndPasteDynamicState = {
    open: boolean,
    hasError?: boolean,
}

export class CopyAndPasteDynamic extends BaseDynamic<CopyAndPasteDynamicField,CopyAndPasteDynamicState> {

    iconButtonRef = React.createRef<any>();

    state: CopyAndPasteDynamicState = {
        open: false,
    }

    constructor(props: ComponentProps<CopyAndPasteDynamicField>){
        super(props);
    }

    getType(){
        return 'copy-paste';
    }

    handleCopyClick = (e: any)=>{
        const node = this.props.context.node;
        let data = node.state;
        if(node.field.target){ data = data[node.field.target]; }
        this.setClipboard(data);
        this.setState({open: false});
    }

    handlePasteClick = (e: any)=>{
        const node = this.props.context.node;
        let data = this.getClipboard();
        let target = node.state;
        var exceptDict = {} as any;
        if(node.field.exceptRootKeys){
            node.field.exceptRootKeys.forEach(x => exceptDict[x] = true);
        }
        if(node.field.target){ target = target[node.field.target]; }
        Object.keys(target).forEach((k)=>{
            if(!exceptDict[k]){
                delete target[k];
            }
        });
        Object.keys(data).forEach((k)=>{
            target[k] = data[k];
        });
        this.setState({open: false});
        this.props.context.form.handleChange(node, 100);
    }

    private getStorageKey(component: boolean){
        const field = this.props.context.node.field;
        return `__clipboard:${field.groupKey}${component?`::component`:''}`;
    }

    private getClipboard(){
        let data = window.localStorage.getItem(this.getStorageKey(false));
        if(data==null) return null;
        return JSON.parse(data);
    }

    private hasCompatibleClipboard(){
        const field = this.props.context.node.field;
        return this.getStorageKey(true)===field.componentKey;
    }

    private setClipboard(value: any){
        const field = this.props.context.node.field;
        var str = JSON.stringify(value);
        if(field.exceptRootKeys && field.exceptRootKeys.length){
            value = JSON.parse(str);
            field.exceptRootKeys.forEach(x => delete value[x]);
            str = JSON.stringify(value);
        }
        debugger;
        window.localStorage.setItem(this.getStorageKey(true), field.componentKey);
        window.localStorage.setItem(this.getStorageKey(false), str);
    }

    private handleIconButtonClick = (e: any)=>{
        this.setState({open: true});
    }

    private handleClose = (e: any) => {
        this.setState({open: false});
    }

    renderComponent(){
        
        let {context} = this.props;
        let { currentPath, parentPath } = context;
                
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }

        return (<React.Fragment>
            <IconButton ref={this.iconButtonRef} onClick={this.handleIconButtonClick}>
                <FileCopy />
            </IconButton>
            <Menu anchorEl={this.iconButtonRef.current} open={this.state.open} onClose={this.handleClose}>
                <MenuItem onClick={this.handleCopyClick}>Copy</MenuItem>
                <MenuItem disabled={this.hasCompatibleClipboard()} onClick={this.handlePasteClick}>Ovewrite</MenuItem>
            </Menu>
        </React.Fragment>);
    }
}
import * as React from 'react';
import { serviceLocator, AuthenticatedUserData } from '../services';

const uiService = serviceLocator.getUIService();
const authenticationService = serviceLocator.getAuthenticationService();

export type Test = (user: AuthenticatedUserData)=>boolean;

export const Tests = {
    hasRole: (role: string) => (user: AuthenticatedUserData) => {
        return user.roles.indexOf(role as any)!==-1;
    },
    belongsToAgency: (agency: string) => (user: AuthenticatedUserData) => { return user.agencies!=null && user.agencies.indexOf(agency)!==-1; },
    belongsToAgencyAsAgent: (agency?: string) => (user: AuthenticatedUserData) => { 
        const selectedAgency = agency ? agency : uiService.getSelectedAgency() || 'all';
        const selectedAgencyData = user.containedAgencies ? user.containedAgencies.find(item => item.identifier == selectedAgency) : false
        const isAgent = selectedAgencyData ? selectedAgencyData.users.some(item => item.AgencyUser.role == 'agent') : false;
  
        return isAgent; 
    },
    isAgencyOwner: (agency?: string) => (user: AuthenticatedUserData) => { 
        console.log('agency, agency', agency, user.ownedAgency)
        const selectedAgency = agency ? agency : uiService.getSelectedAgency() || 'all';
        return user.ownedAgency == selectedAgency; 
    },
    isDeployer: (website:any) => (user: AuthenticatedUserData) => { 
        console.log('website', website)
        if(website) {
            const authUserData = authenticationService.getAuthenticatedUserData();
            const user = website.users.find((item:any) => item.username == authUserData?.username)
            return user.WebsiteUser.roles.indexOf('deployer') > -1
        }
        return false
    },
    hasAnyAgency: () => (user: AuthenticatedUserData) => { return user.agencies!=null&&user.agencies.length > 0; },
    and: (tests: Array<Test>) => (user: AuthenticatedUserData) => {
        for (let t = 0; t < tests.length; t++) {
            const test = tests[t];
            if(!test(user)) return false;
        }
        return true;
    },
    or: (tests: Array<Test>) => (user: AuthenticatedUserData) => {
        for (let t = 0; t < tests.length; t++) {
            const test = tests[t];
            if(test(user)) return true;
        }
        return false;
    }
}

export class RestrictedSection extends React.Component<{test: Test, forbidden?: React.ReactNode, render?: (forbbiden: boolean)=>React.ReactNode},any> {

    render(){
        const authUser = serviceLocator.getAuthenticationService().getAuthenticatedUserData();
        const forbidden = authUser==null||!this.props.test(authUser);
        if(this.props.render){
            return this.props.render(forbidden);
        }
        if(forbidden){
            return this.props.forbidden||(null);
        }
        return this.props.children||(null);
    }
}

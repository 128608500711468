import * as React from 'react';
import { RestrictedSection, Tests } from './RestrictedSection';

export class AdminOrAgencyownerOrAgentOrDeployerSection extends React.Component<{agency?: string, website: any, forbidden?: React.ReactNode, render?: (forbbiden: boolean)=>React.ReactNode},any> {

    render(){
        const { agency, website, ...rest } = this.props;
        return (<RestrictedSection
            test={Tests.or([
                Tests.hasRole('admin'),
                Tests.belongsToAgencyAsAgent(),
                Tests.isAgencyOwner(agency),
                Tests.isDeployer(website),
            ])}
            {...rest}
        />);
    }
}

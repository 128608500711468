import axios from 'axios';

export class InfraCredentialService{
    public async listCredentials(agency?: string): Promise<{credentials: Array<{_id: string, name: string, roles:string[]}>}>{
        const result = await axios.get(`/api/infra-credentials/${agency?`?agency=${agency}`:''}`);
        return result.data;        
    }

    public async putCredential(cred: any): Promise<any>{
        const result = await axios.post(`/api/infra-credentials/put/`, cred);
        return result.data;
    }

    public async purgeCredential(id: string): Promise<void>{
        const result = await axios.post(`/api/infra-credentials/purge/`, {id});
        return result.data;
    }
}
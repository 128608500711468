import * as React from 'react';
import { InfraCredentialList } from './components/InfraCredentialList';
import { NotAllowed } from '../NotAllowed';
import { AppSection } from '../../components/AppSection';
import { AdminOrAgencyMemberSection } from '../../components/AdminOrAgencyMemberSection';


export class InfraCredentials extends React.Component<any,any>{
    
    public render(){
        return (<React.Fragment>
            <AppSection section="infra-credentials" />
            <AdminOrAgencyMemberSection forbidden={<NotAllowed />}><InfraCredentialList /></AdminOrAgencyMemberSection>
        </React.Fragment>)
    }
}
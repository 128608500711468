import * as React from 'react';
import { RestrictedSection, Tests } from './RestrictedSection';

export class RoleSection extends React.Component<{role: 'admin', forbidden?: React.ReactNode, render?: (forbbiden: boolean)=>React.ReactNode},any> {

    render(){
        const { role, ...rest } = this.props;
        return (<RestrictedSection
            test={Tests.hasRole(role)}
            {...rest}
        />);
        
    }
}

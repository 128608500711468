const pthSplitter = /[.]|\[|\]/g;
const pthNotEmpty = (v:string)=> !!v;
export const pth = (expression: string, obj: any) => {
    if(obj==null){ return null; }
    const keys = expression.split(pthSplitter).filter(pthNotEmpty);
    let current = obj;
    for(const key of keys){
        current = current[key];
        if(current==null){ return null; }
    }
    return current;
}
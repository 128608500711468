import * as React from 'react';
import { ComponentProps, CrawlContext, NormalizeStateContext } from '../HoForm';
import { BaseDynamic } from '../HoForm';
import { TextField } from '@material-ui/core';
import { setValidationErrorIntoState, getValidationError } from '../HoForm/utils';
import InputAdornment from '@material-ui/core/InputAdornment';

type TextFieldDynamicField = {
    key: string,
    type: string,
    default?: string,
    multiline?: boolean,
    tip?: string,
    title?: string,
    required?: boolean,
    monospace?: boolean,
    pattern?: string,
    margin?: 'none' | 'dense' | 'normal',
    visible?: boolean,
    limit?: number
}

type TextFieldDynamicState = {
    limit?: number,
    current_length: number,
    hasError?: boolean
}

const INPUT_LABEL_PROPS = { shrink: true };

export class TextFieldDynamic extends BaseDynamic<TextFieldDynamicField, TextFieldDynamicState> {
    state: TextFieldDynamicState = {
        limit: this?.props?.context?.node?.field?.limit ? this?.props?.context?.node?.field?.limit : 0,
        current_length: this?.props?.context?.value?.length ? this?.props?.context?.value?.length : 0
    }

    constructor(props: ComponentProps<TextFieldDynamicField>) {
        super(props);
    }

    normalizeState({ state, field }: NormalizeStateContext<TextFieldDynamicField>) {
        let key = field.key;
        if (state[key] == null) {
            state[key] = field.default || '';
        }
    }

    getType() {
        return 'text-field';
    }

    handleChange = (e: any) => {
        this.forceUpdate();
        this.props.context.setValue(e.target.value, 250);
        this.setState({ current_length: e.target.value.length })
        console.log('handleChange')
    }

    crawlComponent({ form, node }: CrawlContext<TextFieldDynamicField>): void {
        const value = this.getValueFromNode(node);
        if (node.field.required || node.field.pattern) {
            let validationError = '';
            if (node.field.required) {
                const invalid = value == null || value === '';
                validationError += invalid ? `The field is required.` : '';
            }
            if (value && node.field.pattern) {
                if (!(new RegExp(node.field.pattern).test(value))) {
                    validationError += `The value format is invalid.`;
                }
            }
            if (node.field.limit) {
                const invalid = node.field.limit < value.length;
                validationError += invalid ? `The field is exceed the limit of characters.` : '';
            }

            setValidationErrorIntoState(node.state, form.buildDisplayPath(node), validationError);
        }
    }

    renderComponent() {

        let { context } = this.props;
        let { node, nodePath, currentPath, parentPath } = context;
        let { field } = node;

        if (!parentPath.startsWith(currentPath)) {
            return (null);
        }

        const error = getValidationError(node.state, nodePath);

        return (
            <>
                {field.visible == false ?
                    <></> :
                    <TextField
                        key={nodePath}
                        onChange={this.handleChange}
                        error={error != null}
                        value={context.value || ''}
                        multiline={field.multiline === true}
                        fullWidth={true}
                        margin={field.margin || 'dense'}
                        InputLabelProps={INPUT_LABEL_PROPS}
                        label={field.title}
                        helperText={([...error ? [error] : [], ...field.tip ? [field.tip] : []]).join(' ')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <span style={field?.limit ? { color: this.state.current_length + 8 > (this.state.limit ? this.state.limit : 0) ? 'red' : 'black' } : {}}>{field?.limit ? `${this.state.current_length}/${this.state.limit}` : ""}</span>
                            </InputAdornment>,
                            style: field.monospace === true ? { fontFamily: 'monospace' } : {}
                        }}

                    />
                }
            </>
        );
    }
}
import * as React from 'react';
import { NavigateNext } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Typography, withStyles, Paper } from '@material-ui/core';

const styles = (theme: any) => {

    return {

        root: {
            borderRadius: '3px',
            display: "flex",
            alignItems: "center",
            padding: '6px 8px'
        },
        paper: {
            background: 'white',
            boxShadow: '0 1px 3px rgba(0,0,0,.2)',
        },
        link: {
            fontSize: 14,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        leaf: {
            fontSize: 14,
        }
    };
}

type BreadcumbUnstyledProps = {
    paperStyle?: boolean;
    items: Array<{ to?: string, label: string } | null | undefined>;
    classes: any;
}

export class BreadcumbUnstyled extends React.Component<BreadcumbUnstyledProps, {}>{

    render() {

        let { items } = this.props;
        let newItems = [];

        for (let i = 0; i < items.length; i++) {
            const data = items[i];
            if (data != null) {
                if (i > 0) {
                    newItems.push(<span key={`arrow-${i}`}> <NavigateNext style={{ fontSize: '14px', position: 'relative', top: '2px' }} /></span>)
                }
                if (data.to != null) {
                    newItems.push(<Typography
                        component={(props: any) => (<Link key={`item-${i}`} to={data.to} {...props} />)}
                        className={this.props.classes.link} color="textPrimary" key={`item-${i}`} variant="body1">{data.label}</Typography>);
                }
                else {
                    newItems.push(<Typography className={this.props.classes.leaf} key={`item-${i}`} variant="body1">{data.label}</Typography>);
                }
            }
        }

        return (<div className={`${this.props.classes.root} ${this.props.paperStyle !== false ? this.props.classes.paper : ""}`}>
            {newItems}
        </div>);
    }
}

export const Breadcumb = withStyles(styles)(BreadcumbUnstyled);
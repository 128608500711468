import * as React from 'react';
import { BaseForm } from './../../components/Websites/BaseForm';
import { serviceLocator } from '../../services';
import { Breadcumb } from '../../components/Breadcumb';

interface Props {
	websiteKey: string;
	themeKey: string;
	collectionKey: string;
	pageKey: string;
}

export class CollectionItemForm extends React.Component<Props>{

	render() {
		const service = serviceLocator.getWebsiteService();
		const { collectionKey, pageKey, websiteKey, themeKey } = this.props;
		return <BaseForm
			themeKey={themeKey}
			debug={false}
			title={<Breadcumb paperStyle={false} items={[
				{ to: `/websites/${themeKey}/`, label: `${themeKey} Websites` },
				{ to: `/websites/${themeKey}/${websiteKey}/`, label: `Website ${this.props.websiteKey}` },
				{ label: `Item ${pageKey}` }
			]} />}
			// value={data}
			websiteKey={websiteKey}
			getFormInitialData={() => service.getCollectionItem(websiteKey, collectionKey, pageKey)}
			saveData={(data: any) => service.updateCollectionItem(websiteKey, collectionKey, pageKey, data)}
			buildSite={() => service.buildWebsite(websiteKey)}
			getNote={() => service.getNote(websiteKey, `collection-${collectionKey}-${pageKey}`)}
			saveNote={(note: string) => service.setNote(websiteKey, `collection-${collectionKey}-${pageKey}`, note)}
		/>
	}
}
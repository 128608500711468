import * as React from 'react';
import { RawDataForm } from './RawDataForm';
import { serviceLocator } from '../../services';
import axios from 'axios';

const service = serviceLocator.getWebsiteService();

export const StaticFileStringEditor = (props: { websiteKey: string, path: string, assets: boolean }) => {
    let mode = props.path.replace(/.+[.]([^.]+$)/, '$1');
    if (mode === 'js') { mode = 'javascript'; }
    return (<RawDataForm
        title={props.path}
        mode={mode || 'text'}
        getData={async () => {
            const fullPath = `/api/websites/${props.websiteKey}/${props.assets ? "assets" : "static"}/${props.path.replace(/^\//, '')}`;
            const response = await axios.get(fullPath);
            return response.data;
        }}
        saveData={async (data: any) => {
            const fullPath = `/api/websites/${props.websiteKey}/${props.assets ? "assets" : "static"}/`;
            await axios.post(fullPath, { path: props.path, content: data });
        }}
        build={() => service.buildWebsite(props.websiteKey)}
    />)
}
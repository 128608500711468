import axios from 'axios';

export class DatabaseService{
    public async list(table: string): Promise<any[]>{
        const result = await axios.get(`/api/database/${table}`);
        return result.data;
    }

    public async listTables(): Promise<any[]>{
        const result = await axios.get(`/api/database/tables/`);
        return result.data;
    }

    public async update(table: string, data: any): Promise<any[]>{
        const result = await axios.post(`/api/database/${table}`, data);
        return result.data;
    }

    public async delete(table: string, data: any): Promise<any[]>{
        const result = await axios.delete(`/api/database/${table}/${data._id}`);
        return result.data;
    }

}
import * as React from 'react';
import { InputAdornment, Grow } from "@material-ui/core";
import { pth } from '../utils/pth';
import { PreviewThumbnail } from './PreviewThumbnail';
import { DebouncedTextField } from './DebouncedTextField';

export class FormikYoutubeTextField extends React.Component<any,any>{

    public render(){
        const {
            field, // { name, value, onChange, onBlur }
            form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            ...fProps
        } = this.props;
        const isTouched = pth(field.name,touched);
        const error = pth(field.name,errors);
        
        const handleBlur = (e: any) => {
            let value = e.currentTarget.value;
            if(this.isValidYoutubeLink(value)){
                const urlSeg = value.split(/[^?]+[?]/);
                const urlParams = new URLSearchParams(urlSeg[urlSeg.length-1]);
                value = urlParams.get('v');
                if(value!=null){
                    setFieldValue(field.name, value);
                }
            }
            else{
                field.onBlur(e);
            }
        }

        const endAdornment = field.value!=null && this.isValidYoutubeID(field.value) ? (
        <InputAdornment position="end">
            <Grow appear={true} in={true}><PreviewThumbnail
                component="a"
                href={`https://www.youtube.com/watch?v=${field.value}`}
                target="_blank"
                url={`https://img.youtube.com/vi/${field.value}/1.jpg`} />
            </Grow>
        </InputAdornment>
        ) : (null);

        return (
            <DebouncedTextField
            {...field}
            {...fProps}
            margin="dense"
            fullWidth={true}
            helperText={isTouched ? `${fProps.helperText||''} ${error||''}`.trim() : fProps.helperText} error={isTouched ? (error!=null) : false}
            onBlur={handleBlur}
            InputProps={{
                endAdornment
            }}
        />
        )
    }

    private isValidYoutubeLink(link: string): boolean{
        return /^(https?[:][/][/])?(www[.])?youtube[.]com/i.test(link);
    }

    private isValidYoutubeID(value: string): boolean{
        return /[a-zA-Z0-9_-]{11}/.test(value);
    }
};
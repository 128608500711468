import * as React from 'react';
import { Paper, Divider, List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { withRouter, RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps{
    paperProps?: any;
    singles: string[];
}

export const SingleList = withRouter(class SingleListInner extends React.Component<Props,any>{

    handleItemClick = (key: string) => (e: any)=>{
        this.props.history.push(`singles/${key}`);
    }

    render(){
        return (
            <Paper {...this.props.paperProps}>
                <List component="nav" subheader={<ListSubheader>Singles</ListSubheader>}>
                    <Divider />
                    {(this.props.singles||[]).map((single, i) => {
                        return (
                            <ListItem key={single}
                                onClick={this.handleItemClick(single)}
                                button={true}
                                divider={true}>
                                <ListItemText primary={single} />
                                {/* <ListItemSecondaryAction>
                                    <IconButton onClick={this.handleSingleMoreClick} data-key={single}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </ListItemSecondaryAction> */}
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
        )
    }
});
import * as React from 'react';
import { Route, Switch } from "react-router-dom"
import { Websites } from './Websites';
import { Form } from './Form';
import { Deploy } from './Deploy';
import { NotFound } from '../NotFound';
import { RoleSection } from '../../components/RoleSection';
import { NotAllowed } from '../NotAllowed';
import { AppSection } from '../../components/AppSection';
import { Website } from './Website';
import { PageForm } from './PageForm';

export class PrimeLendingWebsites extends React.Component<any,any>{

    public render(){
        return (<React.Fragment>
        <AppSection section="websites/prime-lending" />
        <Switch>
            <Route path={"/websites/prime-lending/:site/deploy/"} exact={true} render={this.renderDeploy} />
            <Route path={"/websites/prime-lending/:site/main-form"} exact={true} render={this.renderForm} />
            <Route path={"/websites/prime-lending/:site/"} exact={true} render={this.renderWebsite} />
            <Route path={"/websites/prime-lending/:site/pages/:page/"} exact={true} render={this.renderPage} />
            <Route path={"/websites/prime-lending/"} exact={true} component={Websites} />
            <Route path={"/websites/prime-lending/*"} component={NotFound} />
        </Switch>
        </React.Fragment>)
    }

    private renderForm = ({match}: any)=>{
        return (<Form websiteKey={match.params.site} />);
    }

    private renderPage = ({match}: any)=>{
        return (<PageForm websiteKey={match.params.site} collectionKey="page" pageKey={match.params.page} />);
    }

    private renderWebsite = ({match}: any)=>{
        return (<Website websiteKey={match.params.site} />);
    }

    private renderDeploy = ({match}: any)=>{
        return (<RoleSection role="admin" forbidden={<NotAllowed />} ><Deploy websiteKey={match.params.site} /></RoleSection>);
    }
}
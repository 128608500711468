import * as React from 'react';
import { Button, FormControl, Paper, TextField, Typography } from '@material-ui/core';
import { serviceLocator } from './../services/service-locator';
import { AuthenticationService } from '../services/authentication-service/types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { UIService } from '../services/ui-service';
import logo from './../logo.png';

interface LoginProps {
    authenticationService: AuthenticationService;
    uiService: UIService;
}

interface LoginState {
    authenticationFailed: boolean;
}

export class Login extends React.Component<LoginProps, LoginState>{
    
    constructor(props: LoginProps){
        super(props);
        this.state = {authenticationFailed: false};
    }

    public render(){
        return (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100%', background:'#f8f8f8'}}>
            <Paper style={{maxWidth: 400, padding:48, flex:1, margin: '1em'}}>
                <div style={{textAlign:'center'}}>
                    <img src={logo} style={{width:'100%', maxWidth:'100%', height:'auto' }} />
                </div>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    onSubmit={this.handleSubmit}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().required('Required'),
                        password: Yup.string().required('Required')
                    })}
                >
                {props => {
                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                    const usernameError = errors.username!=null && touched.username!=null ? errors.username : '';
                    const passwordError = errors.password!=null && touched.password!=null ? errors.password : '';
                    return (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <TextField name="username"
                                fullWidth={true}
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                margin={'normal'}
                                label={'Username *'}
                                error={!!usernameError}
                                helperText={usernameError}
                            />
                        </div>
                        <div>
                            <TextField name="password"
                                fullWidth={true}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="password"
                                margin={'normal'}
                                label={'Password *'}
                                error={!!passwordError}
                                helperText={passwordError}
                            />
                        </div>
                        <FormControl margin={'normal'} fullWidth={true}>
                            <Button type="submit" disabled={isSubmitting} variant="contained" size="large" color="primary" fullWidth={true}>Login</Button>
                        </FormControl>
                        {this.state.authenticationFailed===true && !isSubmitting && <Typography color="error" style={{textAlign:'center'}}>Username and password do not match.</Typography>}
                        {isSubmitting && <Typography color="textSecondary" style={{textAlign:'center'}}>Submitting...</Typography>}
                    </form>
                    );
                }}
            </Formik>
        </Paper>
    </div>)
    }

    private handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: (value: boolean)=>void }) =>{
        try{
            const authenticated = await this.props.authenticationService.authenticate(values);
            if(authenticated){
                this.props.uiService.forceUpdate();
            }
            else{
                this.setState({authenticationFailed: true});
            }
            setSubmitting(false);
        }
        catch(e){
            alert('An error has ocurred.');
            setSubmitting(false);
        }              
    }    
}

export const LoginSmart = () => {
    return <Login
        authenticationService={serviceLocator.getAuthenticationService()}
        uiService={serviceLocator.getUIService()}
    />
}
import axios from 'axios';
import { LogService } from './types';

export class DefaultLogService implements LogService{
    
    public async listLogs(): Promise<any[]>{
        const response = await axios.get('/api/logs',{});
        return response.data;
    }

    public async getLogContent(key: string): Promise<string>{
        const response = await axios.get(`/api/logs/${key}`,{});
        return response.data;
    }
    
}
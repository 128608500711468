import axios from 'axios';

export class ReviewService{
    public async getReviewsGroup(websiteKey: string): Promise<{ name: string, providers: Array<{ identifier: string }> }>{
        const result = await axios.get(`/api/reviews-group/${encodeURIComponent(websiteKey)}`);
        return result.data;
    }

    public async loadReviewsGroup(websiteKey: string): Promise<void>{
        const result = await axios.post(`/api/reviews-group/${encodeURIComponent(websiteKey)}/load`);
        return result.data;
    }

    public async createReviewsGroup(websiteKey: string): Promise<void>{
        const result = await axios.post(`/api/reviews-group/${encodeURIComponent(websiteKey)}/create`);
        return result.data;
    }

    public async addProviderToReviewsGroup(websiteKey: string, identifier: string): Promise<void>{
        const result = await axios.post(`/api/reviews-group/${encodeURIComponent(websiteKey)}/add-provider`, { identifier });
        return result.data;
    }

    public async removeProviderFromReviewsGroup(websiteKey: string, identifier: string): Promise<void>{
        const result = await axios.post(`/api/reviews-group/${encodeURIComponent(websiteKey)}/remove-provider`, { identifier });
        return result.data;
    }

    public async getProviderIdentifierSuggestions(): Promise<{suggestions:Array<{name: string, identifier: string}>}>{
        const result = await axios.get(`/api/reviews-group/identifier-suggestions/`);
        return result.data;
        
    }
}
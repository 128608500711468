import axios from 'axios';

export class UserService{
    public async listUsers(active: boolean): Promise<{users: Array<any>}>{
        const result = await axios.get(`/api/users?active=${active}`);
        return result.data;        
    }

    public async listDesigners(): Promise<{users: Array<any>}>{
        const result = await axios.get(`/api/users/designers`);
        return result.data;        
    }

    public async getUser(username: string): Promise<any>{
        const result = await axios.get(`/api/users/${encodeURIComponent(username)}`);
        return result.data;
    }

    public async createOrUpdateUser(user: any): Promise<void>{
        let currentUser = null;
        try{
            currentUser = await this.getUser(user.username);
        }
        catch{}
        await axios.post(`/api/user/${currentUser!=null?'update':'create'}`, user);
    }
}
import * as React from 'react';
import { serviceLocator } from '../../services';
import { RawDataForm } from './../../components/Websites/RawDataForm';

const service = serviceLocator.getWebsiteService();

export const SingleFormRaw = (props: { websiteKey: string, singleKey: string }) => {
    return (<RawDataForm
        mode="json"
        getData={async () => {
            const data = await service.getSingle(props.websiteKey, props.singleKey)
            return JSON.stringify(data.value, null, '  ');
        }}
        saveData={(data: any) => service.putSingle(props.websiteKey, props.singleKey, JSON.parse(data))}
        build={() => service.buildWebsite(props.websiteKey)}
    />)
}

export const CollectionItemFormRaw = (props: { websiteKey: string, collectionKey: string, collectionItemKey: string }) => {
    return (<RawDataForm
        mode="json"
        getData={async () => {
            const data = await service.getCollectionItem(props.websiteKey, props.collectionKey, props.collectionItemKey);
            return JSON.stringify(data.value, null, '  ');
        }}
        saveData={(data: any) => service.updateCollectionItem(props.websiteKey, props.collectionKey, props.collectionItemKey, JSON.parse(data))}
        build={() => service.buildWebsite(props.websiteKey)}
    />)
}
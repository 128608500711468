import * as React from 'react';
import * as Yup from 'yup';
import { Divider, List, Paper, ListSubheader, ListItem, ListItemSecondaryAction, ListItemText, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Formik, Field } from 'formik';
import { serviceLocator } from '../../services';
import { FormikSelect } from '../../components/FormikSelect';
import { MainPadding } from '../../components/MainPadding';
import { Typography } from '@material-ui/core';
import { AppSection } from '../../components/AppSection';

const userService = serviceLocator.getUserService();
const agencyService = serviceLocator.getAgencyService();
const authenticationService = serviceLocator.getAuthenticationService();

interface Props {
  selectedAgency: string;
}

interface State {
  agencyUsers: Array<any>,
  usersOptions: Array<any>,
  agency: any | null;
  menuAnchor: any;
  userMenuOpen: boolean;
  userMenuKey: string;
  openDialog: string | null;
}

const dialogContentStyle: any = {
  minWidth: '300px'
}


export class AgencyMembers extends React.Component<Props, State>{

  state: State = {
    agency: [],
    usersOptions: [],
    agencyUsers: [],
    menuAnchor: null,
    userMenuOpen: false,
    userMenuKey: '',
    openDialog: null,
  }


  componentDidMount = async () => {
    this.reloadAgency();
  }

  handleOnCreateDialogOpen = async () => {
    const { users } = await userService.listUsers(true);
    const authUserData = authenticationService.getAuthenticatedUserData();
    this.setState({
      usersOptions: users.map(x => {
        return { value: x.username }
      }).filter(x => authUserData?.username != x.value)
    });
  }


  reloadAgency = async () => {
    const authUserData = authenticationService.getAuthenticatedUserData();
    if (this.props.selectedAgency) {
      const { agency } = await agencyService.getAgency(this.props.selectedAgency);
      this.setState({ agency });
      this.setState({ agencyUsers: agency.users });
    }
  }

  private restoreScroll() {
    // fix
    setTimeout(() => {
      document.body.style.overflow = "";
    }, 100);
  }

  private handleNewClick = async (e: any) => {
    this.setState({ openDialog: 'new' });
    this.handleOnCreateDialogOpen();
  }

  handleUserDelete = async (e: any) => {
    this.setState({ userMenuOpen: false });
    await agencyService.removeUser(this.state.agency.identifier, {username: this.state.userMenuKey});
    this.reloadAgency();

  }

  private handleUserMoreClick = (e: any) => {
    const target = e.currentTarget;
    this.setState({ userMenuOpen: true, menuAnchor: target, userMenuKey: target.dataset.key });
  }

  private handleDialogClose = (e: any) => {
    this.setState({ openDialog: null });
  }

  handlePutUserFormSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      await agencyService.addUser(this.state.agency.identifier, { username: values.username, role: values.role });
      this.reloadAgency();
      setSubmitting(false);
      resetForm();
      this.setState({ openDialog: null });
    }
    catch {
      setSubmitting(false);
      alert('An error has ocurred');
    }
  }

  private handleMenuClose = (e: any) => {
    this.setState({ userMenuOpen: false });
  }

  renderDialog() {
    return (
      <Formik
        initialValues={{ username: '', role: "designer" }}
        onSubmit={this.handlePutUserFormSubmit}
        validationSchema={
          Yup.object().shape({
            username: Yup.string().required('Required.'),
            role: Yup.string().required('Required.')
          }).required()
        }>
        {props => {
          const { handleSubmit, isSubmitting, submitForm } = props;

          return (
            <Dialog
              onClose={this.handleDialogClose}
              onExited={this.restoreScroll}
              open={this.state.openDialog === 'new'}>
              <DialogTitle style={dialogContentStyle}>Add New User</DialogTitle>
              <DialogContent style={dialogContentStyle}>
                <form onSubmit={handleSubmit}>
                  <Field
                    name="username" label="User"
                    component={FormikSelect}
                    options={this.state.usersOptions}
                  />
                  <Field
                    name="role" 
                    label="Role"
                    component={FormikSelect}
                    default
                    options={[{value: 'designer'}, {value: 'agent'}]}
                  />
                  <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{ width: 1, height: 1, visibility: 'hidden' }}>Create</button>
                </form>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                  value="create"
                  onClick={submitForm}>Add</Button>
              </DialogActions>
            </Dialog>

          )
        }}
      </Formik>
    );
  }

  renderMenu() {
    return (
      <Menu
        key="user-menu"
        open={this.state.userMenuOpen}
        anchorEl={this.state.menuAnchor}
        onClose={this.handleMenuClose}
      >
        {/* <MenuItem component={this.currentJsonSchemaLink} >Open</MenuItem> */}
        <MenuItem onClick={this.handleUserDelete}>Delete</MenuItem>
      </Menu>
    );
  }

  render() {
    return (
      <MainPadding>
        <AppSection section="themePanel" />
        <Typography variant="h6">Agency Members</Typography><br />

        <Paper style={{ maxWidth: "400px", }}>
          <List component="nav" subheader={<ListSubheader>Members</ListSubheader>}>
            <Divider />
            {this.state.agencyUsers.map((user, i) => {
              return (
                <ListItem key={user.username}
                  divider={true}>
                  <ListItemText primary={user.username} secondary={user.AgencyUser?.role} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={this.handleUserMoreClick} data-key={user.username}>
                      <MoreVertIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </Paper>
        <div style={{ padding: '16px 0' }}>
          <span> </span><Button onClick={this.handleNewClick} variant='contained' color='primary'>Add Member</Button>
        </div>
        {this.renderDialog()}
        {this.renderMenu()}
      </MainPadding>
    )
  }
}
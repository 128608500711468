import * as React from 'react';
import { FormikTextField } from './FormikTextField';

const INPUT_PROPS = {style:{fontFamily:'monospace'}};

export class FormikRawInclude extends React.Component<any,any>{
    public render(){
        return (
        <FormikTextField {...this.props} multiline={true} inputProps={INPUT_PROPS} />
        );
    }
};
import * as React from 'react';
import { List, Button, Popover, TextField, Fade, withStyles } from '@material-ui/core';
import { CreateNewFolder, CloudUpload, NavigateNext } from '@material-ui/icons';
import { MainPadding } from './../MainPadding';
import backgroundImage from './../grid.png';
import { FileManagerListItem } from './FileManagerListItem';
import TransformImageDialog from './TransformImageDialog';
import axios from 'axios';

export function isImagePath(path: string) {
  return /[.](png|jpg|jpeg|svg|gif)$/.test(path);
}

export interface FileManagerProps {
  getItems: () => Promise<Array<{ path: string, size: any }>>;
  uploadSingleFile: (e: any, file: string, name: string, folder: string, imagifySourceUrl: string) => Promise<any>;
  deleteItem: (e: any, name: string) => Promise<void>;
  createFolder: (e: any, folder: string) => Promise<void>;
  onPick: ((filePath: string) => void) | null;
  getStaticUrl: (path: string) => string;
  renamePath: (from: string, to: string) => Promise<void>;
  transformImage: (data: any) => Promise<void>;
}

interface FileManagerState {
  newFolder: string;
  items: Array<{ path: string, size: any }>;
  uploading: boolean;
  targetFolder: string;
  newFolderOpen: boolean;
  copyAnchor: any;
  copyValue: string;
  previewAnchorEl: any;
  previewPath: string;
  duplicateTo: string,
  duplicateFrom: string,
  duplicateOpen: boolean,
  renameOpen: boolean;
  renameFrom: string;
  renameTo: string;
  popupAnchorEl: any;
  transformOpen: boolean;
  transformPath: string;
}

const styles = (theme: any) => ({
  inputFileStyle: { width: '1px', height: '1px', visibility: 'hidden' as any, position: 'absolute' as any },
  newFolderContainerStyle: { minWidth: '200px' },
  breadcumbWrapperStyle: { background: '#eeeeee', padding: '4px', marginTop: '8px' },
  breadcumbButtonStyle: { textTransform: 'none', minWidth: '30px' } as any,
  buttonUploadFileStyle: { marginRight: 8 } as any,
  iconInsideButtronStyle: { marginLeft: 8 } as any,
});

class FileManager extends React.Component<FileManagerProps & { classes: any }, FileManagerState>{

  state = {
    newFolder: '',
    items: [] as Array<{ path: string, size: any }>,
    uploading: false,
    targetFolder: '/',
    newFolderOpen: false,
    copyAnchor: null,
    copyValue: '',
    previewAnchorEl: null,
    previewPath: '',
    popupAnchorEl: null,
    renameTo: '',
    renameFrom: '',
    renameOpen: false,
    duplicateTo: '',
    duplicateFrom: '',
    duplicateOpen: false,
    transformOpen: false,
    transformPath: '',
  }

  private fileInputRef: any;
  private uploadFormRef: any;
  private buttonNewFolderRef: any;

  componentDidMount = async () => {
    this.loadItems();
  }

  loadItems = async () => {
    let items = await this.props.getItems();
    items = items.sort((a, b) => {
      const aMod = a.path.endsWith('/') ? ('____' + a.path) : a.path;
      const bMod = b.path.endsWith('/') ? ('____' + b.path) : b.path;
      return aMod.localeCompare(bMod);
    });
    this.setState({ items, uploading: false });
  }

  handleUploadFileChange = async (e: any) => {
    e.persist()
    this.setState({ uploading: true });
    try {
      await this.uploadStaticFile(e, this.state.targetFolder);
      this.uploadFormRef.reset();
      await this.loadItems();
    }
    catch (e) {
      //report fail
      this.setState({ uploading: false });
    }
  }

  handleNewFolderClick = (e: any) => {
    this.setState({ newFolderOpen: true });
  }

  handleFileInputRef = (ref: any) => {
    this.fileInputRef = ref;
  }

  handleButtonUploadClick = (e: any) => {
    this.fileInputRef.click();
  }

  handleButtonNewFolderRef = (ref: any) => {
    this.buttonNewFolderRef = ref;
  }

  handleNewFolderSubmit = async (e: any) => {
    e.preventDefault();
    await this.props.createFolder(e, this.state.targetFolder + this.state.newFolder);
    await this.loadItems();
    this.setState({ newFolder: '', newFolderOpen: false });
  }

  handleNewFolderChange = (e: any) => {
    this.setState({ newFolder: e.target.value });
  }

  handleNewFolderClose = (e: any) => {
    this.setState({ newFolder: '', newFolderOpen: false });
  }

  getHandleItemClick = (value: string) => (e: any) => {
    if (value.endsWith('/')) {
      //folder
      this.setState({ targetFolder: value });
    }
    else if (this.props.onPick != null) {
      this.props.onPick(value);
    }
    else {
      window.open(this.props.getStaticUrl(value), '_blank');
    }
  }

  handleItemDelete = async (e: any) => {
    await this.props.deleteItem(e, e.currentTarget.dataset.value);
    this.loadItems();
  }

  handleItemCopyClick = (e: any) => {
    this.setState({ copyAnchor: e.currentTarget, copyValue: e.currentTarget.dataset.value })
  }

  handleCopyAreaClose = (e: any) => {
    this.setState({ copyValue: '' });
  }

  handleUploadFormRef = (ref: any) => {
    this.uploadFormRef = ref;
  }


  handleBackToRootClick = (e: any) => {
    this.setState({ targetFolder: '/' })
  }

  private uploadStaticFile: (e: any, folder: string) => Promise<any> = async (e: any, folder) => {
    const name = e.target.name;
    const promises: Array<Promise<any>> = []
    for (let index = 0; index < e.target.files.length; index++) {
      const file = e.target.files[index];

      var bodyFormData = new FormData();
      bodyFormData.append('image', file);

      try {
        if(e.target.files[0] && e.target.files[0]['type'].split('/')[0] !== 'image'){
          throw "Unsupported file extension";
        }
  
        const response = await axios.post("https://app.imagify.io/api/upload/", bodyFormData, 
            { headers: {"Authorization": `token ${process.env.REACT_APP_IMAGIFY_TOKEN}`, "Content-Type": "multipart/form-data"} });
        
        const promise = this.props.uploadSingleFile(e, file, name, folder, response.data.image);
        promises.push(promise);
      } catch (error:any) {
        const promise = this.props.uploadSingleFile(e, file, name, folder, 'optimized-already');
        promises.push(promise);
      }
    }

    return Promise.all(promises);
  }

  renderBreadcumb(path: string) {
    const breadcumb = [<Button key="root" size="small" onClick={this.handleBackToRootClick} className={this.props.classes.breadcumbButtonStyle}>.</Button>];
    let currentFragment = '/';
    path.replace(/(\/$|^\/)/m, '').split('/').forEach((fragment: string, i: number) => {
      if (fragment) {
        breadcumb.push(<span key={`next-${i}`}> <NavigateNext style={{ fontSize: '16px', position: 'relative', top: '4px' }} /> </span>);
        currentFragment += (fragment + '/');
        let targetFolder = currentFragment;

        breadcumb.push(<Button
          key={`path-${i}`}
          onClick={() => this.setState({ targetFolder })}
          className={this.props.classes.breadcumbButtonStyle} size="small">{fragment}</Button>
        );
      }
    })
    return breadcumb;
  }

  handleItemActiveChange = (active: boolean, previewAnchorEl: HTMLElement | null, previewPath: string) => {
    if (active) {
      this.setState({ previewAnchorEl, previewPath });
    }
    else {
      this.setState({ previewAnchorEl: null });
    }
  }

  closePreview = (e: any) => {
    this.setState({ previewAnchorEl: null });
  }

  handleRenameDialogClose = (e: any) => {
    this.setState({ renameOpen: false });
  }

  handleRenameClick = (e: any) => {
    this.setState({
      renameFrom: e.currentTarget.dataset.value,
      renameTo: e.currentTarget.dataset.value,
      popupAnchorEl: e.currentTarget,
      renameOpen: true
    });
  }

  handleRenameChange = (e: any) => {
    this.setState({
      renameTo: e.target.value,
    });
  }

  handleRenameSubmitClick = async (e: any) => {
    if (e) { e.preventDefault(); }
    try {
      await this.props.renamePath(this.state.renameFrom, this.state.renameTo);
      this.loadItems();
    }
    catch (e) {
      throw e;
    }
    finally {
      this.setState({ renameOpen: false });
    }
  }

  handleResizeClick = async (e: any) => {
    this.setState({ transformOpen: true, transformPath: e.currentTarget.dataset.value });
  }

  handleTransformClose = (e: any) => {
    this.setState({ transformOpen: false });
  }

  handleTransformExited = (e: any) => {
    this.setState({ transformPath: '' });
  }

  handleTransform = async (data: any) => {
    try {
      await this.props.transformImage(data);
      setTimeout(this.loadItems, 100);//give some time to let the image file settle down
      this.setState({ transformOpen: false });
    }
    catch (e) { throw e; }
  }

  //duplicate methods

  handleDuplicateClick = (e: any) => {
    this.setState({
      renameFrom: e.currentTarget.dataset.value,
      renameTo: e.currentTarget.dataset.value,
      popupAnchorEl: e.currentTarget,
      renameOpen: true
    });
  }

  handleDuplicateChange = (e: any) => {
    this.setState({
      renameTo: e.target.value,
    });
  }

  handleDuplicateSubmitClick = async (e: any) => {
    if (e) { e.preventDefault(); }
    try {
      //await this.props.duplicatePath(this.state.renameFrom, this.state.renameTo);
      this.loadItems();
    }
    catch (e) {
      throw e;
    }
    finally {
      this.setState({ duplicateOpen: false });
    }
  }

  handleDuplicateDialogClose = (e: any) => {
    this.setState({ duplicateOpen: false });
  }

  render() {
    const { classes } = this.props;
    return (<div style={{ minWidth: '30vw' }}>
      <form ref={this.handleUploadFormRef}>
        <input ref={this.handleFileInputRef} multiple={true} className={classes.inputFileStyle} type="file" onChange={this.handleUploadFileChange} />
        <Button
          disabled={this.state.uploading || this.props.onPick != null}
          onClick={this.handleButtonUploadClick}
          variant="contained"
          className={classes.buttonUploadFileStyle}
          color="primary"
        >
          Upload File <CloudUpload className={classes.iconInsideButtronStyle} />
        </Button>
        <Button
          disabled={this.state.uploading || this.props.onPick != null}
          buttonRef={this.handleButtonNewFolderRef}
          onClick={this.handleNewFolderClick}
          color="primary"
        >
          New Folder <CreateNewFolder className={classes.iconInsideButtronStyle} />
        </Button>
      </form>

      <div className={classes.breadcumbWrapperStyle}>
        {this.renderBreadcumb(this.state.targetFolder)}
      </div>

      <List component="nav">
        {this.state.items.filter(x => x.path.startsWith(this.state.targetFolder)).map((x: { path: string, size: any }, i: number) => {
          return (<FileManagerListItem
            key={`file-${i}`}
            onClick={this.getHandleItemClick(x.path)}
            onActiveChange={this.handleItemActiveChange}
            filePath={x.path}
            fileSize={x.size}
            pickOnly={this.props.onPick != null}
            onDeleteClick={this.handleItemDelete}
            onCopyPathClick={this.handleItemCopyClick}
            targetFolder={this.state.targetFolder}
            onRenameClick={this.handleRenameClick}
            onResizeClick={this.handleResizeClick}
          />);
        })}
      </List>

      <Popover
        key="new-folder"
        open={this.state.newFolderOpen}
        anchorEl={this.buttonNewFolderRef}
        onClose={this.handleNewFolderClose}
      >
        <MainPadding>
          <form className={classes.newFolderContainerStyle} onSubmit={this.handleNewFolderSubmit}>
            <TextField value={this.state.newFolder} onChange={this.handleNewFolderChange} margin="dense" fullWidth={true} label="Folder" />
            <Button type="submit" fullWidth={true}>Create</Button>
          </form>
        </MainPadding>
      </Popover>

      <Popover
        key="copy-area"
        open={!!this.state.copyValue}
        anchorEl={this.state.copyAnchor}
        onClose={this.handleCopyAreaClose}
      >
        <MainPadding>
          <form className={classes.newFolderContainerStyle}>
            <TextField value={this.state.copyValue} onFocus={(e) => e.currentTarget.select()} autoFocus={true} margin="dense" fullWidth={true} label="Copy" />
          </form>
        </MainPadding>
      </Popover>

      <Popover
        key="rename"
        open={this.state.popupAnchorEl != null && this.state.renameOpen}
        anchorEl={this.state.popupAnchorEl}
        onClose={this.handleRenameDialogClose}
      >
        <MainPadding>
          <form className={classes.newFolderContainerStyle} onSubmit={this.handleRenameSubmitClick}>
            <TextField onChange={this.handleRenameChange} value={this.state.renameTo} onFocus={(e) => e.currentTarget.select()} autoFocus={true} margin="dense" fullWidth={true} label="Path" />
            <div>
              <Button type="submit" color="primary" variant="contained">Rename</Button>
            </div>
          </form>
        </MainPadding>
      </Popover>

      <Popover
        key="duplicate"
        open={this.state.popupAnchorEl != null && this.state.duplicateOpen}
        anchorEl={this.state.popupAnchorEl}
        onClose={this.handleDuplicateDialogClose}
      >
        <MainPadding>
          <form className={classes.newFolderContainerStyle} onSubmit={this.handleDuplicateSubmitClick}>
            <TextField onChange={this.handleDuplicateChange} value={this.state.duplicateTo} onFocus={(e) => e.currentTarget.select()} autoFocus={true} margin="dense" fullWidth={true} label="Path" />
            <div>
              <Button type="submit" color="primary" variant="contained">Duplicate</Button>
            </div>
          </form>
        </MainPadding>
      </Popover>

      <TransformImageDialog
        open={this.state.transformOpen}
        path={this.state.transformPath}
        onClose={this.handleTransformClose}
        onExited={this.handleTransformExited}
        transform={this.handleTransform}
      />

      {this.state.previewAnchorEl != null && <Popover
        key={this.state.previewPath}
        style={{ pointerEvents: 'none' }}
        anchorEl={this.state.previewAnchorEl}
        open={isImagePath(this.state.previewPath)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={this.closePreview}
        TransitionComponent={Fade}
        disableRestoreFocus
      >
        <div style={{ background: `white url(${backgroundImage})` }}>
          <div style={{
            width: '120px',
            height: '120px',
            background: `url(${this.props.getStaticUrl(this.state.previewPath)}) no-repeat center center`,
            backgroundSize: "contain"
          }} />
        </div>
      </Popover>}

    </div>);
  }
}

export default withStyles(styles, { withTheme: true })(FileManager);
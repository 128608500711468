import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import logo from './../logo.png';
import { AgencyPicker } from './AgencyPicker';
import { AgencyLogo } from './AgencyLogo';
import { darkTheme } from '../themes';
import { serviceLocator } from '../services/service-locator';

const authenticationService = serviceLocator.getAuthenticationService();
const drawerWidth = 280;

const styles = (theme: any) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: `calc(100% - ${drawerWidth}px)`
    }
  },
});

interface ResponsiveDrawerProps {
  classes: any;
  theme: any;
  listItems: any;
  content: any;
}

interface ResponsiveDrawerState {
  mobileOpen: boolean;
}

class ResponsiveDrawer extends React.Component<ResponsiveDrawerProps, ResponsiveDrawerState> {

  private mounted: boolean = false;

  constructor(props: ResponsiveDrawerProps) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): any {

    const { classes, content, listItems, theme } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <div style={{ padding: '2em' }}><img src={logo} style={{ width: '100%', maxWidth: '100%', height: 'auto' }} /></div>
        <Divider />
        <List>
          {listItems}
        </List>
        <Divider />
      </div>
    );

    const authUserData = authenticationService.getAuthenticatedUserData();
    const hasAgency = authUserData?.ownedAgency !=null;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <MuiThemeProvider theme={darkTheme}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography style={{ flexGrow: 1 }} variant="h6" color="inherit" noWrap={true}>
                WebDrvn Static Website Manager
            </Typography>
              <AgencyPicker />

              {
              hasAgency && 
              <div style={{marginLeft: "8px"}}>
                <AgencyLogo avatar={true} />
              </div>
              }
            </Toolbar>
          </MuiThemeProvider>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp={true} implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClick={this.handleDrawerClick}
              id="mobile-menu"
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown={true} implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open={true}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {content}
        </main>
      </div>
    );
  }

  private handleDrawerToggle = () => {
    this.setState((state: any) => ({ mobileOpen: !state.mobileOpen }));
  };

  private handleDrawerClick = (e: any) => {
    if (this.state.mobileOpen) {
      setTimeout(() => this.mounted && this.setState({ mobileOpen: false }), 300);
    }
  }

}

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
import * as React from 'react';
import { Button, Typography, Grid, Paper, Divider } from '@material-ui/core';
import { Link } from "react-router-dom"
import { MainPadding } from '../../../components/MainPadding';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { PageList } from './PageList';
import { JsonSchemasList } from './JsonSchemaList';
import { Breadcumb } from '../../../components/Breadcumb';
import { FolderOutlined } from '@material-ui/icons';
import { SmartFileManagerDialog } from '../../../components/Websites/SmartFileManagerDialog';
import { UsersList } from '../../../components/Websites/UsersList/UsersList';
import { AliasesList } from '../../../components/Websites/AliasesList';
import { RoleSection } from '../../../components/RoleSection';
import { serviceLocator } from '../../../services';
import { AdminOrAgencyMemberSection } from '../../../components/AdminOrAgencyMemberSection';
import { AdminOrAgencyownerOrAgentOrDeployerSection } from '../../../components/AdminOrAgencyownerOrAgentOrDeployerSection';
import { ReviewsProvidersList } from '../../../components/ReviewsProvidersList';
import { QualityReportsList } from './QualityReportsList/QualityReportsList';
import { InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';

const genericWebsiteService = serviceLocator.getWebsiteService();
const paperProps = { style: { height: '100%' } };

interface WebsitesProps {
    websiteKey: string;
}

interface WebsiteState {
    fileManagerOpen: boolean;
    website: any;
    advancedExpanded: boolean;
}

export class Website extends React.Component<WebsitesProps, WebsiteState>{

    constructor(props: any) {
        super(props);
        this.state = {
            fileManagerOpen: false,
            website: null,
            advancedExpanded: false
        };
    }

    loadSite = async () => {
        const website = await genericWebsiteService.getWebsite(this.props.websiteKey);
        if (website.status == null) {
            website.status = "active"
        }
        this.setState({ website });
    }

    public componentDidMount = async () => {
        this.loadSite();
    }

    private globalPropsLink = React.forwardRef((props: any, ref: any) => {
        return <Link ref={ref} to={`main-form/`} {...props} />;
    });

    private homePropsLink = React.forwardRef((props: any, ref: any) => {
        return <Link ref={ref} to={`home/`} {...props} />;
    });

    handleFileManagerOpen = (e: any) => {
        this.setState({ fileManagerOpen: true });
    }

    toggleDailyCheck = async () => {
        await genericWebsiteService.toggleQualityChecks(this.props.websiteKey, this.state.website.enableQualityChecks !== true);
        this.loadSite();
    }

    handleOpenProductionClick = async () => {
        const data = await genericWebsiteService.getInfrastructureData(this.props.websiteKey);
        if (data?.domain != null) {
            window.open(`https://${data.domain}`, '_blank');
        }
        else {
            alert(`"${this.props.websiteKey}" is not deployed.`);
        }
    }

    handleStatusChange = async (event: any) => {
        this.setState({ website: { ...this.state.website, status: event.target.value } });
        const data = await genericWebsiteService.putStatus(this.props.websiteKey, event.target.value);

        this.loadSite();
    };

    public render() {

        if (this.state.website == null) return (null);

        // LIST + DIALOGS
        return (
            <MainPadding>
                <Button style={{ float: 'right', marginLeft: '4px' }} onClick={this.handleFileManagerOpen}>Static &nbsp; <FolderOutlined /></Button>
                {/* <Button style={{float: 'right', marginLeft:'4px'}} onClick={this.handleFileManagerOpen}>Assets Files &nbsp; <FolderOutlined /></Button> */}
                <Breadcumb items={[
                    { to: `/websites/skeleton-bootstrap-5-2/`, label: `Bootstrap 5.2` },
                    { label: `Website ${this.props.websiteKey}` }
                ]} />

                <br />
                <div style={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Button variant="contained" color="primary" component={this.globalPropsLink}>Site Config</Button>
                        &nbsp; <Button variant="contained" component={this.homePropsLink}>Home</Button>
                        &nbsp; <Button component={React.forwardRef((props: any, ref: any) => <a {...props} ref={ref} target="_blank" href={`http://skeleton-bootstrap-5-2--${this.props.websiteKey}.hugosites.webdrvn.com`} />)} variant="text" color="default">Preview</Button>
                        <AdminOrAgencyownerOrAgentOrDeployerSection website={this.state.website} agency={this.state.website.agency}>
                            &nbsp; <Button component={React.forwardRef((props: any, ref: any) => <Link ref={ref} {...props} to={`/websites/skeleton-bootstrap-5-2/${this.props.websiteKey}/deploy/`} />)} variant="text" color="default">Deploy</Button>
                            &nbsp; <Button onClick={this.handleOpenProductionClick}>Production Website</Button>
                        </AdminOrAgencyownerOrAgentOrDeployerSection>
                    </div>
                    <div>
                        <FormControl variant='outlined' style={{ minWidth: '120px', background: 'white' }}>
                            <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.website.status}
                                label="Status"
                                onChange={this.handleStatusChange}
                            >
                                <MenuItem value={'active'}>Active</MenuItem>
                                <MenuItem value={'archived'}>Archived</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>


                <br />

                <Grid container spacing={3} alignItems="stretch">
                    <Grid item lg={6} md={6} xs={12}>
                        <ErrorBoundary>
                            <PageList paperProps={paperProps} websiteKey={this.props.websiteKey} title={"Pages"}  collectionKey={"pages"} />
                        </ErrorBoundary>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <ErrorBoundary>
                            <PageList paperProps={paperProps} websiteKey={this.props.websiteKey} title={"Blogs"}  collectionKey={"blogs"} />
                        </ErrorBoundary>
                    </Grid>
                    {/* <Grid item md={4} xs={12}>
                        <ErrorBoundary>
                            <JsonSchemasList paperProps={paperProps} websiteKey={this.props.websiteKey} />
                        </ErrorBoundary>
                    </Grid> */}

                    <AdminOrAgencyMemberSection agency={this.state.website.agency}>
                        <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}></Grid>
                        {!this.state.advancedExpanded ? (
                            <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <Divider style={{ margin: '1.2rem 0' }} />
                                <Button variant="contained" onClick={() => this.setState({ advancedExpanded: true })}>Advanced Settings</Button>
                            </Grid>
                        ) : (
                            <React.Fragment>
                                <Grid item md={4} xs={12}>
                                    <ErrorBoundary>
                                        <UsersList paperProps={paperProps} websiteKey={this.props.websiteKey} />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <ErrorBoundary>
                                        <AliasesList paperProps={paperProps} websiteKey={this.props.websiteKey} />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <ErrorBoundary>
                                        <QualityReportsList
                                            dailyCheckEnabled={this.state.website.enableQualityChecks === true}
                                            onEnableDailyCheckToggle={this.toggleDailyCheck}
                                            agency={this.state.website.agency}
                                            websiteKey={this.props.websiteKey} />
                                    </ErrorBoundary>
                                </Grid>
                                <RoleSection role="admin">
                                    <Grid style={{visibility: 'hidden'}} item md={4} xs={12}>
                                        <ErrorBoundary>
                                            <ReviewsProvidersList websiteKey={this.props.websiteKey} />
                                        </ErrorBoundary>
                                    </Grid>
                                </RoleSection>
                            </React.Fragment>
                        )}
                    </AdminOrAgencyMemberSection>

                </Grid>

                <SmartFileManagerDialog
                    assets={false}
                    themeKey="skeleton-bootstrap-5-2"
                    websiteKey={this.props.websiteKey}
                    open={this.state.fileManagerOpen}
                    onClose={() => this.setState({ fileManagerOpen: false })}
                    onPick={null} />

            </MainPadding>
        )
    }
}
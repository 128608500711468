import * as React from 'react';
import { ListSubheader, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';

interface Props {
    title: string;
    onNewClick: (e: any)=> void;
}

export const ListSubheaderWithButtons = (props: Props) => {
    return (<ListSubheader>
        {props.onNewClick!=null && <IconButton style={{"float": "right"}} onClick={props.onNewClick}>
            <Add />
    </IconButton>}{props.title}</ListSubheader>
    )
}
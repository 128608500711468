import * as React from 'react';
import { CreateOrUpdateForm } from "../../../QualityCheckConfig/CreateOrUpdateForm";
import { Dialog, DialogContent } from '@material-ui/core';
import { serviceLocator } from '../../../../services';

const service = serviceLocator.getQualityCheckService();

interface Props {
    open: boolean;
    onClose: ()=>void;
    websiteKey: string;
    agency: string;
}

interface State {
    config: any;
    configLoaded: boolean;
}

export class SetupDialog extends React.Component<Props, State>{

    state = {
        config: null,
        configLoaded: false
    }

    componentDidMount = async () => {
        await this.load();
    }

    load = async ()=>{
        const config = await service.getConfigByWebsiteId(this.props.websiteKey);
        this.setState({config, configLoaded: true});
    }

    render(){
        const key = this.state.config||{id:'NEW'}.id;
        return (<Dialog scroll="paper" open={this.props.open} onClose={this.props.onClose} maxWidth="sm">
            <DialogContent>
                { this.state.configLoaded ? 
                    (<CreateOrUpdateForm
                        key={key} website={this.props.websiteKey} agency={this.props.agency} config={this.state.config} parentConfig={null} onSave={this.load} />)
                    : (<p>Loading...</p>)
                }
            </DialogContent>
        </Dialog>);
        
    }
}


import * as React from 'react';
import { HoForms, HoFormMaterial } from './../../ho-forms';
import { FolderOutlined } from '@material-ui/icons';
import { Paper, Typography, withStyles, Button } from '@material-ui/core';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { HoForm } from '../../_ho-forms-lib/src/HoForm';
import { dynamicComponents } from '../../ho-forms-custom/dynamic-components';
import { serviceLocator } from '../../services';
import { Prompt } from 'react-router';
import { SmartFileManagerDialog } from './../../components/Websites/SmartFileManagerDialog';
import { FormActions } from '../../components/FormActions';


const styles: (theme: any) => any = (theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 64px)',
    },
    content: {
      overflowY: 'auto',
      padding: '16px'
    },
    formPaper: {
      minWidth: '20em',
      padding: '16px',
      flex: 4
    },
    formHeader: {
      display: "flex",
      flex: '0 0 auto',
      alignItems: 'center',
      borderBottom: 'solid 1px rgba(0,0,0,0.1)',
      background: 'white'
    }
  }
};

interface BaseFormProps extends WithSnackbarProps {
  title: React.ReactNode;
  websiteKey: string;
  debug?: boolean;
  getData: () => Promise<any>;
  saveData: (data: any) => Promise<any>;
  buildSite: () => Promise<any>;
  getNote: () => Promise<any>;
  saveNote: (note: string) => Promise<any>;
  plugins?: any,
  classes: any;
  fields: any;
  includes: { [key: string]: any };
}

interface BaseFormState {
  changed: boolean;
  valid: boolean;
  value: any | null;
  fileManagerOpen: boolean;
  notes: string | null;
  handlePick: ((path: string) => void) | null;
  tab: string;
}

class BaseFormUnstyled extends React.Component<BaseFormProps, BaseFormState>{

  versionId: string | null = null;
  state: BaseFormState = { changed: false, valid: true, value: null, fileManagerOpen: false, notes: null, handlePick: null, tab: 'notes' };
  currentPath: string = "ROOT/";
  scrollMemory: { [path: string]: number } = {};
  getFormValue: () => any = () => this.state.value;
  hoFormRef = React.createRef<HoForm>();
  previewWindow: any;

  scrollEventListener = (e: any) => { this.scrollMemory[this.currentPath] = window.scrollY; }

  componentDidMount = async () => {

    window.addEventListener("scroll", this.scrollEventListener);

    const initialFormModel = await this.props.getData();
    const notes = await this.props.getNote() || '';

    this.setState({ value: initialFormModel || {}, notes }, () => {
      this.refreshIsValid();
    });
  }
  componentWillUnmount = () => { window.removeEventListener("scroll", this.scrollEventListener); }

  handleFormChange = (getFormValue: () => any) => {
    this.getFormValue = getFormValue;
    if (!this.state.changed) {
      this.setState({ changed: true });
    }
  }

  handleSaveNotes = async (content: string) => {
    this.props.saveNote(content);
    this.setState({ notes: content });
  }

  handleKeyDown = async (event: any) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === 's') {
      event.preventDefault();
      this.handleSaveClick(event);
    }
    else if (event.ctrlKey && charCode === 'b') {
      event.preventDefault();
      this.handleBuildClick(event);
    }
    else if (event.ctrlKey && charCode === 'p') {
      event.preventDefault();
      await this.handleBuildClick(event);
      if (this.previewWindow != null) this.previewWindow.close();
      this.previewWindow = window.open(`http://prime-lending--${this.props.websiteKey}.hugosites.webdrvn.com`, "prime-lending-preview");
    }
  }

  handlePathChange = (path: string) => {
    const currentPathLevel = this.currentPath.split('/').length;
    const newPathLevel = path.split('/').length;
    if (newPathLevel < currentPathLevel) {
      delete this.scrollMemory[currentPathLevel];
    }
    const lastY = this.scrollMemory[path];
    setTimeout(() => window.scrollTo(0, lastY || 0), 0);
    this.currentPath = path;
  }

  refreshIsValid = () => {
    setTimeout(() => {
      if (this.hoFormRef.current != null) {
        const valid = this.hoFormRef.current.isValid();
        if (this.state.valid != valid) {
          this.setState({ valid });
        }
      }
    }, 100);
  }

  handleSaveClick = async (e: any) => {
    try {
      const data = this.getFormValue();
      if (this.versionId != null) {
        data.versionId = this.versionId;
      }
      const { error, versionId } = await this.props.saveData(data);
      if (error) {
        alert(JSON.stringify(error));
        this.props.enqueueSnackbar("Failed to save.");
        return;
      }
      this.versionId = versionId;
      this.props.enqueueSnackbar("Saved successfully.");

      if (this.state.changed) {
        this.setState({ changed: false });
      }
    }
    catch (e) {
      console.log(e);
      this.props.enqueueSnackbar("Failed to save.");
    }
  }

  pickFile = async (onPick: (path: string) => void) => {
    this.setState({
      fileManagerOpen: true,
      handlePick: (path: string) => {
        onPick(path);
        this.handleFileManagerClose(null);
      }
    });
  }

  handleBuildClick = async (e: any) => {
    try {
      await this.handleSaveClick(e);
      const buildResult = await this.props.buildSite();
      if (buildResult.error) {
        alert(buildResult.error);
        this.props.enqueueSnackbar("Failed to build.");
      }
      else {
        this.props.enqueueSnackbar(`Website built successfully${(buildResult.time ? ` in ${buildResult.time} ms` : '')}.`);
      }
    }
    catch{
      this.props.enqueueSnackbar("Failed to build.");
    }
  }

  handleHardUpdate = () => {
    this.refreshIsValid();
  }

  private uploadImage: (e: any) => Promise<string> = async (e: any) => {
    const name = e.target.name;
    const dataSet: any = e.currentTarget.dataset || {};
    const file = await serviceLocator.getWebsiteService().uploadImage(
      this.props.websiteKey, e.target.files[0], name,
      { maxWidth: dataSet.maxWidth, maxHeight: dataSet.maxHeight }
    );
    return `/img/${file}`;
  }

  private resolveImgPreviewURL = (value: string) => {
    if (value.startsWith('http')) {
      return value;
    }
    else {
      if (value) {
        value = value.replace(/\{\{.+?\}\}/, '');
      }
      return `/api/websites/${encodeURIComponent(this.props.websiteKey)}/static/${encodeURI(value.replace(/^\//, ''))}`;
    }
  }

  private handleFileManagerClose = (e: any) => {
    this.setState({ fileManagerOpen: false, handlePick: null });
  }

  private handleFileManagerOpen = (e: any) => {
    this.setState({ fileManagerOpen: true });
  }

  render() {

    return (
      <div className={this.props.classes.root} onKeyDown={this.handleKeyDown}>

        <div className={this.props.classes.formHeader}>
          <div style={{ flex: 1 }}>{(typeof this.props.title === 'string') ? <Typography variant="h6">{this.props.title}</Typography> : this.props.title}</div>
          <Button size="small" style={{ flex: '0 0 150px' }} onClick={this.handleFileManagerOpen}>Static &nbsp; <FolderOutlined /></Button>
        </div>

        <div className={this.props.classes.content}>
          <Paper className={this.props.classes.formPaper}>{this.state.value != null && (
            <HoForms.HoForm
              ref={this.hoFormRef}
              includes={this.props.includes}
              plugins={{
                uploadImage: this.uploadImage,
                resolveImgPreviewURL: this.resolveImgPreviewURL,
                pickFile: this.pickFile,
                ...this.props.plugins
              }}
              breadcumbComponentType={HoFormMaterial.FormBreadcumb}
              debug={this.props.debug === true ? 'state' : undefined}
              rootName="Root"
              values={this.state.value}
              onChange={this.handleFormChange}
              onDebouncedChange={this.handleHardUpdate}
              onPathChange={this.handlePathChange}
              componentRegistry={new HoForms.ComponentRegistry(dynamicComponents)}
              fields={this.props.fields}
            />)}
          </Paper>
        </div>
        <Prompt when={this.state.changed} message="There are unsaved changes. Proceed anyway?" />
        <SmartFileManagerDialog
          themeKey="prime-lending"
          assets={false}
          websiteKey={this.props.websiteKey}
          open={this.state.fileManagerOpen}
          onClose={this.handleFileManagerClose}
          onPick={this.state.handlePick} />
        {this.state.value != null && (<FormActions
          websiteKey={this.props.websiteKey}
          templateKey="prime-lending"
          onSaveClick={this.handleSaveClick}
          onBuildClick={this.handleBuildClick}
          formIsValid={this.state.valid}
        />)}
      </div>
    );
  }
}

export const BaseForm = withSnackbar(withStyles(styles)(BaseFormUnstyled));
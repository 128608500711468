import * as React from 'react';
import { RestrictedSection, Tests } from './RestrictedSection';

export class AdminOrAgencyMemberSection extends React.Component<{agency?: string, forbidden?: React.ReactNode, render?: (forbbiden: boolean)=>React.ReactNode},any> {

    // render(){
    //     const { agency, ...rest } = this.props;
    //     return (<RestrictedSection
    //         test={Tests.or([
    //             Tests.hasRole('admin'),
    //             agency!=null ? Tests.belongsToAgency(agency) : Tests.hasAnyAgency()
    //         ])}
    //         {...rest}
    //     />);
        
    // }
    render(){
        const { agency, ...rest } = this.props;
        return (<RestrictedSection
            test={Tests.or([
                Tests.hasRole('admin'),
                Tests.belongsToAgencyAsAgent(agency),
                Tests.isAgencyOwner(agency)
            ])}
            {...rest}
        />);
        
    }
}

//@flow

import * as React from 'react';
import { FieldBase, BaseDynamic, NormalizeStateContext, ExtendFieldContext, CrawlContext } from '../HoForm';


type BorderLeftField  = {
    key: string,
    type: string,
    fields: Array<FieldBase>
};

export class BorderLeftDynamic extends BaseDynamic<BorderLeftField, {}> {

    allocateStateLevel(field : BorderLeftField, parentState : any, rootState : any){
        return parentState;
    }

    normalizeState({state, field, stateBuilder} : NormalizeStateContext<BorderLeftField>){
        stateBuilder.setLevelState(state, field.fields);                
    }

    extendField({field, extender} : ExtendFieldContext<BorderLeftField>): void{
        extender.extendFields(field.fields);            
    }

    getType(){
        return 'border-left';
    }

    buildBreadcumbFragment(node : any, buttons : Array<{label:string, node:any}>){
        
    }

    buildDisplayPathFragment(node : any){
        return null;
    }

    crawlComponent({form, node} : CrawlContext<BorderLeftField>): void{
        const { field, state } = node;
        form.crawlLevel({fields: field.fields, state, parent:node});
    }

    renderComponent(){      
        let {context} = this.props;
        let {node, currentPath, nodePath, parentPath} = context;
        let {field} = node;

        var state = node.state;
        
        if(currentPath===parentPath){
            return (
                <div style={{ paddingLeft: '1em', borderLeft: 'solid 0.5em #eeeeee' }}>
                    { context.form.renderLevel({ fields: field.fields, state, parent: node.parent as any }) }  
                </div>
            );
        }
        
        if(currentPath.startsWith(nodePath)){
            return (<React.Fragment>
                { context.form.renderLevel({ fields: field.fields, state, parent: node.parent as any }) }                    
            </React.Fragment>)
        }

        return (null);
    }
}


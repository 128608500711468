import * as React from 'react';
import { HoForms, HoFormMaterial } from './../../ho-forms';
import { FormActions } from '../../components/FormActions';
import { Paper, Typography, withStyles, Dialog, DialogTitle, DialogContent, Button, Tabs, Tab, Fade } from '@material-ui/core';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { HoForm } from '../../_ho-forms-lib/src/HoForm';
import { dynamicComponents } from '../../ho-forms-custom/dynamic-components';
import { FolderOutlined, ViewModuleOutlined } from '@material-ui/icons';
import { AutoSaveTextField } from '../../components/AutoSaveTextfield';
import { InfrastructureData, serviceLocator } from '../../services';
import { Prompt } from 'react-router';
import { SmartFileManagerDialog } from './SmartFileManagerDialog';
import { WebsiteVariables } from '../../components/WebsiteVariables';
import { SvgPicker } from '../../components/SvgPicker';
import axios from 'axios';

const styles: (theme: any) => any = (theme) => {
	return {
		root: {
			display: 'flex',
			flexDirection: 'column',
			height: 'calc(100vh - 64px)',
		},
		formPaper: {
			minWidth: '20em',
			padding: '16px',
			flex: 4
		},
		formHeader: {
			display: "flex",
			flex: '0 0 auto',
			alignItems: 'center',
			borderBottom: 'solid 1px rgba(0,0,0,0.1)',
			background: 'white'
		},
		verticalDivider: {
			minWidth: '16px',
			flex: 0,
		},
		leftColumn: {
			minWidth: '12em',
			flex: 2
		},
		tabs: {

		},
		notesPaper: {
			padding: '16px',
		},
		tabsPaper: {
			padding: '8px',
		},
		content: {
			flex: '1 1 auto',
			overflowY: 'auto',
			padding: '16px'
		},
		row: {
			display: 'flex',
			alignItems: 'flex-start',
		},
		notesTextField: {
			fontFamily: 'monospace'
		}
	}
};

interface BaseFormProps extends WithSnackbarProps {
	title: React.ReactNode;
	websiteKey: string;
	themeKey: string;
	debug?: boolean;
	getFormInitialData: () => Promise<InitialFormData>;
	saveData: (data: any) => Promise<any>;
	buildSite: () => Promise<any>;
	getNote: () => Promise<any>;
	saveNote: (note: string) => Promise<any>;
	plugins?: any,
	classes: any;
	pageVariablesPath?: string;
	getWebsiteInfrastructureData?: (websiteKey: string) => Promise<InfrastructureData | null>;
}

interface BaseFormState {
	changed: boolean;
	valid: boolean;
	formData?: any;
	fileManagerOpen: boolean;
	assetsFileManagerOpen: boolean;
	svgPickerOpen: boolean;
	notes: string | null;
	handlePick: ((path: string) => void) | null;
	tab: string;
	infrastructure: InfrastructureData | null
}

interface InitialFormData {
	value: any;
	fields: any;
	includes: { [key: string]: any }
}

class BaseFormUnstyled extends React.Component<BaseFormProps, BaseFormState>{

	versionId: string | null = null;
	state: BaseFormState = {
		changed: false,
		valid: true,
		fileManagerOpen: false,
		assetsFileManagerOpen: false,
		notes: null,
		handlePick: null,
		tab: 'notes',
		infrastructure: null,
		svgPickerOpen: false
	};
	currentPath: string = "ROOT/";
	scrollMemory: { [path: string]: number } = {};
	getFormValue: () => any = () => this.state.formData.value;
	hoFormRef = React.createRef<HoForm>();
	previewWindow: any;
	websiteVariablesRef: any;
	pageWebsiteVariablesRef: any;
	contentRef = React.createRef<HTMLDivElement>();

	componentDidMount = async () => {
		let infrastructure = null
		if (this.props.getWebsiteInfrastructureData) {
			try {
				
				infrastructure = await this.props.getWebsiteInfrastructureData(this.props.websiteKey);
			} catch (error) {
				
			}
		}

		const initialFormData = await this.props.getFormInitialData();
		const notes = await this.props.getNote() || '';

		this.setState({ formData: initialFormData, notes, infrastructure }, () => {
			this.refreshIsValid();
		});
	}

	componentWillUnmount = () => {

	}

	scrollEventListener = (e: any) => {
		this.scrollMemory[this.currentPath] = this.contentRef.current?.scrollTop || 0;
	}

	handleFormChange = (getFormValue: () => any) => {
		this.getFormValue = getFormValue;
		if (!this.state.changed) {
			this.setState({ changed: true });
		}
	}

	handleSaveNotes = async (content: string) => {
		this.props.saveNote(content);
		this.setState({ notes: content });
	}

	handleKeyDown = async (event: any) => {
		let charCode = String.fromCharCode(event.which).toLowerCase();
		if (event.ctrlKey && charCode === 's') {
			event.preventDefault();
			this.handleSaveClick(event);
		}
		else if (event.ctrlKey && charCode === 'b') {
			event.preventDefault();
			this.handleBuildClick(event);
		}
		else if (event.ctrlKey && charCode === 'p') {
			event.preventDefault();
			await this.handleBuildClick(event);
			if (this.previewWindow != null) this.previewWindow.close();
			this.previewWindow = window.open(`http://${this.props.themeKey}--${this.props.websiteKey}.hugosites.webdrvn.com`, `${this.props.websiteKey}-preview`);
		}
	}

	handlePathChange = (path: string) => {
		const currentPathLevel = this.currentPath.split('/').length;
		const newPathLevel = path.split('/').length;
		if (newPathLevel < currentPathLevel) {
			delete this.scrollMemory[currentPathLevel];
		}
		const lastY = this.scrollMemory[path];
		setTimeout(() => {
			this.contentRef.current?.scrollTo(0, lastY || 0)
		}
			, 1);
		this.currentPath = path;
	}

	refreshIsValid = () => {
		setTimeout(() => {
			if (this.hoFormRef.current != null) {
				const valid = this.hoFormRef.current.isValid();
				if (this.state.valid != valid) {
					this.setState({ valid });
				}
			}
		}, 100);
	}

	handleSaveClick = async (e: any) => {
		try {
			const data = this.getFormValue();
			if (this.versionId != null) {
				data.versionId = this.versionId;
			}
			const { error, versionId } = await this.props.saveData(data);
			if (error) {
				alert(JSON.stringify(error));
				this.props.enqueueSnackbar("Failed to save.");
				return;
			}
			this.versionId = versionId;
			this.props.enqueueSnackbar("Saved successfully.");
			if (this.websiteVariablesRef != null) {
				this.websiteVariablesRef.saveAndUpdate();
			}
			if (this.pageWebsiteVariablesRef != null) {
				this.pageWebsiteVariablesRef.saveAndUpdate();
			}
			if (this.state.changed) {
				this.setState({ changed: false });
			}
		}
		catch (e) {
			console.log(e);
			this.props.enqueueSnackbar("Failed to save.");
		}
	}

	handleBuildClick = async (e: any) => {
		try {
			await this.handleSaveClick(e);
			const buildResult = await this.props.buildSite();
			if (buildResult.error) {
				alert(buildResult.error);
				this.props.enqueueSnackbar("Failed to build.");
			}
			else {
				this.props.enqueueSnackbar(`Website built successfully${(buildResult.time ? ` in ${buildResult.time} ms` : '')}.`);
			}
		}
		catch {
			this.props.enqueueSnackbar("Failed to build.");
		}
	}

	handleHardUpdate = () => {
		this.refreshIsValid();
	}

	//image upload functions
	private pickFile = async (group: string, onPick: (path: string) => void) => {
		if (group == 'assets') {
			this.setState({
				assetsFileManagerOpen: true,
				handlePick: (path: string) => {
					onPick(path);
					this.handleAssetsFileManagerClose(null);
				}
			});
		}
		else {
			this.setState({
				fileManagerOpen: true,
				handlePick: (path: string) => {
					onPick(path);
					this.handleFileManagerClose(null);
				}
			});
		}
	}

	private uploadImage: (group: string, e: any) => Promise<string> = async (group, e) => {
		const name = e.target.name;
		var svc = serviceLocator.getWebsiteService();

		var bodyFormData = new FormData();
		bodyFormData.append('image', e.target.files[0]);
  
		try {
			if(e.target.files[0] && e.target.files[0]['type'].split('/')[0] !== 'image'){
				throw "Unsupported file extension";
			}

			const response = await axios.post("https://app.imagify.io/api/upload/", bodyFormData, 
				{ headers: {"Authorization": `token ${process.env.REACT_APP_IMAGIFY_TOKEN}`, "Content-Type": "multipart/form-data"} });
			
			const file = await svc.uploadStaticFile(
				this.props.websiteKey,
				e.target.files[0],
				name,
				'img',
				group == 'assets',
				response.data.image
			);
			return `/img/${file}`;
		} catch (error:any) {
			console.log('imagify error', error?.response?.data)
			const file = await svc.uploadStaticFile(
				this.props.websiteKey,
				e.target.files[0],
				name,
				'img',
				group == 'assets',
				'optimized-already'
			);
			return `/img/${file}`;
		}
	}

	private resolveImgPreviewURL = (group: string, value: string) => {
		if (value.startsWith('http')) {
			return value;
		}
		else {
			if (value) {
				value = value.replace(/\{\{.+?\}\}/, '');
			}
			return `/api/websites/${encodeURIComponent(this.props.websiteKey)}/${group}/${encodeURI(value.replace(/^\//, ''))}`;
		}
	}

	private handleFileManagerClose = (e: any) => {
		this.setState({ fileManagerOpen: false, handlePick: null });
	}

	private handleAssetsFileManagerClose = (e: any) => {
		this.setState({ assetsFileManagerOpen: false, handlePick: null });
	}

	private handleFileManagerOpen = (e: any) => {
		this.setState({ fileManagerOpen: true });
	}

	private handleAssetsFileManagerOpen = (e: any) => {
		this.setState({ assetsFileManagerOpen: true });
	}

	handleWebsiteVariablesRef = (ref: any) => {
		this.websiteVariablesRef = ref;
	}

	handleWebsitePageVariablesRef = (ref: any) => {
		this.pageWebsiteVariablesRef = ref;
	}

	handleTabChange = (e: any, tab: string) => {
		this.setState({ tab });
	}

	handleSvgPickerOpen = (e: any) => {
		this.setState({ svgPickerOpen: true })
	}

	render() {

		const queryDebug = /[&?]debug=/.test(window.location.search);

		const debug = (this.props.debug === true || queryDebug);
		if (debug) {
			console.log(this.state.formData);
		}

		return (
			<div className={this.props.classes.root} onKeyDown={this.handleKeyDown}>

				<div className={this.props.classes.formHeader}>
					<div style={{ flex: 1 }}>{(typeof this.props.title === 'string') ? <Typography variant="h6">{this.props.title}</Typography> : this.props.title}</div>
					<Button size="small" style={{ flex: '0 0 90px' }} onClick={this.handleSvgPickerOpen}>SVG &nbsp; <ViewModuleOutlined /></Button>
					<Button size="small" style={{ flex: '0 0 110px' }} onClick={this.handleAssetsFileManagerOpen}>Assets &nbsp; <FolderOutlined /></Button>
					<Button size="small" style={{ flex: '0 0 110px' }} onClick={this.handleFileManagerOpen}>Static &nbsp; <FolderOutlined /></Button>
				</div>

				<div className={this.props.classes.content} ref={this.contentRef} onScroll={this.scrollEventListener}>
					<SvgPicker onClose={() => this.setState({ svgPickerOpen: false })} open={this.state.svgPickerOpen} />
					<div className={this.props.classes.row}>
						<Paper className={this.props.classes.formPaper}>
							{this.state.formData != null && (
								<HoForms.HoForm
									ref={this.hoFormRef}
									plugins={{
										uploadImage: this.uploadImage,
										resolveImgPreviewURL: this.resolveImgPreviewURL,
										pickFile: this.pickFile,
										...this.props.plugins
									}}
									breadcumbComponentType={HoFormMaterial.FormBreadcumb}
									debug={debug ? 'state' : undefined}
									rootName="Root"
									onChange={this.handleFormChange}
									onDebouncedChange={this.handleHardUpdate}
									onPathChange={this.handlePathChange}
									componentRegistry={new HoForms.ComponentRegistry(dynamicComponents)}
									includes={this.state.formData.includes ?? {}}
									fields={this.state.formData.fields ?? []}
									values={this.state.formData.value || {}}
									websiteKey={this.props.websiteKey}
									infrastructure={this.state.infrastructure}
								/>)}
						</Paper>
						<div className={this.props.classes.verticalDivider}></div>
						<div className={this.props.classes.leftColumn}>
							<Paper>
								<Tabs
									className={this.props.classes.tabs}
									value={this.state.tab}
									onChange={this.handleTabChange}
									indicatorColor="primary"
									textColor="primary"
								>
									<Tab label="Notes" value="notes" />
									<Tab label="Global Variables" value="global-variables" />
									{this.props.pageVariablesPath && (<Tab label="Page Variables" value="page-variables" />)}
								</Tabs>
							</Paper>
							<br />
							{this.state.notes != null && this.state.tab === "notes" && (
								<Fade key="notes" in={true} appear>
									<Paper className={this.props.classes.notesPaper}>
										<Typography variant="subtitle2">Notes</Typography>
										<AutoSaveTextField
											variant="outlined"
											InputProps={{ className: this.props.classes.notesTextField }}
											save={this.handleSaveNotes}
											initialValue={this.state.notes}
											multiline={true}
											label=""
											fullWidth={true}
											helperText="Changes will be saved automatically. Use this as a scratch."
										/>
									</Paper>
								</Fade>
							)}
							{this.state.tab === "global-variables" && (<Fade key="variables" in={true} appear>
								<div>
									<WebsiteVariables
										key="global-variables"
										ref={this.handleWebsiteVariablesRef}
										websiteKey={this.props.websiteKey} />
								</div>
							</Fade>)}
							{this.state.tab === "page-variables" && (<Fade key="variables" in={true} appear>
								<div>
									<WebsiteVariables
										key="page-variables"
										ref={this.handleWebsitePageVariablesRef}
										path={this.props.pageVariablesPath}
										websiteKey={this.props.websiteKey} />
								</div>
							</Fade>)}

						</div>
					</div>
				</div>

				<Prompt when={this.state.changed} message="There are unsaved changes. Proceed anyway?" />

				<SmartFileManagerDialog
					assets={true}
					themeKey={this.props.themeKey}
					websiteKey={this.props.websiteKey}
					open={this.state.assetsFileManagerOpen}
					onClose={() => this.setState({ assetsFileManagerOpen: false })}
					onPick={this.state.handlePick} />

				<SmartFileManagerDialog
					assets={false}
					websiteKey={this.props.websiteKey}
					themeKey={this.props.themeKey}
					open={this.state.fileManagerOpen}
					onClose={this.handleFileManagerClose}
					onPick={this.state.handlePick} />

				{this.state.formData != null && (<FormActions
					websiteKey={this.props.websiteKey}
					templateKey={this.props.themeKey}
					onSaveClick={this.handleSaveClick}
					onBuildClick={this.handleBuildClick}
					formIsValid={this.state.valid}
				/>)}

			</div>
		);
	}
}

export const BaseForm = withSnackbar(withStyles(styles)(BaseFormUnstyled));
import * as React from 'react';
import { serviceLocator } from './../../services';
import { Websites as WebsitesInner } from './../../components/Websites';
import { Breadcumb } from '../../components/Breadcumb';

const service = serviceLocator.getWebsiteService();
const uiService = serviceLocator.getUIService();

export class Websites extends React.Component<{}, {}>{

    render() {
        const agency = uiService.getSelectedAgency();
        return (
            <WebsitesInner
                showAgencyTags={!agency}
                agency={agency}
                themeKey="skeleton-bootstrap-5-2"
                title={<Breadcumb items={[
                    { label: `Bootstrap 5.2` },
                ]} />}
                buildWebsite={service.buildWebsite}
                syncWebsite={(key: string) => service.syncWebsiteBucket(key, true)}
                listWebsites={(agency: string, status: string) => service.listWebsites(agency, 'skeleton-bootstrap-5-2', status)}
                listWebsitesExtended={(agency) => service.listWebsitesExtended(agency, 'skeleton-bootstrap-5-2')}
                renameWebsite={service.renameWebsite}
                deleteWebsite={service.deleteWebsite}
                createNewWebsite={service.createNewWebsite}
                cloneWebsite={service.cloneWebsite}
                setWebsiteAsPreConfigured={service.setWebsiteAsPreConfigured}
                listPreConfigureds={() => service.listPreConfigureds(agency, 'skeleton-bootstrap-5-2')}
            />
        );
    }
}
import * as React from 'react';
import {
    FormControl,
    Button,
    Paper,
    withStyles,
    Typography,
    CircularProgress,
    ButtonGroup,
    Menu,
    MenuItem,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { FormikTextField } from '../FormikTextField';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { InfrastructureData, serviceLocator } from './../../services';
import { SetupDialog } from './components/SetupDialog';
import { OverrideDomain } from './components/OverrideDomain';
import { AutoSaveTextField } from '../../components/AutoSaveTextfield';
import { MainPadding } from '../../components/MainPadding';
import { ArrowDropDown } from '@material-ui/icons';


const formControlStyle: any = { flexDirection: "row" };

const INPUT_PROPS = { style: { fontFamily: 'monospace' } };

const styles: (theme: any) => any = (theme) => {
    return {
        root: {
            maxWidth: '42em'
        },
        paper: {
            padding: 24,
        },
        form: {
            maxWidth: '42em'
        },
        button: {
            marginRight: '2px',
        },
        progress: {
            float: 'right'
        },
        softLink: {
            color: theme.palette.primary.main,
            textDecoration: 'underline'
        }
    }
};



const LabelAndValue = (props: any) => {
    console.log('props.marginBottom', props.marginBottom, props)
    return (
        <div style={{ marginBottom: props.marginBottom != undefined? props.marginBottom : 16 }}>
            <Typography variant="caption" color="textSecondary">{props.label}</Typography>
            <Typography color={props.value ? "textPrimary" : "textSecondary"} variant="body1">{props.value || 'Undefined'}</Typography>
            {props.tip && <Typography color={"textSecondary"} variant="caption">{props.tip}</Typography>}
        </div>
    )
}

interface DeployProps {
    websiteKey: string;
    getNote: (websiteKey: string, key: string) => Promise<string>;
    setNote: (websiteKey: string, key: string, note: string) => Promise<void>;
    getWebsiteInfrastructureData: (websiteKey: string) => Promise<InfrastructureData | null>;
    postInfrastructureSetup: (websiteKey: string, data: any) => Promise<void>;
    postInfrastructuredomainOverride: (websiteKey: string, data: any) => Promise<void>;
    createWebsiteBucket: (websiteKey: string) => Promise<void>;
    createWebsiteCloudFrontDistribution: (websiteKey: string) => Promise<void>;
    syncWebsiteBucket: (websiteKey: string, force: boolean) => Promise<void>;
    credentialsProvider: () => Promise<Array<{ label: string, value: string }>>;
    title: React.ReactNode;
};

interface DeployState {
    infrastructure: InfrastructureData | null;
    loaded: boolean;
    refreshing: boolean;
    dialog: "setup" | null;
    notes: string | null;
    showSyncOptions: boolean;
    isShowOverrideDomainModal: boolean;
    syncBucketStartTime: number;
    syncBucketRunning: boolean;
}

type DeployUnstyledProps = DeployProps & WithSnackbarProps & { classes: any };

export class DeployUnstyled extends React.Component<DeployUnstyledProps, DeployState>{

    private refreshPromise: any;
    private mounted: boolean = false;
    private refreshInterval: any;
    private syncGroupRef = React.createRef<any>();

    constructor(props: DeployUnstyledProps) {
        super(props);
        this.state = {
            infrastructure: null,
            refreshing: false,
            loaded: false,
            dialog: null,
            notes: null,
            showSyncOptions: false,
            isShowOverrideDomainModal: false,
            syncBucketStartTime: 0,
            syncBucketRunning: false,
        };
    }

    public async componentDidMount() {
        this.mounted = true;

        this.props.getNote(this.props.websiteKey, 'infrastructure').then(notes => {
            this.setState({ notes: notes || '' });
        });

        await this.refresh();
        // start auto refresh - only trigger when in busy state
        this.refreshInterval = setInterval(() => {
            const infra: any = this.state.infrastructure;
            console.log('refreshInterval', infra);

            if (infra != null && infra.busy) {
                this.refresh()
            }
            // else {
            //     if (this.state.syncBucketRunning) {
            //         this.props.enqueueSnackbar(`Website deployed successfully in ${infra.time - this.state.syncBucketStartTime} ms`);
            //         this.setState({ syncBucketRunning: false })
            //     }

            // }
        }, 3000);


    }

    handleSyncOptionsClick = (e: any) => {
        this.setState({ showSyncOptions: true });
    }

    handleCloseSyncOptions = (e: any) => {
        this.setState({ showSyncOptions: false });
    }

    public async componentWillUnmount() {
        this.mounted = false;
        clearInterval(this.refreshInterval);
    }

    public render() {

        const { classes } = this.props;

        if (!this.state.loaded) {
            return (<MainPadding>
                <Typography color="textSecondary">Loading...</Typography>
            </MainPadding>);
        }

        const infrastructure: InfrastructureData = { state: "None", ...this.state.infrastructure } as any;
        const distribution: any = infrastructure.distribution || {};
        const bucket: any = infrastructure.bucket || {};

        const installerBusy = infrastructure.busy || false;
        const freeze = installerBusy || this.state.refreshing;

        return (<MainPadding className={classes.root}>
            {(typeof this.props.title === 'string') ? <Typography variant="h6">{this.props.title}</Typography> : this.props.title}
            <br />
            <Paper className={classes.paper}>
                {installerBusy && <CircularProgress color="secondary" size={32} className={classes.progress} />}
                <LabelAndValue label={'AWS Infra Credential'} value={infrastructure.infraCredentialId || (infrastructure.domain ? 'Default' : '')} />
                <Grid container spacing={3}>
                    <Grid item xs style={{marginBottom: "15px"}}> 
                        <LabelAndValue label={'Domain'} marginBottom={0} value={infrastructure.domain != null ? (<a className={classes.softLink} target="_blank" href={`https://${infrastructure.domain.replace(/"/g, '')}`}>{infrastructure.domain.replace(/"/g, '')}</a>) : null} />
                        {infrastructure.domain ? <>
                            <Button size="small" onClick={this.handleOverrideDomainClick} className={classes.button} variant="contained" color="primary">Override Domain</Button>
                            <a target="_blank" className={classes.softLink} href={'https://pagespeed.web.dev/report?url=' + encodeURIComponent(infrastructure.domain)}>
                                <Button size="small" variant="contained" color="primary">Check Page Speed</Button>
                            </a>
                            </>
                            : ''}
                    </Grid>
                </Grid>
                {infrastructure.domainOverride ?
                    <LabelAndValue label={'Override Domain'} value={infrastructure.domainOverride != null ? (<a className={classes.softLink} target="_blank" href={`https://${infrastructure.domainOverride.replace(/"/g, '')}`}>
                        {infrastructure.domainOverride.replace(/"/g, '')}</a>)
                        : null} /> : ''}
                <LabelAndValue label={'Bucket URL'} value={bucket.url != null ?
                    (<>
                        <a target="_blank" className={classes.softLink} href={bucket.url}>{bucket.url}</a>
                    </>) : null
                } />
                <LabelAndValue label={'Distribution Domain'} value={distribution.domainName != null ?
                    (<a target="_blank" className={classes.softLink} href={`https://${distribution.domainName.replace(/"/g, '')}`}>{distribution.domainName.replace(/"/g, '')}</a>) : null
                } />
                <LabelAndValue label={'Distribution ID'} value={distribution.id} />
                <LabelAndValue label={'Distribution E-Tag'} value={distribution.eTag} />
                <LabelAndValue label={'Installer State'} value={installerBusy ? 'Busy' : 'Idle'} tip={installerBusy ? 'Please wait while the operation is running' : 'Awaiting commands'} />
                <AutoSaveTextField variant="outlined" save={this.saveNote} inputProps={INPUT_PROPS} initialValue={this.state.notes} multiline={true} label="Notes" fullWidth={true} helperText="Changes will be saved automatically." />
                <div><br /></div>
                <FormControl margin="dense" style={formControlStyle}>
                    <Button size="small" disabled={freeze || (infrastructure.state !== "None")} onClick={this.handleSetupClick}
                        className={classes.button} variant="contained" color="primary">Initial Setup</Button>
                    <Button size="small" disabled={freeze || (infrastructure.state !== "InitialSetupSubmited")} onClick={this.handleCreateBucketSubmit}
                        className={classes.button} variant="contained" color="primary">Create Bucket</Button>
                    <Button size="small" disabled={freeze || infrastructure.state !== "BucketMounted"} onClick={this.handleCreateCDNClick}
                        className={classes.button} variant="contained" color="primary">Create CDN</Button>
                    <ButtonGroup disabled={freeze || infrastructure.state !== "CloudFrontMounted"} size="small" color="primary" ref={this.syncGroupRef}>
                        <Button variant="contained"
                            onClick={this.handleSyncBuildClick}
                        >Sync Build</Button>
                        <Button variant="contained" onClick={this.handleSyncOptionsClick}><ArrowDropDown /></Button>
                    </ButtonGroup>
                    <Menu open={this.state.showSyncOptions} anchorEl={this.syncGroupRef.current} onClose={this.handleCloseSyncOptions}>
                        <MenuItem onClick={this.handleForcedSyncBuildClick}>Force Full Sync</MenuItem>
                    </Menu>
                </FormControl>
            </Paper>
            {infrastructure.state === 'None' && <SetupDialog
                open={this.state.dialog === 'setup'}
                onClose={this.handleCloseDialog}
                credentialsProvider={this.props.credentialsProvider}
                onSetupSubmit={this.handleSetupSubmit}
            />}
            <OverrideDomain
                open={this.state.isShowOverrideDomainModal}
                domain={infrastructure.domain}
                domainOverride={infrastructure.domainOverride}
                onClose={this.handleCloseModal}
                onAddOverrideDomain={this.onAddOverrideDomain}
            />
        </MainPadding>);
    }

    private saveNote = (text: string) => {
        return this.props.setNote(this.props.websiteKey, 'infrastructure', text);
    }

    private handleSetupClick = async (e: any) => {
        this.setState({ dialog: "setup" });
    }

    private handleOverrideDomainClick = () => {
        this.setState({ isShowOverrideDomainModal: true })
    }

    private handleCloseModal = () => {
        this.setState({ isShowOverrideDomainModal: false })
    }

    private onAddOverrideDomain = async (data: any) => {
        this.setState({ isShowOverrideDomainModal: false })
        try {
            this.setState({ dialog: null });
            await this.props.postInfrastructuredomainOverride(this.props.websiteKey, data);
        }
        catch (e) {
            this.props.enqueueSnackbar("Setup has failed.");
        }
        this.refresh();
    }

    private handleSetupSubmit = async (data: any) => {
        try {
            this.setState({ dialog: null });
            await this.props.postInfrastructureSetup(this.props.websiteKey, data);
        }
        catch (e) {
            this.props.enqueueSnackbar("Setup has failed.");
        }
        this.refresh();
    }

    private refresh = async () => {
        if (this.refreshPromise == null) {
            this.setState({ refreshing: true });
            this.refreshPromise = this.props.getWebsiteInfrastructureData(this.props.websiteKey);
            try {
                const infrastructure = await this.refreshPromise;
                this.refreshPromise = null;
                if (this.mounted) {
                    this.setState({ refreshing: false, loaded: true, infrastructure });

                    const infra: any = infrastructure;

                    if (infra != null && infra.busy) {
                    } else {
                        if (this.state.syncBucketRunning) {
                            this.props.enqueueSnackbar(`Website deployed successfully in ${infra.time - this.state.syncBucketStartTime} ms`);
                            this.setState({ syncBucketRunning: false })
                        }

                    }
                }
            }
            catch {
                this.refreshPromise = null;
                if (this.mounted) {
                    this.props.enqueueSnackbar('Refresh failed.');
                    this.setState({ refreshing: false });
                }
            }
        }
    }

    private handleCreateBucketSubmit = async (e: any) => {
        try {
            console.log('Creating bucket...');
            await this.props.createWebsiteBucket(this.props.websiteKey);
        }
        catch {
            this.props.enqueueSnackbar("Bucket creation has failed.");
        }
        await this.refresh();
    }

    private handleCreateCDNClick = async (e: any) => {
        try {
            console.log('Creating CDN...');
            await this.props.createWebsiteCloudFrontDistribution(this.props.websiteKey);
        }
        catch {
            this.props.enqueueSnackbar("CDN creation has failed.");
        }
        await this.refresh();
    }

    private handleForcedSyncBuildClick = async (e: any) => {
        this.handleCloseSyncOptions(e);
        try {
            console.log('Syncing...');
            const res: any = await this.props.syncWebsiteBucket(this.props.websiteKey, true);
            this.setState({ syncBucketRunning: true, syncBucketStartTime: res.data.time })
        }
        catch {
            this.props.enqueueSnackbar("Website synchronization has failed.");
        }
        await this.refresh();
    }

    private handleSyncBuildClick = async (e: any) => {
        this.handleCloseSyncOptions(e);
        try {
            console.log('Syncing...');
            const res: any = await this.props.syncWebsiteBucket(this.props.websiteKey, false);
            this.setState({ syncBucketRunning: true, syncBucketStartTime: res.data.time })
        }
        catch {
            this.props.enqueueSnackbar("Website synchronization has failed.");
        }
        await this.refresh();
    }

    private handleCloseDialog = (e: any) => {
        this.setState({ dialog: null });
    }
}

export const Deploy = withSnackbar(withStyles(styles)(DeployUnstyled)) as React.ComponentClass<DeployProps>;
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

type AccordionHeaderProps = {
    active: boolean,
    onClick: ()=>void,
    style: any,
    headerLeftItems: any,
    headerRightItems: any,
    label: string,
    error: boolean
}

class AccordionHeader extends React.PureComponent<AccordionHeaderProps,any>{
    
    render(){
        let { active, error, headerLeftItems, headerRightItems, label, onClick, style } = this.props;
        return (<a style={style} onClick={onClick}>
            <span style={{ display:'inline-block', margin: '-10px 0px -10px -5px'}}>
                { headerLeftItems.map((item: any, index: number) => { return  (
                    <span key={index}  style={{ display: 'inline-block', margin:'0 5px' }}>{item}</span>
                )})}
            </span>
            <span style={{ position:'absolute', top:'8px', right: '5px'}}>
                { headerRightItems.map((item: any, index: number) => { return  (
                    <span key={index} style={{ display: 'inline-block', margin:'0 5px' }}>{item}</span>
                )})}
                <Button
                    style={{minWidth: '40px'}}
                >{active?<ExpandLess />:<ExpandMore />}</Button>
            </span>
            <Typography display="inline" color={error?"error":undefined} component="span">{label}</Typography>
        </a>);
    }
}

class AccordionItem extends React.Component<any, any>{
    render(){
        let {active, body, error, label, onHeadClick, headerRightItems=[], headerLeftItems=[], headStyle, bodyStyle, style, wrapperProps } = this.props;
 
        let _headStyle = Object.assign({
            border: 'solid 1px #e8e8e8',
            borderRadius: active ? '3px 3px 0 0' : '3px',
            padding: '16px',
            display:'block',
            cursor:'pointer',
            background: active ? 'white': 'rgba(0,0,0,.02)',
            margin: active?'16px 0 0 0' : '3px 0',
            position:'relative',
            borderBottomWidth: active ? '0' : '1px',
        }, headStyle);
        
        let _bodyStyle = Object.assign({
            display:active?'block':'none',
            padding:'8px 0',
            marginBottom: '16px',
            border: 'solid 1px #e8e8e8',
            borderTopWidth: 0
        },bodyStyle);
        
        return <div
            style={Object.assign({}, style, {boxShadow: active ? '0 1px 3px rgba(0,0,0,.1' : 'none'})}
            className="accordion-item" {...wrapperProps} >
            <AccordionHeader
                error={error}
                style={_headStyle}
                onClick={onHeadClick}
                headerLeftItems={headerLeftItems}
                headerRightItems={headerRightItems}
                active={active}
                label={label}
            />
            <div style={_bodyStyle}>
                { active? body : ( null ) }
            </div>
        </div>;
    }
}

class Accordion extends React.Component<any, any>{

    constructor(props: any){
        super(props);
        this.state = { index : -1 };
    }

    getOpenedIndex(){
        if(this.props.index!=null){
            return this.props.index;
        }
        else{
            return this.state.index;
        }
    }

    getHandleChange(i: number){
        return (e: any) => {
            if(this.props.onChange){
                this.props.onChange(i);
            }
            if(this.props.index==null){
                let index = i !== this.state.index?i:-1;
                this.setState(Object.assign({}, this.state, {index}))
            }
        };
    }

    render(){
        let openedIndex = this.getOpenedIndex();
        return <div className="accordion" style={Object.assign({marginTop:'-3px'},this.props.style)}>
            { (this.props.children as any||[]).map((item: any, index: number) => {
                let active = index === openedIndex;
                const onHeadClick = this.getHandleChange(index);
                return React.cloneElement(item, {
                    active,
                    onHeadClick
                });
            }) }
        </div>
    }
}

export { Accordion, AccordionItem };
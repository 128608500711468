import * as React from 'react';
import { Route, Switch, matchPath } from "react-router-dom"
import { Websites } from './Websites';
import { Deploy } from './Deploy';
import { Website } from './Website';
import { NotFound } from '../NotFound';
import { AppSection } from '../../components/AppSection';
import { StaticFileStringEditor } from '../../components/Websites/StaticFileStringEditor';
import { CollectionItemForm } from './CollectionItemForm';
import { SingleForm } from './SingleForm';
import { SingleFormRaw, CollectionItemFormRaw } from './FormsRaw';

export class DynamicWebsites extends React.Component<any, any>{

    public render() {

        return (<React.Fragment>
            <AppSection section="websites/simple-business" />
            <Switch>
                <Route path={"/websites/:theme/:site/deploy/"} exact={true} render={this.renderDeploy} />
                <Route path={"/websites/:theme/:site/collections/:collection/:item/raw"} exact={true} render={this.renderCollectionItemRaw} />
                <Route path={"/websites/:theme/:site/collections/:collection/:item/"} exact={true} render={this.renderCollectionItem} />
                <Route path={"/websites/:theme/:site/singles/:item/"} exact={true} render={this.renderSingle} />
                <Route path={"/websites/:theme/:site/singles/:item/raw"} exact={true} render={this.renderSingleRaw} />
                <Route path={"/websites/:theme/:site/static-file-editor/*"} exact={false} render={this.renderStaticFileEditor} />
                <Route path={"/websites/:theme/:site/assets-file-editor/*"} exact={false} render={this.renderAssetsFileEditor} />
                <Route path={"/websites/:theme/:site/"} exact={true} render={this.renderWebsite} />
                <Route path={"/websites/:theme/"} exact={true} render={this.renderWebsites} />
                <Route path={"/websites/*"} component={NotFound} />
            </Switch>
        </React.Fragment>)
    }

    private renderWebsites = ({ match }: any) => {
        return (<Websites theme={match.params.theme} />);
    }

    private renderSingle = ({ match }: any) => {
        return (<SingleForm themeKey={match.params.theme} singleKey={match.params.item} websiteKey={match.params.site} />);
    }

    private renderSingleRaw = ({ match }: any) => {
        return (<SingleFormRaw singleKey={match.params.item} websiteKey={match.params.site} />);
    }

    private renderCollectionItem = ({ match }: any) => {
        return (<CollectionItemForm themeKey={match.params.theme} collectionKey={match.params.collection} pageKey={match.params.item} websiteKey={match.params.site} />);
    }

    private renderCollectionItemRaw = ({ match }: any) => {
        return (<CollectionItemFormRaw collectionKey={match.params.collection} collectionItemKey={match.params.item} websiteKey={match.params.site} />);
    }

    private renderStaticFileEditor = ({ match }: any) => {
        const path = match.url.split('/static-file-editor/').slice(-1)[0]
        return (<StaticFileStringEditor websiteKey={match.params.site} path={path} assets={false} />);
    }

    private renderAssetsFileEditor = ({ match }: any) => {
        const path = match.url.split('/assets-file-editor/').slice(-1)[0]
        return (<StaticFileStringEditor websiteKey={match.params.site} path={path} assets={true} />);
    }

    private renderWebsite = ({ match }: any) => {
        return (<Website websiteKey={match.params.site} themeKey={match.params.theme} />);
    }

    private renderDeploy = ({ match }: any) => {
        return (<Deploy websiteKey={match.params.site} themeKey={match.params.theme} />);
    }
}
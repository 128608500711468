import { dynamicComponents as materialDynamicComponents } from "../_ho-forms-lib/src/HoForms";
import { ImageUploadDynamic } from "./ImageUploadDynamic";
import { YoutubePickDynamic } from "./YoutubePickDynamic";
import { CodeDynamic } from "./CodeDynamic";
import { VisualEditorDynamic } from "./VisualEditorDynamic";

export const dynamicComponents = [
    ...materialDynamicComponents,
    ImageUploadDynamic,
    YoutubePickDynamic,
    CodeDynamic,
    VisualEditorDynamic,
]
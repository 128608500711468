import * as React from 'react';
import { Card, CardActionArea, CardContent, Typography, CardActions, Button, CardMedia, withStyles } from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { red, lightGreen } from '@material-ui/core/colors';
import { Link } from "react-router-dom";

const styles = {
    card: {
        
    },
    icon: {
        height:'32px',
        width:'32px',
        marginTop: '15px',
        fill: 'white'
    },
    iconWrapper: {
        height: '12px',
        width: '12px',
        textAlign: 'center' as any,
        float: 'right' as any,
        backgroundColor: '#eeeeee',
        marginLeft: '10px',
        borderRadius:'50%',
        trasition: 'background-color 3s'
    },
    iconWrapperOk: {
        backgroundColor: lightGreen['500'],
    },
    iconWrapperWarn: {
        backgroundColor: red['900'],
    },
};

const QualityLink = React.forwardRef((props: any, ref: any)=>(<Link to="/quality" ref={ref} {...props} />));

class StatusCard extends React.PureComponent<{ok: boolean|null, title: string, iconComponent: any, description: string, cardContentProps: any} & any> {
    render(){
        const { classes, cardContentProps, ok, title, iconComponent, description, ...rest } = this.props;
        const Icon = iconComponent || AccessAlarmIcon;
        return (
            <Card className={classes.card} { ...rest }>
            <CardActionArea component={QualityLink}>
                <CardContent { ...cardContentProps }>
                <div className={`${classes.iconWrapper} ${ok==null? '' : ok===true ? classes.iconWrapperOk:classes.iconWrapperWarn}`}>
                    
                </div>
                <Typography gutterBottom variant="subtitle2" component="h2">
                    {title}
                </Typography>
                    {description}
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button disabled={ok!==false} component={QualityLink} size="small" color="primary">
                View Failed
                </Button>
                {/* <Button size="small" color="primary">
                About
                </Button> */}
            </CardActions>
            </Card>
        )
    }
}
  
export default withStyles(styles)(StatusCard);
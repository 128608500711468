import * as React from 'react';
import { MainPadding } from '../../components/MainPadding';
import { Breadcumb } from '../../components/Breadcumb';
import { UsersList } from './UsersList';
import { serviceLocator } from '../../services';
import { Paper, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const agencyService = serviceLocator.getAgencyService();
const userService = serviceLocator.getUserService();

interface Props {
    identifier: string;
}

interface State {
    agency: any|null;
    usersOptions: Array<{value: string}>;
}

export class Agency extends React.Component<Props, State>{

    state: State = {
        agency: null,
        usersOptions: []
    }

    componentDidMount = async () =>{
        this.reloadAgency();
    }

    reloadAgency = async () =>{
        const {agency} = await agencyService.getAgency(this.props.identifier);
        this.setState({agency});
    }

    editLink = React.forwardRef((props: any, ref: any) => <Link ref={ref} to={`/agencies/${this.props.identifier}/edit/`} {...props} />);

    handleOnCreateDialogOpen = async() => {
        const { users } = await userService.listUsers(true);
        this.setState({
            usersOptions: users.map(x =>({value: x.username}))
        });
    }

    handleAddUser = async (user: any) =>{
        await agencyService.addUser(this.props.identifier, user);
        this.reloadAgency();
    }

    handleRemoveUser = async (username: any) =>{
        await agencyService.removeUser(this.props.identifier, {username});
        this.reloadAgency();
    }

    render(){
        const { agency } = this.state;
        
        if(agency===null)
            return (null);

        return (<MainPadding style={{maxWidth: '42em'}}>
        <Breadcumb items={[
            { label:"Agencies", to:`/agencies/` },
            { label:`Agency ${this.props.identifier}` },
        ]} />
        <br />
        <Paper style={{padding:16}}>
            <Typography variant="caption">Identifier</Typography>
            <Typography>{agency.identifier}</Typography>
            <br />
            <Typography variant="caption">Name</Typography>
            <Typography>{agency.name}</Typography>
            <br />
            <Typography variant="caption">Owner</Typography>
            <Typography>{agency.owner}</Typography>
            <br />
            <Typography variant="caption">Active</Typography>
            <Typography>{agency.active?'Yes':'No'}</Typography>
            <br />
            <Button component={this.editLink} variant="contained" color="primary">Edit</Button>
        </Paper>
        <br />
        <UsersList
            onCreatedDialogOpen={this.handleOnCreateDialogOpen}
            addUser={this.handleAddUser}
            deleteUser={this.handleRemoveUser}
            usersOptions={this.state.usersOptions}
            users={agency.users}
        />
        </MainPadding>);
    }
}
import axios from 'axios';

interface Report {
    key: string,
    label: string,
    fail: boolean
}

export class QualityCheckController{

    //PROCESS

    public async runDailyCheck(): Promise<void>{
        await axios.post(`/api/quality-check/run-daily/`);
    }

    public async runForOneWebsite(websitekey: string): Promise<void>{
        await axios.post(`/api/quality-check/run/${encodeURIComponent(websitekey)}`);
    }

    //REPORTS

    public async listFailedWebsiteChecks(): Promise<Array<{key: string, label: string|null, failed: boolean}>>{
        const result = await axios.get(`/api/quality-check/failed-reports`);
        return result.data;
    }

    public async getReport(key: string): Promise<any>{
        const result = await axios.get(`/api/quality-check/reports/${encodeURIComponent(key)}`);
        return result.data;
    }

    public async getReportsByName(websiteKey: string): Promise<{reports: Report[]}>{
        const result = await axios.get(`/api/quality-check/reports/name/${encodeURIComponent(websiteKey)}`);
        return result.data;
    }

    public async removeReport(key: string): Promise<any>{
        const result = await axios.post(`/api/quality-check/reports/${encodeURIComponent(key)}/remove`);
        return result.data;
    }

    public async getDailyCheckProgress(): Promise<{progress: number}>{
        const result = await axios.get(`/api/quality-check/daily-check-progress/`);
        return result.data;
    }

    //CONFIGS

    public async listTemplateConfigs(agency: string): Promise<{configs:Array<any>}>{
        const result = await axios.get(`/api/quality-check/template-configs/headers/?agency=${encodeURIComponent(agency)}`);
        return result.data;
    }

    public async listConfigsOptionsForWebsite(website: string): Promise<{configs:Array<any>}>{
        const result = await axios.get(`/api/quality-check/configs/website-options/${encodeURIComponent(website)}`);
        return result.data;
    }

    public async getConfigById(id: string){
        const result = await axios.get(`/api/quality-check/configs/${encodeURIComponent(id)}/`);
        return result.data;
    }

    public async getConfigByWebsiteId(website: string){
        const result = await axios.get(`/api/quality-check/configs/website/${encodeURIComponent(website)}/`);
        return result.data;
    }

    public async createConfig(data: any){
        const result = await axios.post(`/api/quality-check/configs/`, data);
        return result.data;
    }

    public async updateConfig(data: any){
        const result = await axios.put(`/api/quality-check/configs/`, data);
        return result.data;
    }
}
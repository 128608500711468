import * as React from 'react';
import { BaseForm } from './../../components/Websites/BaseForm';
import { serviceLocator } from '../../services';
import {  topTypesExtender, typesIncludes } from './json-ld-utils';
import { traverse } from '../../utils/traverse';
import { Breadcumb } from '../../components/Breadcumb';

interface PageFormProps {
    websiteKey: string;
    itemKey: string;
}

export class JsonLDForm extends React.Component<PageFormProps,any>{

	removeEmptyFields = (data: any)=>{
		let tryDelete = true;
		while(tryDelete){
			tryDelete = false;
			traverse(data, (obj, prop, value)=>{
				if(
					(value==null)
					|| (value==='')
					|| (Array.isArray(value)&&value.length===0)
					|| (typeof value === "object" && (
						(Object.keys(value).length===0)
						|| (Object.keys(value).length===1 && value['@type'] != null)
					))
				){
					tryDelete = true;
					delete obj[prop];
				}
			})
		}
		return data;
	}

	render(){

		const fields = [
			{ key: "top-row", type:"row", cols: [
				{
					weight: 1, fields: [
						{ key: "instructions0", type:"typography", margin:"none", variant:"body2", content:"Use this form to build JSON-LDs to improve Google Search results (https://developers.google.com/search/docs/data-types/local-business)." },
					]
				},
				{
					weight: 0, fields: [
							{ key: "copy-paste", type: "copy-paste", group: "custom-website", component: "json-ld", exceptRootKeys: ["versionId"] }
					]
				}
			] },
			
			{ key: "instructions1", type:"typography", margin:"none", variant:"body2", content:"After binding the form to a page, you can test the resulting JSON Schema using the following tool:" },
			{ key: "instructions2", type:"typography", margin:"none", variant:"body2", content:" - https://search.google.com/structured-data/testing-tool?hl=en-US" },
			{ key: "instructions3", type:"typography", margin:"normal", variant:"body2", content:"" },
			{ key: "@id", title:"@id", type: "text-field" },
			{ key: "@context", type: "hidden", default: "https://schema.org/" },
			topTypesExtender
		];
		const includes = typesIncludes;

		const service = serviceLocator.getWebsiteService();
		const { itemKey, websiteKey } = this.props;
		return <BaseForm
			themeKey="custom-website"
			title={<Breadcumb paperStyle={false} items={[
				{to:`/websites/custom-website/`, label: `Custom Website`},
				{to:`/websites/custom-website/${websiteKey}/`, label: `Website ${this.props.websiteKey}`},
				{label: `JSON Schema ${itemKey}`},
			]} />}
			debug={false}
			getFormInitialData={()=> service.getJsonLD(websiteKey, itemKey).then(value => ({ value, includes, fields })) }
			websiteKey={websiteKey}
			saveData={(data: any)=>{
				return service.updateJsonLD(websiteKey, itemKey, this.removeEmptyFields(data))
			}}
			buildSite={()=> service.buildWebsite(websiteKey)}
			getNote={() => service.getNote(websiteKey, `jsonld-${itemKey}`)}
			saveNote={(note: string) => service.setNote(websiteKey, `jsonld-${itemKey}`, note)}
		/>
	}
}
import * as React from 'react';
import { Button, Paper, Typography, Avatar } from '@material-ui/core';
import { serviceLocator } from '../services/service-locator';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import axios from 'axios';

const authenticationService = serviceLocator.getAuthenticationService();

export interface AgencyLogoState {
  selectedFile: any;
}

interface AgencyLogoProps extends WithSnackbarProps {
  classes: any;
  avatar?: boolean;
}

const styles = (theme: any) => ({
  imgPreview: {
    width: "100%",
    height: "100%",
    maxWidth: "300px",
    maxHeight: "300px",
    // objectFit: "cover",
  },
  buttonsWrapper: {
    display: "flex",
    // justifyContent: "space-between"
  }
});

const noImage = "https://trusteid.mioa.gov.mk/wp-content/plugins/uix-page-builder/uixpb_templates/images/UixPageBuilderTmpl/no-logo.png";

export class AgencyLogoUnstyled extends React.Component<AgencyLogoProps, AgencyLogoState>{

  constructor(props: AgencyLogoProps) {
    super(props);
    this.state = {
      selectedFile: noImage,
    };
  }

  componentDidMount() {
    this.getLogo()
  }

  getLogo = async () => {
    const authUserData = authenticationService.getAuthenticatedUserData();
    const response = await axios.get(`/api/agencies/${authUserData?.ownedAgency}/logo/`);
    console.log('response', response)
    if (response.data?.success == false) {
      this.setState({
        selectedFile: noImage
      });
    } else {
      this.setState({
        selectedFile: "data:image\/png;base64, " + response.data
      });
    }
  }

  public render() {
    const authUserData = authenticationService.getAuthenticatedUserData();


    return (
      this.props.avatar ?
        <Avatar src={this.state.selectedFile} alt={authUserData?.ownedAgency} />
        :
        <img src={this.state.selectedFile} alt={authUserData?.ownedAgency} className={this.props.classes.imgPreview} />
    );
  }
}

export const AgencyLogo = withSnackbar(withStyles(styles)(AgencyLogoUnstyled));
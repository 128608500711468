import * as React from 'react';
import { BaseForm } from './BaseForm';
import { serviceLocator } from '../../services';
import { Breadcumb } from '../../components/Breadcumb';

interface PageFormProps {
    websiteKey: string;
    collectionKey: string;
	pageKey: string;
}

export class PageForm extends React.Component<PageFormProps,any>{

	render(){
		const service = serviceLocator.getWebsiteService();
		const { pageKey, websiteKey } = this.props;
		return <BaseForm
			debug={false}
			websiteKey={websiteKey}
			includes={{}}
            title={<Breadcumb paperStyle={false} items={[
				{to:`/websites/prime-lending/`, label: `Prime Lending`},
				{to:`/websites/prime-lending/${websiteKey}/`, label: `Website ${this.props.websiteKey}`},
				{ label: `Page ${pageKey}`}
			]} />}
			fields={[
                { key: "url", type:"text-field", title:"URL", default: pageKey },
                { key: "title", type:"text-field", title:"Title" },
                { key: "content", type:"code", title:"Content", language: 'html' }
            ]}
			plugins={{}}
			getData={async ()=> (await service.getPage(websiteKey, "pages", pageKey))||{} }
			saveData={(data: any)=> service.updatePage(websiteKey, "pages", pageKey, data)}
			buildSite={()=> service.buildWebsite(websiteKey)}
			getNote={() => service.getNote(websiteKey, `page-${pageKey}`)}
			saveNote={(note: string) => service.setNote(websiteKey, `page-${pageKey}`, note)}
		/>
	}
}
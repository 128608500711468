import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Theme } from '@material-ui/core';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormikSelect } from '../FormikSelect';
import { FormikTextField } from '../FormikTextField';
import { DialogProps } from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';

const styles: (theme: Theme) => any = (theme) => {
  return {
    root: {},
    warningButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark
      },
      "&:disabled": {
        backgroundColor: theme.palette.error.light
      }
    }
  };
};

interface Props {
  path: string;
  transform: (transformConfig: any) => Promise<void>;
}

interface State {

}
class TransformImageDialog extends React.Component<DialogProps & Props, State>{

  isJpeg = (value: string) => { const v = value || ''; return (v.endsWith('.jpg') || v.endsWith('.jpeg')) };
  isValidFilePath = /[.](jpe?g|png)$/;

  validation = Yup.object().shape({
    to: Yup.string().matches(this.isValidFilePath, 'The extension is invalid.').required('Required.'),
    quality: Yup.string()
      .when("to", {
        is: this.isJpeg,
        then: Yup.string().required('Required.')
      }),
    scalingConstraint: Yup.string().required('Required'),
    size: Yup.string()
      .when("scalingConstraint", {
        is: (value: string) => (value !== 'original-size'),
        then: Yup.string().matches(new RegExp('^[0-9]+$'), 'Please provide a integer number.')
      })
  })

  handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      await this.props.transform(values);
    }
    catch {
      alert('An error has ocurred');
    }
    finally {
      setSubmitting(false);
    }
  }

  render() {

    if (!this.props.path)
      return (null);

    const classes: any = this.props.classes || {};

    const { path, transform, ...dialogProps } = this.props;

    return (

      <Formik
        initialValues={{
          from: this.props.path || '',
          to: this.props.path || '',
          quality: '70',
          scalingConstraint: "original-size",
          size: ''
        }}
        onSubmit={this.handleSubmit}
        validationSchema={this.validation}
      >
        { props => {
          const { handleSubmit, isSubmitting, submitForm, values } = props;
          const isOriginalSize = values.scalingConstraint === 'original-size';
          const compressionRequired = this.isJpeg(values.to);
          const overriding = values.from === values.to;
          return (
            <Dialog {...dialogProps} >
              <DialogTitle>Transform Image</DialogTitle>
              <DialogContent>
                <form onSubmit={handleSubmit}>
                  <Field
                    name="from"
                    label="Source"
                    component={FormikTextField}
                    fullWidth={true}
                    disabled={true} />
                  <Field
                    name="to"
                    label="Save To"
                    component={FormikTextField}
                    fullWidth={true}
                    helperText="To create a new file, use a different file path. Only .jpg and .png extensions are accepted." />
                  <Field
                    disabled={!compressionRequired}
                    name="quality"
                    label="* Quality"
                    component={FormikSelect}
                    options={compressionRequired ? [{ value: '1', label: '0' }, { value: '10' }, { value: '20' }, { value: '30' }, { value: '40' }, { value: '50' }, { value: '60' }, { value: '70' }, { value: '80' }, { value: '90' }, { value: '100' }] : []}
                    fullWidth={true}
                    helperText="70 is good compression rate. Remember to use a uncompressed file source when saving to JPG." />
                  <Field
                    name="scalingConstraint"
                    label="* Scaling Contraint"
                    component={FormikSelect}
                    fullWidth={true}
                    options={[{ label: 'Original Size', value: 'original-size' }, { label: 'Longest Edge', value: 'longest-edge' }, { label: 'Width', value: 'width' }, { label: 'Height', value: 'height' }]}
                  />
                  <Field
                    disabled={isOriginalSize}
                    value={isOriginalSize ? '' : values.size}
                    name="size" label="Size (pixels)"
                    component={FormikTextField}
                    fullWidth={true}
                    helperText="The number of pixels. Use a size slightly higher (+10%) than necessary for responsive images." />
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    onClick={submitForm}
                    style={{ width: 1, height: 1, visibility: 'hidden'
                  }}>
                    Transform
                  </button>
                </form>
              </DialogContent>
              <DialogActions>
                <Button
                  className={overriding ? classes.warningButton : ''}
                  variant="contained" color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  value="create"
                  onClick={submitForm}>{overriding ? 'Transform (Overwrite Source)' : 'Transform'}</Button>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    );
  }
}

export default withStyles(styles)(TransformImageDialog);
import * as React from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { Delete, FileCopy, Edit, PhotoSizeSelectLarge } from '@material-ui/icons';
import DangerButton from '../../_ho-forms-lib/src/MaterialComponents/DangerButton';
import { Debounce } from '../../utils/debounce';
import { isImagePath } from './FileManager';

interface FileManagerListItemProps {
    filePath: string;
    fileSize: number;
    targetFolder: string;
    pickOnly: boolean;
    onCopyPathClick: (e: any)=>void;
    onDeleteClick: (e: any)=>void;
    onRenameClick: (e: any)=>void;
    onActiveChange: (active: boolean, element: HTMLElement|null, filePath: string)=>void;
    onResizeClick: (e: any)=> void;
}

export interface FileManagerListItemState{
    hover: boolean;
}

export class FileManagerListItem extends React.Component<FileManagerListItemProps & {[key: string]: any}, any> {
    state = {
        hover: false
    };

    itemRef: any = React.createRef();

    hoverDebounce = new Debounce(100);

    static levelMatcher = /\//g;

    humanReadableSize(size: any){
        if(size>1000000){
            return Math.round(size/1000000).toString()+'mb';
        }
        else{
            return Math.round(size/1000).toString()+'kb';
        }

    }

    handleItemDelete = (e: any, ready: boolean) => {
        if(ready){
            this.props.onDeleteClick(e);
            if(this.state.hover){ this.props.onActiveChange(false, null, ''); }
        }
    }

    handleItemMouseOver = (e: React.SyntheticEvent)=>{
        this.hoverDebounce.run(()=>{
            if(this.state.hover===false){
                this.setState({hover:true});
                this.props.onActiveChange(true, this.itemRef.current, this.props.filePath);
            }
        });
    }

    handleItemMouseOut = (e: React.SyntheticEvent)=>{
        this.hoverDebounce.run(()=>{
            if(this.state.hover===true){
                this.setState({hover:false});
                this.props.onActiveChange(false, this.itemRef.current, this.props.filePath);
            }
        });
    }

    render(){
        const {onDeleteClick, onCopyPathClick, onActiveChange, pickOnly, button, filePath, fileSize, targetFolder, onRenameClick, onResizeClick, ...rest} = this.props;
        const isImage = isImagePath(filePath);
        const displayValue = filePath.replace(targetFolder,'/');
            if(displayValue === '/' ) return (null);
            const level = (displayValue.match(FileManagerListItem.levelMatcher)||[]).length;
            const isFolder = displayValue.endsWith('/');
            if(isFolder){
                if(level!==2) return (null);
            }
            else if(level!==1) return (null);
        return (<ListItem
            ref={this.itemRef}
            button={true}
            onMouseOver={this.handleItemMouseOver}
            onMouseOut={this.handleItemMouseOut}
            data-value={filePath}
            {...rest}
        >
            <ListItemText primary={displayValue.substr(1)} />
            <ListItemSecondaryAction onMouseOver={this.handleItemMouseOver} onMouseOut={this.handleItemMouseOut}>
                { isFolder?null:<small>{this.humanReadableSize(fileSize)}</small> }
                <span style={{display: this.state.hover?"inline":"none"}}>
                    {/* { !isFolder && <IconButton disabled={pickOnly} data-value={filePath} onClick={onCopyPathClick}><FileCopy /></IconButton> } */}
                    <IconButton disabled={pickOnly} data-value={filePath} onClick={onRenameClick}><Edit /></IconButton>
                    {isImage && (<IconButton disabled={pickOnly} data-value={filePath} onClick={onResizeClick}><PhotoSizeSelectLarge /></IconButton>)}
                    <DangerButton
                        onClick={this.handleItemDelete}
                        loadedButton={<IconButton data-value={filePath}><Delete color="error" /></IconButton>}
                        button={<IconButton disabled={pickOnly} data-value={filePath}><Delete /></IconButton>}
                    />
                </span>                
            </ListItemSecondaryAction>
        </ListItem>);
    }
}
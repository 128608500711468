import * as React from 'react';
import { serviceLocator } from './../../services';
import { Websites as WebsitesInner } from './../../components/Websites';
import { Breadcumb } from '../../components/Breadcumb';
import { keyToDisplay } from '../../utils/string-utils';
import { Link } from 'react-router-dom';

const service = serviceLocator.getWebsiteService();
const uiService = serviceLocator.getUIService();

export class Websites extends React.Component<{ theme: string }, {}>{

    render() {
        const agency = uiService.getSelectedAgency();
        return (
            <WebsitesInner
                showAgencyTags={!agency}
                agency={agency}
                themeKey={this.props.theme}
                title={<Breadcumb items={[
                    { label: `${keyToDisplay(this.props.theme)} Websites` },
                ]} />}
                buildWebsite={service.buildWebsite}
                syncWebsite={(key: string) => service.syncWebsiteBucket(key, true)}
                listWebsites={(agency: string, status: string) => service.listWebsites(agency, this.props.theme, status)}
                listWebsitesExtended={(agency) => service.listWebsitesExtended(agency, this.props.theme)}
                renameWebsite={service.renameWebsite}
                deleteWebsite={service.deleteWebsite}
                createNewWebsite={service.createNewWebsite}
                cloneWebsite={service.cloneWebsite}
                setWebsiteAsPreConfigured={service.setWebsiteAsPreConfigured}
                listPreConfigureds={() => service.listPreConfigureds(agency, this.props.theme)}
            />
        );
    }
}
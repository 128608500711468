import * as React from 'react';
import { ComponentProps, CrawlContext, NormalizeStateContext } from '../HoForm';
import { BaseDynamic } from '../HoForm';
import { TextField, Typography } from '@material-ui/core';

type TypographyDynamicField = {
    key: string, 
    type: string,
    content: string,
    variant: any,
    margin?: 'none'|'dense'|'normal'
}

type TypographyDynamicState = {
    
}

const INPUT_LABEL_PROPS = { shrink: true };

export class TypographyDynamic extends BaseDynamic<TypographyDynamicField,TypographyDynamicState> {

    constructor(props: ComponentProps<TypographyDynamicField>){
        super(props);
    }

    getType(){
        return 'typography';
    }


    renderComponent(){
        
        let {context} = this.props;
        let {node, currentPath, parentPath} = context;
        let {field} = node;
                
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }

        const margin =  field.margin==='none'? '0px' : field.margin==='normal' ? '16px 0 8px 0' : '8px 0 4px 0';

        return (
            <Typography style={{margin}} variant={field.variant}>{ field.content }</Typography>                
        );
    }
}
import React from 'react';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import { FormStateBuilder, BaseDynamic, NormalizeStateContext } from '../HoForm';

type SwitchDynamicField = {
    key: string,
    type: string,
    tip?: string,
    title: string,
    default?: boolean
}

type SwitchDynamicFieldState = {

}

class SwitchDynamic extends BaseDynamic<SwitchDynamicField,SwitchDynamicFieldState> {

    normalizeState({state, field, stateBuilder} : NormalizeStateContext<SwitchDynamicField>){
        let key = field.key;
        if(state[key]==null){
            state[key] = field.default!=null?field.default:false;
        }
    }

    getType(){
        return 'switch';
    }


    handleChange = (e: any) => {
        this.props.context.setValue(e.target.checked);
    }

    renderComponent(){

        let {context} = this.props;
        let {node, currentPath, parentPath} = context;
        let {field} = node;
        
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }

        return (
        
            <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={context.value===true}
                      onChange={this.handleChange}
                    />
                  }
                  label={field.title}
                />
            </FormGroup>
        );
    }
}

export default SwitchDynamic;
import * as React from 'react';
import { serviceLocator } from '../services';


export class AppSection extends React.PureComponent<{section: string}>{
    change = ()=>{
        serviceLocator.getUIService().setActiveSection(this.props.section);
    }

    render(){
        setTimeout(this.change, 1);
        return (null);
    }
}
import * as React from 'react';
import { pth } from '../utils/pth';
import { DebouncedTextField } from './DebouncedTextField';
import { FormControl, Select, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';

export class FormikSelect extends React.Component<any,any>{
    public render(){
        const {
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            label,
            options,
            helperText,
            ...fProps
        } = this.props;
        const isTouched = pth(field.name,touched);
        const error = pth(field.name,errors);

        return (
        <FormControl margin="dense" fullWidth={true}>
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            {...fProps}
          >
            { (options || []).map((item: any, index: number)=>(
                <MenuItem key={index} value={item.value}>{item.label!=null?item.label:item.value}</MenuItem>
            )) }
          </Select>
          { (error!=null || helperText!=null) && <FormHelperText error={isTouched ? (error!=null) : false}>{`${helperText||''} ${error||''}`.trim()}</FormHelperText> }
        </FormControl>
        )
    }
};
import { TextFieldDynamic } from './TextFieldDynamic';
import { HiddenDynamic } from '../HoForm/HiddenDynamic';
import { DataNestDynamic } from '../HoForm/DataNestDynamic';
import { IncludeDynamic } from '../HoForm/IncludeDynamic';
import NestDynamic from './NestDynamic';
import AccordionDynamic from './AccordionDynamic';
import SelectDynamic from './SelectDynamic';
import SwitchDynamic from './SwitchDynamic';
import { ColorTextFieldDynamic } from './ColorTextFieldDynamic';
import ExtenderDynamic from './ExtenderDynamic';
import RowDynamic from '../HoForm/RowDynamic';
import { ExtendDynamic } from '../HoForm/ExtendDynamic';
import LeafArrayDynamic from './LeafArrayDynamic';
import { NullableDynamic } from './NullableDynamic';
import { TypographyDynamic } from './TypographyDynamic';
import { BorderLeftDynamic } from './BorderLeftDynamic';
import { CopyAndPasteDynamic } from './CopyAndPasteDynamic';
import { DateTimeDynamic } from './DateTimeDynamic';

export const dynamicComponents :Array<any> = [
    AccordionDynamic,
    BorderLeftDynamic,
    ColorTextFieldDynamic,
    CopyAndPasteDynamic,
    DateTimeDynamic,
    DataNestDynamic,
    ExtendDynamic,
    ExtenderDynamic,
    HiddenDynamic,
    IncludeDynamic,
    LeafArrayDynamic,
    NestDynamic,
    NullableDynamic,
    RowDynamic,
    SelectDynamic,
    SwitchDynamic,
    TextFieldDynamic,
    TypographyDynamic,
];
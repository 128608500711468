import * as React from 'react';
import { FileManagerDialog } from '../FileManagerDialog/FileManagerDialog';
import { serviceLocator } from '../../services';

interface Props {
    open: boolean;
    websiteKey: string;
    onPick: ((path: string) => void) | null;
    onClose: (e: any) => void;
    themeKey: string;
    assets: boolean;
}

export class SmartFileManagerDialog extends React.Component<Props, {}>{

    render() {

        const service = serviceLocator.getWebsiteService();
        const { onPick, websiteKey, open, onClose, themeKey, assets } = this.props;

        return <FileManagerDialog
            assets={assets}
            open={open}
            onClose={onClose}
            getItems={() => service.listStaticFilesExtended(websiteKey, assets)}
            deleteItem={(e: any, file: string) => service.deleteStaticFile(websiteKey, file, assets)}
            uploadSingleFile={(e: any, file: string, name: string, folder: string, imagifySourceUrl: string) => service.uploadStaticFile(websiteKey, file, name, folder, assets, imagifySourceUrl)}
            createFolder={(e: any, folder: string) => service.createStaticFolder(websiteKey, folder, assets)}
            getStaticUrl={(path) => {
                if (/[.](js|s?css|json|xml|html)/.test(path)) { return `/websites/${themeKey}/${websiteKey}/${assets?"assets":"static"}-file-editor${path}`; }
                else { return `/api/websites/${websiteKey}/${(assets?'assets':'static')}${path}` }
            }}
            renamePath={(p1, p2) => { return service.renameStaticFile(websiteKey, p1, p2, assets); }}
            transformImage={(data) => service.transformStaticImage(websiteKey, data, assets)}
            onPick={onPick} />
    }
}
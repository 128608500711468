const notSetOption = { text: "Not set", value: "" };
const allColors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark', 'action'];
allColors.sort();
const allLayouts = ['single-col-smallest', 'single-col-small', 'single-col', 'single-col-large', 'single-col-full', 'two-cols', 'two-cols-reverse'].map(x => ({ value: x }));
const allSubLayouts = ['single-col', 'two-cols', 'two-cols-reverse'].map(x => ({ value: x }));
const allButtonVariantOptions: Array<any> = [{ value: 'link' }];
allColors.forEach(x => { allButtonVariantOptions.push({ value: x }); allButtonVariantOptions.push({ text: `${x} (outlined)`, value: `outline-${x}` }) });
allButtonVariantOptions.push({ value: 'white' });
allButtonVariantOptions.push({ text: 'white (outlined)', value: 'outline-white' });

const allBackgroundVariantOptions: Array<any> = [{ value: 'white' }, { value: 'transparent-white-text' }, { value: 'transparent-dark-text' }];
allColors.forEach(x => {
    ['', '-light', '-lighter', '-lightest', '-dark', '-darker'].forEach(m => {
        allBackgroundVariantOptions.push({ value: `${x}${m}` });
    });
});
allBackgroundVariantOptions.unshift(notSetOption);
allButtonVariantOptions.unshift(notSetOption);
allLayouts.unshift(notSetOption);
allSubLayouts.unshift(notSetOption);

const propsField = [
    { key: "label", title: "Label", type: "text-field" },
    { key: "value", title: "Value", type: "text-field" },
    { key: "href", title: "Href", type: "text-field" },
];

const scopedSCSS = () => ({ key: "scopedSCSS", title: "Scoped SCSS", type: "code", language: "scss" });

const cssAndJsBare = () => {
    return [
        { key: "onReadyScript", title: "On Ready Script", type: "code", language: "javascript", tip: "Use ${blockId} in your scripts." },
        scopedSCSS()
    ];
}

const cssAndJs = () => {
    return [{
        key: "advanced-options", title: "Advanced Options", groupdata: false, type: "nest", fields: [
            ...cssAndJsBare()
        ]
    }];
}

const imgWidthAndHeight = (key: string, name: string) => {
    return [
        {
            key: `${key}-props`, type: "row", cols: [
                { fields: [{ key: `${key}Href`, title: `${name} Href`, type: "text-field" },], weight: 2 },
                { fields: [{ key: `${key}Width`, title: `Width`, type: "text-field" }], weight: 1 },
                { fields: [{ key: `${key}Height`, title: `Height`, type: "text-field" },], weight: 1 },
                { fields: [{ key: `${key}Size`, title: `Sizing`, type: "select", default: "default", options: [{ value: "default" }, { value: "contain" }, { value: "cover" }] },], },
                { fields: [{ key: `${key}Lazy`, title: `Lazy`, default: true, type: "switch" },] }
            ]
        }
    ];
}

const imgFields = (key: string, name: string, required: boolean, defaultVal?: string) => {
    return [
        {
            key: `${key}-basic`, type: "row", cols: [
                { fields: [{ key: key, title: name, type: "image-upload", required, default: defaultVal },], weight: 2 },
                { fields: [{ key: `${key}Alt`, title: `Alt Text`, type: "text-field" },], weight: 1 },
            ]
        },
        ...imgWidthAndHeight(key, name)
    ]
}

const inputExtenderInclude = [
    {
        key: "inputExtender", type: "extender", selectorKey: "type", nest: false, groupdata: false, fields: [
            {
                key: "type", title: "Type", type: "select", options: [
                    { value: "checkbox" },
                    { value: "custom" },
                    { value: "email" },
                    { value: "hidden" },
                    { value: "radio" },
                    { value: "select" },
                    { value: "text" },
                    { value: "textarea" }
                ]
            },
            { key: "name", title: "Name", type: "text-field", required: true },
            { key: "class", title: "Input Class", type: "text-field", required: false, tip:"CSS class applied to input, select or textarea." },
        ],
        types: [
            {
                key: "checkbox", fields: [
                    { key: "label", title: "Label", type: "text-field", required: true },
                    { key: "required", type: "switch", default: false, title: "Required" },
                    { key: "value", title: "Value", type: "text-field", required: true },
                    { key: "checked", type: "switch", default: false, title: "Checked" },
                    { key: "invalidFeedback", title: "Invalid Feedback", type: "text-field", required: false },
                    { key: "wrapperClass", title: "Container CSS Class", type: "text-field", required: false },
                ]
            },
            {
                key: "custom", fields: [
                    { key: "content", title: "Content", type: "code", language: "markdown", required: true },
                ]
            },
            {
                key: "email", fields: [
                    { key: "label", title: "Label", type: "text-field" },
                    { key: "required", type: "switch", default: false, title: "Required" },
                    { key: "value", title: "Initial Value", type: "text-field", required: false },
                    { key: "placeholder", title: "Placeholder", type: "text-field", required: false },
                    { key: "invalidFeedback", title: "Invalid Feedback", type: "text-field", required: false },
                    { key: "wrapperClass", title: "Container CSS Class", type: "text-field", required: false },
                ]
            },
            {
                key: "hidden", fields: [
                    { key: "value", title: "Value", type: "text-field", required: true },
                ]
            },
            {
                key: "select", fields: [
                    { key: "label", title: "Label", type: "text-field" },
                    { key: "required", type: "switch", default: false, title: "Required" },
                    { key: "value", title: "Initial Value", type: "text-field", required: false },
                    { key: "invalidFeedback", title: "Invalid Feedback", type: "text-field", required: false },
                    {
                        key: "options", title: "Options", type: "accordion", itemTitleKey: "label", fields: [
                            { key: "label", title: "Label", type: "text-field", required: true },
                            { key: "value", title: "Value", type: "text-field" },
                        ]
                    },
                    { key: "wrapperClass", title: "Container CSS Class", type: "text-field", required: false },
                ]
            },
            {
                key: "text", fields: [
                    { key: "label", title: "Label", type: "text-field" },
                    { key: "required", type: "switch", default: false, title: "Required" },
                    { key: "value", title: "Initial Value", type: "text-field", required: false },
                    { key: "placeholder", title: "Placeholder", type: "text-field", required: false },
                    { key: "pattern", title: "Regex Pattern", type: "text-field", required: false },
                    { key: "invalidFeedback", title: "Invalid Feedback", type: "text-field", required: false },
                    { key: "wrapperClass", title: "Container CSS Class", type: "text-field", required: false },
                ]
            },
            {
                key: "textarea", fields: [
                    { key: "label", title: "Label", type: "text-field" },
                    { key: "required", type: "switch", default: false, title: "Required" },
                    { key: "value", title: "Initial Value", type: "text-field", required: false },
                    { key: "placeholder", title: "Placeholder", type: "text-field", required: false },
                    { key: "pattern", title: "Regex Pattern", type: "text-field", required: false },
                    { key: "invalidFeedback", title: "Invalid Feedback", type: "text-field", required: false },
                    { key: "wrapperClass", title: "Container CSS Class", type: "text-field", required: false },
                ]
            },
            {
                key: "radio", fields: [
                    { key: "label", title: "Label", type: "text-field", required: true },
                    { key: "required", type: "switch", default: false, title: "Required" },
                    { key: "value", title: "Value", type: "text-field", required: true },
                    { key: "checked", type: "switch", default: false, title: "Checked" },
                    { key: "wrapperClass", title: "Container CSS Class", type: "text-field", required: false },
                ]
            },
        ]
    }
]

const uiNameField = (defaultV: string, type = "widget") => {
    return { key: "uiName", title: "UI Name", tip: `To help you do itentify the ${type} in the UI`, type: "text-field", default: defaultV };
}

const widgetTypes = [
    {
        key: "accordion", fields: [
            // uiNameField("Accordion"),
            { key: "id", title: "ID", type: "text-field", required: true },
            { key: "spacing", title: "Spacing (vertical)", type: "text-field", required: true, default: "2" },
            { key: "itemsVariant", title: "Items Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: "light" },
            { key: "animated", title: "Animated", type: "switch", default: true },
            { key: "activeIndex", title: "Active Index", type: "text-field", required: false, default: "0" },
            {
                key: "items", title: "Items", type: "accordion", fields: [
                    { key: "id", title: "ID", type: "text-field" },
                    { key: "header", title: "Header", type: "text-field", required: true },
                    { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions" },
                    { key: "include-widget-v2", type: "include", include: "widgetV2Include" }
                ]
            },
        ]
    },
    {
        key: "business-card", fields: [
            // uiNameField("Business Card"),
            { key: "picture", title: "Picture", type: "image-upload", default: "/img/sample-pic.jpg" },
            { key: "props", title: "Properties", type: "accordion", itemTitleKey: "label", fields: propsField },
            {
                key: "button", title: "Button", type: "extender", selectorKey: "enabled", nest: true, groupdata: true, fields: [
                    { key: "enabled", title: "Enabled", type: "switch", required: true, default: true, clearOnChange: false },
                ], types: [
                    {
                        key: "true", fields: [
                            { key: "label", title: "Label", type: "text-field", required: true },
                            { key: "href", title: "Href", type: "text-field", required: true },
                            { key: "variant", title: "Variant", type: "select", includeOptions: "allButtonVariantOptions" },
                        ]
                    }
                ]
            },
        ]
    },
    {
        key: "button", fields: [
            {
                key: "base-row", type: "row", cols: [
                    { weight: 2, fields: [{ key: "content", title: "Label", type: "text-field", required: true },] },
                    { weight: 1, fields: [{ key: "element", title: "Element", type: "select", default: "a", options: [{ value: "a" }, { value: "button" }] },] }
                ]
            },
            {
                key: "link-extender", type: "extender", selectorKey: "element", nest: false, fields: [], clearOnChange: ['href'], types: [
                    {
                        key: "a", fields: [
                            {
                                key: "a-row", type: "row", cols: [
                                    { weight: 1, fields: [{ key: "href", title: "Href", type: "text-field" },] },
                                    {
                                        weight: 1, fields: [
                                            { key: "target", title: "Target", type: "select", options: [{ value: "", text: "none" }, { value: "_self" }, { value: "_blank" }], required: false, default: "" },
                                        ]
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                key: "style-row", type: "row", cols: [
                    { weight: 1, fields: [{ key: "variant", title: "Variant", type: "select", includeOptions: "allButtonVariantOptions" },] },
                    { weight: 1, fields: [{ key: "size", title: "Size", type: "select", default: "md", options: [{ value: "sm" }, { value: "md" }, { value: "lg" }] },] },
                    { weight: 1, fields: [{ key: "modifiers", title: "Modifiers", type: "select", multiple: true, options: [{ value: "btn-block" }, { value: "btn-tall" }, { value: "btn-confort" }] },] },
                    { weight: 1, fields: [{ key: "extraClass", title: "Extra Class", type: "text-field" },] },
                ]
            },
            {
                key: "actions", title: "Click Actions", type: "accordion", itemTitleKey: "uiName", fields: [
                    { key: "triggerActionsInclude", type: "include", include: "triggerActionsInclude" },
                ]
            },
            {
                key: "attributes", title: "HTML Attributes", type: "accordion", itemTitleKey: "key", fields: [
                    {
                        key: "row", type: "row", cols: [
                            { weight: 1, fields: [{ key: "key", title: "Key", type: "text-field", required: true },] },
                            { weight: 2, fields: [{ key: "value", title: "Value", type: "text-field", required: true },] }
                        ]
                    },
                ]
            }
        ]
    },
    {
        key: "button-group", fields: [
            { key: "aligment", title: "Aligment", type: "select", default: "center", options: [{ value: 'left' }, { value: 'center' }, { value: 'right' }] },
            {
                key: "buttons", title: "Buttons", type: "accordion", itemTitleKey: "label", fields: [
                    { key: "label", title: "Label", type: "text-field", required: true },
                    { key: "href", title: "Href", type: "text-field", required: true },
                    { key: "size", title: "Size", type: "select", default: "lg", options: [{ value: "sm" }, { value: "md" }, { value: "lg" }] },
                    { key: "extraHorizontalPadd", title: "Extra Horizontal Padding", type: "switch", default: true },
                    { key: "variant", title: "Variant", type: "select", includeOptions: "allButtonVariantOptions" },
                    { key: "customClass", title: "Custom Class", type: "text-field" },
                ]
            }
        ]
    },
    {
        key: "column", fields: [
            { key: "columnClassAppend", title: "Column Class Append", type: "text-field" },
            {
                key: "cells", title: "Cells", type: "accordion", itemTitleKey: "uiName", fields: [
                    uiNameField(""),
                    { key: "cellClassOverride", title: "Cell Class Override", type: "text-field" },
                    { key: "include-widget", type: "include", include: "widgetInclude" }
                ]
            }
        ]
    },
    {
        key: "column-v2", fields: [
            { key: "columnClassAppend", title: "Column Class Append", type: "text-field" },
            {
                key: "cells", title: "Cells", type: "accordion", itemTitleKey: "uiName", fields: [
                    uiNameField(""),
                    { key: "skipRender", title: "Skip Render", type: "switch", default: false },
                    { key: "cellClassOverride", title: "Cell Class Override", type: "text-field" },
                    { key: "include-widget-v2", type: "include", include: "widgetV2Include" },
                ]
            }
        ]
    },
    {
        key: "custom", fields: [
            // uiNameField("Custom"),
            { key: "forceBlock", title: "Force Block", type: "switch", default: false },
            { key: "content", title: "Content", type: "code", language: "markdown" },
        ]
    },
    {
        key: "form", fields: [
            { key: "id", title: "ID", type: "text-field", required: true },
            { key: "formInclude", type: "include", include: "formInclude" }
        ]
    },
    {
        key: "html", fields: [
            { key: "noWrap", title: "No Wrap", type: "switch", default: false },
            { key: "content", title: "HTML", type: "code", language: "html" },
        ]
    },
    {
        key: "html-rich", fields: [
            { key: "content", title: "HTML", type: "visual-editor" },
        ]
    },
    {
        key: "image", fields: [
            // uiNameField("Image"),
            ...imgFields("src", "Src", true),
        ]
    },
    {
        key: "map", fields: [
            { key: "id", title: "Id", type: "text-field", required: true },
            { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions" },
            { key: "height", title: "Height", type: "text-field", required: true, default: "300" },
            { key: "embedSrc", title: "Embed Src", type: "text-field", required: true },
        ]
    },
    {
        key: "many", fields: [
            {
                key: "items", title: "Items", type: "accordion", itemTitleKey: "uiName", fields: [
                    uiNameField(""),
                    { key: "include-widget-v2", type: "include", include: "widgetV2Include" },
                ]
            }
        ]
    },
    {
        key: "menu", fields: [
            { key: "fragmentName", title: "Fragment Name", type: "text-field", required: true, tip: "The Menu Fragment to generate the menu." },
        ]
    },
    {
        key: "modal", fields: [
            { key: "id", title: "Modal Id", type: "text-field", required: true },
            { key: "title", title: "Title", type: "text-field", required: true },
            { key: "modalSize", title: "Size", type: "select", options: [{ value: "sm" }, { value: "md" }, { value: "lg" }, { value: "xl" }, { value: "full" }], default: "md" },
            { key: "modalDialogCentered", title: "Center", type: "switch", default: false },
            { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions" },
            { key: "headerVariant", title: "Header Variant", type: "select", includeOptions: "allBackgroundVariantOptions" },
            {
                key: "bodyWidget", title: "Modal Body", type: "nest", fields: [
                    { key: "include-widget-v2", type: "include", include: "widgetV2UngroupedInclude" }
                ]
            },
            { key: "enableCloseButton", title: "Enable Close Button", type: "switch", default: true },
            { key: "disableBackdropExit", title: "Disable Exit On Backdrop Click", type: "switch", default: false },
            { key: "disableKeyboardExit", title: "Disable Exit On ESC", type: "switch", default: false },
            { key: "enableExitPopup", title: "Enable Exit Popup", type: "switch", default: false },
            { key: "autoShowAfter", title: "Auto Show After (seconds)", type: "text-field", default: "-1", pattern:"^(-1|[0-9]+)$" },
            { key: "instructions", type: "typography", variant: "caption", content: "To invoke your modal you need to call $('#your-modal-id').modal('show') or place a button or link having the attributes data-toggle=\"modal\" and data-target=\"#your-modal-id\"." }
        ]
    },
    {
        key: "mortgage-calculator", fields: [
            { key: "id", title: "ID", type: "text-field", required: true },
            {
                key: "calculateConfigNullable", type: "nullable", defaultNull: true, field:
                {
                    key: "calculateConfig", title: "Calculation Config", type: "nest", group: true, fields: [
                        { key: "monthlyPropertyTaxesRate", title: "Monthly Property Taxes Rate", type: "text-field", default: "0.000916667" },
                        { key: "monthlyHazardInsuranceRate", title: "Monthly Hazard Insurance Rate", type: "text-field", default: "0.000333333" },
                        { key: "monthlyPrivateMortgageInsuranceRate", title: "Monthly Private Mortgage Insurance Rate", type: "text-field", default: "0.0005" },
                        { key: "noPrivateMortageMaxLoanToValue", title: "No Private Mortage Max Loan To Value", type: "text-field", default: "0.8", tip: "PMI taxes will only be considered if the \"Loan Amount\" divided by \"Property Value\" is greater than this value." }
                    ]
                }
            },
            {
                key: "formInitialValuesNullable", type: "nullable", defaultNull: true, field:
                {
                    key: "formInitialValues", title: "Form Initial Values", type: "nest", group: true, fields: [
                        { key: 'salePriceOfProperty', title: 'Sale Price of Property', type: "text-field", default: "", },
                        { key: 'loanAmount', title: 'Loan Amount', type: "text-field", default: "100000" },
                        { key: 'annualInterestRate', title: 'Annual Interest Rate', type: "text-field", default: "4.0" },
                        { key: 'numberOfMonths', title: 'Number of Months', type: "select", default: "360", options: [{ value: "60", text: "5 years" }, { value: "120", text: "10 years" }, { value: "180", text: "15 years" }, { value: "240", text: "20 years" }, { value: "360", text: "30 years" }, { value: "", text: "Other" },] },
                        { key: 'numberOfMonthsCustom', title: 'Number of Months (Custom)', type: "text-field", default: "", tip: "This option will only show up if \"Number of Months\" is equals to \"Other\"." },
                        { key: 'amortizationSchedule', title: 'Amortization Schedule', type: "select", options: [{ value: 'monthly' }, { value: 'yearly' }], default: "monthly", tip: "Only affects the detailed table." },
                        { key: 'taxesAndInsuranceMode', title: 'Taxes And Insurance Mode', type: "select", default: "auto", values: [{ value: 'manual' }, { value: 'auto' }] },
                        { key: 'annualPropertyTaxes', title: 'Manual Annual Property Taxes', type: "text-field", default: "", tip: "Only available when \"Taxes And Insurance Mode\" equals to \"auto\"." },
                        { key: 'annualHazardInsurance', title: 'Manual Annual Hazard Insurance', type: "text-field", default: "", tip: "Only available when \"Taxes And Insurance Mode\" equals to \"auto\"." },
                        { key: 'monthlyPrivateMortgageInsurance', title: 'Manual Monthly Private Mortgage Insurance', type: "text-field", default: "", tip: "Only available when \"Taxes And Insurance Mode\" equals to \"auto\"." }
                    ]
                }
            },
            {
                key: "injects", title: "Injects", type: "data-nest", group: false, fields: [
                    { key: "beforeLoadPlaceholder", title: "Before Load Placeholder", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] },
                    { key: "resultsPlaceholder", title: "Results Placeholder", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] },
                    { key: "beforeResults", title: "Before Results", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] },
                    { key: "afterResults", title: "After Results", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] }
                ]
            }
        ]
    },
    {
        key: "home-value-info", fields: [
            { key: "id", title: "ID", type: "text-field", required: true },
            { key: "displayArea", title: "Display Area", type: "text-field", required: false, tip:"Sample: Redding. Leave it blank if you want to auto-detect it." },
            { key: "googleMapsKey", title: "Google Maps Key", type: "text-field", required: false },
            { key: "homeValueServerKey", title: "Home Value Server Key", type: "text-field", required: false },
            { key: "primaryAction", type:"data-nest", fields: [
                { key: "row", type: "row", cols: [
                    { weight: 1, fields: [{ key: "html", title: "Primary Btn Html", type: "text-field" }] },
                    { weight: 1, fields: [{ key: "href", title: "Primary Btn Href", type: "text-field" }] },
                    { weight: 1, fields: [{ key: "btnColor", title: "Override Variant", type: "select", includeOptions: "allButtonVariantOptions" },] },
                ]},
            ] },
            { key: "secondaryAction", type:"data-nest", fields: [
                { key: "row", type: "row", cols: [
                    { weight: 1, fields: [{ key: "html", title: "Secondary Btn Html", type: "text-field" }] },
                    { weight: 1, fields: [{ key: "href", title: "Secondary Btn Href", type: "text-field" }] },
                    { weight: 1, fields: [{ key: "btnColor", title: "Override Variant", type: "select", includeOptions: "allButtonVariantOptions" },] },
                ]},
            ]},
            { key: "modal", title: "Modal", type: "switch", defaultNull: true },
            { key: "stringResources", title: "String Resources", type: "nest", fields: [
                { key: "location-title", type: "typography", margin: "normal", variant: "body1", content: "Select Location" },
                { key: "location_title", type:"text-field", title: "Title", tip:"\"[AREA]\" will be replaced by the user area." },
                { key: "location_subtitle", type:"text-field", title: "Subtitle" },
                { key: "location_btn_confirm", type:"text-field", title: "Confirm Button" },
                { key: "ownerinfo-title", type: "typography", margin: "normal", variant: "body1", content: "Owner Information Form" },
                { key: "ownerinfo_title", type:"text-field", title: "Title" },
                { key: "ownerinfo_subtitle", type:"text-field", title: "Subtitle" },
                { key: "ownerinfo_title2", type:"text-field", title: "Title 2" },
                { key: "ownerinfo_generate_report", type:"text-field", title: "Generate Report Button" },
                { key: "report-title", type: "typography", margin: "normal", variant: "body1", content: "Report" },
                { key: "report_title_beingPrepared", type:"text-field", title: "Report Being Prepared Message" },
                { key: "report_title_ready", type:"text-field", title: "Report Ready Message" }
            ], },
            // {
            //     key: "injects", title: "Injects", type: "nest", group: true, nest: true, fields: [
            //         { key: "succeededReportTopWidget", title: "Succeeded Report Top", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] },
            //         { key: "failedReportTopWidget", title: "Failed Report Top Placeholder", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] },
            //     ]
            // }
        ]
    },
    {
        key: "house-purchase-wizard", fields: [
            { key: "id", title: "Id", type: "text-field", required: true },
            { key:"formAction", title: "Form Action", type: "text-field", required: true }
        ]
    },
    {
        key: "house-refinance-wizard", fields: [
            { key: "id", title: "Id", type: "text-field", required: true },
            { key:"formAction", title: "Form Action", type: "text-field", required: true }
        ]
    },
    {
        key: "prefab", fields: [
            { key: "name", title: "Name", tip: "Name must match an registered prefab.", type: "text-field", required: true }
        ]
    },
    {
        key: "refinance-calculator", fields: [
            { key: "id", title: "ID", type: "text-field", required: true },
            {
                key: "formInitialValuesNullable", type: "nullable", defaultNull: true, field:
                {
                    key: "formInitialValues", title: "Form Initial Values", type: "nest", group: true, fields: [
                        { key: "currentLoanGroupTitle", type: "typography", margin: "normal", variant: "body1", content: "Current Loan" },
                        {
                            key: "currentLoanGroup", type: "border-left", fields: [
                                { key: "cLoanBalance", title: "Loan Balance", type: "text-field", default: "200000" },
                                { key: "cAnnualInterestRate", title: "Annual Interest Rate", type: "text-field", default: "6.0" },
                                { key: "cRemainingMonths", title: "Remaining Months", type: "text-field", default: "360" },
                            ]
                        },
                        { key: "proposedLoandGroupTitle", type: "typography", margin: "normal", variant: "body1", content: "Proposed Loan" },
                        {
                            key: "newMortgageGroup", type: "border-left", fields: [
                                { key: "nAnnualInterestRate", title: "Annual Interest Rate", type: "text-field", default: "4.0" },
                                { key: 'nMonths', title: 'Number of Months', type: "select", default: "360", options: [{ value: "60", text: "5 years" }, { value: "120", text: "10 years" }, { value: "180", text: "15 years" }, { value: "240", text: "20 years" }, { value: "360", text: "30 years" }, { value: "", text: "Other" },] },
                                { key: 'nMonthsCustom', title: 'Number of Months (Custom)', type: "text-field", default: "", tip: "This option will only show up if \"Number of Months\" is equals to \"Other\"." },
                                { key: "nLoanOriginationFee", title: "Loan Origination Fee", type: "text-field", default: "1.0" },
                                { key: "nOtherPercentualFees", title: "Other Percentual Fees", type: "text-field", default: "0.0" },
                                { key: "nOtherFees", title: "Other Fees", type: "text-field", default: "2500" },
                            ]
                        }
                    ]
                }
            },
            {
                key: "injects", title: "Injects", type: "data-nest", group: false, fields: [
                    { key: "beforeLoadPlaceholder", title: "Before Load Placeholder", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] },
                    { key: "resultsPlaceholder", title: "Results Placeholder", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] },
                    { key: "beforeResults", title: "Before Results", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] },
                    { key: "afterResults", title: "After Results", type: "nest", fields: [{ key: "include", type: "include", include: "widgetV2Include" }] }
                ]
            }
        ]
    },
    {
        key: "reviews-carousel", fields: [
            // uiNameField("Reviews Carousel"),
            { key: "id", title: "ID", type: "text-field", required: true },
            { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: "white" },
            { key: "maxChars", title: "Review Text Max Length", type: "text-field", required: false },
            { key: "fluidItems", title: "Fluid Items", type: "switch", defaultNull: true },
            {
                key: "summaryNullable", type: "nullable", defaultNull: false, field: {
                    key: "summary", title: "Summary", type: "nest", fields: [
                        { key: "fluid", title: "Fluid", type: "switch", default: false }
                    ]
                }
            },
            {
                key: "jsonLDNullable", type: "nullable", defaultNull: true, field: {
                    key: "jsonLD", title: "JSON-LD", type: "nest", fields: [
                        { key: "name", title: "Name", type: "text-field", required: true },
                        { key: "description", title: "Description", type: "text-field" },
                    ]
                }
            },
            {
                key: "scopedSCSS", title: "Scoped SCSS", type: "code", language: "scss", default: `.rating-summary {
  .rating-stars{ font-size: 3rem; }
  text-align: center;
}
.review-date { display: inline-block; }
blockquote {
  font-style: italic;
  padding-left: 2rem;
  padding-right: 1rem;
  &::after, &::before { font-size: 3rem; opacity: .30; font-weight: bold; }
  &::before { content: '“'; position: absolute; margin-left: -2.5rem; }
  &::after { content: '”'; position: absolute; margin-left:0.5rem; }
}`      }
        ]
    },
    {
        key: "reviews-list", fields: [
            // uiNameField("Reviews List"),
            { key: "id", title: "ID", type: "text-field", required: true },
            { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: "white" },
            { key: "useDefaultIcons", title: "Use Default Icons", type: "switch", default: true },
            { key: "includeSummary", title: "Include Summary", type: "switch", default: true },
            {
                key: "jsonLDNullable", type: "nullable", defaultNull: true, field: {
                    key: "jsonLD", title: "JSON-LD", type: "nest", fields: [
                        { key: "name", title: "Name", type: "text-field", required: true },
                        { key: "description", title: "Description", type: "text-field" },
                    ]
                }
            },
            {
                key: "scopedSCSS", title: "Scoped SCSS", type: "code", language: "scss", default: `.rating-summary {
  .rating-stars{ font-size: 3rem; }
  text-align: center;
}
.review-date { display: inline-block; }
blockquote {
  font-style: italic;
  padding-left: 2rem;
  padding-right: 1rem;
  &::after, &::before { font-size: 3rem; opacity: .30; font-weight: bold; }
  &::before { content: '“'; position: absolute; margin-left: -2.5rem; }
  &::after { content: '”'; position: absolute; margin-left:0.5rem; }
}`      }
        ]
    },
    {
        key: "rotator", fields: [
            { key: "id", title: "ID", type: "text-field", required: true },
            {
                key: "engineExtender", type: "extender", selectorKey: "engine", nest: false, groupdata: false, clearOnChange: ["engineInterval", "engineSwapDuration"], fields: [
                    { key: "engine", title: "Rotation Engine", type: "select", default: "self", required: true, options: [{ value: "self" }, { value: "background-rotator" }] },
                ], types: [
                    {
                        key: "self", fields: [
                            { key: "engineInterval", title: "Interval", type: "text-field", default: "5000" },
                            { key: "engineSwapDuration", title: "Swap Duration", type: "text-field", default: "1000" },
                            { key: "showIndicators", title: "Show Indicators", type: "switch", default: false },
                            { key: "showNavArrows", title: "Show Navigations Arrows", type: "switch", default: false },
                            { key: "uiRGBColor", title: "UI RGB Color", type: "text-field", default: "rgb(0,0,0)" },
                        ]
                    },
                    {
                        key: "background-rotator", fields: [
                            { key: "engineSwapDuration", title: "Swap Duration", type: "text-field", default: "300" },
                        ]
                    }
                ]
            },
            { key: "transition", title: "Transtion", type: "select", options: [{ value: "none" }, { value: "fade" }, { value: "slide" }, { value: "slide-local" }] },
            {
                key: "items", title: "Items", type: "accordion", itemTitleKey: "uiName", fields: [
                    uiNameField(""),
                    { key: "include-widget-v2", type: "include", include: "widgetV2Include" }
                ]
            }
        ]
    },
    {
        key: "row", fields: [
            { key: "rowClassAppend", title: "Row Class Append", type: "text-field" },
            { key: "colClassDefault", title: "Column Class Default", type: "text-field", default: "col-md" },
            {
                key: "cols", title: "Columns", type: "accordion", itemTitleKey: "uiName", fields: [
                    uiNameField(""),
                    {
                        key: "col-setup", type: "row", cols: [
                            { weight: 1, fields: [{ key: "colClassOverride", title: "Column Class Override", type: "text-field", tip: "Fill this only if you want to override the default column class." },] },
                            { weight: 1, fields: [{ key: "colClassAppend", title: "Column Class Append", type: "text-field" },] }
                        ]
                    },
                    { key: "include-widget", type: "include", include: "widgetInclude" }
                ]
            }
        ]
    },
    {
        key: "row-v2", fields: [
            { key: "rowClassAppend", title: "Row Class Append", type: "text-field", tip: "Bootstrap 4 classes. Try: align-items-start | align-items-center | align-items-end ; justify-content-start | justify-content-center | justify-content-end | justify-content-around | justify-content-between ; no-gutters ; row-eq-height." },
            { key: "colClassDefault", title: "Column Class Default", type: "text-field", default: "col-md" },
            {
                key: "cols", title: "Columns", type: "accordion", itemTitleKey: "uiName", fields: [
                    uiNameField(""),
                    { key: "skipRender", title: "Skip Render", type: "switch", default: false },
                    {
                        key: "col-setup", type: "row", cols: [
                            { weight: 1, fields: [{ key: "colClassOverride", title: "Column Class Override", type: "text-field", tip: "Bootstrap 4 classes. The default is col-md (cols that breaks in mobile). Try using margin classes ( like mt-4 ) and order ( order-1, order-2, ... )." },] },
                            { weight: 1, fields: [{ key: "colClassAppend", title: "Column Class Append", type: "text-field" },] }
                        ]
                    },
                    { key: "include-widget-v2", type: "include", include: "widgetV2Include" }
                ]
            }
        ]
    },
    {
        key: "social-icons", fields: [
            { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions" },
            { key: "openInBlankPage", title: "Open In Blank Page", type: "switch", default: true, tip:"Uncheck if you want to avoid ADA issues." },
            {
                key: "items", title: "Icons", type: "accordion", itemTitleKey: "icon", fields: [
                    { key: "url", title: "URL", type: "text-field", required: true },
                    {
                        key: "icon", title: "Icon", default: "facebook", type: "select", options: [
                            { value: "allRedding" },
                            { value: "angiesList" },
                            { value: "bbb" },
                            { value: "custom" },
                            { value: "facebook" },
                            { value: "gmb" },
                            { value: "googlePlus" },
                            { value: "houzz" },
                            { value: "instagram" },
                            { value: "linkedIn" },
                            { value: "maps" },
                            { value: "pinterest" },
                            { value: "twitter" },
                            { value: "youTube" },
                            { value: "yelp" },
                            { value: "zillow" },
                        ]
                    },
                    { key: "customIcon", title: "Custom Icon", tip: "A SVG to override the selected icon.", type: "text-field" },
                    { key: "linkTitle", title: "Link Title", type: "text-field" },
                    {
                        key: "custom-extender", type: "extender", selectorKey: "icon", nest: false, groupdata: false, clearOnChange: ["key"], fields: [], types: [
                            {
                                key: "custom", fields: [
                                    { key: "key", title: "Key", tip: "A key to identify the social media icon.", type: "text-field", required: true },
                                ]
                            }
                        ]
                    },
                    {
                        key: "extender-all-redding-do-follow", type: "extender", selectorKey: "icon", nest: false, groupdata: false, clearOnChange: ["excludeRelNoFollow"], fields: [], types: [
                            {
                                key: "allRedding", fields: [
                                    { key: "excludeRelNoFollow", title: "Exclude rel=\"nofollow\"", type: "switch", default: true },
                                ]
                            },
                            {
                                key: "default", fields: [
                                    { key: "excludeRelNoFollow", title: "Exclude rel=\"nofollow\"", type: "switch" },
                                ]
                            }
                        ]
                    }

                ]
            }
        ]
    },
    {
        key: "tabs", fields: [
            // uiNameField("Tabs"),
            { key: "id", title: "ID", type: "text-field", required: true },
            { key: "spacing", title: "Spacing (vertical)", type: "text-field", required: true, default: "2" },
            { key: "contentPadding", title: "Content Padding", type: "text-field", required: true, default: "2" },
            { key: "itemsVariant", title: "Items Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: "light" },
            { key: "contentVariant", title: "Content Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: "white" },
            { key: "animated", title: "Animated", type: "switch", default: true },
            { key: "usePills", title: "Use Pills (not tabs)", type: "switch", default: false },
            { key: "tabsAligment", title: "Aligment", type: "select", default: "left", options: [{ text: "Not set", value: "" }, { value: 'fill' }, { value: 'justified' }, { value: 'left' }, { value: 'center' }, { value: 'right' }] },
            { key: "activeIndex", title: "Active Index", type: "text-field", required: false, default: "0" },
            {
                key: "items", title: "Tabs", type: "accordion", itemTitleKey: "id", fields: [
                    { key: "id", title: "Tab ID", type: "text-field" },
                    { key: "header", title: "Header", type: "text-field", required: true },
                    { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions" },
                    { key: "include-widget-v2", type: "include", include: "widgetV2Include" }
                ]
            },
        ]
    },
    {
        key: "wrapper", fields: [
            { key: "before", title: "Before", type: "code", language: "markdown", default: "" },
            { key: "include", type: "include", include: "widgetV2Include" },
            { key: "after", title: "After", type: "code", language: "markdown", default: "" },
        ]
    },
    {
        key: "video", fields: [
            { key: "id", title: "ID", type: "text-field", tip: "Optional. Only required if you want to use the follow feature." },
            // uiNameField("Video"),
            { key: "video", title: "Video", type: "youtube-pick", required: true },
            { key: "heightFactor", title: "Height Factor", type: "text-field", tip: "Default is 0.667 (3:2)" },
            { key: "delay", title: "Install Delay", type: "text-field", default: "0" },
            { key: "controls", title: "Controls", type: "switch", default: true },
            { key: "autoplay", title: "Autoplay", type: "switch", default: false },
            { key: "mute", title: "Mute", type: "switch", default: false },
            { key: "loop", title: "Loop", type: "switch", default: false },
            // { key: "showinfo", title: "Show Info", type:"switch", default: false },
            { key: "autohide", title: "Auto Hide", type: "switch", default: true },
            // { key: "modestbranding", title: "Modest Branding", type:"switch", default: true },
            {
                key: "followNullable", type: "nullable", defaultNull: true, field: {
                    key: "follow", type: "nest", title: "Follow Behavior", fields: [
                        {
                            key: "floatingSCSS", title: "Floating SCSS", type: "code", language: "scss", default: `/*
You can uncomment this to override defaults.
"right" and "bottom" are also valid attributes.
width: 33%;
left: 20px;
top: 20px;
*/`
                        }
                    ]
                }
            },
            { key: "disabletab", title: "Disable Tab Navigation", type: "switch", default: false }
        ]
    }
]

const wrappedWidgets = widgetTypes.map(({ key, fields }) => ({ key, fields: [{ key: "widgetBorder", type: "border-left", fields }] }));

const widgetIncludeFactory = (nest: boolean, groupdata: boolean, clearOnChange: boolean, selectorKey: string) => [
    {
        key: "widget", title: "Widget", titleKey: ["id", "widgetType", "type"], titleFallbackKey: "id", type: "extender", nest, groupdata, selectorKey, clearOnChange, fields: [
            {
                key: "row", type: "row", cols: [
                    {
                        weight: 1, 
                        fields: [
                            {
                                key: selectorKey, title: "Widget Type", type: "select", default: "empty", options: [
                                    { value: "accordion", text: "Accordion" },
                                    { value: "business-card", text: "Business Card" },
                                    { value: "button", text: "Button" },
                                    { value: "button-group", text: "Button Group" },
                                    { value: "column-v2", text: "Column" },
                                    { value: "empty", text: "Empty" },
                                    { value: "form", text: "Form" },
                                    { value: "html", text: "HTML" },
                                    { value: "html-rich", text: "HTML Editor (Beta)" },
                                    { value: "image", text: "Image" },
                                    { value: "map", text: "Map" },
                                    { value: "many", text: "Many" },
                                    { value: "custom", text: "Markdown" },
                                    { value: "menu", text: "Menu" },
                                    { value: "modal", text: "Modal" },
                                    { value: "prefab", text: "Prefab" },
                                    { value: "row-v2", text: "Row" },
                                    { value: "rotator", text: "Rotator" },
                                    { value: "social-icons", text: "Social Icons" },
                                    { value: "tabs", text: "Tabs" },
                                    { value: "wrapper", text: "Wrapper" },
                                    { value: "video", text: "Video" },
                                    {
                                        value: "calculators", text: "Loan & Mortage", options: [
                                            { value: "mortgage-calculator", text: "Mortgage Calculator" },
                                            { value: "refinance-calculator", text: "Refinance Calculator" },
                                            { value: "home-value-info", text: "Home Value Calculator" },
                                            { value: "house-refinance-wizard", text: "Home Refinance Wizard" },
                                            { value: "house-purchase-wizard", text: "Home Purchase Wizard" },
                                        ]
                                    },
                                    {
                                        value: "reviews", text: "Reviews", options: [
                                            { value: "reviews-carousel", text: "Reviews Carousel" },
                                            { value: "reviews-list", text: "Reviews List" }
                                        ]
                                    },
                                    {
                                        value: "deprecated", text: "Deprecated Widgets", options: [
                                            { value: "column", text: "Column V1 (Deprecated)" },
                                            { value: "row", text: "Row V1 (Deprecated)" },
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        weight: 0, fields: [
                            { key: "copy-paste", type: "copy-paste", group: "custom-website", component: "widget" }
                        ]
                    }
                ]
            }
        ], types: wrappedWidgets
    },
];
const widgetInclude = widgetIncludeFactory(false, false, false, "widgetType");
const widgetV2Include = widgetIncludeFactory(false, true, true, "type");
const widgetV2UngroupedInclude = widgetIncludeFactory(false, false, false, "type");

const formInclude = [
    { key: "formAction", title: "Form Action", type: "text-field", default: "/contact", required: true, tip: "Tip: You can point your forms to services like Zapier and Formspree." },
    { key: "formClass", title: "Form Class", type: "text-field", required: false, tip: "CSS class." },
    {
        key: "submitType", title: "Submit Type", type: "select", default: "ajax-json-post", required: true, options: [
            { value: "ajax-url-encoded-post", text: "AJAX URL Encoded POST" },
            { value: "ajax-json-post", text: "AJAX JSON POST" },
            { value: "regular-post", text: "Regular POST" },
            { value: "regular-get", text: "Regular GET" },
            { value: "ajax", text: "AJAX (Deprecated)" },
            { value: "regular", text: "Regular (Deprecated)" },
        ]
    },
    { key: "secure", type: "switch", default: true, title: "Use Secure Form" },
    { key: "useNameField", type: "switch", default: true, title: "Use Default Name Field" },
    { key: "useEmailField", type: "switch", default: true, title: "Use Default Email Field" },
    { key: "useSubjectField", type: "switch", default: false, title: "Use Default Subject Field" },
    { key: "useMessageField", type: "switch", default: true, title: "Use Default Message Field" },
    { key: "messageLabel", title: "Message Label", type: "text-field", default: "Message", required: true },
    { key: "submitLabel", title: "Submit Label", type: "text-field", default: "Submit", required: true },
    { key: "submitVariant", title: "Submit Variant", type: "select", options: allButtonVariantOptions },
    {
        key: "messageFields", title: "Extra Fields", type: "accordion", itemTitleKey: "label", fields: [
            { key: "inputExtenderInclude", type: "include", include: "inputExtenderInclude", nest: false }
        ]
    },
    {
        key: "successCallbackActions", title: "Success Callback Actions", type: "accordion", itemTitleKey: "uiName", fields: [
            { key: "formCallbackInclude", type: "include", include: "formCallbackInclude" },
        ]
    },
    {
        key: "failCallbackActions", title: "Fail Callback Actions", type: "accordion", itemTitleKey: "uiName", fields: [
            { key: "formCallbackInclude", type: "include", include: "formCallbackInclude" },
        ]
    },
]


const triggerActionsInclude = [
    uiNameField('', 'action'),
    {
        key: "triggerActionsExtender", type: "extender", nest: false, groupdata: false, selectorKey: "type",
        fields: [
            { key: "type", title: "Type", type: "select", options: [{ value: "alert" }, { value: "dispatchEventToTrackers" }, { value: "hideClosestAncestorModal" }, { value: "js" }, { value: "openModal" }, , { value: "hideModal" }], default: "js" },
            { key: "timeout", title: "Timeout", type: "text-field", pattern: "^[0-9]+$", required: false, tip: "The number of milisseconds to wait before invoking the action." }
        ],
        types: [
            {
                key: "alert", fields: [
                    { key: "text", title: "Message", type: "text-field", required: true },
                ]
            },
            {
                key: "dispatchEventToTrackers", fields: [
                    { key: "eventName", title: "Event Name", type: "text-field", required: true },
                ]
            },
            { key: "hideClosestAncestorModal", fields: [] },
            {
                key: "js", fields: [
                    { key: "js", title: "Javascript", type: "code", language: "javascript", default: "console.log('Callback triggered on element: ', element);", required: true },
                ]
            },
            {
                key: "openModal", fields: [
                    { key: "target", title: "Target ID", type: "text-field", required: true },
                ]
            },
            {
                key: "hideModal", fields: [
                    { key: "target", title: "Target ID", type: "text-field", required: true },
                ]
            },
        ]
    }
]

const formCallbackInclude = [
    uiNameField('', 'action'),
    {
        key: "callbackExtender", type: "extender", nest: false, groupdata: false, selectorKey: "type",
        fields: [
            { key: "type", title: "Type", type: "select", options: [{ value: "alert" }, { value: "hideClosestAncestorModal" }, { value: "js" }, { value: "openModal" }, { value: "hideModal" }], default: "js" },
            { key: "timeout", title: "Timeout", type: "text-field", pattern: "^[0-9]+$", required: false, tip: "The number of milisseconds to wait before invoking the action." }
        ],
        types: [
            {
                key: "alert", fields: [
                    { key: "text", title: "Message", type: "text-field", required: true },
                ]
            },
            { key: "hideClosestAncestorModal", fields: [] },
            {
                key: "js", fields: [
                    { key: "js", title: "Javascript", type: "code", language: "javascript", default: "console.log('Callback triggered on element: ', element);", required: true },
                ]
            },
            {
                key: "openModal", fields: [
                    { key: "target", title: "Target ID", type: "text-field", required: true },
                ]
            },
            {
                key: "hideModal", fields: [
                    { key: "target", title: "Target ID", type: "text-field", required: true },
                ]
            },
        ]
    }
]

const contactFormFields = (extraFields: Array<any>) => [
    {
        key: "contactForm", title: "Contact Form", type: "extender", selectorKey: "type", nest: true, groupdata: true, fields: [
            { key: "type", title: "Type", type: "select", default: "default", required: true, options: [{ value: "default" }, { value: "generic" }] },
            { key: "id", title: "ID", type: "text-field", required: true },
            { key: "title", title: "Title", type: "text-field" },
            ...extraFields
        ], types: [
            {
                key: "generic", fields: [
                    { key: "content", title: "Content", type: "code", language: "markdown", default: "<form><!--some contact form--></form>" },
                    ...cssAndJsBare()
                ]
            },
            {
                key: "default", fields: [
                    { key: "formInclude", type: "include", include: "formInclude" }
                ]
            }
        ]
    },
];

const advancedOptionsBareInclude = [
    { key: "sectionClass", title: "Section Class", type: "text-field" },
    { key: "fluidContainer", title: "Fluid Container", type: "switch", default: false },
    { key: "skipTitleTransformation", title: "Skip Title Transformation", type: "switch", default: false },
    {
        key: "backgroundExtender", type: "extender", selectorKey: "backgroundType", nest: false, groupdata: false, clearOnChange: ["backgroundImage", "mobileBackgroundImage", "imageRotatorSetup", 'backgroundType'], fields: [
            { key: "backgroundType", type: "select", title: "Background Type", default: "static-image", options: [{ value: "static-image" }, { value: "image-rotator" }, { value: 'widget' }] },
        ], types: [
            {
                key: "widget", fields: [
                    {
                        key: "backgroundWidget", type: "data-nest", fields: [
                            { key: "include-widget", type: "include", include: "widgetV2Include" }
                        ]
                    },
                ]
            },
            {
                key: "static-image", fields: [
                    { key: "backgroundImage", title: "Background Image", type: "image-upload" },
                    { key: "mobileBackgroundImage", title: "Mobile Background Image", type: "image-upload" },
                    { key: "backgroundColor", title: "Background Color", type: "color-text-field" },
                    { key: "lazyBackground", title: "Lazy Load Background", type: "switch", default: false },
                ]
            },
            {
                key: "image-rotator", fields: [
                    {
                        key: "imageRotatorSetup", type: "data-nest", fields: [
                            { key: "interval", title: "Interval", type: "text-field", default: "6000" },
                            { key: "swapDuration", title: "Swap Duration", type: "text-field", default: "1000" },
                            { key: "showIndicators", title: "Show Indicators", type: "switch", default: false },
                            { key: "showNavArrows", title: "Show Navigations Arrows", type: "switch", default: false },
                            { key: "uiRGBColor", title: "UI RGB Color", type: "text-field", default: "rgb(0,0,0)" },
                            {
                                key: "items", title: "Items", type: "accordion", fields: [
                                    { key: "backgroundImage", title: "Background Image", type: "image-upload" },
                                    { key: "mobileBackgroundImage", title: "Mobile Background Image", type: "image-upload" },
                                    { key: "backgroundColor", title: "Background Color", type: "color-text-field" },
                                ]
                            }
                        ]
                    },
                ]
            },
        ]
    },
    { key: "overlayColor", title: "Overlay Color", type: "color-text-field" },
    { key: "onReadyScript", title: "On Ready Script", type: "code", language: "javascript", tip: "Use ${blockId} in your scripts." },
    { key: "scopedSCSS", title: "Scoped SCSS", type: "code", language: "scss" },
];


const advancedOptions = (extra: Array<any> = []) => {
    return [{
        key: "advanced-options", title: "Block Advanced Options", groupdata: false, type: "nest", fields: [
            { key: "advancedOptionsInclude", type: "include", include: "advancedOptionsBareInclude" },
            ...extra
        ]
    }];
}

const defaultBlockFields = (defaults: any = {}) => ([
    { key: "id", title: "ID", type: "text-field", required: true, default: defaults["id"] },
    { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: defaults["variant"] },
    { key: "layout", title: "Layout", type: "select", includeOptions: "allLayouts", default: defaults["layout"] },
    {
        key: "title-row", type: "row", cols: [
            { weight: 1, fields: [{ key: "title", title: "Title", type: "text-field", default: defaults["title"] },] },
            { weight: 1, fields: [{ key: "subtitle", title: "Subtitle", type: "text-field", default: defaults["subtitle"] },] }
        ]
    },
    { key: "video", title: "Video", type: "youtube-pick", default: defaults["video"] },
    ...imgFields("banner", "Banner", false, defaults["banner"]),
    { key: "content", title: "Content", type: "code", language: "markdown", default: defaults["content"] },
]);

const simplerDefaultBlockFields = (defaults: any = {}) => ([
    { key: "id", title: "ID", type: "text-field", required: true, default: defaults["id"] },
    { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: defaults["variant"] },
    {
        key: "title-row", type: "row", cols: [
            { weight: 1, fields: [{ key: "title", title: "Title", type: "text-field", default: defaults["title"] },] },
            { weight: 1, fields: [{ key: "subtitle", title: "Subtitle", type: "text-field", default: defaults["subtitle"] },] }
        ]
    },
    { key: "layout", title: "Space After Title", type: "select", options: [{ value: "empty", text: "None" }, { value: "space", text: "Yes" }] },
]);

const defaultSubBlockFields = (defaults: any = {}) => ([
    { key: "id", title: "ID", type: "text-field", required: true, default: defaults["id"] },
    { key: "layout", title: "Layout", type: "select", includeOptions: "allSubLayouts", default: defaults["layout"] },
    { key: "title", title: "Title", type: "text-field", default: defaults["title"] },
    { key: "subtitle", title: "Subtitle", type: "text-field", default: defaults["subtitle"] },
    { key: "video", title: "Video", type: "youtube-pick", default: defaults["video"] },
    { key: "banner", title: "Banner", type: "image-upload", default: defaults["banner"] },
    { key: "content", title: "Content", type: "code", language: "markdown", default: defaults["content"] },
]);

const blockIncludeFactory = (nest: boolean, groupdata: boolean, prefabNames?:any) => [
    {
        key: "block", title: "Block", type: "extender", selectorKey: "type", nest, groupdata,
        fields: [
            {
                key: "config", type: "row", cols: [
                    {
                        weight: 2, fields: [
                            {
                                key: "type", title: "Type", type: "select", default: "widget-block-v3", options: [
                                    { value: "anchor-block", text: "Anchor" },
                                    { value: "action-bar-block", text: "Action Bar" },
                                    { value: "cards-gallery-block", text: "Cards Gallery" },
                                    { value: "jumbotron-with-form", text: "Jumbotron With Form" },
                                    { value: "google-maps-block", text: "Google Maps" },
                                    { value: "horizontal-image-display-block", text: "Horizontal Image Display" },
                                    { value: "many-block", text: "Many" },
                                    { value: "prefab-block", text: "Prefab (Beta)" },
                                    { value: "services-block", text: "Services" },
                                    { value: "scrollable-horizontal-image-display-block", text: "Scrollable Horizontal Image Display" },
                                    { value: "widget-block-v3", text: "Widget Block" },
                                    { value: "technicalseo-snippet", text: "Technical SEO Snippet" },
                                    {
                                        value: "deprecated", text: "Deprecated Blocks", options: [
                                            { value: "contact-block", text: "Contact" },
                                            { value: "custom-links-block", text: "Custom Links" },
                                            { value: "generic-block", text: "Generic" },
                                            { value: "reviews-widget-block", text: "Reviews Carousel (Grade.us)" },
                                            { value: "reviews-full-block", text: "Reviews List (Grade.us)" },
                                            { value: "team-block", text: "Team" },
                                            { value: "widget-block-v2", text: "Widget Block V2" },
                                            { value: "widget-block", text: "Widget Block V1" },
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        weight: 0, fields: [
                            { key: "skipRender", type: "switch", default: false, title: "Skip Render" }
                        ]
                    },
                    {
                        weight: 0, fields: [
                            { key: "copy-paste", type: "copy-paste", group: "custom-website", component: "block" }
                        ]
                    }
                ]
            },
        ],
        types: [
            {
                key: "anchor-block", fields: [
                    { key: "id", title: "ID", type: "text-field", required: true },
                ]
            },
            {
                key: "action-bar-block", fields: [
                    { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions" },
                    {
                        key: "buttons", title: "Buttons", type: "accordion", itemTitleKey: "label", fields: [
                            { key: "label", title: "Label", type: "text-field", required: true },
                            { key: "href", title: "Href", type: "text-field", required: true },
                            { key: "variant", title: "Variant", type: "select", includeOptions: "allButtonVariantOptions" },
                        ]
                    },
                ]
            },
            {
                key: "horizontal-image-display-block", fields: [
                    ...defaultBlockFields({
                        id: "horizontal-image-display",
                        title: "Horizontal Item Display",
                    }),
                    { key: "fullWidth", title: "Full Width", type: "switch", default: false },
                    { key: "noGutters", title: "No Gutters", type: "switch", default: true },
                    {
                        key: "items", title: "Items", type: "accordion", itemTitleKey: "label", fields: [
                            ...imgFields("src", "Image", true),
                            { key: "label", title: "label", type: "text-field" },
                            { key: "href", title: "Href", type: "text-field" },
                        ]
                    },
                    ...advancedOptions()
                ]
            },
            {
                key: "scrollable-horizontal-image-display-block", fields: [
                    ...defaultBlockFields({
                        id: "scrollable-horizontal-image-display",
                        title: "Scrollable Horizontal Item Display",
                    }),
                    { key: "leftArrow", title: "Left Arrow", type: "text-field" },
                    { key: "rightArrow", title: "Right Arrow", type: "text-field" },
                    { key: "fullWidth", title: "Full Width", type: "switch", default: false },
                    {
                        key: "items", title: "Items", type: "accordion", fields: [
                            { key: "content", title: "Content", type: "code", language: "markdown" },
                        ]
                    },
                    ...advancedOptions()
                ]
            },
            {
                key: "prefab-block", fields: prefabNames ? [
                    { key: "name", type: "select", title: "Name", options: prefabNames, required: "true" },

                ] : [
                    { key: "name", type: "text-field", title: "Name", required: "true" },
                ]
            },
            {
                key: "many-block", fields: [
                    uiNameField(""),
                    blocksAccordion("items", "Blocks")
                ]
            },
            {
                key: "jumbotron-with-form", fields: [
                    { key: "id", title: "ID", type: "text-field", required: true, default: "jumbotron-with-form" },
                    { key: "backgroundImage", title: "Background Image", type: "image-upload", required: true, default: "https://images.pexels.com/photos/567540/pexels-photo-567540.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
                    { key: "mobileBackgroundImage", title: "Mobile Background Image", type: "image-upload", required: true, default: "https://images.pexels.com/photos/62289/yemen-chameleon-chamaeleo-calyptratus-chameleon-reptile-62289.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
                    { key: "backgroundColor", title: "Background Color", type: "color-text-field" },
                    { key: "overlayColor", title: "Overlay Color", type: "color-text-field" },
                    { key: "minHeight", title: "Min Height", type: "text-field", default: "70vh" },
                    { key: "textColor", title: "Text Color", type: "color-text-field", default: "white" },
                    { key: "title", title: "Title", type: "text-field", required: true },
                    { key: "subtitle", title: "Subtitle", type: "text-field" },
                    { key: "content", title: "Content", type: "code", language: "markdown", default: "# Write Something Awesome!\n\n## Multiple times." },
                    {
                        key: "actionButton", title: "Action Button", type: "nest", fields: [
                            { key: "label", title: "Label", type: "text-field", required: true },
                            { key: "href", title: "Href", type: "text-field", required: true },
                            { key: "variant", title: "Variant", type: "select", options: allButtonVariantOptions },
                        ]
                    },
                    ...contactFormFields([
                        { key: "backgroundColor", title: "Background Color", type: "color-text-field", default: "rgba(0,0,0,.15)" },
                        { key: "textColor", title: "Text Color", type: "color-text-field", default: "white" },
                    ]),
                    scopedSCSS()
                ]
            },
            {
                key: "cards-gallery-block", fields: [
                    ...defaultBlockFields({
                        id: "gallery",
                        title: "Gallery",
                    }),
                    { key: "itemsPerLine", title: "Items Per Line", type: "text-field", default: "3" },
                    { key: "mobileItemsPerLine", title: "Mobile Items Per Line", type: "text-field", default: "2" },
                    { key: "visibleLines", title: "Visible Lines", type: "text-field", default: "-1" },
                    { key: "showMoreVariant", title: "Button Show More Variant", type: "select", includeOptions: "allButtonVariantOptions", default: "secondary" },
                    { key: "cardDefaultVariant", title: "Card Default Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: "light" },
                    { key: "noGutters", type: "switch", default: false, title: "No Gutters" },
                    {
                        key: "cards", title: "Cards", type: "accordion", itemTitleKey: "title", fields: [
                            { key: "renderAsEmpty", type: "switch", default: false, title: "Empty Widget" },
                            { key: "variant", title: "Variant", type: "select", includeOptions: "allButtonVariantOptions" },
                            { key: "title", title: "Title", type: "text-field" },
                            { key: "subtitle", title: "Subtitle", type: "text-field" },
                            ...imgFields("banner", "Banner", false, "https://images.pexels.com/photos/2072476/pexels-photo-2072476.png?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"),
                            { key: "content", title: "Content", type: "code", language: "markdown" },
                            { key: "href", title: "Href", type: "text-field" },
                        ]
                    },
                    ...advancedOptions()
                ]
            },
            {
                key: "generic-block", fields: [
                    ...defaultBlockFields({
                        title: "Some Title",
                        subtitle: "Some subtitle.",
                        banner: "https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
                        content: "Some content."
                    }),
                    ...advancedOptions()
                ]
            },
            {
                key: "team-block", fields: [
                    ...defaultBlockFields({
                        id: "team-members",
                        title: "Team Members",
                        subtitle: "See Our Team",
                    }),
                    {
                        key: "members", title: "Members", itemTitleKey: "title", type: "accordion", fields: [
                            { key: "title", title: "Title", type: "text-field" },
                            { key: "subtitle", title: "Subtitle", type: "text-field" },
                            ...imgFields("picture", "Picture", false, "/img/sample-pic.jpg"),
                            { key: "props", title: "Properties", type: "accordion", itemTitleKey: "label", fields: propsField },
                            { key: "content", title: "Content", type: "code", language: "markdown" },
                        ]
                    },
                    ...advancedOptions()
                ]
            },
            {
                key: "services-block", fields: [
                    ...defaultSubBlockFields({
                        id: "services",
                        title: "Services",
                        subtitle: "What We Do",
                        content: "A content block."
                    }),
                    {
                        key: "services", title: "Services", type: "accordion", itemTitleKey: "title", fields: [
                            ...defaultBlockFields({
                                id: "service",
                                title: "Service",
                                subtitle: "This is our service.",
                                content: "But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences.",
                                layout: 'two-cols',
                                video: '1wkPMUZ9vX4',
                            })
                        ]
                    },
                    ...advancedOptions()
                ]
            },
            {
                key: "reviews-widget-block", fields: [
                    ...defaultBlockFields({
                        id: "reviews",
                        title: "Customer Reviews",
                        subtitle: "See What Others Say About Us",
                        layout: 'single-col'
                    }),
                    { key: "btnSeeAllVariant", title: "Submit Variant", type: "select", includeOptions: "allButtonVariantOptions", default: "secondary" },
                    ...advancedOptions()
                ]
            },
            { key: "reviews-full-block", fields: [] },
            {
                key: "custom-links-block", fields: [
                    ...defaultBlockFields({
                        id: "service",
                        title: "Resources",
                        layout: 'single-col'
                    }),
                    ...imgFields("sideBanner", "Side Banner", false),
                    { key: "reverseSideBanner", title: "Reverse Side Banner", type: "switch", default: false },
                    {
                        key: "sections", title: "Sections", type: "accordion", itemTitleKey: "title", fields: [
                            { key: "title", title: "Title", type: "text-field" },
                            {
                                key: "links", title: "Links", type: "accordion", itemTitleKey: "href", fields: [
                                    { key: "label", title: "Label", type: "text-field" },
                                    { key: "href", title: "Href", type: "text-field", required: true },
                                ]
                            },
                        ]
                    },
                    ...advancedOptions()
                ]
            },
            {
                key: "google-maps-block", fields: [
                    { key: "id", title: "ID", type: "text-field", required: true, default: "google-maps" },
                    { key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: "light" },
                    { key: "embedSrc", title: "Embed Source", type: "text-field", default: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10748533.893070618!2d-104.77402857112185!3d38.87675058061799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sEstados+Unidos!5e0!3m2!1spt-BR!2sbr!4v1554493424578!5m2!1spt-BR!2sbr" },
                    { key: "height", title: "Height", type: "text-field", default: "350" },
                ]
            },
            {
                key: "contact-block", fields: [
                    ...defaultBlockFields({
                        id: "contact",
                        title: "Contact",
                        subtitle: "Contact Us",
                        variant: "primary"
                    }),
                    {
                        key: "reachUs", title: "Reach Block", type: "nest", fields: [
                            { key: "title", title: "Title", type: "text-field" },
                            { key: "subtitle", title: "Subtitle", type: "text-field" },
                            { key: "content", title: "Content", type: "code", language: "markdown" },
                            { key: "props", title: "Properties", type: "accordion", itemTitleKey: "label", fields: propsField },
                        ]
                    },
                    { key: "socialVariant", title: "Social Buttons Variant", type: "select", includeOptions: "allBackgroundVariantOptions", default: "primary-darker" },
                    {
                        key: "socialLinks", title: "Social Links", type: "nest", fields: [
                            { key: "facebook", title: "Facebook", type: "text-field", default: "" },
                            { key: "twitter", title: "Twitter", type: "text-field", default: "" },
                            { key: "googlePlus", title: "GooglePlus", type: "text-field", default: "" },
                            { key: "gmb", title: "Google My Business", type: "text-field", default: "" },
                            { key: "yelp", title: "Yelp", type: "text-field", default: "" },
                            { key: "instagram", title: "Instagram", type: "text-field", default: "" },
                            { key: "linkedIn", title: "LinkedIn", type: "text-field", default: "" },
                            { key: "youTube", title: "YouTube", type: "text-field", default: "" },
                            { key: "zillow", title: "Zillow", type: "text-field", default: "" },
                            { key: "maps", title: "Maps", type: "text-field", default: "" },
                            {
                                key: "custom", title: "Custom", type: "accordion", itemTitleKey: "title", fields: [
                                    { key: "title", title: "Title", type: "text-field", default: "" },
                                    { key: "link", title: "Link", type: "text-field", default: "" },
                                    { key: "icon", title: "Icon", type: "text-field", default: "" },
                                    { key: "trackingKey", title: "Tracking key", type: "text-field", default: "" },
                                ]
                            },
                        ]
                    },
                    ...contactFormFields([]),
                    ...advancedOptions()
                ]
            },
            {
                key: "widget-block", fields: [
                    ...defaultBlockFields({
                        title: "Some Title",
                        subtitle: "Some subtitle.",
                        banner: "https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
                        content: "Some content."
                    }),
                    { key: "widget-include", type: "include", include: "widgetInclude" },

                    ...advancedOptions()
                ]
            },
            {
                key: "widget-block-v2", fields: [
                    ...defaultBlockFields({
                        title: "Some Title",
                        subtitle: "Some subtitle.",
                        banner: "https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
                        content: "Some content."
                    }),
                    { key: "widgetFluidContainer", title: "Widget Fluid Container", type: "switch", default: false },
                    { key: "widget-include", type: "include", include: "widgetV2Include" },
                    ...advancedOptions()
                ]
            },
            {
                key: "widget-block-v3", fields: [
                    ...simplerDefaultBlockFields({
                        title: "Some Title",
                        subtitle: "Some subtitle.",
                    }),
                    { key: "content", type: "hidden", default: "<div></div>" },
                    { key: "widgetFluidContainer", title: "Widget Fluid Container", type: "switch", default: false },
                    { key: "widget-include", type: "include", include: "widgetV2Include" },
                    ...advancedOptions()
                ]
            },
            {
                key: "technicalseo-snippet", fields: [
                    { key: "content", title: "Content", type: "code", language: "markdown" },
                    // { key: "widget-include", type: "include", include: "widgetInclude" },
                ]
            },

        ],
    }
];

const blockUngroupedInclude = blockIncludeFactory(false, false);
const blockInclude = blockIncludeFactory(false, true);

function blocksAccordion(key: string, title: string) {
    return {
        key, title, itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
            { key: "blockInclude", type: "include", "include": "blockUngroupedInclude" }
        ]
    }
}

const htmlAttributesInclude = [
    {
        key: "attributes", title: "HTML Attributes", type: "accordion", itemTitleKey: "key", fields: [
            {
                key: "row", type: "row", cols: [
                    { weight: 1, fields: [{ key: "key", title: "Key", type: "text-field", required: true },] },
                    { weight: 2, fields: [{ key: "value", title: "Value", type: "text-field", required: true },] }
                ]
            },
        ]
    }
];

const menuItemInclude = [
    {
        "key": "itemProps", type: "extender", nest: false, groupdata: false, selectorKey: "type", fields: [
            { key: "type", title: "Type", type: "select", default: "link", options: [{ value: "link" }, { value: "submenu" }, { value: "fragment" }] },
        ], types: [
            {
                key: "link", fields: [
                    { key: "name", title: "Label", type: "text-field", required: true },
                    { key: "url", title: "URL", type: "text-field", required: true },
                    { key: "openOnNewWindow", title: "Open On New Window", type: "switch", default: false },
                    { key: "variant", title: "Button Variant", type: "select", options: allButtonVariantOptions },
                    {
                        key: "actions", title: "Click Actions", type: "accordion", itemTitleKey: "uiName", fields: [
                            { key: "triggerActionsInclude", type: "include", include: "triggerActionsInclude" },
                        ]
                    },
                    { key: "includeAttributes", type: "include", include: "htmlAttributesInclude" },
                    {
                        key: 'rowShowOn', type: "row", cols: [
                            { fields: [{ key: "stickyMenu", title: "Show On Sticky", type: "switch", default: true },], weight: 1 },
                            { fields: [{ key: "topMenu", title: "Show On Top", type: "switch", default: true },], weight: 1 },
                            { fields: [{ key: "mobileMenu", title: "Show On Mobile", type: "switch", default: true },], weight: 1 },
                        ]
                    },
                ]
            },
            {
                key: "submenu", fields: [
                    { key: "name", title: "Label", type: "text-field", required: true },
                    { key: "url", title: "URL", type: "text-field", default: "#" },
                    { key: "variant", title: "Button Variant", type: "select", options: allButtonVariantOptions },
                    { key: "alignment", title: "Aligment", type: "select", default: 'left', options: [{ value: 'left' }, { value: 'center' }, { value: 'right' }] },
                    {
                        key: "items", title: "Items", type: "accordion", itemTitleKey: "name", fields: [
                            { key: "items-include", type: "include", include: "menuItemInclude" },
                        ]
                    },
                    {
                        key: 'rowShowOn', type: "row", cols: [
                            { fields: [{ key: "stickyMenu", title: "Show On Sticky", type: "switch", default: true },], weight: 1 },
                            { fields: [{ key: "topMenu", title: "Show On Top", type: "switch", default: true },], weight: 1 },
                            { fields: [{ key: "mobileMenu", title: "Show On Mobile", type: "switch", default: true },], weight: 1 },
                        ]
                    },
                ]
            },
            {
                key: "fragment", fields: [
                    { key: "name", title: "Name", type: "text-field", required: true, tip: "The fragment Name to be expanded." },
                    {
                        key: 'rowShowOn', type: "row", cols: [
                            { fields: [{ key: "stickyMenu", title: "Show On Sticky", type: "switch", default: true },], weight: 1 },
                            { fields: [{ key: "topMenu", title: "Show On Top", type: "switch", default: true },], weight: 1 },
                            { fields: [{ key: "mobileMenu", title: "Show On Mobile", type: "switch", default: true },], weight: 1 },
                        ]
                    },
                ]
            }
        ]
    }
];

const allOptions: { [key: string]: any } = {
    allButtonVariantOptions,
    allLayouts,
    allSubLayouts,
    allBackgroundVariantOptions
}

const getOptions: (key: string) => any = (key) => {
    return allOptions[key as any];
}

export default {
    advancedOptions,
    blocksAccordion,
    contactFormFields,
    defaultSubBlockFields,
    imgFields,
    imgWidthAndHeight,
    propsField,
    allButtonVariantOptions,
    scopedSCSS,
    allBackgroundVariantOptions,
    defaultBlockFields,
    includes: {
        advancedOptionsBareInclude,
        blockInclude,
        blockUngroupedInclude,
        formInclude,
        formCallbackInclude,
        inputExtenderInclude,
        menuItemInclude,
        widgetInclude,
        widgetV2Include,
        widgetV2UngroupedInclude,
        triggerActionsInclude,
        htmlAttributesInclude
    },
    getOptions,
    blockIncludeFactory
} as any
import * as React from 'react';
import { ComponentProps, CrawlContext, NormalizeStateContext, FieldBase, DynamicFormNode, ComponentContext } from '../HoForm';
import { BaseDynamic } from '../HoForm';
import { TextField, Checkbox } from '@material-ui/core';

type NullableDynamicField = {
    key: string, 
    type: string,
    defaultNull?: boolean,
    title?: string,
    field: FieldBase
}

type NullableDynamicState = {
    
}

export class NullableDynamic extends BaseDynamic<NullableDynamicField,NullableDynamicState> {

    constructor(props: ComponentProps<NullableDynamicField>){
        super(props);
    }

    getValue(context: ComponentContext<NullableDynamicField>): any{
        return context.node.state[context.node.field.field.key];
    }

    getValueFromNode(node: DynamicFormNode<NullableDynamicField>): any{
        return node.state[node.field.field.key];
    }

    setValue(context: ComponentContext<NullableDynamicField>, value : any): void{
        context.node.state[context.node.field.field.key] = value;
    }

    buildDisplayPathFragment(node: DynamicFormNode<NullableDynamicField>, nodeLevel: number, nodes: Array<DynamicFormNode<FieldBase>>): string|null {
        return null;
    }

    normalizeState({state, field, stateBuilder}: NormalizeStateContext<NullableDynamicField>){
        let key = field.field.key;
        if(state[key]===undefined){
            if(field.defaultNull===true){
                state[key] = null;
            }
            else{
                stateBuilder.setLevelState(state, [field.field]);
            }
        }
    }

    getType(){
        return 'nullable';
    }

    handleChange = (e: any)=>{
        const isNull = !e.currentTarget.checked;
        const context = this.props.context;
        if(isNull){
            context.setValue(null);
        }
        else{
            const node = context.node;
            context.form.stateBuilder.setLevelState(node.state, [node.field.field]);
            context.form.forceUpdate();
        }
    }

    crawlComponent({form, node} : CrawlContext<NullableDynamicField>): void{
        const value = this.getValueFromNode(node);
        if(value!==null){
            const newNode = { ...node, field:node.field.field };
            form.crawlField(newNode);
        }        
    }

    renderComponent(){
        
        let {context} = this.props;
        let {node, currentPath, parentPath, nodePath} = context;
        let {field} = node;
                
        const isNull = context.value===null;

        if(currentPath===parentPath){
            return (
                <div style={{position: 'relative'}}>
                    <div style={{position: "absolute"}}>
                        <Checkbox
                            checked={!isNull}
                            onChange={this.handleChange}
                            value="checkedA"
                        />
                    </div>
                    <div style={{ marginLeft: '50px'}}>
                        { isNull ? (
                            <TextField fullWidth={true} margin="dense" value="Null" disabled={true} label={field.title || (field.field as any).title} />
                        ):(
                            context.form.renderField({ ...node, field: field.field })
                        )}
                    </div>
                    
                </div>
            );
        }

        if(!isNull && currentPath.startsWith(nodePath)){
            return context.form.renderField({ ...node, field: field.field });
        }

        return (null);
    }
}
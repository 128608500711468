import * as React from 'react'
import { Select, MenuItem, Paper, Button, Chip } from '@material-ui/core'
import { DebouncedTextField } from '../components/DebouncedTextField';
import { serviceLocator } from '../services';
import { MainPadding } from '../components/MainPadding';
import DangerButton from '../_ho-forms-lib/src/MaterialComponents/DangerButton';
import { TransferWithinAStationOutlined } from '@material-ui/icons';
import { AppSection } from '../components/AppSection';

interface Record {
  initialValue: string;
  value: string;
  dirty: boolean;
  valid: boolean;
  _id: string;
}

interface State {
  table: string;
  items: Array<Record>;
  focusedIndex: number;
  tables: string[];
}

const databaseService = serviceLocator.getDatabaseService();

export class Database extends React.Component<any, any>{

  state: State = {
    table: "websites",
    items: [],
    focusedIndex: -1,
    tables: ['websites']
  }

  handleItemChange = (e: any) => {
    const value = e.target.value;
    let valid = false;
    try{
      JSON.parse(value);
      valid = true
    }
    catch(e){

    }
    const item = this.state.items[this.state.focusedIndex];
    item.dirty = true;
    item.valid = valid;
    item.value = value;
    this.forceUpdate();
  }

  handleFocus = (e: any) => {
    const index = parseInt(e.currentTarget.dataset.index);
    this.setState({ focusedIndex: index });
  }

  loadItems = async () => {
    const items = (await databaseService.list(this.state.table))
      .map(x =>{
        var value = JSON.stringify(x);
        return {
          initialValue: value, value, dirty: false, valid: true, _id: x._id
        };
      });
    this.setState({ items });
  }

  loadTables = async () => {
    const tables = await databaseService.listTables();
    this.setState({ tables });
  }

  handleTableChange = (e: any) => {
    this.setState({table: e.target.value}, ()=>{
      this.loadItems();
    });
  }

  handleRevertClick = (e: any) => {
    const index = this.state.focusedIndex;
    const data = this.state.items[index];
    data.value = data.initialValue;
    this.forceUpdate();
  }

  componentDidMount = () => {
    this.loadItems();
    this.loadTables();
  }

  handleDelete = (e: any, loaded: boolean) => {
    if(loaded){
      const index = this.state.focusedIndex;
      const data = this.state.items[index];
      databaseService.delete(this.state.table, data);
      this.state.items.splice(index, 1);
      this.setState({focusedIndex: -1});
    }
  }

  handleRevert = (e: any) => {
    const index = this.state.focusedIndex;
    const data = this.state.items[index];
    data.value = data.initialValue;
    data.dirty = false;
    data.valid = true;
    this.setState({focusedIndex: -1});    
  }

  handleUpdate = async () => {
    const index = this.state.focusedIndex;
    const data = this.state.items[index];
    if(data.valid){
      await databaseService.update(this.state.table, JSON.parse(data.value));
      data.dirty = false;
      data.initialValue = JSON.stringify(JSON.parse(data.value));
      data.value = data.initialValue;
      this.setState({focusedIndex: -1});
    }
  }

  render() {
    return (
      <MainPadding>
        <AppSection section="database" />
        <Paper style={{ padding: '16px' }}>
          <Select value={this.state.table} fullWidth={true} onChange={this.handleTableChange}>
            { this.state.tables.map(x =>(<MenuItem key={x} value={x}>{x}</MenuItem>)) }
          </Select>
          {this.state.items.map((x: Record, i) => {
            const hasFocus = i === this.state.focusedIndex;
            let value = x.value;
            if(hasFocus){
              try{
                value = JSON.stringify(JSON.parse(x.value), null, '  ');
              }
              catch(e){

              }
            }
            return (<React.Fragment>
              <DebouncedTextField
                key={x._id}
                onFocus={this.handleFocus}
                label={`Item ${x._id}`}
                fullWidth={true}
                margin="normal"
                onChange={this.handleItemChange}
                multiline={true}
                inputProps={{ 'data-index': i }}
                value={value} />
                <div>
                  {x.dirty && (<span><Chip color="primary" label="Dirty" /> </span>)}
                  {!x.valid && (<span><Chip color="secondary" label="Invalid" /> </span>)}
                { hasFocus && <React.Fragment>
                  <Button onClick={this.handleUpdate} disabled={!x.dirty}>Save</Button>
                  <Button onClick={this.handleRevert} disabled={!x.dirty}>Revert</Button>
                  <DangerButton onClick={this.handleDelete} button={<Button>Delete</Button>} loadedButton={<Button color="secondary">Delete</Button>} />
                </React.Fragment> }
                </div>
            </React.Fragment>);
          })}
        </Paper>
      </MainPadding>
    );
  }
}
import * as React from 'react';
import * as Yup from 'yup';
import { DialogTitle, TextField, Dialog, DialogContent, Button, DialogActions } from '@material-ui/core';
import { Formik, Field } from 'formik';
import { DialogProps } from '@material-ui/core/Dialog';
import { VALID_SITE_NAME, SITE_NAME_HELPER } from './Websites';
import { FormikTextField } from '../FormikTextField';

const dialogContentStyle: any = {
    minWidth: '300px'
}

type Props = {
    renameDialogKey: string|null,
    renameWebsite: (key: string, newKey: string)=> Promise<void>
} & DialogProps;

export class RenameSiteDialog extends React.Component<Props, {}>{

    private handleRenameSiteFormSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
        try{
            await this.props.renameWebsite(this.props.renameDialogKey||'', values.name);
            setSubmitting(false);
            resetForm();
        }
        catch{
            setSubmitting(false);
            alert('An error has ocurred');
        }
    }

    render(){

        const {renameDialogKey, renameWebsite, ...rest} = this.props;

        return (
            <Formik
                initialValues={{ name: '' }}
                onSubmit={this.handleRenameSiteFormSubmit}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string().matches(VALID_SITE_NAME, SITE_NAME_HELPER).required('Required.')
                    }).required()
                }>
                { props => {
                    const { handleSubmit, isSubmitting, submitForm } = props;
    
                    return (
                        <Dialog
                            {...rest}
                        >
                            <DialogTitle style={dialogContentStyle}>Rename Site</DialogTitle>
                            <DialogContent style={dialogContentStyle}>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        fullWidth={true} margin="none" label="Website Name"
                                        InputProps={{readOnly:true}} value={this.props.renameDialogKey||''} />
                                    <Field autoFocus={true} margin="normal"
                                        debounce={15} name="name" label="New Website Name" component={FormikTextField} />
                                    <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{width:1, height:1, visibility:'hidden'}}>Update</button>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    disabled={isSubmitting}
                                    color="primary"
                                    type="submit"
                                    value="rename"
                                    onClick={submitForm}>Rename</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }}
            </Formik>
        );
    }
}
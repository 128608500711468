import * as React from 'react';
import { RestrictedSection, Tests } from './RestrictedSection';

export class AdminOrAgencyownerOrAgentSection extends React.Component<{agency?: string, forbidden?: React.ReactNode, render?: (forbbiden: boolean)=>React.ReactNode},any> {

    render(){
        const { agency, ...rest } = this.props;
        return (<RestrictedSection
            test={Tests.or([
                Tests.hasRole('admin'),
                Tests.belongsToAgencyAsAgent(),
                Tests.isAgencyOwner()
            ])}
            {...rest}
        />);
        
    }
}

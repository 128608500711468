import * as React from 'react';
import { MainPadding } from '../../components/MainPadding';
import { Paper, TextField, FormControl, Button, Typography } from '@material-ui/core';
import { Formik, FastField, Field } from 'formik';
import * as Yup from 'yup';
import { serviceLocator } from '../../services';
import { FormikTextField } from '../../components/FormikTextField';
import { FormikSelect } from '../../components/FormikSelect';
import { Breadcumb } from '../../components/Breadcumb';
import { withSnackbar, WithSnackbarProps } from 'notistack';

const agencyService = serviceLocator.getAgencyService();
const userService = serviceLocator.getUserService();

interface Props extends WithSnackbarProps {
    identifier?: string;
    history: any;
}

interface State {
    agency: any;
    usersOptions: Array<{label:string, value:string}>;
}

const NEW_AGENCY = { identifier: '', name: '',  active:'true', owner: '' };

class EditAgencyFormDumb extends React.Component<Props,State>{

    private handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: (value: boolean)=>void }) =>{
        try{
            const isNewAgency = this.props.identifier==null;
            setSubmitting(true);
            await (isNewAgency ? agencyService.createAgency(values) : agencyService.updateAgency(values));
            setSubmitting(false);
            if(isNewAgency){
                this.props.history.push(`/agencies/${values.identifier}`);
                this.props.enqueueSnackbar("Agency created successfully.");
            }
            else{
                this.props.enqueueSnackbar("Agency updated successfully.");
            }
        }
        catch(e){
            alert('An error has ocurred.');
            setSubmitting(false);
        }              
    }

    constructor(props: Props){
        super(props);
        this.state = {
            agency: props.identifier ? null : NEW_AGENCY,
            usersOptions: []
        }
    }

    componentDidMount = async () => {
        if(this.state.agency==null && this.props.identifier!=null){
            const {agency} = await agencyService.getAgency(this.props.identifier);
            agency.active = agency.active.toString();
            this.setState({agency});
        }
        const usersOptions = (await userService.listUsers(true)).users.map(x => ({ value:x.username, label:`${x.username}`, roles: x.roles }));
        this.setState({usersOptions: usersOptions.filter(op => op.roles.indexOf('agency') > -1)});
    }

    render(){
        const isNew = this.props.identifier == null;
        return (<MainPadding style={{maxWidth: '42em'}}>
            <Breadcumb items={[
                { label:"Agencies", to:`/agencies/` },
                isNew ? null : { label:`Agency ${this.props.identifier}`, to:`/agencies/${this.props.identifier}/` },
                { label: isNew ? "New Agency" : `Edit` }
            ]} /><br />

            <Paper style={{padding:16}}>
                { this.state.agency && (<Formik
                    initialValues={ this.state.agency }
                    onSubmit={this.handleSubmit}
                    validationSchema={Yup.object().shape({
                        identifier: Yup.string().required('Required'),
                        name: Yup.string().required('Required'),
                        owner: Yup.string().required('Required'),
                    })}
                >
                    {props => {
                        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                        return (
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <div>
                                <FastField InputProps={{readOnly: !isNew }} name="identifier" label="* identifier" component={FormikTextField} />
                            </div>
                            <div>
                                <FastField name="name" label="* Name" component={FormikTextField} />
                            </div>
                            <div>
                                <Field readOnly={this.props.identifier!=null} name="owner" label="* Owner" component={FormikSelect} options={this.state.usersOptions} />
                            </div>
                            <div>
                                <FastField name="active" label="* Active" component={FormikSelect} options={[{value: 'true' }, {value: 'false' }]} />
                            </div>
                            
                            <FormControl margin={'normal'} fullWidth={true}>
                                <Button disabled={isSubmitting} type="submit" variant="contained" size="large" color="primary" fullWidth={true}>Save</Button>
                            </FormControl>
                        </form>
                        );
                    }}
                </Formik>)}
            </Paper>
        </MainPadding>)
    }
}

export const EditAgencyForm = withSnackbar(EditAgencyFormDumb);
import * as React from 'react';
import { BaseDynamic, ComponentProps, NormalizeStateContext } from '../_ho-forms-lib/src/HoForm';
import { getValidationError } from '../_ho-forms-lib/src/HoForm/utils';
import _AceEditor from "react-ace";
import { Typography } from '@material-ui/core';

const AceEditor = _AceEditor as any;

declare const ace: any;

type CodeDynamicField = {
    key: string, 
    type: string,
    default?: string,
    title?: string,
    language: string,
    tip: string,
}

type CodeDynamicState = {
    
}

export class CodeDynamic extends BaseDynamic<CodeDynamicField,CodeDynamicState> {

    value: string|null = null;

    constructor(props: ComponentProps<CodeDynamicField>){
        super(props);
        ace.config.set('basePath', '/ace-builds/src-noconflict');
        ace.config.set('modePath', '/ace-builds/src-noconflict');
        ace.config.set('themePath', '/ace-builds/src-noconflict');
    }

    normalizeState({state, field}: NormalizeStateContext<CodeDynamicField>){
        let key = field.key;
        if(state[key]==null){
            state[key] = field.default || '';
        }
    }

    getType(){
        return 'code';
    }

    handleChange = (value: string)=>{
        this.value = value;
        this.forceUpdate();
        this.props.context.setValue(value, 200);
        this.tryResize();
    }

    tryResize = (clearSelection?: boolean) =>{
        const ace = this.refs.reactAceComponent as any;
        if(ace!=null){
            ace.editor.resize();
            if(clearSelection===true){
                setTimeout(()=>{
                    ace.editor.clearSelection();
                },100);
            }
        }
    }

    componentDidMount(){
        this.tryResize(true);
    }

    renderComponent(){
        
        let {context} = this.props;
        let {node, nodePath, currentPath, parentPath} = context;
        let {field} = node;
                
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }

        const error = getValidationError(node.state, nodePath);

        return (<div style={{marginTop: '8px', marginBottom: '4px'}}>
            <Typography color="textSecondary" display="block" variant="caption" style={{marginBottom: '4px'}}>{field.title}</Typography>
            <div style={{border: 'solid 1px #eee', padding: '4px 0'}}>
                <AceEditor
                    mode={field.language||'html'}
                    theme="chrome"
                    tabSize={2}
                    ref="reactAceComponent"
                    showPrintMargin={false}
                    maxLines={60}
                    width="100%"
                    fontSize={15}
                    showGutter={false}
                    onChange={this.handleChange}
                    value={this.value||context.value}
                    name={`code-name-${field.key}`}
                    editorProps={{ $blockScrolling: true }}
                />
            </div>
            { field.tip && (<Typography color="textSecondary" display="block" variant="caption" style={{marginBottom: '4px'}}>{field.tip}</Typography>) }
        </div>
        );
    }
}
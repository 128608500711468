import axios from 'axios';

export class ThemeService{
    public async getThemeBlock(block: string): Promise<any>{
        const result = await axios.get(`/api/themes/${encodeURIComponent(block)}`);
        return result.data;
    }

    public async createOrUpdateTheme(theme: any): Promise<void>{
        return await axios.post(`/api/themes/${encodeURIComponent(theme.agency)}`, theme);
    }
}
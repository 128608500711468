import * as React from 'react';
import { MainPadding } from '../../components/MainPadding';
import { Paper, TextField, FormControl, Button, Typography } from '@material-ui/core';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { serviceLocator } from '../../services';
import { FormikTextField } from '../../components/FormikTextField';
import { FormikSelect } from '../../components/FormikSelect';
import { Breadcumb } from '../../components/Breadcumb';
import { withSnackbar, WithSnackbarProps } from 'notistack';

const userService = serviceLocator.getUserService();

interface Props extends WithSnackbarProps {
    username?: string;
    history: any;
}

interface State {
    user: any;
}

const NEW_USER = { username: '', password: '', email:'', roles:[], active:true };

class EditUserFormDumb extends React.Component<Props,State>{

    private handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: (value: boolean)=>void }) =>{
        try{
            const isNewUser = this.props.username==null;
            setSubmitting(true);
            await userService.createOrUpdateUser(values);
            setSubmitting(false);
            if(isNewUser){
                this.props.history.push('/users/');
                this.props.enqueueSnackbar("User created successfully.");
            }
            else{
                this.props.enqueueSnackbar("User updated successfully.");
            }
                
        }
        catch(e){
            alert('An error has ocurred.');
            setSubmitting(false);
        }              
    }

    constructor(props: Props){
        super(props);
        this.state = {
            user: props.username ? null : NEW_USER
        }
    }

    componentDidMount = async () => {
        if(this.state.user==null && this.props.username!=null){
            const {user} = await userService.getUser(this.props.username);
            this.setState({user});
        }
    }

    render(){
        const isNew = this.props.username == null;
        const passwordValidation = Yup.string().min(8, 'Must be at least 8 characters');
        return (<MainPadding style={{maxWidth: '42em'}}>
            <Breadcumb items={[
                { label:"Users", to:`/users/` },
                { label: isNew ? "New User" : `Edit ${this.props.username}` }
            ]} /><br />

            <Paper style={{padding:16}}>
                { this.state.user && (<Formik
                    initialValues={ this.state.user }
                    onSubmit={this.handleSubmit}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().required('Required'),
                        email: Yup.string().email().required('Required'),
                        password: isNew ? passwordValidation.required('Required') : passwordValidation,
                    })}
                >
                    {props => {
                        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                        return (
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <div>
                                <FastField InputProps={{readOnly: !isNew }} name="username" label="* Username" component={FormikTextField} />
                            </div>
                            <div>
                                <FastField name="email" label="* Email" component={FormikTextField} />
                            </div>
                            <div>
                                <FastField name="password" autoComplete="new-password" label="* Password" type="password" component={FormikTextField} />
                            </div>
                            <div>
                                <FastField name="roles" label="* Roles" component={FormikSelect} multiple={true} options={[{value: 'admin' }, {value: 'agency' }, {value: 'designer' }]} />
                            </div>
                            <div>
                                <FastField name="active" label="* Active" component={FormikSelect} options={[{value: 'true' }, {value: 'false' }]} />
                            </div>

                            <FormControl margin={'normal'} fullWidth={true}>
                                <Button disabled={isSubmitting} type="submit" variant="contained" size="large" color="primary" fullWidth={true}>Save</Button>
                            </FormControl>
                        </form>
                        );
                    }}
                </Formik>)}
            </Paper>
        </MainPadding>)
    }
}

export const EditUserForm = withSnackbar(EditUserFormDumb);
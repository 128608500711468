import * as React from 'react';
import { MainPadding } from '../../../components/MainPadding';
import { Paper, List, ListItem, ListItemText, Button, Dialog, DialogTitle, DialogContent, IconButton, ListItemSecondaryAction, Typography, DialogActions } from '@material-ui/core';
import { serviceLocator } from '../../../services';
import { Breadcumb } from '../../../components/Breadcumb';
import { CreateInfraCredentialForm } from './CreateInfraCredentialForm';
import { DeleteForever as RemoveIcon } from '@material-ui/icons';

const infraCredentialService = serviceLocator.getInfraCredentialService();
const uiService = serviceLocator.getUIService();

interface State {
    credentials: Array<{_id: string, name: string}>;
    showCreateCredentialDialog: boolean;
    showDeleteCredentialDialog: boolean;
    credentialDeleteTarget: {_id: string, name: string}|null;
}

export class InfraCredentialList extends React.Component<any,State>{

    state: State = {
        credentials: [],
        showCreateCredentialDialog: false,
        showDeleteCredentialDialog: false,
        credentialDeleteTarget: null
    }

    componentDidMount(){
        this.refreshList();
    }

    refreshList = async () =>{
        const { credentials } = await infraCredentialService.listCredentials(uiService.getSelectedAgency());
        console.log(credentials);
        this.setState({ credentials });
    }

    handleNewClick = (e:any) => { this.setState({showCreateCredentialDialog: true}); }

    handleInfraCredCreate = () => { this.setState({showCreateCredentialDialog: false}); this.refreshList(); }

    handleCloseCreateDialog = () =>{ this.setState({showCreateCredentialDialog: false}); this.refreshList(); }

    handleCloseDeleteDialog = () =>{ this.setState({showDeleteCredentialDialog: false}); this.refreshList(); }

    handleCredentialRemoveClick = async (e: any) =>{
        const target = (this.state.credentials||[]).find(x => x._id===e.currentTarget.dataset.key);
        if(target!=null){
            this.setState({
                credentialDeleteTarget: target,
                showDeleteCredentialDialog: true
            });
        }
        
    }

    handlePurgeCredential = async (e: any) =>{
        if(this.state.credentialDeleteTarget!=null){
            await infraCredentialService.purgeCredential(this.state.credentialDeleteTarget._id);
            this.setState({showDeleteCredentialDialog: false});
            this.refreshList();
        }
    }

    render(){

        const creteDialog = (
            <Dialog open={this.state.showCreateCredentialDialog} onClose={this.handleCloseCreateDialog}>
                <DialogTitle>Create Infrastructure Credential</DialogTitle>
                <DialogContent>
                    <CreateInfraCredentialForm
                        agency={uiService.getSelectedAgency()}
                        submitForm={(cred: any) => infraCredentialService.putCredential(cred)}
                        onFormSubmited={this.handleInfraCredCreate}
                    />
                </DialogContent>
            </Dialog>
        )

        const deleteCrendetialTarget = this.state.credentialDeleteTarget;
        const deleteDialog = deleteCrendetialTarget!=null && (
            <Dialog open={this.state.showDeleteCredentialDialog} onClose={this.handleCloseDeleteDialog}>
                <DialogTitle>Delete Infrastructure Credential</DialogTitle>
                <DialogContent>
                    <Typography>You are about to delete the infrastructure credential <Typography display="inline" component="span" color="error">{deleteCrendetialTarget.name} ({deleteCrendetialTarget._id})</Typography>.</Typography>
                    <Typography>This action will not remove any published website binded to this credential, but they will not be available for deploy before a manual intervation.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={this.handlePurgeCredential}>Purge Credential</Button>
                </DialogActions>
            </Dialog>
        )

        return (
            <MainPadding>
                
                <Breadcumb items={[
                    { label:"Credentials" }
                ]} />
                <br />
                
                <div style={{ padding: '16px 0' }}>
                <Button variant="contained" color="primary" onClick={this.handleNewClick}>New</Button>
                     {/* &nbsp; <Button variant="text" size="small" color="default" onClick={this.handleBatchBuildClick}>Batch Build</Button> */}
                </div>

                <Paper>
                    <List component="nav">
                        { (this.state.credentials||[]).map((credential: any, i: number) => {
                            return (<ListItem key={credential._id}
                                // button={true}
                                divider={i < this.state.credentials.length - 1}>
                                <ListItemText primary={`${credential.name}`} secondary={`${credential._id}`} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={this.handleCredentialRemoveClick} data-key={credential._id}>
                                        <RemoveIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>)
                        }) }
                    </List>
                </Paper>
                { creteDialog }
                { deleteDialog }
            </MainPadding>
        )
    }
}
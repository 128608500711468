import * as React from 'react';
import { FormControl, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, IconButton, InputAdornment } from '@material-ui/core';
import { Formik, FastField, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { serviceLocator } from '../../services';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { FormikTextField } from '../../components/FormikTextField';
import { ExpandMore as ExpandMoreIcon, Clear } from '@material-ui/icons';
import { FormikSelect } from '../../components/FormikSelect';

const service = serviceLocator.getQualityCheckService();

export interface CreateOrUpdateFormProps {
    parentConfig: any;
    config: any;
    onSave?: ()=>void;
    website?: string;
    agency: string;
}

type Props = CreateOrUpdateFormProps & WithSnackbarProps;

interface State {
    config: any;
    parentOptions: Array<{label:string, value: string}>
}

const NEW_CONFIG = {
    label: '',
    lighthouse: {
        pages: [],
    },
    brokenLinks: {
        ignoreMatch: [],
    },
};

export const CreateOrUpdateForm = withSnackbar(
class CreateOrUpdateFormDumb extends React.Component<Props,State>{

    private handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: (value: boolean)=>void }) =>{
        try{
            const isCreate = this.props.config==null;
            const valuesCopy = { ...values };
            setSubmitting(true);
            if(isCreate){
                await service.createConfig(valuesCopy);
                this.props.enqueueSnackbar("Config created successfully.");
            }
            else{
                await service.updateConfig(valuesCopy);
                this.props.enqueueSnackbar("Config updated successfully.");
            }
            if(this.props.onSave){
                this.props.onSave();
            }
            
        }
        catch(e){
            alert('An error has ocurred.');
        }
        finally{
            setSubmitting(false);
        }
    }

    constructor(props: Props){
        super(props);
        this.state = {
            parentOptions: [],
            config: (
                props.config ?
                { label: this.props.website||'', ...props.config }
                : { ...NEW_CONFIG, label: this.props.website||'', website: this.props.website, agency: this.props.agency })
        }
    }

    componentDidMount = async () => {
        const { configs } = this.props.website ?
            (await service.listConfigsOptionsForWebsite(this.props.website))
            : (await service.listTemplateConfigs(this.props.agency));
        const parentOptions = configs.map(x =>({label:x.label, value:x._id}));
        console.log(parentOptions);
        this.setState({parentOptions});
    }

    render(){

        const lighthouseNumber = Yup.number()
            .integer('Only intergers are accepted.')
            .typeError('Only intergers are accepted.')
            .min(0,'Min value is 0.')
            .max(100, 'Max value is 100.')
            .nullable()
            .transform((value: string, originalValue: string) => originalValue.trim() === "" ? null: value);

        return (<React.Fragment>
            { this.state.config && (<Formik
                initialValues={ this.state.config }
                onSubmit={this.handleSubmit}
                validationSchema={Yup.object().shape({
                    _id: Yup.string(),
                    website: Yup.string(),
                    parent: Yup.string(),
                    agency: Yup.string().required('Required.'),
                    label: Yup.string().required('Required.'),
                    lighthouse: Yup.object().shape({
                        pages: Yup.array(Yup.object().shape({
                            path: Yup.string(),
                            performance: lighthouseNumber,
                            pwa: lighthouseNumber,
                            seo: lighthouseNumber,
                            accessibility: lighthouseNumber,
                            bestPractices: lighthouseNumber,
                        }))
                    }),
                    brokenLinks: Yup.object().shape({ ignoreMatch: Yup.array(Yup.string().required('Required.')) })
                })}
            >
                {props => {
                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                    const ignoreMatch = (values.brokenLinks.ignoreMatch || []);
                    const lighthouse = (values.lighthouse|| {pages:[]});
                    const lighthousePages = lighthouse.pages||[];
                    const lighthouseThresholdHelper = 'Default: 75 or value inherited from parent.';
                    return (
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <Field type="hidden" name="_id" ></Field>
                            <Field type="hidden" name="agency" ></Field>
                            <Field type="hidden" name="website" ></Field>
                            <div>
                                <FastField name="label" label="* Label"
                                    InputProps={this.state.config.website ? { readOnly: true } : undefined}
                                    component={FormikTextField} fullWidth={true} />
                            </div>

                            { this.props.website!=null && (
                            <div>
                                <Field name="parent" label="Parent"
                                    options={this.state.parentOptions}
                                    component={FormikSelect} fullWidth={true} />
                            </div>
                            ) }
                            
                            <FieldArray
                                name="lighthouse.pages"
                                render={arrayHelpers => (<div>
                                    <div>
                                        <br />
                                        <Button variant="outlined" color="primary" size="small"
                                            onClick={()=>{ arrayHelpers.push({
                                                path:'', parent: '', performance:'', pwa:'',
                                                seo:'', accessibility:'', bestPractices:''
                                            }); }}>New Lighthouse Page Setup</Button>
                                        <Typography display="block" variant="caption" color="textSecondary">Override the Lighthouse thresholds globally or for a specific page URL.</Typography>
                                    </div>
                                    {!!lighthousePages.length && <br />}
                                    {lighthousePages.map((item: any, index: number) =>{
                                        return (
                                            <ExpansionPanel key={index}>
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography display="inline">Page: {item.path||'any'}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <div>
                                                        <Field helperText={'Leave it empty to match any page. Sample: "/customer-reviews".'} name={`lighthouse.pages[${index}].path`} label="Page Path" component={FormikTextField} fullWidth={true} />
                                                        <FastField helperText={lighthouseThresholdHelper} name={`lighthouse.pages[${index}].performance`} label="Performance" component={FormikTextField}  fullWidth={true} />
                                                        <FastField helperText={lighthouseThresholdHelper} name={`lighthouse.pages[${index}].pwa`} label="PWA" component={FormikTextField} fullWidth={true} />
                                                        <FastField helperText={lighthouseThresholdHelper} name={`lighthouse.pages[${index}].seo`} label="SEO" component={FormikTextField} fullWidth={true} />
                                                        <FastField helperText={lighthouseThresholdHelper} name={`lighthouse.pages[${index}].accessibility`} label="Accessibility" component={FormikTextField} fullWidth={true} />
                                                        <FastField helperText={lighthouseThresholdHelper} name={`lighthouse.pages[${index}].bestPractices`} label="Best Practices" component={FormikTextField} fullWidth={true} />
                                                        <div>
                                                            <br /><Button size="small" onClick={()=> arrayHelpers.remove(index)}>Remove</Button>
                                                        </div>
                                                    </div>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        )
                                    })}
                                </div>)}
                            />

                            <FieldArray
                                name="brokenLinks.ignoreMatch"
                                render={arrayHelpers => (<div>
                                    <div>
                                        <br />
                                        <Button size="small" color="primary" variant="outlined" onClick={()=>{ arrayHelpers.push(''); }}>New Broken Link Ignore</Button>
                                        <Typography display="block" variant="caption" color="textSecondary">To ignore some errors, add regular expressions to match the failed URL.</Typography>
                                    </div>
                                    {ignoreMatch.map((item: any, index: number) =>{
                                        return (
                                            <div>
                                                <FastField
                                                    margin="none" fullWidth={true} name={`brokenLinks.ignoreMatch[${index}]`} component={FormikTextField}
                                                    InputProps={{startAdornment:(<InputAdornment position="start"><IconButton onClick={()=>arrayHelpers.remove(index)}><Clear fontSize="small" /></IconButton></InputAdornment>)}}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>)}
                            />
                            
                            <FormControl margin={'normal'} fullWidth={true}>
                                <Button disabled={isSubmitting} type="submit" variant="contained" size="large" color="primary" fullWidth={true}>{this.props.config!=null ? 'Update':'Create'}</Button>
                            </FormControl>
                        </form>
                    );
                }}
            </Formik>)}
        </React.Fragment>)
    }
}
);
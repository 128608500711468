import * as React from 'react';
import * as Yup from 'yup';
import { Divider, List, Paper, ListSubheader, ListItem, ListItemSecondaryAction, ListItemText, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Formik, FastField } from 'formik';
import { FormikTextField } from '../../../components/FormikTextField';
import { serviceLocator } from '../../../services';
import { Link } from 'react-router-dom';
import { ListSubheaderWithButtons } from '../../../components/ListSubheaderWithButtons';

declare const confirm: any;

const websiteService = serviceLocator.getWebsiteService();

interface Props {
    websiteKey: string;
    collectionKey: string;
    title: string;
    paperProps?: any;
}

interface State {
    menuAnchor: any;
    pageMenuOpen: boolean;
    menuPageKey: string;
    pages: Array<any>;
    openDialog: string | null;
    cloneKey: string | null
}

const dialogContentStyle: any = {
    minWidth: '300px'
}

const PAGE_NAME_HELPER = 'Sample: some-page';

export class PageList extends React.Component<Props, State>{

    state: State = {
        menuAnchor: null,
        pageMenuOpen: false,
        menuPageKey: '',
        pages: [],
        openDialog: null,
        cloneKey: null
    }

    private validPageName = /^[a-z0-9-_]+$/;

    private restoreScroll() {
        // fix
        setTimeout(() => {
            document.body.style.overflow = "";
        }, 100);
    }

    componentDidMount = () => {
        this.loadPages();
    }

    loadPages = async () => {
        const pages = await websiteService.listPages(this.props.websiteKey, this.props.collectionKey);
        this.setState({ pages: pages.filter(p => p != "_index") });
    }

    private handleNewClick = (e: any) => {
        this.setState({ openDialog: 'new' });
    }

    handlePageDelete = async (e: any) => {
        this.setState({ pageMenuOpen: false });
        if (confirm(`Are you sure you want to delete the page "${this.state.menuPageKey}"?`)) {
            await websiteService.removePage(this.props.websiteKey, this.props.collectionKey, this.state.menuPageKey);
            this.loadPages();
        }
    }

    private handlePageItemMoreClick = (e: any) => {
        const target = e.currentTarget;
        console.log('target.dataset', target.dataset)
        this.setState({ pageMenuOpen: true, menuAnchor: target, menuPageKey: target.dataset.key });
    }

    private currentPageLink = React.forwardRef((props: any, ref: any) => {
        return <Link ref={ref} to={`collection/${this.props.collectionKey}/${this.state.menuPageKey}/`} {...props} />;
    });

    private handleDialogClose = (e: any) => {
        this.setState({ openDialog: null, cloneKey: null });
    }

    handleNewPageFormSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
        try {
            if (values.fromName) {
                await websiteService.clonePage(this.props.websiteKey, this.props.collectionKey, values.fromName, values.name);
            }
            else {
                await websiteService.createPage(this.props.websiteKey, this.props.collectionKey, values.name);
            }

            setSubmitting(false);
            resetForm();
            this.setState({ openDialog: null, cloneKey: null });
            this.loadPages();
        }
        catch {
            setSubmitting(false);
            alert('An error has ocurred');
        }
    }

    handlePageClone = async (evt: any) => {
        this.setState({ openDialog: 'new', cloneKey: this.state.menuPageKey, pageMenuOpen: false });
    }

    getPageLink = (pageKey: string) => React.forwardRef((props: any, ref: any) => <Link ref={ref} to={`collection/${this.props.collectionKey}/${pageKey}/`} {...props} />);

    private handleMenuClose = (e: any) => {
        this.setState({ pageMenuOpen: false });
    }

    viewPreview = () => {
        var url = `http://skeleton-bootstrap-5-2--${this.props.websiteKey}.hugosites.webdrvn.com/${this.state.menuPageKey}`
        window.open(url, '_blank');
    }

    viewProduction = async () => {
        const data = await websiteService.getInfrastructureData(this.props.websiteKey);
        if (data?.domain != null) {
            window.open(`https://${data.domain}/${this.state.menuPageKey}`, '_blank');
        }
        else {
            alert(`"${this.props.websiteKey}" is not deployed.`);
        }
    }

    renderDialog() {
        const itemType = this.props.collectionKey == "pages" ? "Page" : "Blog"
        const namePlaceHolder = this.props.collectionKey == "pages" ? "Page Name" : "Blog Post Title"
        return (
            <Formik
                key={this.state.cloneKey || 'new'}
                initialValues={{ name: '', fromName: this.state.cloneKey || '' }}
                onSubmit={this.handleNewPageFormSubmit}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string().matches(this.validPageName, PAGE_NAME_HELPER).required('Required.'),
                        fromName: Yup.string().matches(this.validPageName, PAGE_NAME_HELPER)
                    }).required()
                }>
                {props => {
                    const { handleSubmit, isSubmitting, submitForm } = props;

                    return (
                        <Dialog
                            onClose={this.handleDialogClose}
                            onExited={this.restoreScroll}
                            open={this.state.openDialog === 'new'}>
                            <DialogTitle style={dialogContentStyle}>{this.state.cloneKey ? `Clone ${itemType} "${this.state.cloneKey}"` : `Create New ${itemType}`}</DialogTitle>
                            <DialogContent style={dialogContentStyle}>
                                <form onSubmit={handleSubmit}>
                                    <FastField autoFocus={true} debounce={15} margin="normal" name="name" label={`${namePlaceHolder}`} component={FormikTextField} />
                                    <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{ width: 1, height: 1, visibility: 'hidden' }}>Create</button>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    disabled={isSubmitting}
                                    color="primary"
                                    type="submit"
                                    value="create"
                                    onClick={submitForm}>Create</Button>
                            </DialogActions>
                        </Dialog>

                    )
                }}
            </Formik>
        );
    }

    renderMenu() {
        return (
            <Menu
                key="page-menu"
                open={this.state.pageMenuOpen}
                anchorEl={this.state.menuAnchor}
                onClose={this.handleMenuClose}
            >
                <MenuItem component={this.currentPageLink} >Open</MenuItem>
                <MenuItem onClick={this.handlePageClone} >Clone</MenuItem>
                <MenuItem onClick={this.handlePageDelete} >Delete</MenuItem>
                <MenuItem onClick={this.viewPreview}>View Preview</MenuItem>
                <MenuItem onClick={this.viewProduction}>View Production</MenuItem>
            </Menu>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Paper {...this.props.paperProps}>
                    <List component="nav" subheader={<ListSubheaderWithButtons title={this.props.title} onNewClick={this.handleNewClick} />}>
                        <Divider />
                        {this.state.pages.map((page, i) => {
                            return (
                                <ListItem key={page}
                                    button={true}
                                    component={this.getPageLink(page)}
                                    divider={true}>
                                    <ListItemText primary={page} />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={this.handlePageItemMoreClick} data-key={page}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                </Paper>
                {/* <div style={{ padding: '16px 0' }}>
                    <span> </span><Button size="small" onClick={this.handleNewClick}>Create Page</Button>
                </div> */}
                {this.renderDialog()}
                {this.renderMenu()}
            </React.Fragment>
        )
    }
}
//@flow

import * as React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Folder } from '@material-ui/icons';
import { FormStateBuilder } from '../HoForm/form-state-builder';
import { BaseDynamic, CrawlContext, FieldBase, NormalizeStateContext, ExtendFieldContext } from '../HoForm';
import { hasValidationErrorInTree, hasValidationErrorInTree2 } from '../HoForm/utils';

type ExtenderDynamicField  = {
    key: string,
    type: string,
    types: Array<FieldBase>,
    title: string,
    titleKey?: string|string[],
    fields: Array<any>,
    initialState?: {[key: string]: any},
    nest?: boolean,
    groupdata?: boolean,
    selectorKey: string,
    clearOnChange?: boolean | string[],
    clearExcept?: string[],
};

class ExtenderDynamic extends BaseDynamic<ExtenderDynamicField, {}> {

    allocateStateLevel(field : ExtenderDynamicField, parentState : any, rootState : any){
        if(field.groupdata===true){
            if(parentState[field.key] ==null || typeof(parentState[field.key])!=='object' || Array.isArray(parentState[field.key])){
                parentState[field.key]={};
            }
            return parentState[field.key];
        }
        return parentState;
    }

    normalizeState({state, field, stateBuilder} : NormalizeStateContext<ExtenderDynamicField>){
        stateBuilder.setLevelState(state, field.fields);
        this.checkStateSwitch(field, state, stateBuilder);
    }

    private findValueInRoot(expression: string, root: any){
        //TODO: support many levels. JsonPath?
        return root[expression.substr(2)];
      }
    
      private getSelectedTypeField(
        field: ExtenderDynamicField,
        state: any,
        rootState: any,
      ): (FieldBase & { [key: string]: any }) | undefined {
        const selector = field.selectorKey;
        let selectorValue: string;
        if(selector.startsWith('$.')){
          selectorValue = this.findValueInRoot(rootState, selector);
        }
        else{
          selectorValue = state[field.selectorKey];
        }        
        const selectorValueStr = selectorValue != null ? selectorValue.toString() : "default";
        var match = field.types.find(x => x.key === selectorValueStr);
        return match || field.types.find(x => x.key === "default" || x.key == null);
    }

    private checkStateSwitch(field: ExtenderDynamicField, state: any, stateBuilder: FormStateBuilder): void{
        const selectedTypeField = this.getSelectedTypeField(field, state,stateBuilder.rootState);
        const typeKey = selectedTypeField == null? null : selectedTypeField.key;
        const lastNormalizedKey = `__lastNormalizedType:${field.key}-${field.selectorKey}`; //this is a hack
        const lastNormalizedValue = state[lastNormalizedKey];
        if(typeKey!==lastNormalizedValue){
            if(lastNormalizedValue!==undefined){
                let clear: Array<string> = [];
                const except = field.clearExcept||[];
                if(field.clearOnChange===true||field.clearOnChange==null){
                    clear = Object.keys(state);
                }
                else if(Array.isArray(field.clearOnChange)){
                    clear = field.clearOnChange;
                }
                clear.forEach(x => {
                    if(x!=field.selectorKey && except.indexOf(x)===-1)
                        delete state[x]
                });
                if(selectedTypeField!=null && selectedTypeField.initialState!=null){
                    const initialState = selectedTypeField.initialState;
                    Object.keys(initialState).forEach((key)=>{
                        state[key] = (initialState as any)[key];
                    });
                }
            }
            stateBuilder.setLevelState(state, field.fields);
            if(selectedTypeField){
                stateBuilder.setLevelState(state, selectedTypeField.fields);
            }
            state[lastNormalizedKey] = typeKey;
        }
    }

    extendField({field, extender} : ExtendFieldContext<ExtenderDynamicField>): void{  
        if(!field.fields){ field.fields = []; }
        for (let i = 0; i < field.types.length; i++) {
            const type = field.types[i];
            if(field.nest==null)field.nest=true;
            if(field.groupdata==null)field.groupdata=field.nest;
            if(type!=null&&type.fields!=null){
                extender.extendFields(type.fields);
            }
        }
        extender.extendFields(field.fields);
    }

    getType(){
        return 'extender';
    }

    buildBreadcumbFragment(node : any, buttons : Array<{label:string, node:any}>){
        buttons.push({label: this.getTitle(node.field, node.state), node});
    }

    buildDisplayPathFragment(node : any){
        return node.field.key;
    }

    crawlComponent({form, node} : CrawlContext<ExtenderDynamicField>): void{
        const { field, state } = node;
        const parent = field.nest===true ? node : node.parent as any;
        this.checkStateSwitch(field, state, form.stateBuilder);
        form.crawlLevel({fields: field.fields, state, parent});
        const selectedTypeField = this.getSelectedTypeField(field, state, form.state.doc) as any;
        if(selectedTypeField)
            form.crawlLevel({ fields: selectedTypeField.fields, state, parent });
    }

    getTitle(field: ExtenderDynamicField, state: any): string{
        let title = '';
        if(field.titleKey!=null){
            if(Array.isArray(field.titleKey)){
                for (let i = 0; i < field.titleKey.length; i++) {
                    const key = field.titleKey[i];
                    title = (state[key]||'').toString();
                    if(title) break;
                }
            }
            else{
                title = state[field.titleKey];
            }
        }        
        if(title) return title;
        return field.title || 'Unnamed';
    }

    renderComponent(){      
        let {context} = this.props;
        let {node, currentPath, nodePath, parentPath} = context;
        let {field,} = node;


        if(field.nest===true){
            
            const title = this.getTitle(node.field, node.state);
            const hasErrors = hasValidationErrorInTree2(node.state, nodePath);

            //nested UI
            if(currentPath===parentPath){
                return (
                    <ListItem
                        style={{border: 'solid 1px #eee', margin: '3px 0'}}
                        button={true}
                        onClick={function(){ context.setPath(node) } }
                    >
                        <ListItemText
                            primaryTypographyProps={{color:hasErrors?"error":undefined}}
                            primary={title} />
                        <ListItemIcon><Folder /></ListItemIcon>
                    </ListItem>
                );
            }
            
            if(currentPath.startsWith(nodePath)){
                var state = node.state;
                const selectedTypeField = this.getSelectedTypeField(field, state, context.form.state.doc);
                return (<React.Fragment>
                    { context.form.renderLevel({ fields: field.fields, state, parent: node }) }
                    { selectedTypeField && context.form.renderLevel({ fields: selectedTypeField.fields||null, state, parent: node }) }
                </React.Fragment>);
            }

            return (null);
        }
        else{
            //flat UI
            if(currentPath.startsWith(parentPath)){
                var state = node.state;
                const selectedTypeField = this.getSelectedTypeField(field, state, context.form.state.doc);
                return (<React.Fragment>
                    { context.form.renderLevel({ fields: field.fields, state, parent: node.parent as any }) }
                    { selectedTypeField && context.form.renderLevel({ fields: selectedTypeField.fields||null, state, parent : node.parent as any }) }
                </React.Fragment>);
            }

            return (null);
        }
    }
}

export default ExtenderDynamic;
import * as React from 'react';
import { UsersList } from './UsersList';
import { EditUserForm } from './EditUserForm';
import { Switch, Route } from 'react-router';
import { NotFound } from '../NotFound';
import { RoleSection } from '../../components/RoleSection';
import { NotAllowed } from '../NotAllowed';
import { AppSection } from '../../components/AppSection';

export class Users extends React.Component<any,any>{ 

    public render(){
        return (<React.Fragment>
            <AppSection section="users" />
            <RoleSection role="admin" render={(forbidden)=>(
                <Switch>
                    {forbidden && <Route path={"*"} component={NotAllowed} />}
                    <Route path={"/users/create/"} exact={true} component={this.renderEdit} />
                    <Route path={"/users/edit/:username/"} exact={true} component={this.renderEdit} />
                    <Route path={"/users/"} exact={true} render={this.renderList} />
                    <Route path={"/users/*"} component={NotFound} />
                </Switch>
            )} />
        </React.Fragment>)
    }

    private renderList = ({match}: any)=>{
        return (<UsersList />);
    }

    private renderEdit = ({match, history}: any)=>{
        return (<EditUserForm history={history} username={match.params.username} />);
    }    
}
import React, { ComponentProps } from 'react';
import { NormalizeStateContext, CrawlContext } from '../HoForm';
import { BaseDynamic } from '../HoForm';
import { MenuItem, Select, FormControl, InputLabel, FormHelperText, ListItemIcon } from '@material-ui/core';
import { setValidationErrorIntoState, getValidationError } from '../HoForm/utils';
import { FolderOutlined } from '@material-ui/icons';

const INPUT_LABEL_PROPS = { shrink: true };

interface OptionItemRoot extends OptionItem{
    options?: Array<OptionItem>;
}

interface OptionItem{
    value:string;
    text:string;
}

type SelectDynamicField = {
    key: string,
    type: string,
    default?: string,
    tip?: string,
    title?: string,
    options?: Array<OptionItemRoot>,
    multiple?: boolean,
    required?: boolean,
    visible?: boolean,
    includeOptions?: string,
}

type SelectDynamicState = {
    isOpen: boolean;
    hasError?: boolean;
    level: string|null;
}

const EMPTY_ROOT: OptionItemRoot = { options: [], value: '', text: '' };

class SelectDynamic extends BaseDynamic<SelectDynamicField,SelectDynamicState> {
    

    state: SelectDynamicState = {
        isOpen: false,
        hasError: false,
        level: null
    };

    normalizeState({state, field} : NormalizeStateContext<SelectDynamicField>){
        //TODO: clear if value is not a valid option
        let key = field.key;
        let isArrayType = field.multiple===true;
        if(state[key]==null && field.default!=null){
            state[key] = field.default;
        }
        else{
            if(isArrayType && !Array.isArray(state[key])){
                state[key] = [];
            }
            else if(!isArrayType && typeof(state[key])!=='string'){
                state[key] = (state[key]||"").toString();
            }
        }
    }

    crawlComponent({form, node} : CrawlContext<SelectDynamicField>): void{
        if(node.field.required){
            const value = this.getValueFromNode(node);
            let validationError = '';
            if(!value){ validationError = 'The field is required.'; }
            setValidationErrorIntoState(node.state, form.buildDisplayPath(node), validationError);
        }
    }

    getType(){
        return 'select';
    }

    handleChange = (e: any)=>{
        let {context} = this.props;
        const value = e.target.value;
        const rootOptions = this.getRootOptions();
        if(Array.isArray(value)){
            const filtered = value.filter(item => rootOptions.find(r => r.value == item) != null)
            context.setValue(filtered);
            return;
        }        
        const level = this.state.level;
        var selectedOption = (level == null ?
            rootOptions : (
            (rootOptions.find(x => x.value == level)||EMPTY_ROOT).options||[])
        ).find(x => x.value===value);
        if(selectedOption!=null){
            if((selectedOption as OptionItemRoot).options!=null){
                this.setState({level: value});
            }
            else{
                context.setValue(value);                
            }
        }
    }

    handleClose = (e: any) =>{
        const value: string|null = e.target.dataset ? e.target.dataset.value : null;
        let isOpen: boolean = false;
        let levelUpdate: any = null;
        const rootOptions = this.getRootOptions();

        if(value!=null){
            let {context} = this.props;
            var selectedOption = rootOptions.find(x => x.value===value);
            if(selectedOption!=null && selectedOption.options!=null){ isOpen = true; levelUpdate = { level: value }; };
        }
        this.setState({isOpen, ...levelUpdate});
    }

    handleOpen = (e: any) =>{
        this.setState({isOpen: true, level: null});
    }
    

    renderValue = (value: any) => {
        const opts = this.getRootOptions();
        var dict: {[key: string]: string} = {};
        
        for (let i = 0; i < opts.length; i++) {
            const opt = opts[i];
            if(opt.options!=null){
                for (let j = 0; j < opt.options.length; j++) {
                    const subOpt = opt.options[j];
                    dict[subOpt.value] = subOpt.text||subOpt.value;
                }
            }
            else{
                dict[opt.value] = opt.text||opt.value;
            }
        }
        if(Array.isArray(value)){
            return value.map(x => dict[x]).sort().join(', ');
        }
        else{
            return dict[value];
        }
    }

    getRootOptions(): Array<OptionItemRoot>{
        let {context} = this.props;
        let {field} = context.node;
        return (
            field.options
            || (
                context.form.props.plugins.getOptions ? 
                    context.form.props.plugins.getOptions(field.includeOptions||'')
                    : []
            )
        )||[];
    }

    renderComponent(){       
        
        let {context} = this.props;
        let {node, nodePath, currentPath, parentPath} = context;
        let {field} = node;
                
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }

        const error = getValidationError(node.state, nodePath);
        const tipText = [ ...error?[error]:[], ...field.tip?[field.tip]:[] ].join(' ');

        const rootOptions = this.getRootOptions();
        const options = this.state.level == null ? rootOptions : (rootOptions.find(x => x.value == this.state.level)||EMPTY_ROOT).options||[];
        const multiple = field.multiple===true;
        
        return (
            <>
            {
                field.visible == false ?
                <></>
                :
                <FormControl fullWidth={true} margin="dense">
                    <InputLabel error={!!error} shrink={true}>{field.title}</InputLabel>
                    <Select
                        value={context.value||''}
                        multiple={multiple}
                        onChange={this.handleChange}
                        fullWidth={true}
                        error={!!error}
                        open={this.state.isOpen}
                        onClose={this.handleClose}
                        renderValue={ this.renderValue }
                        onOpen={this.handleOpen}
                    >
                        {options.map((option: any, i: number)=>(
                            <MenuItem
                                key={i}
                                data-value={option.value}
                                value={option.value}>
                                    {option.options && <ListItemIcon ><FolderOutlined /></ListItemIcon>}
                                    {option.text||option.value}
                                </MenuItem>
                        ))}
                    </Select>
                    { tipText && <FormHelperText error={error!=null} color="error">{tipText}</FormHelperText> }
                </FormControl>
            }
            </>
        );
    }
}

export default SelectDynamic;
import { AuthenticationService } from "./authentication-service";
import { EventEmitter } from "events";

export class UIService {

    private updateHandler: (() => void)|null = null;
    private closeMainMenuHandler: (() => void)|null = null;
    private activeSection: string = '';
    private selectedAgency: string|null = null;
    authenticationService: AuthenticationService;

    constructor(eventEmitter: EventEmitter, authenticationService: AuthenticationService){
        this.authenticationService = authenticationService;
        eventEmitter.on('authenticated', ()=>{
            this.selectedAgency = null;
        });
    }    

    public setCloseMainMenuHandler(handler: () => void){
        this.closeMainMenuHandler = handler;
    }

    public setActiveSection(section: string){
        if(this.activeSection!=section){
            this.activeSection = section;
            this.forceUpdate();
        }
    }

    public getActiveSection() : string {
        return this.activeSection;
    }
    
    public getSelectedAgency() : string {
        if(this.selectedAgency==null){
            const authData = this.authenticationService.getAuthenticatedUserData();
            if(authData!=null){
                this.selectedAgency = (authData.agencies||[])[0]||'';
            }
        }
        return this.selectedAgency||'';
    }

    public setSelectedAgency(agency: string) : void {
        if(this.selectedAgency != agency){
            this.selectedAgency = agency;
            this.forceUpdate();
        }
    }

    public closeMainMenu(){
        if(this.closeMainMenuHandler!=null){
            this.closeMainMenuHandler();
        }
    }

    public forceUpdate(){
        if(this.updateHandler!=null){
            this.updateHandler();
        }
    }

    public setForceUpdateHandler(handler: () => void){
        this.updateHandler = handler;
    }
}
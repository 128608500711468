import axios from 'axios';
import { InfrastructureData, ListWebsitesExtendedResponse, ListWebsitesResponse } from './types';

export class GenericWebsiteService {

    public listWebsites: (agency: string, template: string, status?: string) => Promise<ListWebsitesResponse> = async (agency, template, status) => {
        const result = await axios.get(`/api/websites/?agency=${encodeURIComponent(agency)}&template=${encodeURIComponent(template)}&status=${status}`);
        return result.data;
    }

    public listPreConfigureds: (agency: string, template: string) => Promise<{ items: Array<any>, noDefault: boolean }> = async (agency, template) => {
        const result = await axios.get(`/api/websites/pre-configureds/?agency=${agency}&template=${template}`);
        return result.data;
    }

    public listWebsitesExtended: (agency: string, template: string) => Promise<ListWebsitesExtendedResponse> = async (agency, template) => {
        const result = await axios.get(`/api/websites/?extended=true&agency=${agency}&template=${template}`);
        return result.data;
    }

    public createNewWebsite: (key: string, agency: string, preConfigured: string, template: string, embeded: boolean) => Promise<void> = async (key, agency, preConfigured, template, embeded) => {
        await axios.post(`/api/websites/new/`, { key, agency, preConfigured, template, embeded, status: 'active' });
    }

    public cloneWebsite: (key: string, newWebsiteKey: string) => Promise<void> = async (key, newWebsiteKey) => {
        await axios.post(`/api/websites/${key}/clone`, { newWebsiteKey });
    }

    public toggleQualityChecks: (key: string, enable: boolean) => Promise<void> = async (key, enable) => {
        await axios.post(`/api/websites/${key}/quality-checks-toggle/`, { enable });
    }

    public setWebsiteAsPreConfigured: (key: string) => Promise<void> = async (key) => {
        await axios.post(`/api/websites/${key}/set-as-pre-configured`, {});
    }

    public buildWebsite: (key: string) => Promise<{ error?: any }> = async (key) => {
        const result = await axios.post(`/api/websites/${key}/build/`, {});
        return result.data;
    }

    public getWebsite: (key: string) => Promise<any> = async (key) => {
        const result = await axios.get(`/api/websites/${key}/`, {});
        return result.data;
    }

    public getFormData: (key: string, dataKey: string) => Promise<any> = async (key, dataKey) => {
        const result = await axios.get(`/api/websites/${key}/data/${dataKey}/`, {});
        return result.data;
    }

    public postFormData: (key: string, dataKey: string, data: any) => Promise<{ error: any, versionId: string }> = async (key, dataKey, data) => {
        const result = await axios.post(`/api/websites/${key}/data/${dataKey}/`, data);
        return result.data;
    }

    public uploadImage: (key: string, file: any, fileKey: string, transform: { maxWidth: string | null, maxHeight: string | null }) => Promise<string> = async (key, file, fileKey, transform) => {
        // file = inputfile.files[0];
        const formData = new FormData();
        formData.append(fileKey, file);
        const query = [
            transform.maxHeight ? `maxHeight=${transform.maxHeight}` : '',
            transform.maxWidth ? `maxWidth=${transform.maxWidth}` : ''
        ].filter(x => x !== '').join('&');
        const response = await axios.post(`/api/websites/${key}/upload-image/?${query}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data[fileKey];
    }

    public getInfrastructureData: (key: string) => Promise<InfrastructureData | null> = async (key) => {
        const result = await axios.get(`/api/websites/${key}/infrastructure/`);
        return result.data;
    }

    public postInfrastructureSetup: (key: string, data: any) => Promise<void> = async (key, data) => {
        const result = await axios.post(`/api/websites/${key}/infrastructure/setup`, data);
        return result.data;
    }

    public postInfrastructuredomainOverride: (key: string, data: any) => Promise<void> = async (key, data) => {
        const result = await axios.post(`/api/websites/${key}/infrastructure/domain-override`, data);
        return result.data;
    }

    public createWebsiteBucket: (key: string) => Promise<void> = async (key) => {
        await axios.post(`/api/websites/${key}/infrastructure/create-bucket/`, {});
    }

    public createWebsiteCloudFrontDistribution: (key: string) => Promise<void> = async (key) => {
        await axios.post(`/api/websites/${key}/infrastructure/create-cloudfront-distribution/`);
    }

    public syncWebsiteBucket: (key: string, sync: boolean, force?: boolean) => Promise<void> = async (key, sync, force) => {
        return await axios.post(`/api/websites/${key}/infrastructure/sync-bucket/?sync=${sync}&force=${force == null ? false : force}`);
    }

    public listStaticFiles: (key: string, assets: boolean) => Promise<Array<string>> = async (key, assets = false) => {
        const result = await axios.get(`/api/websites/${key}/${assets ? "assets" : "static"}/`);
        return result.data;
    }

    public renameStaticFile: (key: string, from: string, to: string, assets: boolean) => Promise<void> = async (key, from, to, assets = false) => {
        const result = await axios.post(`/api/websites/${key}/${assets ? "assets" : "static"}/rename-path`, { key, from, to });
        return result.data;
    }

    public transformStaticImage: (key: string, config: any, assets: boolean) => Promise<void> = async (key, config, assets = false) => {
        const result = await axios.post(`/api/websites/${key}/${assets ? "assets" : "static"}/transform-image`, config);
        return result.data;
    }

    public listStaticFilesExtended: (key: string, assets: boolean) => Promise<Array<{ path: string, size: string }>> = async (key, assets = false) => {
        const result = await axios.get(`/api/websites/${key}/${assets ? "assets" : "static"}/?extended=true`);
        return result.data;
    }

    public deleteStaticFile: (key: string, path: string, assets: boolean) => Promise<void> = async (key, path, assets = false) => {
        const result = await axios.post(`/api/websites/${key}/${assets ? "assets" : "static"}/delete/`, { path });
        return result.data;
    }

    public createStaticFolder: (key: string, folder: string, assets: boolean) => Promise<void> = async (key, folder, assets = false) => {
        const result = await axios.post(`/api/websites/${key}/${assets ? "assets" : "static"}/create-folder/`, { folder });
    }

    public uploadStaticFile: (key: string, file: any, fileKey: string, folder: string, assets: boolean, imagifySourceUrl: string) => Promise<string> = async (key, file, fileKey, folder, assets = false, imagifySourceUrl) => {

        // file = inputfile.files[0];
        const formData = new FormData();
        formData.append(fileKey, file);
        formData.append('folder', folder);
        formData.append('imagifySourceUrl', imagifySourceUrl);
        const response = await axios.post(`/api/websites/${key}/${assets ? "assets" : "static"}/upload-file/`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data[fileKey];
    }

    public getPage: (key: string, collectionKey: string, pageKey: string) => Promise<any> = async (key, collectionKey, pageKey) => {
        const result = await axios.get(`/api/websites/${key}/page/${collectionKey}/${pageKey}/`);
        return result.data;
    }

    public getHomePage: (key: string) => Promise<void> = async (key) => {
        const result = await axios.get(`/api/websites/${key}/homepage/`);
        return result.data;
    }

    public updateHomePage: (key: string, data: any) => Promise<void> = async (key, data) => {
        const result: any = await axios.post(`/api/websites/${key}/homepage/`, data);
        return result.data;
    }

    public listPages: (key: string, collectionKey: string) => Promise<Array<any>> = async (key, collectionKey) => {
        const result: any = await axios.get(`/api/websites/${key}/page/${collectionKey}/`);
        return result.data;
    }

    public listJsonLD: (key: string) => Promise<Array<any>> = async (key) => {
        const result: any = await axios.get(`/api/websites/${key}/jsonld/`);
        return result.data;
    }

    public getJsonLD: (key: string, itemKey: string) => Promise<void> = async (key, itemKey) => {
        const result = await axios.get(`/api/websites/${key}/jsonld/${itemKey}/`);
        return result.data;
    }

    public createJsonLD: (key: string, itemKey: string) => Promise<void> = async (key, itemKey) => {
        await axios.post(`/api/websites/${key}/jsonld/${itemKey}/create/`);
    }

    public updateJsonLD: (key: string, itemKey: string, data: any) => Promise<void> = async (key, itemKey, data) => {
        const result: any = await axios.post(`/api/websites/${key}/jsonld/${itemKey}/`, data);
        return result.data;
    }

    public removeJsonLD: (key: string, itemKey: string) => Promise<void> = async (key, itemKey) => {
        await axios.post(`/api/websites/${key}/jsonld/${itemKey}/remove/`);
    }

    public createPage: (key: string, collectionKey: string, pageKey: string) => Promise<void> = async (key, collectionKey, pageKey) => {
        await axios.post(`/api/websites/${key}/page/${collectionKey}/${pageKey}/create/`);
    }

    public clonePage: (key: string, collectionKey: string, pageKey: string, newPageKey: string) => Promise<void> = async (key, collectionKey, pageKey, newPageKey) => {
        await axios.post(`/api/websites/${key}/page/${collectionKey}/${pageKey}/clone/`, { newPageKey });
    }

    public updatePage: (key: string, collectionKey: string, pageKey: string, data: any) => Promise<void> = async (key, collectionKey, pageKey, data) => {
        const result: any = await axios.post(`/api/websites/${key}/page/${collectionKey}/${pageKey}/`, data);
        return result.data;
    }

    public removePage: (key: string, collectionKey: string, pageKey: string) => Promise<void> = async (key, collectionKey, pageKey) => {
        await axios.post(`/api/websites/${key}/page/${collectionKey}/${pageKey}/remove/`);
    }

    public renameWebsite: (key: string, to: string) => Promise<void> = async (key, to) => {
        await axios.post(`/api/websites/${key}/rename/`, { to });
    }

    public deleteWebsite: (key: string) => Promise<void> = async (key) => {
        await axios.post(`/api/websites/${key}/delete/`);
    }

    public getNote: (key: string, noteKey: string) => Promise<string> = async (key, noteKey) => {
        const result = await axios.get(`/api/websites/${key}/note/${noteKey}/`);
        return result.data;
    }

    public setNote: (key: string, noteKey: string, content: string) => Promise<void> = async (key, noteKey, content) => {
        await axios.post(`/api/websites/${key}/note/${noteKey}/`, { content });
    }

    public deleteUser: (key: string, username: string) => Promise<void> = async (key, username) => {
        await axios.post(`/api/websites/${key}/users/delete`, { username });
    }

    public putUser: (key: string, data: { username: string, roles: string[] }) => Promise<void> = async (key, data) => {
        await axios.post(`/api/websites/${key}/users/put`, data);
    }

    public listUsers: (key: string) => Promise<Array<{ username: string }>> = async (key) => {
        const result = await axios.get(`/api/websites/${key}/users/`, {});
        return result.data;
    }

    public getVariables: (key: string, path?: string) => Promise<{ variables: { used: any, unused: any }, versionId: string }> = async (key, path) => {
        const result = await axios.get(`/api/websites/${key}/variables/${path ? '?path=' + encodeURIComponent(path) : ''}`, {});
        return result.data;
    }

    public putVariables: (key: string, path: string | null, data: { variables: any, versionId: string }) => Promise<{ variables: { used: any, unused: any }, versionId: string }> = async (key, path, data) => {
        const result = await axios.put(`/api/websites/${key}/variables/${path ? '?path=' + encodeURIComponent(path) : ''}`, data);
        return result.data;
    }

    //singles

    public getSingle: (website: string, single: string) => Promise<any> = async (website, single) => {
        const result = await axios.get(`/api/websites/${website}/singles/${single}/`);
        return result.data;
    }

    public putSingle: (website: string, single: string, data: any) => Promise<void> = async (website, single, data) => {
        const result = await axios.put(`/api/websites/${website}/singles/${single}/`, data);
        return result.data;
    }

    //collections

    public getCollectionItem: (website: string, collection: string, pageKey: string) => Promise<any> = async (website, collection, pageKey) => {
        const result = await axios.get(`/api/websites/${website}/collections/${collection}/${pageKey}/`);
        return result.data;
    }

    public listCollection: (website: string, collection: string) => Promise<{ items: Array<any> }> = async (website, collection) => {
        const result: any = await axios.get(`/api/websites/${website}/collections/${collection}/`);
        return result.data;
    }

    public createCollectionItem: (website: string, collection: string, pageKey: string) => Promise<void> = async (website, collection, pageKey) => {
        await axios.post(`/api/websites/${website}/collections/${collection}/${pageKey}/create/`);
    }

    public updateCollectionItem: (website: string, collection: string, pageKey: string, data: any) => Promise<void> = async (website, collection, pageKey, data) => {
        const result: any = await axios.post(`/api/websites/${website}/collections/${collection}/${pageKey}/`, data);
        return result.data;
    }

    public removeCollectionItem: (website: string, collection: string, pageKey: string) => Promise<void> = async (website, collection, pageKey) => {
        await axios.post(`/api/websites/${website}/collections/${collection}/${pageKey}/remove/`);
    }

    public getAliases: (website: string) => Promise<Array<any>> = async (website) => {
        const result: any = await axios.get(`/api/websites/${encodeURIComponent(website)}/aliases/`);
        return result.data;
    }

    public putAlias: (website: string, alias: string) => Promise<void> = async (website, alias) => {
        const result: any = await axios.put(`/api/websites/${encodeURIComponent(website)}/aliases/${encodeURIComponent(alias)}`);
        return result.data;
    }

    public putStatus: (website: string, status: string) => Promise<void> = async (website, status) => {
        const result: any = await axios.put(`/api/websites/${encodeURIComponent(website)}/status/${encodeURIComponent(status)}`);
        return result.data;
    }

    public deleteAlias: (website: string, alias: string) => Promise<void> = async (website, alias) => {
        const result: any = await axios.delete(`/api/websites/${encodeURIComponent(website)}/aliases/${encodeURIComponent(alias)}`);
        return result.data;
    }
}
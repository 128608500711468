import * as React from 'react';
import { Button, Paper, Typography, Divider } from '@material-ui/core';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import _AceEditor from "react-ace";
const AceEditor = _AceEditor as any;
declare const ace: any;

interface Props extends WithSnackbarProps {
    title?: string;
    mode: string;
    getData: () => Promise<any>;
    saveData: (data: any) => Promise<void>;
    build?: ()=> Promise<{error?: string}>;
}

interface State {
    value: string;
    modified: boolean;
    saving: boolean;
}

export const RawDataForm = withSnackbar(class RawDataForm extends React.Component<Props,State>{
    input: any;
    state: State = {
        saving: false,
        value: "",
        modified: false
    }

    constructor(props: any){
        super(props);
        ace.config.set('basePath', '/ace-builds/src-noconflict');
        ace.config.set('modePath', '/ace-builds/src-noconflict');
        ace.config.set('themePath', '/ace-builds/src-noconflict');
    }

    componentDidMount = async () => {
        await this.load();
    }

    load = async () =>{
        let data = await this.props.getData();
        this.setState({value: data});
    }

    handleSave = async (e: any) => {
        try{
            this.setState({saving: true});
            await this.props.saveData(this.state.value);
            await this.load();
            this.props.enqueueSnackbar('Saved successfully.');
            this.setState({modified: false});
        }
        catch(e){
            this.props.enqueueSnackbar('Failed to save file.');
        }
        finally{
            this.setState({saving: false});
        }
    }

    handleBuild = async (event: any) => {
        if(this.props.build==null) return;
        try{
            await this.handleSave(event);
            const result = await this.props.build();
            this.props.enqueueSnackbar('Website built successfully.');
            if(result.error){ alert(result.error); }
        }
        catch(e: any){
            alert(e.toString());
        }
    }

    handleKeyDown = async (event: any) => {
		let charCode = String.fromCharCode(event.which).toLowerCase();
		if(event.ctrlKey && charCode === 's') {
			event.preventDefault();
			this.handleSave(event);
        }
        if(event.ctrlKey && charCode === 'b' && this.props.build!=null) {
			event.preventDefault();
            this.handleBuild(event);
		}
	}

    handleChange = (value: string)=>{
        this.setState({value, modified: true});
    }

    private _savingStyle = {opacity: .5};


	render(){
		return (
        <div style={{padding: '5px'}} onKeyDown={this.handleKeyDown}>
            <Paper style={{padding: '3px'}}>
                { this.props.title && (<React.Fragment>
                    <Typography>{this.props.title}</Typography>
                    <Divider />
                </React.Fragment>) }
                <div style={{height: '5px'}}></div>
                <div style={this.state.saving?this._savingStyle:undefined}>
                    <AceEditor
                        mode={this.props.mode}
                        theme="chrome"
                        tabSize={2}
                        showPrintMargin={false}
                        maxLines={(window.innerHeight - 160)/15}
                        width="100%"
                        fontSize={15}
                        showGutter={true}
                        onChange={this.handleChange}
                        value={this.state.value}
                        editorProps={{ $blockScrolling: true }}
                    />
                </div>
            </Paper>
            <div style={{height: '5px'}}></div>
            <Button size="small" disabled={!this.state.modified} variant="contained" color="primary" onClick={this.handleSave}>Save Changes</Button>
             &nbsp; { this.props.build && <Button size="small" onClick={this.handleBuild}>Build Website</Button> }
        </div>
        )
	}
});
import { AuthenticationService } from './authentication-service/types';
import { UIService } from './ui-service';
import { LogService } from './log-service';
import { DefaultAuthenticationService } from './authentication-service/default-authentication-service';
import { DefaultLogService } from './log-service/default-log-service';
import { QualityCheckController } from './quality-check-service';
import { GenericWebsiteService } from './hugo-sites-service/generic-website-service';
import { UserService } from './user-service';
import { InfraCredentialService } from './infra-credential-service';
import { AgencyService } from './agencyService';
import { EventEmitter } from 'events';
import { ReviewService } from './review-service';
import { DatabaseService } from './database-service';
import { SvgService } from './svg-service';
import { ThemeService } from './theme-service';


class ServiceLocator {
    
    private authenticationService: AuthenticationService;
    private uiService: UIService;
    private logService: LogService;
    private websiteService: GenericWebsiteService;
    private qualityCheckService: QualityCheckController;
    private userService: UserService;
    private infraCredentialService: InfraCredentialService;
    private agencyService: AgencyService;
    private eventEmitter: EventEmitter;
    private reviewService: ReviewService;
    private databaseService: DatabaseService;
    private svgService: SvgService;
    private themeService: ThemeService;

    constructor(){
        this.qualityCheckService = new QualityCheckController();
        if(false){
            // this.authenticationService = new MockAuthenticationService();
            // this.uiService = new UIService();
            // this.primeLendingService = new MockPrimeLendingService();
            // this.logService = new MockLogService();
            // this.genericWebsiteService = new GenericWebsiteService();
        }
        else{
            this.eventEmitter = new EventEmitter();
            this.authenticationService = new DefaultAuthenticationService(this.eventEmitter);
            this.uiService = new UIService(this.eventEmitter, this.authenticationService);
            this.websiteService = new GenericWebsiteService();
            this.logService = new DefaultLogService();
            this.userService = new UserService();
            this.infraCredentialService = new InfraCredentialService();
            this.agencyService = new AgencyService();
            this.reviewService = new ReviewService();
            this.databaseService = new DatabaseService();
            this.svgService = new SvgService();
            this.themeService = new ThemeService();
        }
    }

    public getDatabaseService() : DatabaseService {
        return this.databaseService;
    }

    public getSvgService() : SvgService {
        return this.svgService;
    }

    public getReviewService() : ReviewService {
        return this.reviewService;
    }

    public getLogService() : LogService {
        return this.logService;
    }

    public getWebsiteService() : GenericWebsiteService {
        return this.websiteService;
    }

    public getAuthenticationService() : AuthenticationService {
        return this.authenticationService;
    }

    public getAgencyService() : AgencyService {
        return this.agencyService;
    }

    public getUIService(){
        return this.uiService;
    }

    public getEventEmitter(): EventEmitter{
        return this.eventEmitter;
    }

    public getQualityCheckService(){
        return this.qualityCheckService;
    }

    public getUserService(){
        return this.userService;
    }

    public getInfraCredentialService(){
        return this.infraCredentialService;
    }

    public getThemeService(){
        return this.themeService;
    }
}

export const serviceLocator = new ServiceLocator();
import * as React from 'react';
import { Link, Typography } from '@material-ui/core';
import * as HoForm from './../HoForm';
import { NavigateNext } from '@material-ui/icons';


type BreadcumbItemProps = {
    disabled?: boolean,
    label: string,
    onClick?: ()=>void
}

type BreadcumbItemState = {

}

export class BreadcumbItem extends React.Component<BreadcumbItemProps,BreadcumbItemState>{
    render(){
        return (<Link
            style={{cursor: 'pointer'}}
            color="inherit"
            onClick={this.props.onClick}>
            <Typography component="span" color={this.props.disabled===true?"textSecondary":"textPrimary"}>{this.props.label}</Typography>
        </Link>);
    }
}

type BreadcumbProps = {
    items: Array<React.ReactElement<typeof BreadcumbItem>>,
    style?: any
}

type BreadcumbState = {

}

export class Breadcumb extends React.Component<BreadcumbProps,BreadcumbState>{
    render(){
        
        let { items } = this.props;
        let newItems = [];
        
        for(let i = 0; i < items.length; i++){
            if(i > 0){
                newItems.push(<span key={`arrow-${i}`}> <NavigateNext style={{fontSize: '16px', position:'relative', top:'2px'}} /></span>)
            }
            
            newItems.push(React.cloneElement(items[i], {key:'breadcumb-item-'+i}));
        }

        return (<div style={{ ...this.props.style, display: "flex", alignItems: "center", padding: '6px' }}>
            {newItems}
        </div>);
    }
}

export class FormBreadcumb extends React.Component<HoForm.BreadcumbProps,{}>{

    render(){
        return (
        <Breadcumb style={{marginBottom:'16px'}} items={
            this.props.items.map((item, i)=>{
                if(item.node!=null){
                    let node = item.node;
                    return ( <BreadcumbItem key={`breadcumb-${i}`} label={item.label||'Untitled'} onClick={()=>this.props.onNodeSelected(node)} /> );
                }
                else{
                    return ( <BreadcumbItem key={`breadcumb-${i}`} label={item.label||'Untitled'} disabled={true} /> );
                }
            })
        } />
        );  
    }
}
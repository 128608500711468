import * as React from 'react';
import { ComponentProps, CrawlContext, NormalizeStateContext } from '../HoForm';
import { BaseDynamic } from '../HoForm';
import { setValidationErrorIntoState, getValidationError } from '../HoForm/utils';
import MomentUtils from '@date-io/moment';
import { KeyboardDateTimePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

type DateTimeDynamicField = {
    key: string,
    type: string,
    default?: string,
    tip?: string,
    title?: string,
    required?: boolean,
    autoUpdate?: boolean,
    margin?: 'none' | 'dense' | 'normal',
    dateType: 'date' | 'datetime' | 'time'
}

type DateTimeDynamicState = {

}

const INPUT_LABEL_PROPS = { shrink: true };

export class DateTimeDynamic extends BaseDynamic<DateTimeDynamicField, DateTimeDynamicState> {

    static dateTypes = {
        "datetime": {
            format: "YYYY-MM-DDTHH:mm:ss",
            mask: "____-__-__T__:__:__"
        },
        "date": {
            format: "YYYY-MM-DD",
            mask: "____-__-__"
        },
        "time": {
            format: "HH:mm:ss",
            mask: "__:__:__"
        }
    };

    constructor(props: ComponentProps<DateTimeDynamicField>) {
        super(props);
    }

    normalizeState({ state, field }: NormalizeStateContext<DateTimeDynamicField>) {
        let key = field.key;
        
        if(field.autoUpdate===true){
            state[key] = moment().format(this.getSet(field.dateType).format);
        }
        else if (state[key] == null) {
            var def = field.default || '';
            if(def=='now'){
                def = moment().format(this.getSet(field.dateType).format);
            }
            state[key] = def;
        }
    }

    getType() {
        return 'datetime';
    }

    getSet(dateType: any){
        return (DateTimeDynamic.dateTypes as any)[dateType] || DateTimeDynamic.dateTypes["datetime"];
    }

    handleChange = (momentDt: any) => {
        this.forceUpdate();
        
        if (momentDt != null && momentDt.isValid()) {
            this.props.context.setValue(momentDt.format(this.getSet(this.props.context.node.field.dateType).format), 250);
        }
        else {
            this.props.context.setValue(null, 250);
        }
    }

    crawlComponent({ form, node }: CrawlContext<DateTimeDynamicField>): void {
        const value = this.getValueFromNode(node);
        if (node.field.required) {
            let validationError = '';
            if (node.field.required) {
                const invalid = value == null || value === '';
                validationError += invalid ? `The field is required.` : '';
            }
            setValidationErrorIntoState(node.state, form.buildDisplayPath(node), validationError);
        }
    }

    renderComponent() {

        let { context } = this.props;
        let { node, nodePath, currentPath, parentPath } = context;
        let { field } = node;
        let set = this.getSet(field.dateType);

        if (!parentPath.startsWith(currentPath)) {
            return (null);
        }

        const error = getValidationError(node.state, nodePath);

        const DateComp = field.dateType=="time" ? KeyboardTimePicker : KeyboardDateTimePicker;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateComp
                    key={nodePath}
                    onChange={this.handleChange}
                    error={error != null}
                    value={context.value || ''}
                    fullWidth={true}
                    format={set.format}
                    mask={set.mask}
                    margin={field.margin || 'dense'}
                    InputLabelProps={INPUT_LABEL_PROPS}
                    variant="inline"
                    label={field.title}
                    helperText={([...error ? [error] : [], ...field.tip ? [field.tip] : []]).join(' ')}
                />
            </MuiPickersUtilsProvider>
        );
    }
}
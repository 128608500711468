import * as React from 'react';
import { BaseDynamic, FieldBase, ComponentProps, CrawlContext } from '../_ho-forms-lib/src/HoForm';
import { TextField, InputAdornment, Grow, Fade, Popover, IconButton } from '@material-ui/core';
import { PreviewThumbnail } from '../components/PreviewThumbnail';
import backgroundImage from './../components/grid.png';
import { CloudUpload, Search } from '@material-ui/icons';
import { setValidationErrorIntoState, getValidationError } from '../_ho-forms-lib/src/HoForm/utils';

interface FileUploadDynamicField extends FieldBase{
    title: string;
    tip?: string;
    required?: boolean;
    group?: string;

}

interface ImageUploadDynamicState {
    anchor: any|null;
    previewVisible: boolean,
    hasError?: boolean,
    uploading: boolean
}

const INPUT_LABEL_PROPS = { shrink: true };
const FILE_UPLOAD_STYLE = { width: '1px', height: '1px', visibility: 'hidden' as any };

export class ImageUploadDynamic extends BaseDynamic<FileUploadDynamicField, ImageUploadDynamicState>{
    
    fileUploadRef?: any;

    state = {
        anchor: null,
        previewVisible: false,
        uploading: false
    }

    constructor(props: ComponentProps<FileUploadDynamicField>){
        super(props);
    }

    normalizeState({state, field}: {state: any, field: FileUploadDynamicField}){
        let key = field.key;
        if(state[key]==null){
            state[key] = field.default || '';
        }
    }

    getType(){
        return 'image-upload';
    }

    handleChange = (e: any)=>{
        this.forceUpdate();
        this.props.context.setValue(e.target.value, 250);
    }

    crawlComponent({form, node} : CrawlContext<FileUploadDynamicField>): void{
        if(node.field.required){
            const value = this.getValueFromNode(node);
            setValidationErrorIntoState(node.state, form.buildDisplayPath(node), !value?`The field is required.`:'');
        }
    }

    handlePreviewClick = (e: any) =>{
        e.preventDefault();
        if(this.props.context.value){
            this.setState({ previewVisible: true, anchor: e.currentTarget });
        }
    };

    handlePopupClose = (e: any) => {
        this.setState({previewVisible: false});
    }

    handleUploadClick = (e: any) => {
        e.preventDefault();
        if(this.fileUploadRef)this.fileUploadRef.click();
    }

    handleSearchClick = (e: any) => {
        this.props.context.form.props.plugins.pickFile(this.props.context.node.field.group||'static', (path: string)=>{
            this.props.context.setValue(path, 0);
        });
    }

    handleFileChange = (e: any)=>{
        e.persist()
        this.setState({uploading: true});
        const uploadFn: any = this.props.context.form.props.plugins.uploadImage;
        if(uploadFn){
            uploadFn(this.props.context.node.field.group||'static', e).then((x: string) =>{
            this.setState({uploading: false});
            this.props.context.setValue(x);
        }, (uploadError: any)=>{
            this.setState({uploading: false});
        });
        }
    }

    handleFileUploadRef = (ref: any) =>{
        this.fileUploadRef = ref;
    }

    renderComponent(){
        
        let {context} = this.props;
        let {node, nodePath, currentPath, parentPath} = context;
        let {field} = node;
                
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }
        
        const error = getValidationError(node.state, nodePath);
        const previewResolve = context.form.props.plugins.resolveImgPreviewURL;
        const previewUrl = previewResolve? previewResolve(field.group||'static', context.value) : context.value;

        return (<React.Fragment>
            <TextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <input
                                ref={this.handleFileUploadRef}
                                type="file"
                                style={FILE_UPLOAD_STYLE}
                                onChange={this.handleFileChange}
                            />
                            <IconButton disabled={this.state.uploading} onClick={this.handleUploadClick}><CloudUpload fontSize="small" /></IconButton>
                            <IconButton disabled={this.state.uploading} onClick={this.handleSearchClick}><Search fontSize="small" /></IconButton>
                            <span> </span>
                            <Grow appear={true} in={true}>
                                <PreviewThumbnail component="a" href={previewUrl} url={previewUrl} onClick={this.handlePreviewClick} />
                            </Grow>
                        </InputAdornment>
                    )
                }}
                id={`text-field-${field.key}`}
                onChange={ this.handleChange }
                error={ !!error }
                value={context.value}
                fullWidth={true}
                disabled={this.state.uploading}
                margin="dense"
                InputLabelProps={INPUT_LABEL_PROPS}
                label={field.title}
                helperText={([ ...error?[error]:[], ...field.tip?[field.tip]:[] ]).join(' ')}
            />
            { context.value && this.state.anchor && (<Popover
                anchorEl={this.state.anchor}
                open={this.state.previewVisible}
                onClose={this.handlePopupClose}
                TransitionComponent={Fade}
            >
                <div style={{backgroundImage: `url(${backgroundImage})`}}>
                    <a target="_blank" href={previewUrl} style={{
                        display: 'block',
                        width:'400px',
                        height:'400px',
                        background:`url(${previewUrl}) no-repeat center center`,
                        backgroundSize:"contain"
                    }}></a>
                </div>
            </Popover>)}
        </React.Fragment>);
    }
}
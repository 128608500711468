import * as React from 'react';
import { Divider, List, Paper, ListSubheader, ListItem, ListItemSecondaryAction, ListItemText, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { serviceLocator } from '../../../services';
import { WebsiteUserForm } from './WebsiteUserForm';
import { ListSubheaderWithButtons } from '../../ListSubheaderWithButtons';


const genericWebsiteService = serviceLocator.getWebsiteService();

interface Props{
    websiteKey: string;
    paperProps?: any;
}

interface State{
    menuAnchor: any;
    userMenuOpen: boolean;
    userMenuKey: string;
    users: Array<any>;
    openDialog: string|null;
    editUser: ({ username: string, roles: string[] } | null);
}

export class UsersList extends React.Component<Props, State>{

    state: State = {
        menuAnchor: null,
        userMenuOpen: false,
        userMenuKey: '',
        users: [],
        openDialog: null,
        editUser: null
    }

    private restoreScroll(){
        // fix
        setTimeout(()=>{
            document.body.style.overflow = "";
        },100);
    }

    componentDidMount = () => {
        this.loadUsers();
    }

    loadUsers = async () => {
        const users = await genericWebsiteService.listUsers(this.props.websiteKey);
        this.setState({ users });        
    }

    private handleNewClick = async (e: any) => {
        this.setState({ openDialog: 'new', editUser: null });
    }   

    handleUserDelete = async (e: any) => {
        this.setState({userMenuOpen: false});
        await genericWebsiteService.deleteUser(this.props.websiteKey, this.state.userMenuKey);
        this.loadUsers();
    }

    handleUserEdit = async (e: any) => {
        this.setState({
            openDialog: 'new',
            editUser: this.state.users.find(x => x.username === e.currentTarget.dataset.user)
        });
    }

    private handleUserMoreClick = (e: any) => {
        const target = e.currentTarget;
        this.setState({ userMenuOpen: true, menuAnchor: target, userMenuKey: target.dataset.key });
    }

    handlePutUserFormSubmit = async (values: any, { setSubmitting, resetForm }: any) =>{
        try{
            await genericWebsiteService.putUser(this.props.websiteKey, values.username);
            setSubmitting(false);
            resetForm();
            this.setState({ openDialog: null });
            this.loadUsers();
        }
        catch{
            setSubmitting(false);
            alert('An error has ocurred');
        }
    }

    private handleMenuClose = (e: any) => {
        this.setState({ userMenuOpen: false });
    }

    renderDialog(){
        const key = (this.state.editUser||{}).username||'new'; //to force the form to refresh
        return <WebsiteUserForm
            key={key}
            initialValues={this.state.editUser}
            websiteKey={this.props.websiteKey}
            open={this.state.openDialog==="new"}
            onClose={()=>{ this.setState({openDialog: null}) }}
            onUpdate={()=>{ this.loadUsers(); this.setState({openDialog: null, editUser: null}) }}
        />
    }

    renderMenu() {
        return (
            <Menu
                key="user-menu"
                open={this.state.userMenuOpen}
                anchorEl={this.state.menuAnchor}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleUserDelete}>Remove</MenuItem>
            </Menu>
        );
    }

    render(){
        return (
            <React.Fragment>
                <Paper {...this.props.paperProps}>
                    <List component="nav" subheader={<ListSubheaderWithButtons title="Users" onNewClick={this.handleNewClick} />}>
                        <Divider />
                        {this.state.users.map((user, i) => {
                            var roles = (user.roles||[]).join(', ');
                            return (
                                <ListItem
                                    key={user.username}
                                    data-user={user.username}
                                    button={true}
                                    onClick={this.handleUserEdit}
                                    divider={true}>
                                    <ListItemText primary={<React.Fragment>{user.username}{roles && <Typography variant="body2" display="inline" color="textSecondary"> [{roles}]</Typography>}</React.Fragment>} />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={this.handleUserMoreClick} data-key={user.username}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                </Paper>
                {/* <div style={{ padding: '16px 0' }}>
                    <span> </span><Button size="small" onClick={this.handleNewClick}>Add User</Button>
                </div> */}
                {this.renderDialog()}
                {this.renderMenu()}
            </React.Fragment>
        )
    }
}
import * as React from 'react';
import { MainPadding } from '../../components/MainPadding';
import { Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { serviceLocator } from '../../services';
import { Link } from 'react-router-dom';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Breadcumb } from '../../components/Breadcumb';

const userService = serviceLocator.getUserService();

interface State {
    users: Array<any>,
    activeUsers: boolean
}

export class UsersList extends React.Component<any,State>{

    state = {
        users: [],
        activeUsers: true
    }

    componentDidMount(){
        this.refreshList();
    }

    refreshList = async () =>{
        const { users } = await userService.listUsers(this.state.activeUsers);
        this.setState({ users });
    }

    getEditLink = (username: string) =>React.forwardRef((props: any, ref: any) => <Link ref={ref} to={`/users/edit/${encodeURIComponent(username)}/`} {...props} />);

    handleActiveUsersChange = (e: any) => {
        this.setState({activeUsers: e.currentTarget.checked}, ()=>{
            this.refreshList();
        });
    }

    render(){
        return (
            <MainPadding>
                
                <Breadcumb items={[
                    { label:"Users" }
                ]} />
                <br />
                
                <div style={{ padding: '16px 0' }}>
                <Button variant="contained" color="primary" component={React.forwardRef((props: any, ref: any)=>(<Link ref={ref} to="/users/create/" {...props} />))}>New</Button>
                     {/* &nbsp; <Button variant="text" size="small" color="default" onClick={this.handleBatchBuildClick}>Batch Build</Button> */}
                </div>

                <FormControlLabel
                    label="Active" control={<Checkbox checked={this.state.activeUsers} onChange={this.handleActiveUsersChange}  />} />
                <Paper>
                    
                    <List component="nav">
                        { (this.state.users||[]).map((user: any, i: number) => {
                            return (<ListItem key={user.username}
                                button={true}
                                component={this.getEditLink(user.username)}
                                divider={i < this.state.users.length - 1}>
                                <ListItemText primary={`${user.username}`} secondary={`${user.email} - [${(user.roles.length===0?['user']:user.roles).join(',')}]`} />
                                {/* <ListItemSecondaryAction>
                                    <IconButton  data-key={user.username}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </ListItemSecondaryAction> */}
                            </ListItem>)
                        }) }
                    </List>
                </Paper>
            </MainPadding>
        )
    }
}
import * as React from 'react';
import { Button, List, Dialog, DialogContent, DialogActions, ListItem, Checkbox, ListItemText, DialogTitle, Divider, CircularProgress } from '@material-ui/core';

type BatchBuildDialogProps = {
    open: boolean,
    operation: 'build'|'sync',
    buildWebsite: (key: string)=>Promise<{error?:any}>,
    syncWebsite: (key: string)=>Promise<void>,
    listWebsitesExtended: ()=>Promise<Array<any>>
} & {[key: string]: any};

type BatchBuildDialogState = {
    runningItem: string | null,
    items: Array<{key: string, selected: boolean}>
}

const listStyle = { minWidth: '400px' };

export class BatchBuildDialog extends React.Component<BatchBuildDialogProps,BatchBuildDialogState>{
    
    state: BatchBuildDialogState = {
        runningItem: null,
        items: [],
    }

    private handleToggle = (value: any) => (e: any) => {
        this.toggleItem(value);
    }

    private toggleItem = (value: string)=>{
        const items = this.state.items;
        const index = items.findIndex(x => x.key===value);
        const item = items[index];
        items[index] = { ...item, selected: !item.selected };
        this.setState({ items: [...items] });
    }

    private runBatchBuild = async ()=>{
        const items = this.state.items;
        for(let i = 0; i < items.length; i++){
            const item = items[i];
            if(item.selected){
                this.setState({ runningItem: item.key });
                try{
                    if(this.props.operation==='build'){
                        await this.props.buildWebsite(item.key);
                    }
                    else{
                        await this.props.syncWebsite(item.key);
                    }
                    this.toggleItem(item.key);
                }
                catch(e){
                    //do nothing
                }
            }
        }
        this.setState({ runningItem: null });
    }

    private handleExited = (e:any) => {
        this.setState({items: []});
    }

    private handleEntering = async (e:any) => {
        // load
        const items = (await this.props.listWebsitesExtended())
            .filter(x =>{
                if(this.props.operation==='build') return true;
                return x.infrastructure!=null && x.infrastructure.bucket!=null && x.infrastructure.distribution!=null
            }).map(x =>(
            {key: x.key, selected: false}
        ));
        this.setState({
            items
        });
        
    }

    public render(){

        const { open, onClose, buildWebsite, syncWebsite, listWebsitesExtended, ...rest } = this.props;
        const { runningItem } = this.state;
        const disableUI = runningItem != null;

        return (
            <Dialog onClose={runningItem != null ? null : onClose} scroll="paper" open={open} {...rest} onEntering={this.handleEntering} onExited={this.handleExited}>
                <DialogTitle>
                    { disableUI && <div style={{float: 'right', position:'relative'}}><CircularProgress style={{position: 'absolute', right: 0}} /></div>}
                    {this.props.operation==='build'?'Batch Build':'Batch Sync'}
                </DialogTitle>
                <DialogContent>
                <List style={listStyle} dense={true}>
                    {this.state.items.map((item,i) => (
                        <React.Fragment key={item.key}>
                            { i !== 0 && (<Divider />) }
                            <ListItem button disabled={disableUI} onClick={this.handleToggle(item.key)}>
                                <Checkbox checked={item.selected} />
                                <ListItemText primary={item.key} />
                            </ListItem>
                        </React.Fragment>
                    ))}
                </List>
                    
                </DialogContent>
                <DialogActions>
                    <Button disabled={disableUI} onClick={onClose} variant="text">Close</Button>
                    <Button disabled={disableUI} variant="contained" color="primary" onClick={this.runBatchBuild}>Run</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
import * as React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Link } from "react-router-dom"
import { MainPadding } from '../../../components/MainPadding';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { Breadcumb } from '../../../components/Breadcumb';
import { FolderOutlined } from '@material-ui/icons';
import { SmartFileManagerDialog } from '../../../components/Websites/SmartFileManagerDialog';
import { RoleSection } from '../../../components/RoleSection';
import { serviceLocator } from '../../../services';
import { AdminOrAgencyMemberSection } from '../../../components/AdminOrAgencyMemberSection';
import { ReviewsProvidersList } from '../../../components/ReviewsProvidersList';
import { QualityReportsList } from '../../GenericWebsites/Website/QualityReportsList/QualityReportsList';

const primeLendingService = serviceLocator.getWebsiteService();

interface WebsitesProps {
    websiteKey: string
}

interface WebsiteState {
    fileManagerOpen: boolean,
    website: any
}

export class Website extends React.Component<WebsitesProps, WebsiteState>{

    constructor(props: any) {
        super(props);
        this.state = {
            fileManagerOpen: false,
            website: null
        };
    }

    loadSite = async () => {
        const website = await primeLendingService.getWebsite(this.props.websiteKey);
        this.setState({ website });
    }

    public componentDidMount = async () => {
        this.loadSite();
    }

    private globalPropsLink = (props: any) => {
        return <Link to={`main-form/`} {...props} />;
    }

    handleFileManagerOpen = (e: any) => {
        this.setState({ fileManagerOpen: true });
    }

    toggleDailyCheck = async () => {
        await primeLendingService.toggleQualityChecks(this.props.websiteKey, this.state.website.enableQualityChecks !== true);
        this.loadSite();
    }

    public render() {

        if (this.state.website == null) return (null);

        // LIST + DIALOGS
        return (
            <MainPadding>
                <Button style={{ float: 'right', marginLeft: '4px' }} onClick={this.handleFileManagerOpen}>Static &nbsp; <FolderOutlined /></Button>
                <Breadcumb items={[
                    { to: `/websites/prime-lending/`, label: `Prime Lending Websites` },
                    { label: `Website ${this.props.websiteKey}` }
                ]} />

                <br />
                <div style={{ padding: '16px 0' }}>
                    <Button variant="contained" color="primary" component={this.globalPropsLink}>Main Page</Button>
                    &nbsp; <Button component={(p) => { const { innerRef, ...rest } = p; return <Link {...rest} to={`/websites/prime-lending/${this.props.websiteKey}/pages/great-businesses`} /> }} variant="text" color="default">Businesses Page</Button>
                     &nbsp; <Button component={(p) => { const { innerRef, ...rest } = p; return <a {...rest} target="_blank" href={`http://prime-lending--${this.props.websiteKey}.hugosites.webdrvn.com`} /> }} variant="text" color="default">Preview</Button>
                    <AdminOrAgencyMemberSection agency={this.state.website.agency}>
                        &nbsp; <Button component={(p) => { const { innerRef, ...rest } = p; return <Link {...rest} to={`/websites/prime-lending/${this.props.websiteKey}/deploy/`} /> }} variant="text" color="default">Deploy</Button>
                    </AdminOrAgencyMemberSection>
                </div>

                <br />

                <Grid container spacing={3} alignItems="flex-start">

                    <RoleSection role="admin">

                        <Grid item md={4} xs={12}>
                            <ErrorBoundary>
                                <ReviewsProvidersList websiteKey={this.props.websiteKey} />
                            </ErrorBoundary>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <ErrorBoundary>
                                <QualityReportsList
                                    dailyCheckEnabled={this.state.website.enableQualityChecks === true}
                                    onEnableDailyCheckToggle={this.toggleDailyCheck}
                                    agency={this.state.website.agency}
                                    websiteKey={this.props.websiteKey} />
                            </ErrorBoundary>
                        </Grid>

                    </RoleSection>

                </Grid>

                <SmartFileManagerDialog
                    assets={false}
                    themeKey="prime-lending"
                    websiteKey={this.props.websiteKey}
                    open={this.state.fileManagerOpen}
                    onClose={() => this.setState({ fileManagerOpen: false })}
                    onPick={null} />

            </MainPadding>
        )
    }
}
import * as React from 'react';
import * as Yup from 'yup';
import { Formik, FastField } from 'formik';
import { FormikTextField } from './FormikTextField';
import { Button, Dialog, DialogTitle, InputAdornment, DialogContent, IconButton, Typography, DialogActions, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { serviceLocator } from '../services';
import { DebouncedTextField } from './DebouncedTextField';
import { Remove, ExpandMore, FileCopy } from '@material-ui/icons';
import { secondaryColor } from '../themes';

const websiteService = serviceLocator.getWebsiteService();

interface Props {
    websiteKey: string;
    path?: string;
}

type VariablesArrayItem = {key: string, value: string, state:"used"|"unused"|"new"};
type VariablesArray = Array<VariablesArrayItem>;

interface State {
    variables: VariablesArray;
    versionId: string;
    showEntryModal: boolean;
}

export class WebsiteVariables extends React.Component<Props,State> {

    _hasChanges: boolean = false;

    state: State = {
        variables: [],
        versionId: 'NOT_LOADED',
        showEntryModal: false,
    }

    groupBy = function<T>(xs: T[], keyExtractor: (item: T)=>any) {
        return xs.reduce(function(rv: any, x: any) {
            (rv[keyExtractor(x)] = rv[keyExtractor(x)] || []).push(x);
            return rv;
        }, {});
    };

    setVariablesState = (variablesIn: {variables: { used: any, unused: any }, versionId: string }|null) => {
        const { used, unused } = { used:{}, unused:{}, ...(variablesIn||{variables: null}).variables };
        const versionId: string = variablesIn!=null ? variablesIn.versionId : '';
        const variables: VariablesArray =[
            ...Object.keys(used).map(key => ({ key, value: used[key], state:"used" } as VariablesArrayItem)),
            ...Object.keys(unused).map(key => ({  key, value: unused[key], state:"unused" } as VariablesArrayItem))
        ];
        variables.sort((a,b)=>{ return a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1; });
        this.setState({
            variables,
            versionId
        });
    }

    componentDidMount = async () => {
        const variables = (await websiteService.getVariables(this.props.websiteKey, this.props.path));
        this.setVariablesState(variables);
    }

    componentWillUnmount = () =>{
        if(this._hasChanges){
            if(window.confirm(`Do you want to save the website variables changes?\nVariables: (${this.props.path||"global"})` )){
                this.saveAndUpdate(false);
            }
        }
    }

    public saveAndUpdate = async (refresh: boolean = true) => {
        var dict: {[key:string]: string} = {};
        this.state.variables.forEach(item =>{ dict[item.key] = item.value });
        const {versionId} = this.state;
        const variables = (await websiteService.putVariables(this.props.websiteKey, this.props.path||null, { variables: dict, versionId }));
        if(refresh) this.setVariablesState(variables);
        this._hasChanges = false;
    }

    handleAddClick = async (e: any) => {
        this.setState({showEntryModal: true});
    }
 
    handleNewItemSubmit = async (values: any, { setSubmitting, resetForm }: any) =>{
        const key = values.key.trim();
        if(this.state.variables.findIndex(x => x.key===key)===-1){
            this.setState({
                variables: [...this.state.variables, { key, state:'new', value:'' }],
                showEntryModal: false
            });
            resetForm();
        }
        setSubmitting(false);
    }

    handleTextFieldChange = (e: any) => {
        this._hasChanges = true;
        const key = e.target.dataset.key;
        const index = this.state.variables.findIndex(x => x.key === key);
        const updateItem = this.state.variables[index];
        if(updateItem!=null){
            const { state } = updateItem;
            const variables = [ ...this.state.variables ];
            variables.splice(index, 1, { key, state, value: e.target.value });
            this.setState({ variables })
        }
    }

    handleDeleteEntryClick = (e: any) => {
        const key = e.currentTarget.dataset.key;
        const index = this.state.variables.findIndex(x => x.key===key);
        const variables = [ ...this.state.variables ];
        variables.splice(index, 1);
        this.setState({ variables })
    }

    handleCopyEntryClick = (e: any) => {
        const key = `{{ ${e.currentTarget.dataset.key} }}`;
        console.log('copy', key)
        navigator.clipboard.writeText(key)
    }

    handleDialogClose = (e: any) => {
        this.setState({showEntryModal: false});
    }

    renderDialog(){
        return (
            <Formik
                initialValues={{ key: '' }}
                onSubmit={this.handleNewItemSubmit} validationSchema={
                    Yup.object().shape({ key: Yup.string().required('Required.') }).required()
                }>
                { props => {
                    const { handleSubmit, isSubmitting, submitForm } = props;
    
                    return (
                        <Dialog
                            onClose={this.handleDialogClose}
                            open={this.state.showEntryModal}>
                            <DialogTitle>Create New Page</DialogTitle>
                            <DialogContent>
                                <form onSubmit={handleSubmit}>
                                    <FastField autoFocus={true} margin="normal" name="key" label="Entry Key" helperText='To group an entry, add a prefix followeb by ":".' component={FormikTextField} />
                                    <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{width:1, height:1, visibility:'hidden'}}>Create</button>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={isSubmitting} color="primary" type="submit" value="create" onClick={submitForm}>Create</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }}
            </Formik>
        );
    }

    extractPrefix = (item: VariablesArrayItem)=>{
        const parts = item.key.split(':');
        if(parts.length===1) return "Default";
        return parts[0].trim();
    }

    render(){

        var group = this.groupBy(this.state.variables, this.extractPrefix);
        var _group: Array<{ prefix: string, items: VariablesArrayItem[] }> = [];
        for(var key in group){
            _group.push({prefix: key, items: group[key]});
        }

        return (<React.Fragment>
            {_group.map(x=>{
            return (<ExpansionPanel key={x.prefix}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore />}
                  id="panel2a-header"
                >
                  <Typography>{x.prefix}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div style={{width: '100%'}}>
                        { x.items.map((x,i) =>(
                            <DebouncedTextField
                                style={x.state==='unused'?{ opacity: .5 }:null}
                                key={x.key} name={`variable:${x.key}`} label={x.key} value={x.value||''}
                                margin="dense"
                                inputProps={{"data-key":x.key}}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink:true,
                                    style: x.state==='new'? {color: secondaryColor[500]} : null
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                        <InputAdornment position="end"><IconButton style={{padding: 0}} data-key={x.key} onClick={this.handleCopyEntryClick}><FileCopy fontSize="medium" /></IconButton></InputAdornment>
                                        {x.state!=="used" && <InputAdornment position="end"><IconButton style={{padding: 0}} data-key={x.key} onClick={this.handleDeleteEntryClick}><Remove fontSize="medium" /></IconButton></InputAdornment>}
                                        </>
                                    )
                                }}
                                onChange={this.handleTextFieldChange} />                
                        )) }
                    </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>)
            })}
            <Button size="small" onClick={this.handleAddClick}>Add Entry</Button>
            {this.renderDialog()}
        </React.Fragment>);

        // return (<React.Fragment>
        //         { this.state.variables.map((x,i) =>(
        //             <DebouncedTextField
        //                 style={x.state==='unused'?{ opacity: .5 }:null}
        //                 key={x.key} name={`variable-${i}`} label={x.key} value={x.value||''}
        //                 margin="dense" inputProps={{"data-index":i}} fullWidth={true}
        //                 InputLabelProps={{
        //                     shrink:true,
        //                     style: x.state==='new'? {color: secondaryColor[500]} : null
        //                 }}
        //                 InputProps={{
        //                     endAdornment: x.state!=="used" && (
        //                         <InputAdornment position="end"><IconButton data-index={i} onClick={this.handleDeleteEntryClick}><Remove fontSize="medium" /></IconButton></InputAdornment>
        //                     )
        //                 }}
        //                 onChange={this.handleTextFieldChange} />                
        //         )) }
        //         <Button size="small" onClick={this.handleAddClick}>Add Entry</Button>
        //         {/* <Button onClick={this.handleUpdateDictionaryClick} size="small">Save / Update</Button> */}
        //     {this.renderDialog()}
        // </React.Fragment>);
    }   

}
import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Button, Fade, FormControl, Paper, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, IconButton, FormControlLabel } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, FolderOutlined, Remove } from "@material-ui/icons";
import { Formik, FastField, FieldArray } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core';
import { TabsSimple } from './../../components/TabsSimple';
import { FormModel } from './types';
import { FormikTextField } from './../../components/FormikTextField';
import { serviceLocator } from './../../services';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { FormikYoutubeTextField } from './../../components/FormikYoutubeTextField';
import { FormikRawInclude } from './../../components/FormikRawInclude';
import { FormikImageUpload } from './../../components/FormikImageUpload';
import { FormikRemovableTextField } from './../../components/FormikRemovableTextField';
import { MainPadding } from '../../components/MainPadding';
import { FormActions } from '../../components/FormActions';
import { FormikSelect } from '../../components/FormikSelect';
import { Breadcumb } from '../../components/Breadcumb';
import { Prompt } from 'react-router';
import { SmartFileManagerDialog } from './../../components/Websites/SmartFileManagerDialog';
import { FormikCheckbox } from '../../components/FormikCheckbox';

const styles: (theme: any) => any = (theme) => {
  return {
    root: {
      maxWidth: '42em'
    },
    caption: {
      marginTop: 8,
      fontSize: '75%',
      letterSpacing: 'normal',
    },
    section: {
      paddingLeft: '12px',
      borderLeft: `solid 6px ${theme.palette.primary.main}`,
      marginBottom: '6px'
    },
    addSectionButton: {
      position: 'absolute',
      marginTop: '-10px',
      marginLeft: '8px',
    },
    expansionPanelSummary: {
      display: 'block',
    },
  }
};

const RES = {
  required: 'Required.',
  email: 'Email format required.',
  rangeOfIntegers: 'Range of values required. Sample: 500-100000',
  url: 'URL format required.',
  openingHour: 'Entry of opening hour. Sample: We 10:00-18:00',
  internationalPhone: 'International phone format. Sample: +27768587777',
  youtubeHelper: 'Paste a Youtube link or just the video ID.'
}

const DEFAULT_TEAM_MEMBER = {
  firstName: '',
  lastName: '',
  nickName: '',
  title: '',
  subTitle: '',
  NMLS: '',
  email: '',
  mainPhone: '',
  biography: '',
  thumbnail: ''
}

const DEFAULT_CUSTOM_LINKS_SECTION = {
  title: 'Link Section',
  links: ['https://google.com']
}

const DEFAULT_DELAYED_SCRIPT = {
  script: '',
  delay: '2000'
}

interface FormProps extends WithSnackbarProps {
  websiteKey: string;
  classes: any;
};

interface FormState {
  teamIndex: number;
  sectionIncludesIndex: number;
  initialFormModel: FormModel | null;
  currentSection: string;
  changed: boolean;
  fileManagerDialogOpen: boolean;
}

export class FormUnstyled extends React.Component<FormProps, FormState>{

  private form: any = null;
  private serviceRenderIndex: number = 1;
  private customLinksRenderIndex: number = 1;
  private socialLinksCustomRenderIndex: number = 1;
  private calculatorsLinksCustomRenderIndex: number = 1;

  private versionId: string | null = null;
  private isSaving: boolean = false;

  constructor(props: FormProps) {

    super(props);

    this.state = {
      changed: false,
      teamIndex: 0,
      sectionIncludesIndex: 0,
      initialFormModel: null,
      currentSection: 'Main Specialist',
      fileManagerDialogOpen: false
    }
  }

  handleValidate = () => {
    if (!this.state.changed)
      this.setState({ changed: true })
  }

  handleFileManagerOpen = (e: any) => {
    this.setState({ fileManagerDialogOpen: true })
  }

  handleFileManagerClose = (e: any) => {
    this.setState({ fileManagerDialogOpen: false })
  }

  public render() {
    if (this.state.initialFormModel == null) {

      return (<MainPadding>
        <Typography color="textSecondary">Loading...</Typography>
      </MainPadding>);

    }

    return (
      <MainPadding className={this.props.classes.root}>
        <Prompt when={this.state.changed} message="There are unsaved changes. Proceed anyway?" />
        <Button style={{ float: 'right', marginLeft: '4px' }} onClick={this.handleFileManagerOpen}>Static &nbsp; <FolderOutlined /></Button>
        <Breadcumb items={[
          { label: "Prime Lending Websites", to: "/websites/prime-lending/" },
          { label: `Website ${this.props.websiteKey}`, to: `/websites/prime-lending/${this.props.websiteKey}/` },
          { label: "Main Page" }
        ]}>
        </Breadcumb>
        <br />
        <Formik
          initialValues={this.state.initialFormModel}
          validate={this.handleValidate}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            main: Yup.object().shape({
              firstName: Yup.string().required(RES.required),
              lastName: Yup.string().required(RES.required),
              nickName: Yup.string(),
              title: Yup.string().required(RES.required),
              subTitle: Yup.string(),
              NMLS: Yup.string().required(RES.required),
              email: Yup.string().email(RES.email).required(RES.required),
              mainPhone: Yup.string().required(RES.required),
              biography: Yup.string().required(RES.required),
              streetAddress: Yup.string().required(RES.required),
              city: Yup.string().required(RES.required),
              state: Yup.string().required(RES.required),
              zip: Yup.string().required(RES.required),
              hoursOfOperation: Yup.string().required(RES.required),
              image: Yup.string().required(RES.required),
              thumbnail: Yup.string().required(RES.required),
            }).required(RES.required),
            service: Yup.object().shape({
              openingHours: Yup.array(Yup.string().matches(new RegExp('^(Mo|Tu|We|Th|Fr|Sa|Su) [0-9]{1,2}:[0-9]{2}-[0-9]{1,2}:[0-9]{2}$'), RES.openingHour)
                .required(RES.required)).required(RES.required), // - Mo 8:00-17:00 - Tu 8:00-17:00 - We 8:00-17:00 - Th 8:00-17:00 - Fr 8:00-17:00 - Sa 8:00-12:00
              priceRange: Yup.string().matches(new RegExp('^[0-9]+[-][0-9]+'), RES.rangeOfIntegers).required(RES.required),
              areasServed: Yup.array(Yup.string().url(RES.url).required(RES.required)).required(RES.required),
              languages: Yup.array(Yup.string().url(RES.url).required(RES.required)).required(RES.required),
              phoneInternational: Yup.string().matches(new RegExp('^[+][0-9]{6,}$'), RES.internationalPhone),
            }),
            externalLinks: Yup.object().shape({
              apply: Yup.string().url(RES.url).required(RES.required)
            }).required(RES.required),
            socialLinks: Yup.object().shape({
              facebook: Yup.string().url(RES.url),
              googlePlus: Yup.string().url(RES.url),
              yelp: Yup.string().url(RES.url),
              instagram: Yup.string().url(RES.url),
              linkedIn: Yup.string().url(RES.url),
              youTube: Yup.string().url(RES.url),
              // maps: Yup.string().url(RES.url),
              zillow: Yup.string().url(RES.url),
              twitter: Yup.string().url(RES.url)
            }).required(RES.required),
            googleMapsIframe: Yup.object().shape({
              url: Yup.string().url(RES.url).required(RES.required),
              link: Yup.string().url(RES.url).required(RES.required),
              latitude: Yup.number().required(RES.required),
              longitude: Yup.number().required(RES.required),
            }).required(RES.required),
            team: Yup.array(Yup.object().shape({
              firstName: Yup.string().required(RES.required),
              lastName: Yup.string().required(RES.required),
              nickName: Yup.string(),
              title: Yup.string().required(RES.required),
              subTitle: Yup.string(),
              NMLS: Yup.string(),
              email: Yup.string().email(RES.email),
              mainPhone: Yup.string(),
              biography: Yup.string().required(RES.required),
              thumbnail: Yup.string().required(RES.required),
            })),
            embedVideos: Yup.object().shape({
              home: Yup.string().required(RES.required),
              purchaseLoans: Yup.string().required(RES.required),
              refinance: Yup.string().required(RES.required),
              renovate: Yup.string().required(RES.required),
            }).required(RES.required),
            dev: Yup.object().shape({
              gtm: Yup.string(),
              facebookPixel: Yup.string(),
              activeCampaign: Yup.string(),
              afterOpeningHead: Yup.string(),
              beforeClosingHead: Yup.string(),
              afterOpeningBody: Yup.string(),
              beforeClosingBody: Yup.string(),
              contactFormEndpoint: Yup.string(),
              delayedScripts: Yup.array(Yup.object().shape({
                script: Yup.string().required(RES.required),
                delay: Yup.string().required(RES.required)
              }))
            }),
            reviewsSnippet: Yup.object().shape({
              key: Yup.string().required(RES.required)
            }).required(RES.required),
            customLinks: Yup.array(Yup.object().shape({
              title: Yup.string().required(RES.required),
              links: Yup.array(Yup.string().url(RES.url).required(RES.required))
            })),
            socialLinksCustom: Yup.array(Yup.object().shape({
              title: Yup.string().required(RES.required),
              link: Yup.string().url().required(RES.required),
              icon: Yup.string().required(RES.required),
              trackingKey: Yup.string().required(RES.required)
            })),
            banners: Yup.object().shape({
              home: Yup.string(),
              services: Yup.string()
            }),
            calculators: Yup.object().shape({
              links: Yup.array(Yup.object().shape({
                label: Yup.string().required(RES.required),
                url: Yup.string().url().required(RES.required),
              })),
            }),
            sectionIncludes: Yup.array(Yup.object().shape({
              id: Yup.string().required(RES.required),
              title: Yup.string(),
              position: Yup.string(),
              content: Yup.string(),
              scopedSCSS: Yup.string(),
            })),
            //LEGACY STUFF - DON'T REMOVE
            topFormIframe: Yup.object().shape({
              url: Yup.string().url(RES.url),
            }),
            bottomFormIframe: Yup.object().shape({
              url: Yup.string().url(RES.url),
            }),
            reviewsV2: Yup.object().shape({
              enabled: Yup.boolean(),
              googlePlaceId: Yup.string(),
              facebookReviewURL: Yup.string(),
            }),
          })}
        >
          {props => {
            const { handleSubmit, values, setValues, submitForm } = props;
            const errors: any = props.errors;
            const isValid = Object.keys(errors).length === 0;
            this.form = { values, setValues };
            // console.log(props);

            const Section = this.renderSection;

            return (
              <form onSubmit={handleSubmit}>

                {/* BUTTONS */}
                {this.state.initialFormModel != null && (<FormActions
                  templateKey="prime-lending"
                  websiteKey={this.props.websiteKey}
                  onSaveClick={this.getHandleSave(values)}
                  onBuildClick={submitForm}
                  formIsValid={isValid}
                />)}

                <div>
                  <Section title="Main Specialist" errors={errors.main}>
                    <div>
                      <FastField name="main.firstName" label="First Name" component={FormikTextField} />
                      <FastField name="main.lastName" label="Last Name" component={FormikTextField} />
                      <FastField name="main.nickName" label="Nick Name" component={FormikTextField} />
                      <FastField name="main.title" label="Title" component={FormikTextField} />
                      <FastField name="main.subTitle" label="Sub-title" component={FormikTextField} />
                      <FastField name="main.NMLS" label="NMLS" component={FormikTextField} />
                      <FastField name="main.email" label="Email" component={FormikTextField} />
                      <FastField name="main.mainPhone" label="Main Phone" component={FormikTextField} />
                      <FastField name="main.biography" label="Biography" component={FormikTextField} multiline={true} />
                      <FastField name="main.streetAddress" label="Street Address" component={FormikTextField} />
                      <FastField name="main.city" label="City" component={FormikTextField} />
                      <FastField name="main.state" label="State" component={FormikTextField} />
                      <FastField name="main.zip" label="ZIP" component={FormikTextField} />
                      <FastField name="main.hoursOfOperation" label="Hours of Operation" component={FormikTextField} />
                      <FastField name="main.image" data-max-width="350" helperText="Recommended size: a squared image with at least 350px." label="Image" component={FormikImageUpload} uploadFile={this.uploadImage} resolvePreviewURL={this.resolveImgPreviewURL} />
                      <FastField name="main.thumbnail" data-max-width="260" helperText="Recommended size: a squared image with at least 260px." label="Thumbnail" component={FormikImageUpload} uploadFile={this.uploadImage} resolvePreviewURL={this.resolveImgPreviewURL} />
                    </div>
                  </Section>

                  <Section title="Team Members" errors={errors.team}>
                    <div>
                      <Button onClick={this.getHandleAddMemberClick()}>New Member</Button>
                      <br /><br />
                      {values.team.length ? (
                        <Paper square={true}>
                          <TabsSimple
                            index={this.state.teamIndex}
                            onChange={this.handleTeamMemberTabChange}
                            labels={values.team.map((x: any) => x.firstName || 'New')}
                          />
                        </Paper>) : (<Typography variant="subtitle1">None</Typography>)}
                      {values.team.map((x: any, i: number) => (i === this.state.teamIndex &&
                        <Fade key={`member-${i}`} in={true} appear={true} >
                          <div className={this.props.classes.section}>
                            <FastField name={`team[${i}].firstName`} label="First Name *" component={FormikTextField} />
                            <FastField name={`team[${i}].lastName`} label="Last Name *" component={FormikTextField} />
                            <FastField name={`team[${i}].nickName`} label="Nick Name" component={FormikTextField} />
                            <FastField name={`team[${i}].title`} label="Title" component={FormikTextField} />
                            <FastField name={`team[${i}].subTitle`} label="Sub-Title" component={FormikTextField} />
                            <FastField name={`team[${i}].NMLS`} label="NMLS" component={FormikTextField} />
                            <FastField name={`team[${i}].email`} label="Email" component={FormikTextField} />
                            <FastField name={`team[${i}].mainPhone`} label="Main Phone" component={FormikTextField} />
                            <FastField name={`team[${i}].biography`} label="Biography" component={FormikTextField} multiline={true} />
                            <FastField name={`team[${i}].thumbnail`} helperText="Recommended size: a squared image with at least 260px." data-max-width="260" label="Thumbnail" component={FormikImageUpload} uploadFile={this.uploadImage} resolvePreviewURL={this.resolveImgPreviewURL} />
                            <FormControl margin="dense">
                              <Button size="small" onClick={this.getHandleRemoveMemberClick(i)}>Remove Member</Button>
                            </FormControl>
                          </div>
                        </Fade>
                      ))}
                    </div>
                  </Section>

                  <Section title="Service" errors={errors.service}>
                    <div key={`service-${this.serviceRenderIndex}`}>
                      <Typography className={this.props.classes.caption} variant="caption" color="textSecondary">Opening Hours</Typography>
                      {((values.service || {}).openingHours || []).map((item: string, i: number) => (
                        <FastField key={`service-opening-hour-${i}`} name={`service.openingHours[${i}]`} label="" component={FormikRemovableTextField} onRemove={this.getHandleRemoveServiceArrayItem('openingHours', i)} />
                      ))}
                      <FormControl margin="dense"><Button size="small" onClick={this.getHandleAddServiceArrayItem('openingHours')} >Add Opening Hours</Button></FormControl>

                      <FastField name="service.priceRange" label="Price Range" component={FormikTextField} />
                      <FastField name="service.phoneInternational" label="International Phone (optional)" component={FormikTextField} />

                      <Typography className={this.props.classes.caption} variant="caption" color="textSecondary">Areas Served</Typography>
                      {((values.service || {}).areasServed || []).map((item: string, i: number) => (
                        <FastField key={`service-areasServed-${i}`} helperText="Sample: https://en.wikipedia.org/wiki/Shasta_County,_California" name={`service.areasServed[${i}]`} label="" component={FormikRemovableTextField} onRemove={this.getHandleRemoveServiceArrayItem('areasServed', i)} />
                      ))}
                      <FormControl margin="dense"><Button size="small" onClick={this.getHandleAddServiceArrayItem('areasServed')}>Add Area Served</Button></FormControl>

                      <Typography className={this.props.classes.caption} variant="caption" color="textSecondary">Languages</Typography>
                      {((values.service || {}).languages || []).map((item: string, i: number) => (
                        <FastField key={`service-languages-${i}`} helperText="Sample: https://en.wikipedia.org/wiki/English_language" name={`service.languages[${i}]`} label="" component={FormikRemovableTextField} onRemove={this.getHandleRemoveServiceArrayItem('languages', i)} />
                      ))}
                      <FormControl margin="dense"><Button size="small" onClick={this.getHandleAddServiceArrayItem('languages')}>Add Language</Button></FormControl>
                    </div>
                  </Section>

                  <Section title="Youtube Videos" errors={errors.embedVideos}>
                    <div>
                      <FastField name="embedVideos.home" label="Home Video" helperText={RES.youtubeHelper} component={FormikYoutubeTextField} />
                      <FastField name="embedVideos.purchaseLoans" helperText={RES.youtubeHelper} label="Purchase Loans Video" component={FormikYoutubeTextField} />
                      <FastField name="embedVideos.refinance" helperText={RES.youtubeHelper} label="Refinance Video" component={FormikYoutubeTextField} />
                      <FastField name="embedVideos.renovate" helperText={RES.youtubeHelper} label="Renovate Video" component={FormikYoutubeTextField} />
                    </div>
                  </Section>

                  {/* <Section title="IFrames" errors={{...errors.topFormIframe as any, ...errors.bottomFormIframe as any}}>
                  <div>
                    <FastField name="topFormIframe.url" label="Header Form" component={FormikTextField} />
                    <FastField name="bottomFormIframe.url" label="Footer Form" component={FormikTextField} />
                  </div>
                  </Section> */}

                  <Section title="External Links" errors={errors.externalLinks}>
                    <div>
                      <FastField name="externalLinks.apply" label="Apply" component={FormikTextField} />
                    </div>
                  </Section>

                  <Section title="Custom Links" errors={errors.customLinks}>
                    <div>
                      <Button onClick={this.getHandleAddCustomLinksSection()}>New Links Section</Button>
                      <br /><br />
                      <div key={`customLinks-${this.customLinksRenderIndex}`}>
                        {values.customLinks && values.customLinks.map((linksSection: any, i: number) => (
                          <div className={this.props.classes.section} key={`customLink-${i}`}>
                            <FastField name={`customLinks[${i}].title`} label="Title" component={FormikTextField} />
                            {linksSection.links.map((link: string, j: number) => (
                              <FastField key={`custom-link-${i}-${j}`} name={`customLinks[${i}].links[${j}]`} label=""
                                component={FormikRemovableTextField} onRemove={this.getHandleRemoveCustomLink(i, j)} />
                            ))}
                            <FormControl style={{ flexDirection: "row" }} margin="dense">
                              <Button size="small" onClick={this.getHandleAddCustomLinksSectionLinkClick(i)}>Add Link</Button>
                              <Button size="small" onClick={this.getHandleRemoveCustomLinksSectionClick(i)}>Remove Link Group</Button>
                            </FormControl>
                          </div>)
                        )}
                      </div>
                    </div>
                  </Section>

                  <Section title="Google Maps Iframe" errors={errors.googleMapsIframe}>
                    <div>
                      <FastField name="googleMapsIframe.url" helperText="The embed map URL." label="URL (embed)" component={FormikTextField} />
                      <FastField name="googleMapsIframe.link" helperText="The map direct link, copied from the address bar." label="Link" component={FormikTextField} />
                      <FastField name="googleMapsIframe.latitude" label="Latitude" helperText="Sample: 40.574129" component={FormikTextField} />
                      <FastField name="googleMapsIframe.longitude" label="Longitude" helperText="Sample: -122.362893" component={FormikTextField} />
                    </div>
                  </Section>

                  <Section title="Social Links" errors={errors.socialLinks}>
                    <div>
                      <FastField name="socialLinks.facebook" label="Facebook" component={FormikTextField} />
                      <FastField name="socialLinks.googlePlus" label="Google Plus" component={FormikTextField} />
                      <FastField name="socialLinks.yelp" label="Yelp" component={FormikTextField} />
                      <FastField name="socialLinks.instagram" label="Instagram" component={FormikTextField} />
                      <FastField name="socialLinks.linkedIn" label="LinkedIn" component={FormikTextField} />
                      <FastField name="socialLinks.youTube" label="YouTube" component={FormikTextField} />
                      <FastField name="socialLinks.zillow" label="Zillow" component={FormikTextField} />
                      {/* <FastField name="socialLinks.maps" label="Maps" component={FormikTextField} /> */}
                      <FastField name="socialLinks.twitter" label="Twitter" component={FormikTextField} />
                    </div>
                  </Section>

                  <Section title="Social Links (Custom)" errors={errors.socialLinksCustom}>
                    <div>
                      <Button onClick={this.handleAddSocialLinkCustom}>New Social Link</Button>
                      <br /><br />
                      <div key={`socialLinksCustom-${this.socialLinksCustomRenderIndex}`}>
                        {values.socialLinksCustom && values.socialLinksCustom.map((socialLink: any, i: number) => (
                          <div className={this.props.classes.section} key={`socialLink-${i}`}>
                            <FastField name={`socialLinksCustom[${i}].title`} label="Title" component={FormikTextField} />
                            <FastField name={`socialLinksCustom[${i}].trackingKey`} label="Tracking Key" component={FormikTextField} />
                            <FastField name={`socialLinksCustom[${i}].link`} label="Link" component={FormikTextField} />
                            <FastField name={`socialLinksCustom[${i}].icon`} data-max-width="80" helperText="Recommended size: a squared image with at least 80px." label="Icon" component={FormikImageUpload} uploadFile={this.uploadImage} resolvePreviewURL={this.resolveImgPreviewURL} />
                            <FormControl style={{ flexDirection: "row" }} margin="dense">
                              <Button size="small" onClick={this.getHandleRemoveSocialLinkCustom(i)}>Remove Social Link</Button>
                            </FormControl>
                          </div>)
                        )}
                      </div>
                    </div>
                  </Section>

                  <Section title="Calculators" errors={errors.socialLinksCustom}>
                    <div>
                      <Button onClick={this.handleAddCalculator()}>New Calculator Link</Button>
                      <br /><br />
                      <div key={`calculatorsLinks-${this.calculatorsLinksCustomRenderIndex}`}>
                        {values.calculators && values.calculators.links && values.calculators.links.map((link: any, i: number) => (
                          <div className={this.props.classes.section} key={`calculator-link-${i}`}>
                            <FastField name={`calculators.links[${i}].label`} label="Label" component={FormikTextField} />
                            <FastField name={`calculators.links[${i}].url`} label="URL" component={FormikTextField} />
                            <FormControl style={{ flexDirection: "row" }} margin="dense">
                              <Button size="small" onClick={this.getHandleRemoveCalculator(i)}>Remove Social Link</Button>
                            </FormControl>
                          </div>)
                        )}
                      </div>
                    </div>
                  </Section>

                  <Section title="Images" errors={errors.banners}>
                    <FastField name="banners.home" data-max-width="1800" helperText="Recommended size: a wide image with at least 1800px." label="Home Banner" component={FormikImageUpload} uploadFile={this.uploadImage} resolvePreviewURL={this.resolveImgPreviewURL} />
                    <FastField name="banners.homeOverlay" label="Home Banner Overlay" component={FormikTextField} helperText="Sample: rgba(1,65,106,0.3)" />
                    <FastField name="banners.services" data-max-width="1800" helperText="Recommended size: a wide image with at least 1800px." label="Services Banner" component={FormikImageUpload} uploadFile={this.uploadImage} resolvePreviewURL={this.resolveImgPreviewURL} />
                    <FastField name="banners.servicesOverlay" label="Services Banner Overlay" component={FormikTextField} helperText="Sample: rgba(1,65,106,0.3)" />
                  </Section>

                  <Section title="Section Includes" errors={errors.sectionIncludes}>
                    <div>
                      <Button onClick={this.getHandleAddSectionIncludeClick()}>New Section Include</Button>
                      <br /><br />
                      {values.sectionIncludes && values.sectionIncludes.length ? (
                        <Paper square={true}>
                          <TabsSimple
                            index={this.state.sectionIncludesIndex}
                            onChange={this.handleSectionIncludesTabChange}
                            labels={values.sectionIncludes.map((x: any) => x.id || 'New')}
                          />
                        </Paper>) : (<Typography variant="subtitle1">None</Typography>)}
                      {values.sectionIncludes && values.sectionIncludes.map((x: any, i: number) => (i === this.state.sectionIncludesIndex &&
                        <Fade key={`section-${i}`} in={true} appear={true} >
                          <div className={this.props.classes.section}>
                            <FastField name={`sectionIncludes[${i}].id`} label="ID" component={FormikTextField} />
                            <FastField name={`sectionIncludes[${i}].position`} label="Position" options={[{ value: 'home-after-banner' }, { value: 'home-after-about' }, { value: 'home-after-services' }, { value: 'home-after-team' }, { value: 'home-after-process' }, { value: 'home-after-resources' }, { value: 'home-after-reviews' }, { value: 'home-after-maps' }]} component={FormikSelect} />
                            <FastField name={`sectionIncludes[${i}].title`} label="Title" component={FormikTextField} />
                            <FastField name={`sectionIncludes[${i}].content`} label="Content" component={FormikRawInclude} />
                            <FastField name={`sectionIncludes[${i}].scopedSCSS`} label="Scoped SCSS" component={FormikRawInclude} />
                            <FormControl margin="dense">
                              <Button size="small" onClick={this.getHandleRemoveSectionIncludeClick(i)}>Remove Section</Button>
                            </FormControl>
                          </div>
                        </Fade>
                      ))}
                    </div>
                  </Section>

                  <Section title="Developer Setup" errors={{ ...errors.dev, ...errors.reviewsSnippet }}>
                    <div>
                      <FastField name="dev.gtm" label="Google Analitics Tracking Code" helperText="Google Tag Manager tracking code can also be used." component={FormikTextField} />
                      <FastField name="dev.facebookPixel" label="Facebook Pixel ID" component={FormikTextField} />
                      <FastField name="dev.activeCampaign" label="ActiveCampaign ID" component={FormikTextField} />

                      <FastField name="reviewsSnippet.key" label="Reviews Widget Key" component={FormikTextField} />
                      <FastField name="dev.afterOpeningHead" label="After Opening Head" component={FormikRawInclude} />
                      <FastField name="dev.beforeClosingHead" label="Before Closing Head" component={FormikRawInclude} />
                      <FastField name="dev.afterOpeningBody" label="After Opening Body" component={FormikRawInclude} />
                      <FastField name="dev.beforeClosingBody" label="Before Closing Body" component={FormikRawInclude} />
                      <FastField name="dev.contactFormEndpoint" label="Contact Form Endpoint" component={FormikTextField} />
                      <FieldArray name="dev.delayedScripts" render={(arrayHelpers) => {
                        return (<React.Fragment>
                          {values.dev.delayedScripts.map((script, i) => (<div key={i}>
                            <div style={{ display: 'flex' }}>
                              <FastField style={{ flex: '2' }} name={`dev.delayedScripts.${i}.script`} label="Delayed Script" component={FormikTextField} />
                              <FastField style={{ flex: '1', marginLeft: 10 }} name={`dev.delayedScripts.${i}.delay`} label="Delay" component={FormikTextField} />
                              <IconButton style={{ alignSelf: 'center' }} onClick={() => arrayHelpers.remove(i)}><Remove fontSize="small" /></IconButton>
                            </div>
                          </div>))}
                          <Button onClick={() => arrayHelpers.push(DEFAULT_DELAYED_SCRIPT)} size="small">Add Delayed Script</Button>
                        </React.Fragment>)
                      }} />
                    </div>
                  </Section>

                  <Section title="Reviews (V2)" errors={errors.reviewsV2}>
                    <div>
                      <FormControlLabel label="Enabled" control={<FastField name="reviewsV2.enabled" component={FormikCheckbox} />} />
                      <FastField name="reviewsV2.googlePlaceId" label="Google Place ID" component={FormikTextField} />
                      <FastField name="reviewsV2.facebookReviewURL" label="Facebook Review URL" component={FormikTextField} />
                    </div>
                  </Section>

                </div>
                <br />
                <br />
                <br />
              </form>
            )
          }}
        </Formik>
        <SmartFileManagerDialog
          themeKey="prime-lending"
          assets={false}
          open={this.state.fileManagerDialogOpen}
          onClose={this.handleFileManagerClose}
          websiteKey={this.props.websiteKey}
          onPick={null}
        />
      </MainPadding>
    )
  }

  public async componentDidMount() {
    const initialFormModel = await serviceLocator.getWebsiteService().getFormData(this.props.websiteKey, 'shared') || {};
    if (Array.isArray(initialFormModel.reviewsSnippet)) {
      initialFormModel.reviewsSnippet = { key: 'primelending' };
    }
    initialFormModel.socialLinksCustom = initialFormModel.socialLinksCustom || [];
    initialFormModel.team = initialFormModel.team || [];
    initialFormModel.dev = initialFormModel.dev || {};
    initialFormModel.dev.delayedScripts = initialFormModel.dev.delayedScripts || [];
    initialFormModel.reviewsV2 = initialFormModel.reviewsV2 || { enabled: false, googlePlaceId: '', facebookReviewURL: '' };
    this.setState({ initialFormModel });
  }

  private renderSection = (props: { title: string, children: any, errors: any }) => {
    const isValid = props.errors == null || Object.keys(props.errors).length === 0;
    return <ExpansionPanel onChange={() => {
      this.setState({ currentSection: props.title === this.state.currentSection ? '' : props.title })
    }} TransitionProps={{ unmountOnExit: true }} expanded={this.state.currentSection === props.title}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color={isValid ? undefined : 'error'}>{props.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={this.props.classes.expansionPanelSummary}>{props.children}</ExpansionPanelDetails>
    </ExpansionPanel>
  }

  private save = async (values: any) => {
    if (this.isSaving) return;
    this.isSaving = true;
    try {
      if (this.versionId != null) {
        values.versionId = this.versionId;
      }
      const { error, versionId } = await serviceLocator.getWebsiteService().postFormData(this.props.websiteKey, 'shared', values);
      if (error) {
        alert(JSON.stringify(error));
        this.props.enqueueSnackbar("Failed to save.");
        return;
      }
      this.versionId = versionId;
      this.props.enqueueSnackbar("Saved successfully.");
      if (this.state.changed) {
        this.setState({ changed: false });
      }
    }
    catch{
      this.props.enqueueSnackbar("Failed to save.");
    }
    this.isSaving = false;
  }

  private getHandleSave = (values: any) => {
    return async (e: any) => {
      e.preventDefault();
      await this.save(values);
    }
  }

  private handleTeamMemberTabChange = (e: any, value: any) => {
    this.setState({ teamIndex: value });
  }

  private handleSectionIncludesTabChange = (e: any, value: any) => {
    this.setState({ sectionIncludesIndex: value });
  }

  private getTeamMemberInitialValues = () => {
    return JSON.parse(JSON.stringify(DEFAULT_TEAM_MEMBER));
  }

  private getHandleAddMemberClick = () => {
    return (e: any) => {
      const values = this.form.values;
      this.form.setValues({ ...values, team: [...(values.team || []), this.getTeamMemberInitialValues()] })
    }
  }

  private handleAddCalculator = () => {
    return (e: any) => {
      const values = this.form.values;
      const calculators = values.calculators ?? {};
      const links = calculators.links ?? [];
      links.push({ label: "", url: "" });
      this.form.setValues({
        ...values,
        calculators: { links, ...calculators }
      });
    }
  }

  private getHandleAddSectionIncludeClick = () => {
    return (e: any) => {
      const values = this.form.values;
      this.form.setValues({ ...values, sectionIncludes: [...(values.sectionIncludes || []), {}] })
    }
  }

  private getHandleRemoveMemberClick = (index: number) => {
    return (e: any) => {
      const values = this.form.values;
      values.team.splice(index, 1);
      this.setState({ teamIndex: Math.min(this.state.teamIndex, values.team.length - 1) });
      this.form.setValues({ ...values, team: [...values.team] })
    }
  }

  private getHandleRemoveCalculator = (index: number) => {
    return (e: any) => {
      const values = this.form.values;
      const calculators = values.calculators;
      calculators.links.splice(index, 1);
      this.calculatorsLinksCustomRenderIndex++;
      this.form.setValues({ ...values, calculators: { ...calculators } });
    }
  }

  getHandleRemoveSectionIncludeClick = (index: number) => {
    return (e: any) => {
      const values = this.form.values;
      values.sectionIncludes.splice(index, 1);
      this.setState({ sectionIncludesIndex: Math.min(this.state.sectionIncludesIndex, values.sectionIncludes.length - 1) });
      this.form.setValues({ ...values, team: [...values.team] })
    }
  }

  private getCustomLinksInitialValues = () => {
    return JSON.parse(JSON.stringify(DEFAULT_CUSTOM_LINKS_SECTION));
  }

  private getHandleAddCustomLinksSection = () => {
    return (e: any) => {
      const values = this.form.values;
      this.form.setValues({ ...values, customLinks: [...(values.customLinks || []), this.getCustomLinksInitialValues()] })
    }
  }

  private getHandleRemoveCustomLinksSectionClick = (index: number) => {
    return (e: any) => {
      const values = this.form.values;
      values.customLinks.splice(index, 1);
      this.customLinksRenderIndex++;
      this.form.setValues({ ...values, customLinks: [...values.customLinks] })
    }
  }

  private getHandleAddCustomLinksSectionLinkClick = (index: number) => {
    return (e: any) => {
      const values = this.form.values;
      const section = values.customLinks[index];
      const links = section.links;
      values.customLinks[index] = { ...section, links: [...links, ''] };
      this.form.setValues({ ...values, customLinks: [...values.customLinks] })
    }
  }

  private getHandleRemoveCustomLink = (sectionIndex: number, linkIndex: number) => {
    return (e: any) => {
      const values = this.form.values;
      const section = values.customLinks[sectionIndex];
      section.links.splice(linkIndex, 1);
      values.customLinks[sectionIndex] = { ...section, links: [...section.links] };
      this.customLinksRenderIndex++;
      this.form.setValues({ ...values, customLinks: [...values.customLinks] })
    }
  }

  private handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      setSubmitting(true);
      await this.save(values);
      await serviceLocator.getWebsiteService().buildWebsite(this.props.websiteKey);
      this.props.enqueueSnackbar("Website built successfully.");
    }
    catch{
      this.props.enqueueSnackbar("Failed to build.");
    }
    setSubmitting(false);
  }

  private resolveImgPreviewURL = (value: string) => {
    return `/api/websites/${encodeURIComponent(this.props.websiteKey)}/img/${encodeURI(value.replace(/^\//, ''))}`;
  }

  private uploadImage: (e: any) => Promise<string> = (e: any) => {
    const name = e.target.name;
    const dataSet: any = e.currentTarget.dataset || {};
    return serviceLocator.getWebsiteService().uploadImage(
      this.props.websiteKey,
      e.target.files[0],
      name,
      { maxWidth: dataSet.maxWidth, maxHeight: dataSet.maxHeight });
  }

  private getHandleRemoveServiceArrayItem = (key: string, index: number) => {
    return () => {
      const values = this.form.values;
      const items = (values.service || {})[key] || []; items.splice(index, 1);
      this.serviceRenderIndex++;
      this.form.setValues({ ...values, service: { ...values.service, [key]: [...items] } })
    };
  }

  private getHandleAddServiceArrayItem = (key: string) => {
    return () => {
      const values = this.form.values;
      const items = (values.service || {})[key] || []; items.push('');
      this.form.setValues({ ...values, service: { ...values.service, [key]: [...items] } });
    };
  }

  private handleAddSocialLinkCustom = (e: any) => {
    const values = this.form.values;
    const socialLinksCustom = values.socialLinksCustom || [];
    socialLinksCustom.push({ title: '', trackingKey: '', link: '', icon: '' });
    this.form.setValues({ ...values, socialLinksCustom: [...socialLinksCustom] });
  }

  private getHandleRemoveSocialLinkCustom = (index: number) => {
    return (e: any) => {
      const values = this.form.values;
      values.socialLinksCustom.splice(index, 1);
      this.socialLinksCustomRenderIndex++;
      this.form.setValues({ ...values, socialLinksCustom: [...values.socialLinksCustom] })
    }
  }
}


export const Form = withSnackbar(withStyles(styles)(FormUnstyled));
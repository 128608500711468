import * as React from 'react';
import { Paper, Divider, List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon, Add } from '@material-ui/icons';
import { serviceLocator } from '../../../services';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import { withRouter, RouteComponentProps } from 'react-router';
import { FormikTextField } from '../../../components/FormikTextField';
import { ListSubheaderWithButtons } from '../../../components/ListSubheaderWithButtons';

interface Props extends RouteComponentProps {
    website: string,
    collection: string;
    paperProps?: any;
}

interface State {
    items: string[];
    openDialog: string | null;
    cloneKey: string | null;
    itemMenuOpen: boolean;
    menuAnchor: any;
    menuItemKey: string;

}

const dialogContentStyle: any = {
    minWidth: '300px'
}
const validPageName = /^[a-z0-9-]+$/;
const PAGE_NAME_HELPER = 'Sample: some-page';

export const CollectionList = withRouter(class CollectionListInner extends React.Component<Props, State>{

    state: State = {
        items: [],
        cloneKey: null,
        itemMenuOpen: false,
        menuAnchor: null,
        menuItemKey: '',
        openDialog: null
    }

    componentDidMount = async () => {
        this.loadItems();
    }

    async loadItems() {
        const { items } = await serviceLocator.getWebsiteService().listCollection(this.props.website, this.props.collection);
        this.setState({ items });
    }

    handleCollectionItemMoreClick = (e: any) => {
        const target = e.currentTarget;
        this.setState({ itemMenuOpen: true, menuAnchor: target, menuItemKey: target.dataset.key });
    }

    private handleMenuClose = (e: any) => {
        this.setState({ itemMenuOpen: false });
    }

    private handleNewClick = (e: any) => {
        this.setState({ openDialog: 'new' });
    }

    handleNewItemFormSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
        try {
            if (values.fromName) {
                //await serviceLocator.getWebsiteService().cloneCollectionItem(this.props.website, "pages", values.fromName, values.name );
            }
            else {
                await serviceLocator.getWebsiteService().createCollectionItem(this.props.website, this.props.collection, values.name);
            }

            setSubmitting(false);
            resetForm();
            this.setState({ openDialog: '', cloneKey: null });
            this.loadItems();
        }
        catch{
            setSubmitting(false);
            alert('An error has ocurred');
        }
    }

    handleDialogClose = () => {
        this.setState({ openDialog: '' });
    }

    handleItemClone = async (evt: any) => {
        this.setState({ openDialog: 'new', cloneKey: this.state.menuItemKey, itemMenuOpen: false });
    }

    handleItemDelete = async (e: any) => {
        this.setState({ itemMenuOpen: false });
        if (window.confirm(`Are you sure you want to delete the page "${this.state.menuItemKey}"?`)) {
            await serviceLocator.getWebsiteService().removeCollectionItem(this.props.website, this.props.collection, this.state.menuItemKey);
            this.loadItems();
        }
    }

    renderMenu() {
        return (
            <Menu
                key="page-menu"
                open={this.state.itemMenuOpen}
                anchorEl={this.state.menuAnchor}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleItemClone} >Clone</MenuItem>
                <MenuItem onClick={this.handleItemDelete} >Delete</MenuItem>
            </Menu>
        );
    }

    renderDialog() {
        return (
            <Formik
                key={this.state.cloneKey || 'new'}
                initialValues={{ name: '', fromName: this.state.cloneKey || '' }}
                onSubmit={this.handleNewItemFormSubmit}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string().matches(validPageName, PAGE_NAME_HELPER).required('Required.'),
                        fromName: Yup.string().matches(validPageName, PAGE_NAME_HELPER)
                    }).required()
                }>
                {props => {
                    const { handleSubmit, isSubmitting, submitForm } = props;

                    return (
                        <Dialog
                            onClose={this.handleDialogClose}
                            open={this.state.openDialog === 'new'}>
                            <DialogTitle style={dialogContentStyle}>{this.state.cloneKey ? `Clone Page "${this.state.cloneKey}"` : 'Create New Page'}</DialogTitle>
                            <DialogContent style={dialogContentStyle}>
                                <form onSubmit={handleSubmit}>
                                    <FastField autoFocus={true} debounce={15} margin="normal" name="name" label="Page Name" component={FormikTextField} />
                                    <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{ width: 1, height: 1, visibility: 'hidden' }}>Create</button>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    disabled={isSubmitting}
                                    color="primary"
                                    type="submit"
                                    value="create"
                                    onClick={submitForm}>Create</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }}
            </Formik>
        );
    }

    renderList() {
        return (<List component="nav" subheader={<ListSubheaderWithButtons onNewClick={this.handleNewClick} title={`Collection (${this.props.collection})`} />}>
            <Divider />
            {(this.state.items || []).map((item, i) => {
                return (
                    <ListItem key={item}
                        button={true}
                        onClick={() => this.props.history.push(`collections/${encodeURIComponent(this.props.collection)}/${encodeURIComponent(item)}`)}
                        divider={true}>
                        <ListItemText primary={item} />
                        <ListItemSecondaryAction>
                            <IconButton onClick={this.handleCollectionItemMoreClick} data-key={item}>
                                <MoreVertIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })}
        </List>);
    }

    render() {
        return (
            <Paper {...this.props.paperProps}>
                {this.renderList()}
                {this.renderDialog()}
                {this.renderMenu()}
            </Paper>
        )
    }
});
import * as React from 'react';
import { Button, Divider, Grid } from '@material-ui/core';
import { Link } from "react-router-dom"
import { MainPadding } from '../../../components/MainPadding';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { Breadcumb } from '../../../components/Breadcumb';
import { FolderOpenOutlined } from '@material-ui/icons';
import { SmartFileManagerDialog } from '../../../components/Websites/SmartFileManagerDialog';
import { UsersList } from './../../../components/Websites/UsersList';
import { serviceLocator } from '../../../services';
import { AdminOrAgencyMemberSection } from '../../../components/AdminOrAgencyMemberSection';
import { SingleList } from './SingleList';
import { CollectionList } from './CollectionList';
import { keyToDisplay } from '../../../utils/string-utils';
import { RoleSection } from '../../../components/RoleSection';
import { ReviewsProvidersList } from '../../../components/ReviewsProvidersList';
import { AliasesList } from '../../../components/Websites/AliasesList';
// import { SnapshotDialogSmart } from '../SnapshotDialogSmart';

const genericWebsiteService = serviceLocator.getWebsiteService();

interface WebsiteProps {
  websiteKey: string;
  themeKey: string;
}

interface WebsiteState {
  fileManagerOpen: boolean;
  assetsFileManagerOpen: boolean;
  website: any | null;
  snapshotModalOpen: boolean;
  advancedExpanded: boolean;
}

const paperProps = { style: { height: '100%' } };

export class Website extends React.Component<WebsiteProps, WebsiteState>{

  constructor(props: any) {
    super(props);
    this.state = {
      fileManagerOpen: false,
      assetsFileManagerOpen: false,
      website: null,
      snapshotModalOpen: false,
      advancedExpanded: false
    };
  }

  public componentDidMount = async () => {
    const website = await genericWebsiteService.getWebsite(this.props.websiteKey);
    this.setState({ website });
  }

  private globalPropsLink = React.forwardRef((props: any, key: any) => (<Link to={`main-form/`} {...props} key={key} />));

  handleFileManagerOpen = (e: any) => {
    this.setState({ fileManagerOpen: true });
  }

  handleAssetsFileManagerOpen = (e: any) => {
    this.setState({ assetsFileManagerOpen: true });
  }

  private previewButtonComponent = React.forwardRef((p: any, ref: any) => {
    const { innerRef, ...rest } = p;
    return (<a {...rest} target="_blank" href={`http://${this.props.themeKey}--${this.props.websiteKey}.hugosites.webdrvn.com`} />);
  });

  private deployButtonComponent = React.forwardRef((p: any, ref: any) => {
    const { innerRef, ...rest } = p;
    return (<Link {...rest} to={`/websites/${this.props.themeKey}/${this.props.websiteKey}/deploy/`} />)
  });

  // private handleSnapshotClick = (e: any) => {
  //     this.setState({ snapshotModalOpen: true });
  // }

  // private handleSnapshotClose = (e: any) => {
  //     this.setState({ snapshotModalOpen: false });
  // }

  private handleOpenProductionClick = async () => {
    const data = await genericWebsiteService.getInfrastructureData(this.props.websiteKey);
    if (data?.domain != null) {
      window.open(`https://${data.domain}`, '_blank');
    }
    else {
      alert(`"${this.props.websiteKey}" is not deployed.`);
    }
  }

  public render() {

    if (this.state.website == null) return (null);

    // LIST + DIALOGS
    return (
      <MainPadding>
        <Button style={{ float: 'right', marginLeft: '4px' }} onClick={this.handleFileManagerOpen}>Static &nbsp; <FolderOpenOutlined /></Button>
        <Button style={{ float: 'right', marginLeft: '4px' }} onClick={this.handleAssetsFileManagerOpen}>Assets &nbsp; <FolderOpenOutlined /></Button>
        <Breadcumb items={[
          { to: `/websites/${this.props.themeKey}`, label: `${keyToDisplay(this.props.themeKey)} Websites` },
          { label: `Website ${this.props.websiteKey}` }
        ]} />

        <br />
        <div style={{ padding: '16px 0' }}>
          <Button component={this.previewButtonComponent} variant="text" color="default">Preview</Button>
          <AdminOrAgencyMemberSection>
            &nbsp; <Button component={this.deployButtonComponent} variant="text" color="default">Deploy</Button>
            {/* &nbsp; <Button onClick={this.handleSnapshotClick} variant="text" color="default">Snapshot</Button> */}
                        &nbsp; <Button onClick={this.handleOpenProductionClick}>Production Website</Button>
          </AdminOrAgencyMemberSection>
        </div>

        <br />

        <Grid container spacing={3} alignItems="stretch">

          {this.state.website.editableEntities["single"].length > 0 &&
            <Grid item md={4} xs={12}>
              <ErrorBoundary>
                <SingleList paperProps={paperProps} singles={this.state.website.editableEntities["single"].map((single: any) => single.key)} />
              </ErrorBoundary>
            </Grid>
          }

          {(this.state.website.editableEntities["collection"] || []).map((collection: any) => {
            return (<Grid key={collection.key} item md={4} xs={12}>
              <ErrorBoundary>
                <CollectionList paperProps={paperProps} website={this.props.websiteKey} collection={collection.key} />
              </ErrorBoundary>
            </Grid>);
          })}

          <AdminOrAgencyMemberSection>
            <Grid xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}></Grid>
            {!this.state.advancedExpanded ? (
              <Grid item xs={12}>
                <Divider style={{ margin: '1.2rem 0' }} />
                <Button variant="contained" onClick={() => this.setState({ advancedExpanded: true })}>Advanced Settings</Button>
              </Grid>
            ) : (
                <React.Fragment>
                  <Grid item md={4} xs={12}>
                    <ErrorBoundary>
                      <UsersList paperProps={paperProps} websiteKey={this.props.websiteKey} />
                    </ErrorBoundary>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <ErrorBoundary>
                      <AliasesList paperProps={paperProps} websiteKey={this.props.websiteKey} />
                    </ErrorBoundary>
                  </Grid>
                  <RoleSection role="admin">
                    <Grid item md={4} xs={12}>
                      <ErrorBoundary>
                        <ReviewsProvidersList websiteKey={this.props.websiteKey} />
                      </ErrorBoundary>
                    </Grid>
                  </RoleSection>
                </React.Fragment>
              )}
          </AdminOrAgencyMemberSection>

        </Grid>

        <SmartFileManagerDialog
          assets={false}
          themeKey={this.state.website.template}
          websiteKey={this.props.websiteKey}
          open={this.state.fileManagerOpen}
          onClose={() => this.setState({ fileManagerOpen: false })}
          onPick={null} />

        <SmartFileManagerDialog
          assets={true}
          themeKey={this.state.website.template}
          websiteKey={this.props.websiteKey}
          open={this.state.assetsFileManagerOpen}
          onClose={() => this.setState({ assetsFileManagerOpen: false })}
          onPick={null} />

        {/* <SnapshotDialogSmart
                    websiteKey={this.props.websiteKey}
                    dialogProps={{open: this.state.snapshotModalOpen, onClose:this.handleSnapshotClose}}
                /> */}

      </MainPadding>
    )
  }
}
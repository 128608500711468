import * as React from 'react';
import * as Yup from 'yup';
import { Formik, FastField, Field } from 'formik';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { serviceLocator } from '../../../services';
import { FormikSelect } from '../../FormikSelect';

const genericWebsiteService = serviceLocator.getWebsiteService();
const userService = serviceLocator.getUserService();

interface Props{
    open: boolean;
    onClose: ()=>void;
    websiteKey: string;
    onUpdate: ()=>void;
    initialValues: ({ username: string, roles: string[] } | null);
}

interface State{
    usersOptions: Array<{value: string}>;
}

const dialogContentStyle: any = { minWidth: '300px' }

export class WebsiteUserForm extends React.Component<Props,State>{

    loadRequested: boolean = false;
    saveCount: number = 0;

    state: State = {
        usersOptions: [],
    }

    handlePutUserFormSubmit = async (values: any, { setSubmitting, resetForm }: any) =>{
        try{
            await genericWebsiteService.putUser(this.props.websiteKey, values);
            setSubmitting(false);
            this.props.onUpdate();
            this.saveCount++;
            resetForm();
        }
        catch (error: any) {
            setSubmitting(false);
            alert(error?.response?.data?.details ? error?.response?.data?.details : 'An error has ocurred');
        }
    }

    loadUserOptions = async () =>{
        if(this.props.initialValues == null && this.loadRequested===false){
            this.loadRequested = true;
            const { users } = await userService.listDesigners();
            this.setState({
                usersOptions: users.map(x => ({value: x.username}))
            });
        }
    }

    render(){
        
        this.loadUserOptions();

        let userOptions = this.state.usersOptions;

        if(this.props.initialValues!=null){
            userOptions = [{ value: this.props.initialValues.username }];
        }

        return (
            <Formik
                initialValues={(this.props.initialValues || { username: '', roles: ['designer'] })}
                onSubmit={this.handlePutUserFormSubmit}
                validationSchema={
                    Yup.object().shape({
                        username: Yup.string().required('Required.'),
                        roles: Yup.array(Yup.string())
                    }).required()
                }>
                { props => {
                    const { handleSubmit, isSubmitting, submitForm } = props;
                    return (
                        <Dialog
                            onClose={this.props.onClose}
                            open={this.props.open}>
                            <DialogTitle style={dialogContentStyle}>Add New User</DialogTitle>
                            <DialogContent style={dialogContentStyle}>
                                <form onSubmit={handleSubmit}>
                                    
                                    <Field name="username" label="User"
                                        component={FormikSelect} options={userOptions} />

                                    <FastField name="roles" label="Roles"
                                        component={FormikSelect} multiple={true} options={[{value: 'designer' },{value: 'deployer' }]} />

                                    <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{width:1, height:1, visibility:'hidden'}}>Create</button>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    disabled={isSubmitting}
                                    color="primary"
                                    type="submit"
                                    value="create"
                                    onClick={submitForm}>{this.props.initialValues==null?"Add":"Update"}</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }}
            </Formik>
        );
    }
}
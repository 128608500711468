import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, FormControl, Button } from '@material-ui/core';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { FormikTextField } from '../../FormikTextField';
import { FormikSelect } from '../../FormikSelect';

interface Props {
    onSetupSubmit: (data: any)=>Promise<void>;
    open: boolean;
    onClose: any;
    credentialsProvider: ()=>Promise<Array<{ label: string, value: string }>>;
}

export class SetupDialog extends React.PureComponent<Props> {

    state : { options: Array<{ label: string, value: string }> } = {
        options: []
    };

    componentDidMount = async () => {
        const loadedOptions = await this.props.credentialsProvider();
        const options = loadedOptions;
        this.setState({options});
    }

    private handleSetupSubmit = async (values: any, { setSubmitting }: { setSubmitting: (value: boolean)=>void })=>{
        try{
            await this.props.onSetupSubmit(values);
            setSubmitting(false);
        }
        catch(e){
            throw e;
            setSubmitting(false);
        }
        
    }

    public render(){
        const { onSetupSubmit, credentialsProvider, ...rest } = this.props;
        return (
            <Dialog
                {...rest}
            >
                <DialogTitle>Setup</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{ domain: '', infraCredentialId: 'default' }}
                        onSubmit={this.handleSetupSubmit}
                        validationSchema={Yup.object().shape({
                            infraCredentialId: Yup.string().required('Required'),
                            domain: Yup.string().matches(/\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/,'Invalid domain').required('Required')
                        })}
                    >
                    {props => {
                        const { isSubmitting, handleSubmit } = props;

                        return (<form onSubmit={handleSubmit}>
                            <FastField options={this.state.options} label="AWS Credentials" name="infraCredentialId" component={FormikSelect} />
                            <FastField label="Domain" name="domain" component={FormikTextField} helperText="Use a naked domain (domain.com rather than www.domain.com)" />
                            <FormControl margin={'normal'} fullWidth={true}>
                                <Button type="submit" disabled={isSubmitting} variant="contained" color="primary" fullWidth={true}>Submit</Button>
                            </FormControl>
                        </form>)
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    
    }
}
import * as React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { serviceLocator } from '../../services';
import { MainPadding } from '../../components/MainPadding';
import { withStyles } from '@material-ui/core/styles';
import { AppSection } from '../../components/AppSection';
import SaveIcon from '@material-ui/icons/Save';
import _AceEditor from "react-ace";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Switch, Route } from 'react-router';
import { NotFound } from '../NotFound';

import { Footer } from './Footer';
import { AgencyMembers } from './Members';
import { AgencyGeneral } from './General';

const uiService = serviceLocator.getUIService();
const themeService = serviceLocator.getThemeService();
const authenticationService = serviceLocator.getAuthenticationService();

export interface AgencyPanelState {
  block: "AgencyPanel" | "header";
  html: string;
  style: string;
  script: string;
}
interface AgencyPanelProps extends WithSnackbarProps {
  classes: any;
}

const styles = (theme: any) => ({
  roleInfo: {
    padding: "7px 24px 0px",
    marginBottom: "-10px",
    color: "#8BC34A",
    fontSize: "16px",
  },
});

export class AgencyPanelUnstyled extends React.Component<AgencyPanelProps, AgencyPanelState>{

  constructor(props: AgencyPanelProps) {
    super(props);
    this.state = {
      block: "AgencyPanel",
      html: "",
      style: "",
      script: ""
    };
  }

  public render() {
    const { classes } = this.props;
    const authUserData = authenticationService.getAuthenticatedUserData();
    const selectedAgency = uiService.getSelectedAgency() || 'all';
    const isAdmin = authUserData?.roles.indexOf('admin') !== -1;
    const hasAgency = authUserData?.ownedAgency == selectedAgency;

    const selectedAgencyData = authUserData ? (authUserData.containedAgencies ? authUserData.containedAgencies.find(item => item.identifier == selectedAgency) : false) : false
    const role = selectedAgencyData ? selectedAgencyData.users[0].AgencyUser.role : "no-role"

    return (
      <div>
        <AppSection section="themePanel" />
        {hasAgency && <p className={classes.roleInfo}>You're the <b>owner</b> of the selected agency: <b>{selectedAgency}</b></p>}
        {role == 'agent' && <p className={classes.roleInfo}>You have <b>agent</b> role for the selected agency: <b>{selectedAgency}</b></p>}

        {
          selectedAgency == "all" ?
            <MainPadding>
              <Typography color="error">
                Please select an agency on top to work on Agency Panel.
              </Typography>
            </MainPadding>
            :
            <Switch>
              {!(isAdmin || hasAgency || role == 'agent') && <Route path={"*"} component={() => <NotAllowed role={role} />} />}
              <Route path="/agency-panel/footer/" exact={true} component={() => <Footer selectedAgency={selectedAgency} />} />
              <Route path="/agency-panel/members/" exact={true} component={() => <AgencyMembers selectedAgency={selectedAgency} />} />
              <Route path="/agency-panel/general/" exact={true} component={() => <AgencyGeneral selectedAgency={selectedAgency} />} />
            </Switch>
        }

      </div>
    );
  }
}

export const AgencyPanel = withSnackbar(withStyles(styles)(AgencyPanelUnstyled));

interface NotAllowedProps {
  role: string;
}

class NotAllowed extends React.Component<NotAllowedProps, any>{

  constructor(props: NotAllowedProps) {
    super(props);
  }

  public render() {
    return (<MainPadding>
      <Typography color="error">
        Resource not allowed. <br />
        You must be an admin or agent for the selected Agency. <br />
        Please try to change the agency on top.</Typography>
    </MainPadding>)
  }

}
import * as React from 'react';
import { BaseDynamic, ComponentProps, NormalizeStateContext, CrawlContext } from '../_ho-forms-lib/src/HoForm';
import { setValidationErrorIntoState, getValidationError } from '../_ho-forms-lib/src/HoForm/utils';
import { Typography } from '@material-ui/core';
const CKEditor = require('ckeditor4-react');
require('./visual-editor.css');

type VisualEditorDynamicField = {
    key: string, 
    type: string,
    default?: string,
    multiline?: boolean,
    tip?: string,
    title?: string,
    required?: boolean,
    monospace?: boolean,
    margin?: 'none'|'dense'|'normal'
}

type VisualEditorDynamicState = {
    
}

export class VisualEditorDynamic extends BaseDynamic<VisualEditorDynamicField,VisualEditorDynamicState> {

    constructor(props: ComponentProps<VisualEditorDynamicField>){
        super(props);
    }

    normalizeState({state, field}: NormalizeStateContext<VisualEditorDynamicField>){
        let key = field.key;
        if(state[key]==null){
            state[key] = field.default || '';
        }
    }

    getType(){
        return 'visual-editor';
    }

    crawlComponent({form, node} : CrawlContext<VisualEditorDynamicField>): void{
        if(node.field.required){
            const value = this.getValueFromNode(node);
            const invalid = value==null||value==='';
            setValidationErrorIntoState(node.state, form.buildDisplayPath(node), invalid?`The field is required.`:'');
                        
        }
    }

    shouldComponentUpdate(nextProps: any, nextState: any){
        // let {context} = this.props;
        // let nextContext = nextProps.context;
        // if(context.currentPath != nextContext.currentPath
        //     ||context.parentPath!=nextContext.parentPath
        // ){
        //     return true;
        // }
        return false;
    }

    renderComponent(){
        
        let {context} = this.props;
        let {node, nodePath, currentPath, parentPath} = context;
        let {field} = node;
                
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }

        const error = getValidationError(node.state, nodePath);

        return (<div style={{marginTop: '8px', marginBottom: '4px'}}>
            <Typography color="textSecondary" display="block" variant="caption" style={{marginBottom: '4px'}}>{field.title}</Typography>
            <CKEditor
                data={context.value}
                onBeforeLoad={ (ckeditor: any) => {
                    ckeditor.config.contentsCss = '/css/ckeditor-content.css'
                    ckeditor.disableAutoInline = true;
                }}
                onChange={ (event: any) => {
                    const data = event.editor.getData();
                    this.props.context.setValue(data, 250);
                }}
            />            
            { field.tip && (<Typography color="textSecondary" display="block" variant="caption" style={{marginBottom: '4px'}}>{field.tip}</Typography>) }
        </div>
        );
    }
}
import * as React from 'react';
import { FormikFileUpload } from './FormikFileUpload';
import { InputAdornment, Popover, Fade, Grow } from '@material-ui/core';
import { PreviewThumbnail } from './PreviewThumbnail';

export class FormikImageUpload extends React.Component<any,any>{

    public state = {
        previewVisible: false,
        anchor: null
    }

    public render(){

        const {
            field, // { name, value, onChange, onBlur }
            form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            ...fProps
        } = this.props;

        const { resolvePreviewURL, ...fPropsRest } = fProps;

        const handleClick = field.value != null ? (e: any) =>{
            e.preventDefault();
            this.setState({ previewVisible: true, anchor: e.currentTarget });
        } : undefined;

        const imageUrl = resolvePreviewURL(field.value);
        
        return (<React.Fragment><FormikFileUpload
            endAdornment={
            <InputAdornment position="end">
                <Grow appear={true} in={true}>
                    <PreviewThumbnail component="a" href={imageUrl} url={imageUrl} onClick={handleClick} />
                </Grow>
            </InputAdornment>
            }
            field={field}
            form={form}
            {...fPropsRest}
        />
        { field.value && this.state.anchor && (
        <Popover
            anchorEl={this.state.anchor}
            open={this.state.previewVisible}
            onClose={this.handleClose}
            TransitionComponent={Fade}
        >
            <div style={{
                width:'400px',
                height:'400px',
                background:`url(${imageUrl}) no-repeat center center`,
                backgroundSize:"contain"
            }} />
        </Popover>
        ) }
        </React.Fragment>)
    }

    private handleClose = (e: any) => {
        this.setState({previewVisible: false});
    }
};
import * as React from 'react';
import { MainPadding } from '../../components/MainPadding';
import { Paper, List as ListView, ListItem, ListItemText, Button, } from '@material-ui/core';
import { serviceLocator } from '../../services';
import { Link } from 'react-router-dom';
import { Breadcumb } from '../../components/Breadcumb';

const service = serviceLocator.getQualityCheckService();
const uiService = serviceLocator.getUIService();

interface State {
    configs: Array<any>,
}

export class List extends React.Component<any,State>{

    state = {
        configs: []
    }

    componentDidMount(){
        this.refreshList();
    }

    refreshList = async () =>{
        const { configs } = await service.listTemplateConfigs(uiService.getSelectedAgency());
        this.setState({ configs });
    }

    getEditLink = (identifier: string) =>{
        return React.forwardRef((props: any, ref:any) => <Link to={`/quality-check-configs/${encodeURIComponent(identifier)}/edit/`} ref={ref} {...props} />);
    }

    render(){
        return (
            <MainPadding>
                <Breadcumb items={[
                    { label:"Quality Check Configs" }
                ]} />

                <br />
                
                <div style={{ padding: '16px 0' }}>
                    <Button variant="contained" color="primary"
                        component={React.forwardRef((props: any, ref: any)=>(<Link to="/quality-check-configs/create/"
                        ref={ref} {...props} />))}>New</Button>
                </div>

                <Paper>
                    <ListView component="nav">
                        { (this.state.configs||[]).map((config: any, i: number) => {
                            return (<ListItem key={config._id}
                                button={true}
                                component={this.getEditLink(config._id)}
                                divider={i < this.state.configs.length - 1}>
                                <ListItemText primary={`${config.label||config.website}`} secondary={`${config._id}`} />
                            </ListItem>)
                        }) }
                    </ListView>
                </Paper>
            </MainPadding>
        )
    }
}
import * as React from 'react';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Clear as ClearIcon } from '@material-ui/icons';
import { pth } from '../utils/pth';

export class FormikRemovableTextField extends React.Component<any,any>{
    public render(){
        const {
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            onRemove,
            ...fProps
        } = this.props;
        const isTouched = pth(field.name,touched);
        const error = pth(field.name,errors);
        
        // if(error){
        //     console.log(field, error);
        // }
        return (
            <TextField
            {...field}
            {...fProps}
            margin="dense"
            fullWidth={true}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <IconButton onClick={onRemove}><ClearIcon fontSize="small" /></IconButton>
                </InputAdornment>
                )
            }}
            helperText={isTouched ? `${fProps.helperText||''} ${error||''}`.trim() : fProps.helperText} error={isTouched ? (error!=null) : false} />
        )
    }
};
const titleFactory = (key: string, content: string) =>({ key, content, type:"typography", margin:"normal", variant:"h6" });

const entryPointTypeInclude = [
    { key:"actionApplication", title:"actionApplication", type:"nest", tip:"An application that can complete the request.", fields: [
        { key: "@type", title: "@type", type: "text-field", readonly:true, default:"SoftwareApplication" },
        { key: "@id", title:"@id", type: "text-field" },
        { key: "name", title:"Name", type:"text-field" },
        { key: "operatingSystem", title:"Operating System", type:"text-field", tip:"Operating systems supported (Windows 7, OSX 10.6, Android 1.6)." },
    ] },
    { key:"actionPlatform", title:"actionPlatform", type:"text-field", tip: "The high level platform(s) where the Action can be performed for the given URL. To specify a specific application or operating system instance, use actionApplication." },
    { key:"contentType", title:"contentType", type:"text-field", tip: "The supported content type(s) for an EntryPoint response." },
    { key:"encodingType", title:"actionApplication", type:"text-field", tip: "The supported encoding type(s) for an EntryPoint request." },
    { key:"httpMethod", title:"actionApplication", type:"text-field", tip:"An HTTP method that specifies the appropriate HTTP method for a request to an HTTP EntryPoint. Values are capitalized strings as used in HTTP." },
    { key:"urlTemplate", title:"actionApplication", type:"text-field", tip: "An url template (RFC6570) that will be used to construct the target of the execution of the action." },    	
]

const localBusinessTypeInclude = [
    titleFactory("localBusinessTitle", "Local Business Fields (S)"),
    { key:"currenciesAccepted", title:"Currencies Accepted", type:"text-field", tip:"Use standard formats: ISO 4217 currency format e.g. \"USD\"; Ticker symbol for cryptocurrencies e.g. \"BTC\"; well known names for Local Exchange Tradings Systems (LETS) and other currency types e.g. \"Ithaca HOUR\"." },
    // textFieldArrayBuilder("openingHours", "Opening Hours"),
    { key:"paymentAccepted", multiple:true, title:"Payment Accepted", type:"select", tip:"Cash, Debit Card, Credit Card, Cryptocurrency, Local Exchange Tradings System, etc.", options:[
        {value: 'Cash'},
        {value: 'Credit Card'},
        {value: 'Cryptocurrency'},
        {value: 'Debit Card'},
        {value: 'Local Exchange Tradings System'}
    ] },
    { key:"priceRange", title:"Price Range", type:"select", tip:"The price range of the business, for example $$$.", options:[{value:"$"}, {value:"$$"}, {value:"$$$"}] }
]

const localBusinessFullTypeInclude = [
    { key: "localBusinessTypeInclude", type:"include", include:"localBusinessTypeInclude" },
    { key: "organizationTypeInclude", type:"include", include:"organizationTypeInclude" },
    { key: "placeTypeInclude", type:"include", include:"placeTypeInclude" },
    { key: "thingTypeInclude", type:"include", include:"thingTypeInclude" },   
]

const financialServiceFullTypeInclude = [
    titleFactory("financialServiceTitle", "Financial Service Fields (S)"),
    { key: "feesAndCommissionsSpecification", title:"Fees and Comissions Specification", type:"text-field" },
    { key: "localBusinessFullTypeInclude", type:"include", include:"localBusinessFullTypeInclude" },
]

const foodStablishmentTypeInclude = [
    titleFactory("foodStablishmentTitle", "Food Stablishment Fields (S)"),
    { key: "acceptsReservations", title: "Accepts Reservations", type: "text-field", tip:"Indicates whether a FoodEstablishment accepts reservations. Values can be Boolean, an URL at which reservations can be made or (for backwards compatibility) the strings Yes or No." },
    { key: "hasMenu", title: "Has Menu", type:"text-field", tip:"Text  or URL 	Either the actual menu as text, or a URL of the menu. Supersedes menu." },
    { key: "servesCuisine", title: "Serves Cuisine", type:"text-field", tip: "The cuisine of the restaurant." },
    // starRating	Rating 	An official rating for a lodging business or food establishment, e.g. from national associations or standards bodies. Use the author property to indicate the rating organization, e.g. as an Organization with name such as (e.g. HOTREC, DEHOGA, WHR, or Hotelstars).
    { key: "localBusinessFullTypeInclude", type:"include", include:"localBusinessFullTypeInclude" },
]

const medicalSpecialtyEnumeration = [
    'Anesthesia'
    ,'Cardiovascular','CommunityHealth'
    ,'Dentistry','Dermatology','DietNutrition'
    ,'Emergency','Endocrine'
    ,'Gastroenterologic','Genetic','Geriatric','Gynecologic'
    ,'Hematologic'
    ,'Infectious'
    ,'LaboratoryScience'
    ,'Midwifery','Musculoskeletal'
    ,'Neurologic','Nursing'
    ,'Obstetric','Oncologic','Optometric','Otolaryngologic'
    ,'Pathology','Pediatric','PharmacySpecialty','Physiotherapy','PlasticSurgery','Podiatric','PrimaryCare','Psychiatric','PublicHealth','Pulmonary',
    'Radiography','Renal','RespiratoryTherapy','Rheumatologic'
    ,'SpeechPathology','Surgical'
    ,'Toxicologic'
    ,'Urologic'
];
    
const medicalOrganizationFullTypeInclude = [
    titleFactory("medicalOrganizationTitle", "Medical Organization Fields (S)"),
    { key: "healthPlanNetworkId", title:"Health Plan Network ID", type:"leaf-array", field:{ key:"healthPlanNetworkIdField", type:"text-field", margin:"none" }, tip:"Name or unique ID of network. (Networks are often reused across different insurance plans)." },
    { key:"isAcceptingNewPatients", type:"switch", title:"Is Accepting New Patients", tip:"Whether the provider is accepting new patients." },
    { key: "medicalSpecialty", title:"Mediacal Specialty", type:"leaf-array", field:{ key:"medicalSpecialtyField", type:"select", options: medicalSpecialtyEnumeration.map(x=>({value:`https://schema.org/${x}`, text:x})), margin:"none" }, tip:"A medical specialty of the provider." },
    { key: "localBusinessFullTypeInclude", type:"include", include:"localBusinessFullTypeInclude" },
];

const organizationTypeInclude = [
    titleFactory("organizationTitle", "Organization Fields"),
    { key:"address", title:"Address", type: "nest", fields: [
        { key: "@type", default:"PostalAddress", type:"hidden" },
        { key:"addressInclude", type:"include", include:"postalAddressTypeInclude" }
    ] },
    { key: "organizationTypeIncludeNullanle", type:"nullable", defaultNull:true, field:
        { key: "department", title:"Departments", type:"accordion", itemTitleKey:"name",
            fields: [
                { key:"@type", type:"hidden", default:"Organization" },
                { key:"organizationFullTypeInclude", type:"include", include:"organizationFullTypeInclude" }
            ]
        }
    },
    { key:"aggregateRating", title:"Aggregate Rating", type: "nest", fields: [
        { key:"@type", type:"hidden", default:"AggregateRating" },
        { key:"ratingValue", title:"Rating Value", type:"text-field" },
        { key: "bestRating", title:"Best Rating", type:"text-field" },
        { key:"ratingCount", title:"Rating Count", type:"text-field" }
    ]},
    { key:"logo", title:"Logo", type: "image-upload", tip:"Logo image." },
    { key:"telephone", title:"Telephone", type:"text-field" }
];

const organizationFullTypeInclude = [
    { key:"organizationTypeInclude", type:"include", include:"organizationTypeInclude" },
    { key: "thingTypeInclude", type:"include", include:"thingTypeInclude" },
];

const openingHoursSpecificationTypeInclude = [
    { key:"@type", type:"hidden", default:"OpeningHoursSpecification" },
    { key:"dayOfWeek", title:"Day of Week", type:"select", options: [
        { text:"Monday", value:"https://schema.org/Monday" },
        { text:"Tuesday", value:"https://schema.org/Tuesday"  },
        { text:"Wednesday", value:"https://schema.org/Wednesday" },
        { text:"Thursday", value:"https://schema.org/Thursday" },
        { text:"Friday", value:"https://schema.org/Friday" },
        { text:"Saturday", value:"https://schema.org/Saturday" },
        { text:"Sunday", value:"https://schema.org/Sunday" },
    ] },
    { key:"opens", title:"Opens", type:"text-field", tip:"Sample: 8:00" },
    { key:"closes", title:"Closes", type:"text-field", tip:"Sample: 17:00" },
    { key:"validFrom", title:"Valid From", type:"text-field", tip:"A date in ISO 8601 format: 2019-10-12" },
    { key:"validThrough", title:"valid Through", type:"text-field", tip:"A date in ISO 8601 format: 2019-10-12" },
];

const personTypeInclude = [
    titleFactory("personTitle", "Person Fields (S)"),
    { key:"additionalName", title:"additionalName", type:"text-field" },
    { key:"address", title:"address", type:"text-field" },
    { key:"email", title:"email", type:"text-field" },
    { key:"familyName", title:"familyName", type:"text-field" },
    { key:"faxNumber", title:"faxNumber", type:"text-field" },
    { key:"givenName", title:"givenName", type:"text-field" },
    { key:"jobTitle", title:"jobTitle", type:"text-field" },
    { key:"telephone", title:"telephone", type:"text-field" },
    { key:"thingTypeInclude", type:"include", include:"thingTypeInclude" }
];

const postalAddressTypeInclude = [
    { key:"addressCountry", title:"Address Country", type:"text-field", tip:"The 2-letter ISO 3166-1 alpha-2 country code. Use US for United States.", required:true },
    { key:"addressLocality", title:"Address Locality", type:"text-field", tip:"City.", required:true },
    { key:"addressRegion", title:"Address Region", type:"text-field", tip:"State or province, if applicable." },
    { key:"streetAddress", title:"Street Address", type:"text-field", tip:"Street number, street name, and unit number (if applicable).", required: true },
];

const placeTypeInclude = [
    titleFactory("placeTitle", "Place Fields (S)"),
    { key: "geo", title: "Geo", type:"nest", fields:[
        { key:"@type", type:"hidden", default:"GeoCoordinates" },
        { key:"latitude", title:"Latitude", type:"text-field" },
        { key:"longitude", title:"Longitude", type:"text-field" },
    ]},
    { key: "openingHoursSpecification", title:"Opening Hours Specification", type:"accordion", itemTitleKey: "dayOfWeek", itemTitleFallbackKey:"opens", fields:[
        { key:"openingHoursSpecificationTypeInclude", type:"include", include:"openingHoursSpecificationTypeInclude" }
    ]},
    { key: "review", title:"Reviews", type:"accordion", fields:[
        { key: "@type", type:"hidden", value:"Review" },
        { key:"reviewTypeInclude", type:"include", include:"reviewTypeInclude" }
    ]}
];

const priceSpecificationTypeInclude = [
    { key: "appliesToDeliveryMethod", title: "Applies To Delivery Method", type: "select",
        options: [ 'DeliveryModeDirectDownload', 'DeliveryModeFreight', 'DeliveryModeMail', 'DeliveryModeOwnFleet', 'DeliveryModePickUp', 'DHL', 'FederalExpress', 'UPS' ].map(x =>({text:x, value:`http://purl.org/goodrelations/v1#${x}`})), tip:"This property applies only to the delivery method: http://purl.org/goodrelations/v1#DeliveryModeOwnFleet" 
    },
    { key: "price", title: "Price", type: "text-field", tip:"Total delivery cost as a numerical only value. Use the priceCurrency property to denote the type of currency instead of currency symbols." },
    { key: "priceCurrency", title:"Price Currency", type: "text-field", tip:"The 3-letter ISO 4217 currency code." },
    { key: "eligibleTransactionNullable", type: "nullable", defaultNull:true, field: {
        key: "eligibleTransaction", type: "nest", title: "Eligible Transaction", fields: [
            { key: "price", title:"Price", type: "text-field", tip: "Numeric only minimum order amount. Use the eligibleTransaction.priceCurrency property to specify the type of currency instead of including currency symbols." },
            { key: "priceCurrency", title:"Price Currency", type: "text-field", tip: "The 3-letter ISO 4217 currency code." },
            { key: "eligibleTransactionVolumeNullable", type: "nullable", defaultNull: true, field: {
                key: "eligibleTransactionVolume", title:"Eligible Transaction Volume", type: "nest", tip: "The minimum order amount to be eligible for the delivery service.", fields:[
                    { key: "priceSpecificationTypeInclude", type:"include", include:"priceSpecificationTypeInclude" }
                ]}
            },
        ]
    }}
];

const reviewTypeInclude = [
    { key:"@type", type:"hidden", value:"Review" },
    { key:"author", title:"Author", type:"text-field" },
    { key:"dataPublished", title:"Date Published", type:"text-field" },
    { key:"description", title:"Description", type:"text-field" },
    { key:"name", title:"Name", type:"text-field" },
    { key:"reviewRating", type:"nest", title:"Review Rating", fields:[
        { key:"@type", type:"hidden", default:"Rating" },
        { key:"bestRating", title:"Best Rating", type:"text-field" },
        { key:"ratingValue", title:"Rating Value", type:"text-field" },
        { key:"worstRating", title:"Worst Rating", type:"text-field" },
    ] }
];

const thingTypeInclude = [
    titleFactory("thingTitle", "Thing Fields (S)"),
    { key: "alternateName", title:"Alternate Name", type:"text-field", tip:"An alias for the item." },
    { key: "description", title:"Description", type:"text-field", tip:"A description of the item." },
    { key: "image", title:"Images", type:"leaf-array", tip:"URL of an image. For best results, provide multiple high-resolution images (minimum of 50K pixels when multiplying width and height) with the following aspect ratios: 16x9, 4x3, and 1x1.",
        field:{ key:"imageField", type:"image-upload" }
    },
    { key: "name", title:"Name", type:"text-field" },
    { key: "potentialAction", title:"Potential Action", type:"accordion", itemTitleKey: "name", itemTitleFallbackKey:"@type", tip:"Indicates a potential Action, which describes an idealized action in which this thing would play an 'object' role.", fields: [
        { key:"potentialActionExtender", type:"extender", groupdata:false, nest:false, selectorKey:"@type", fields:[
            { key:"@type", type:"select", title:"@type", options: [
                { value: "OrderAction" },
                { value: "ReserveAction" },
                { value: "SendAction" },
            ], required: true }
        ], types: [
            { key: "OrderAction", fields:[
                { key:"deliveryMethod", title:"Delivery Method", type:"select", options: [
                    { value:"http://purl.org/goodrelations/v1#DeliveryModePickUp", text:"Delivery Mode PickUp" },
                    { value:"http://purl.org/goodrelations/v1#DeliveryModeOwnFleet", text:"Delivery Mode Own Fleet" },
                ],},
                { key: "priceSpecificationNullable", type: "nullable", defaultNull:true, field: {
                    key: "priceSpecification", type: "nest", title:"Price Specification", fields: [
                        { key:"@type", type:"hidden", default:"PriceSpecification" },
                        { key: "priceSpecificationTypeInclude", type:"include", include:"priceSpecificationTypeInclude" }
                    ]
                } },
            ] },
            { key: "ReserveAction", fields:[
                { key: "target", type: "accordion", title:"Target", fields: [
                    { key:"@type", type:"hidden", default:"EntryPoint" },
                    { key:"entryPointInclude", type:"include", include: "entryPointTypeInclude" }
                ] },
                { key: "name", type: "text-field", title:"Name" }
            ] },
            { key: "SendAction", fields:[
                { key: "target", type: "accordion", title:"Target", fields: [
                    { key:"@type", type:"hidden", default:"EntryPoint" },
                    { key:"entryPointInclude", type:"include", include: "entryPointTypeInclude" }
                ] },
                { key: "name", type: "text-field", title:"Name" }
            ] },
        ] }
    ]},
    { key: "sameAs", title:"Same As", type:"leaf-array", tip:"URL of a reference Web page that unambiguously indicates the item's identity. E.g. the URL of the item's Wikipedia page, Wikidata entry, or official website.",
        field:{ key:"sameAsField", type:"text-field", margin:"none" }
    },
    { key: "url", title:"URL", type:"text-field", tip:"URL of the item." }
];

const thingStrictTypeInclude = (()=>{
    const clone = JSON.parse(JSON.stringify(thingTypeInclude))
    clone.filter((x:any) => x.key==='name').forEach((x:any) => x.required=true);
    clone.filter((x:any) => x.key==='images').forEach((x:any) => x.minLength=1);
    return clone;
})();

export const typesIncludes = {
    entryPointTypeInclude,
    financialServiceFullTypeInclude,
    foodStablishmentTypeInclude,
    localBusinessTypeInclude,
    localBusinessFullTypeInclude,
    medicalOrganizationFullTypeInclude,
    openingHoursSpecificationTypeInclude,
    organizationTypeInclude,
    organizationFullTypeInclude,
    personTypeInclude,
    placeTypeInclude,
    postalAddressTypeInclude,
    priceSpecificationTypeInclude,
    reviewTypeInclude,
    thingTypeInclude,
    thingStrictTypeInclude
}
const genFoodStablishment = (key: string) => (
    { key, fields: [{ key:"foodStablishmentTypeInclude", type:"include", include: "foodStablishmentTypeInclude" }] }
);
const genLocalBusiness = (key: string) => (
    { key, fields: [{ key:"localBusinessFullTypeInclude", type:"include", include: "localBusinessFullTypeInclude" }] }
);

export const topTypesExtender = {
    key:"topTypesExtender", selectorKey:"@type", clearExcept:['@id','@context','versionId'], type:"extender", nest:false, groupdata:false, fields: [
        { key:"@type", type:"select", title:"@type", required: true, options: [
            { value: "Dentist" },
            { value: "FinancialService" },
            { value: "FoodStablishment" },
            { value: "LocalBusiness" },
            { value: "MedicalOrganization" },
            { value: "RealEstateAgent" },
            { value: "Restaurant" },
            { value: "RoofingContractor" },
        ]}
    ], types: [
        genLocalBusiness("Dentist"),
        { key: "FinancialService", fields: [{ key:"financialServiceFullTypeInclude", type:"include", include: "financialServiceFullTypeInclude" }] },
        genFoodStablishment("FoodStablishment"),
        genLocalBusiness("LocalBusiness"),
        { key: "MedicalOrganization", fields: [{ key:"medicalOrganizationFullTypeInclude", type:"include", include: "medicalOrganizationFullTypeInclude" }] },
        genLocalBusiness("RealEstateAgent"),
        genFoodStablishment("Restaurant"),
        genLocalBusiness("RoofingContractor"),
    ]
}
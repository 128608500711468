import * as React from 'react';
import { MainPadding } from '../components/MainPadding';
import { Typography } from '@material-ui/core';

export class NotAllowed extends React.Component<any,any>{
    
    public render(){
        return (<MainPadding>
            <Typography color="error">Resource not allowed. You don't have the required permission.</Typography>
        </MainPadding>)
    }
    
}
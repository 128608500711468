import * as React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Paper, Typography, Drawer, Button, CircularProgress } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { serviceLocator } from '../../services';
import { MainPadding } from '../../components/MainPadding';
import { SimpleReport } from './components/SimpleReport';
import { AppSection } from '../../components/AppSection';

const qualityCheckService = serviceLocator.getQualityCheckService();

interface Props {
    
}

interface State {
    reports: Array<any> | null;
    loadedReport: any | null;
    openReport: boolean;
    progress: null|number
}

export class Quality extends React.Component<Props, State>{

    checkProgressInterval: any;

    constructor(props: Props){
        super(props);
        this.state = { reports: null, loadedReport: null, openReport: false, progress: null };
    }

    loadDailyProgress = async ()=>{
        const progress = await qualityCheckService.getDailyCheckProgress();
        this.setState(progress);
    }

    componentDidMount = async  () => {
        await this.loadReports();
        await this.loadDailyProgress();
        this.checkProgressInterval = setInterval(this.loadDailyProgress, 5000);
    }

    componentWillUnmount = async () =>{
        clearInterval(this.checkProgressInterval);
    }

    loadReports = async () => {
        const reports = await qualityCheckService.listFailedWebsiteChecks();
        reports.sort((a: any, b: any) => (a.label||'' + a.key) > (b.label||''+b.key) ? 1 : -1 );
        this.setState({reports});
    }

    handleReportItemClick = async (key: string) => {
        const loadedReport = await qualityCheckService.getReport(key);
        this.setState({loadedReport, openReport: true});
    }

    handleItemDelete = async (e:any) => {
        const reportKey = e.currentTarget.dataset.key;
        await qualityCheckService.removeReport(reportKey);
        await this.loadReports();
    }

    handleRunDailyCheckClick = async ()=> {
        await qualityCheckService.runDailyCheck()
        this.loadDailyProgress();
    }

    render(){
        const notNullReports = this.state.reports||[];
        return (<MainPadding>
            <AppSection section="quality-reports" />
            <div>
                <Typography component="p">Click in the button below to run a full check in background.</Typography>
                <Typography component="p">If a process is already running, the action will be ignored.</Typography>
                <br />
                <Button variant="contained" color="primary" disabled={this.state.progress != null} onClick={ this.handleRunDailyCheckClick }>{this.state.progress != null?"Running Daily Checks...":"Run Daily Checks Now"}</Button> 
                { this.state.progress != null && (<CircularProgress
                    style={{verticalAlign:"bottom", width:36, height: 36, marginLeft: 6 }}
                    variant="static"
                    value={this.state.progress*100} />) }
            </div>
            <br />
            <br />
            <Typography variant="h6">Quality Reports: Failed</Typography><br />
            <Paper>
                <List component="nav">
                    { notNullReports.map((report, i) =>{
                        const splittedKey = report.key.replace(/:/,'_:_').split('_:_');
                        const label = report.label;
                        return (
                        <ListItem key={report.key}
                            button={true}
                            divider={i < notNullReports.length - 1}
                            onClick={()=>this.handleReportItemClick(report.key)}
                        >
                            <ListItemText
                                primaryTypographyProps={{ color: report.fail ? "error" : undefined }}
                                primary={`${label!=null?`[${label}] `:''}${splittedKey[0]}`} secondary={splittedKey[1]} />
                            <ListItemSecondaryAction>
                                <IconButton onClick={this.handleItemDelete} data-key={report.key}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        );
                }) }
                </List>
            </Paper>
            <Drawer anchor="right" open={this.state.openReport && this.state.loadedReport} onClose={()=>this.setState({openReport: false})}>
                <MainPadding>
                    { this.state.loadedReport!=null ? <SimpleReport report={this.state.loadedReport} /> : null }
                </MainPadding>
            </Drawer>
        </MainPadding>);
    }
}
import { blue, pink, teal } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core';

export const primaryColor = teal;
export const secondaryColor = blue;
export const errorColor = pink;

export const defaultTheme = createTheme({
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    error: errorColor,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: 'rgba(0,0,0,.6)'
      }
    },
    MuiFormHelperText: {
      root: {
        color: 'rgba(0,0,0,.3)'
      }
    }
  },
});

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: primaryColor,
    secondary: secondaryColor,
    error: errorColor,
  },
});
import * as React from 'react';
import { BaseForm } from './../../components/Websites/BaseForm';
import { serviceLocator } from '../../services';
import fieldsUtil from './fields-util';
import { Breadcumb } from '../../components/Breadcumb';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import {mainFormFields} from './MainFormFields';

interface PageFormProps {
	websiteKey: string;
	collectionKey: string;
	pageKey: string;
}

function getPageFields(pageKey: string, isHome: boolean, collectionKey: string, mainFormData?:any) {

	const isNotPage = collectionKey != "pages" && pageKey != "_index"
	const { blocksAccordion, blockIncludeFactory } = fieldsUtil;
	let categories: { value: string; }[] = []
	let tags: { value: string; }[] = []
	let authors: { value: string; }[] = []
	if(mainFormData) {
		mainFormData.blog?.categoriesConfig?.categories?.map((item: any) => categories.push({value: item.name}))
		mainFormData.blog?.tagsConfig?.tags?.map((item: any) => tags.push({value: item.name}))
		mainFormData.blog?.authorsConfig?.authors?.map((item: any) => authors.push({value: item.name}))
		console.log('mainFormData',  mainFormData, categories, tags, authors)
	}

	return [
		...((isHome) ? [] : [
			{ key: isNotPage ? "slug": "url", title: isNotPage ? "slug": "url", type: "text-field", required: false, default: `${pageKey}` },
			{ key: "aliases", title: "Aliases", type: "leaf-array", field: { key: "alias", type: "text-field", margin: "none" } },
		]),
		...(isNotPage ? [
			{
				key: "blog", title: "Blog", type: "nest", groupdata: true, fields: [
					{ key: "status", title: "Status", type: "select", default: "draft", options: [
						{ value: "draft", text: "Draft" }, 
						{ value: "active", text: "Active" }, 
						{ value: "achived", text: "Achived" }], 
					},
					{ key: "title", title: "Title", type: "text-field", required: true, default: "Untitled" },
					{ key: "publishDate", title: "Publish Date", type: "datetime", dateType: "datetime", default: "blogs", required: false },
					{ key: "shortDesc", title: "Short Description", type: "text-field", required: false },
					// { key: "blogImg", title: "Blog Img", type: "text-field", required: false },
                    { key: "blogImg", title: "Blog Image", type: "image-upload" },
				
					{ key: "categories", type: "select", title: "Categories", multiple: true, options: categories},
					{ key: "tags", type: "select", title: "Tags", multiple: true, options: tags},
					{ key: "authors", type: "select", title: "Authors", multiple: true, options: authors},
 
					{ key: "prevNext", title: "Prev/Next", type: "nest", groupdata: true, fields: [
						{ key: "enabled", title: "Show Prev/Next buttons?", type: "switch", default: true },
					]},
				]
			},
			
		] : [] ),
		// { key: "aliases", title: "Aliases", type:"leaf-array", default:[], field: [
		// 	{ key: "alias", title: "Alias", type:"text-field", required: true },
		// ] },
		
		{
			key: "includes", title: "Includes", type: "nest", fields: [
				{ key: "afterOpeningHeadInclude", title: "After Opening Head", type: "code", language: "html", required: false },
				{ key: "beforeClosingHeadInclude", title: "Before Closing Head", type: "code", language: "html", multiline: true, required: false },
				{ key: "afterOpeningBodyInclude", title: "After Opening Body", type: "code", language: "html", multiline: true, required: false },
				{ key: "beforeClosingBodyInclude", title: "Before Closing Body", type: "code", language: "html", multiline: true, required: false }
			]
		},
		{
			key: "style", title: "Style", type: "nest", groupdata: true, fields: [
				// { key: "globalSCSS", title: "Global SCSS", type: "code", language: "scss" },
				{ key: "customSCSS", title: "Custom SCSS", type: "code", language: "scss" },
			]
		},
		{
			key: "meta", title: "Meta", type: "nest", groupdata: false, fields: [
				{ key: "title", title: "Title", type: "text-field", required: true, limit: 60, showCounter: true },
				{ key: "description", title: "Meta Description", type: "text-field", required: false, limit: 155, showCounter: true },
				{ key: "ogimage", title: "Meta OG:Image", type: "image-upload", required: false },
				// { key: "ogtitle", title: "Meta OG:Title", type: "text-field", required: false },
				// { key: "ogdescription", title: "Meta OG:Description", type: "text-field", required: false },
				// { key: "jsonLD", title: "JSON-LD Refs", type: "leaf-array", field: { key: "jsonLDItem", type: "text-field", margin: "none", required: true } },
				...(isHome ? [] : [{ key: "noIndex", title: "No Index", type: "switch", required: true, default: false }]),
			]
		},
		{
			key: "header", title: "Header", type: "nest", groupdata: true, fields: [
				{ key: "mode", type: "select", title: "Global Header Mode", options: [{ value: "global" }, { value: "hidden" }], default: "global" },
				{ key: "blogHeaderContainer", type: "select", title: "Blog Header Container", options: [{ value: "container" }, { value: "container-fluid" }], default: "container-fluid" },
			]
		},
		{
			key: "pageMenu", title: "Menu", type: "nest", groupdata: true, fields: [
				{
					"key": "pageMenyExtender", type: "extender", nest: false, groupdata: false, selectorKey: "mode", fields: [
						{ key: "mode", type: "select", title: "Mode", options: [{ value: "global" }, { value: "fragment" }, { value: "hidden" }], default: "global" },
					], types: [
						{ key: "fragment", fields: [{ key: "name", type: "text-field", title: "Fragment Name", required: true }] }
					]
				}
			]
		},
		...(isNotPage ? 
		[
			{
				key: "beforeBlocks", title: "Blocks (Before Content)", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
					{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
				]
			},
			{
				key: "contentBlocks", title: "Main Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
					{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
				]
			},
			{
				key: "afterBlocks", title: "Blocks (After Content)", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
					{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
				]
			},
		]
		:
		[{
			key: "blocks", title: "Blocks", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
				{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
			]
		}]),
		// blocksAccordion("blocks", "Blocks"),
		{
			key: "footer", title: "Footer", type: "nest", groupdata: true, fields: [
				{ key: "mode", type: "select", title: "Mode", options: [{ value: "global" }, { value: "hidden" }], default: "global" },
			]
		},
	];
}

interface PageFormProps {
	websiteKey: string;
	collectionKey: string;
	pageKey: string;
}
interface PageFormState {
	categories: { value: any; }[];
}

export class PageForm extends React.Component<PageFormProps, PageFormState>{

	state: PageFormState = {
		categories: []
	}
	
	public async componentDidMount() {
		await this.setCategories();
	}

	async setCategories () {
		const service = serviceLocator.getWebsiteService();
		const { websiteKey } = this.props;
		const data = await service.getFormData(websiteKey, 'content') 
		const cates: { value: any; }[] = [];
		data?.blockPrefabs.map((block: { name: any; }) => cates.push({value: block.name}))
		this.setState({categories: cates})
		return data;
	}

	render() {
		const service = serviceLocator.getWebsiteService();
		const { includes, getOptions, blockIncludeFactory } = fieldsUtil;

		const { pageKey, websiteKey, collectionKey } = this.props;
        const itemType = collectionKey == "pages" ? "Page" : "Blog"

		const getMainFormData =  async () => {return await service.getFormData(websiteKey, 'content') }
		const blockIncludeWithPrefabFilled = blockIncludeFactory(false, false, this.state.categories)
		includes.blockIncludeWithPrefabFilled = blockIncludeWithPrefabFilled

		return <BaseForm
			themeKey="custom-website"
			debug={false}
			websiteKey={websiteKey}
			title={<Breadcumb paperStyle={false} items={[
				{ to: `/websites/custom-website/`, label: `Custom Website` },
				{ to: `/websites/custom-website/${websiteKey}/`, label: `Website ${this.props.websiteKey}` },
				{ label: `${itemType} ${pageKey}` }
			]} />}
			getFormInitialData={() => service.getPage(websiteKey, this.props.collectionKey, pageKey).then(async (value) => ({ value, includes, fields: getPageFields(this.props.pageKey, false, this.props.collectionKey, await getMainFormData()) }))}
			plugins={{ getOptions }}
			saveData={(data: any) => service.updatePage(websiteKey, this.props.collectionKey, pageKey, data)}
			buildSite={() => service.buildWebsite(websiteKey)}
			getNote={() => service.getNote(websiteKey, `page-${pageKey}`)}
			saveNote={(note: string) => service.setNote(websiteKey, `page-${pageKey}`, note)}
			pageVariablesPath={`${this.props.collectionKey}/${pageKey}`}
			getWebsiteInfrastructureData={service.getInfrastructureData}
		/>
	}
}

interface HomePageFormProps {
	websiteKey: string;
}
interface HomePageFormState {
	categories: { value: any; }[];
}


export class HomePageForm extends React.Component<HomePageFormProps, HomePageFormState>{

	state: HomePageFormState = {
		categories: []
	}
	
	public async componentDidMount() {
		await this.setCategories();
	}

	async setCategories () {
		const service = serviceLocator.getWebsiteService();
		const { websiteKey } = this.props;
		const data = await service.getFormData(websiteKey, 'content') 
		const cates: { value: any; }[] = [];
		data?.blockPrefabs.map((block: { name: any; }) => cates.push({value: block.name}))
		this.setState({categories: cates})
	}

	render() {
		
		const service = serviceLocator.getWebsiteService();
		const { websiteKey } = this.props;

		const { includes, getOptions, blockIncludeFactory } = fieldsUtil;

		const blockIncludeWithPrefabFilled = blockIncludeFactory(false, false, this.state.categories)
		includes.blockIncludeWithPrefabFilled = blockIncludeWithPrefabFilled

		return <BaseForm
			themeKey="custom-website"
			debug={false}
			websiteKey={websiteKey}
			title={<Breadcumb paperStyle={false} items={[
				{ to: `/websites/custom-website/`, label: `Custom Website` },
				{ to: `/websites/custom-website/${websiteKey}/`, label: `Website ${this.props.websiteKey}` },
				{ label: `Homepage` }
			]} />}
			plugins={{ getOptions }}
			getFormInitialData={() => service.getHomePage(websiteKey).then(value => ({ value, includes, fields: getPageFields('home', true, 'pages') }))}
			saveData={(data: any) => service.updateHomePage(websiteKey, data)}
			buildSite={() => service.buildWebsite(websiteKey)}
			getNote={() => service.getNote(websiteKey, `homepage`)}
			saveNote={(note: string) => service.setNote(websiteKey, `homepage`, note)}
			getWebsiteInfrastructureData={service.getInfrastructureData}
		/>
	}
}
import * as React from 'react';
import { List, ListItem, ListItemText, Paper, Typography, Dialog, DialogContent, DialogTitle, DialogActions, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { serviceLocator } from './../services';
import { MainPadding } from '../components/MainPadding';
import { withStyles } from '@material-ui/core/styles';
import { AppSection } from '../components/AppSection';

export interface LogsState {
    currentLogKey: string | null;
    currentLogContent: string | null;
    logsKeys: string[];
    openDialog: boolean;
    prettyPrint: boolean
}

const styles = (theme: any) => ({
    pre: {
        whiteSpace: 'pre-wrap' as any,
        wordWrap: 'break-word' as any,
        width: '100%' as any
    },
    logItem: {
      fontFamily: 'monospace' as any,
      borderTop: 'solid 1px #eee' as any,
      padding: '0.5rem',
      margin: '0'
    }
  });

const preStyle: any = {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    width: '100%'
}

export class LogsUnstyled extends React.Component<any,LogsState>{

    constructor(props: any){
        super(props);
        this.state = {logsKeys:[], currentLogKey: null, currentLogContent: null, openDialog: false, prettyPrint: true };
    }

    public async componentDidMount(){
        const logsKeys = await serviceLocator.getLogService().listLogs();
        this.setState({logsKeys});
    }

    private handlePrettyPrintChange = (e: any) =>{
        this.setState({prettyPrint: e.target.checked});
    }

    public render(){
        const logsKeys = this.state.logsKeys;
        return (
        <MainPadding>
            <AppSection section="logs" />
            <Typography variant="h6">Application Logs</Typography><br />
            <FormControlLabel label="Pretty Print" control={<Checkbox checked={this.state.prettyPrint} onChange={this.handlePrettyPrintChange}  />} />
            <Paper>
                <List component="nav">
                    {logsKeys.map((logKey: any, i: number)=>(
                    <ListItem
                        selected={this.state.currentLogKey === logKey}
                        key={`${logKey}`}
                        data-key={logKey}
                        button={true}
                        onClick={this.handleItemClick}
                        divider={i<logsKeys.length-1}>
                        <ListItemText primary={logKey}  />
                    </ListItem>
                    ))}
                </List>
            </Paper>

            <Dialog
                open={this.state.openDialog}
                scroll="paper"
                fullScreen={true}
                onClose={this.handleDialogClose}
            >
                <DialogTitle>{this.state.currentLogKey}</DialogTitle>
                <DialogContent>
                    {this.state.prettyPrint? (
                        <React.Fragment>{ (this.state.currentLogContent||'').split(/\n/).reverse().map((x,i) => {
                            return (<p key={i} className={this.props.classes.logItem}>{x}</p>)
                        }) }</React.Fragment>
                    ) : (
                        <pre className={this.props.classes.pre}>
                            {this.state.currentLogContent}
                        </pre>
                    ) }
                    
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.handleDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>

        </MainPadding>
        );
    }

    private handleDialogClose = async (e: any) =>{
        this.setState({openDialog: false})
    }

    private handleItemClick = async (e: any) =>{
        const currentLogKey = e.currentTarget.dataset.key;
        this.setState({currentLogKey, openDialog: true});
        const currentLogContent = await serviceLocator.getLogService().getLogContent(currentLogKey);
        this.setState({currentLogContent});
    }
}

export const Logs = withStyles(styles)(LogsUnstyled);
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppSmart } from './App';
import './index.css';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { defaultTheme } from './themes';



ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <MuiThemeProvider theme={defaultTheme}>
      <AppSmart />
    </MuiThemeProvider>
  </SnackbarProvider>,
  document.getElementById('root') as HTMLElement
);

unregisterServiceWorker();

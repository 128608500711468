import * as React from 'react';
import { AgenciesList } from './AgenciesList';
import { EditAgencyForm } from './EditAgencyForm';
import { Switch, Route } from 'react-router';
import { NotFound } from '../NotFound';
import { RoleSection } from '../../components/RoleSection';
import { NotAllowed } from '../NotAllowed';
import { Agency } from './Agency';
import { AppSection } from '../../components/AppSection';

export class Agencies extends React.Component<any,any>{ 

    public render(){
        
        return (<React.Fragment>
            <AppSection section="agencies" />
            <RoleSection role="admin" render={(forbidden)=>(
                <Switch>
                    {forbidden && <Route path={"*"} component={NotAllowed} />}
                    <Route path={"/agencies/create/"} exact={true} component={this.renderCreate} />
                    <Route path={"/agencies/:identifier/edit/"} exact={true} component={this.renderEdit} />
                    <Route path={"/agencies/:identifier/"} exact={true} component={this.renderView} />
                    <Route path={"/agencies/"} exact={true} render={this.renderList} />
                    <Route path={"/agencies/*"} component={NotFound} />
                </Switch>
            )} />
        </React.Fragment>)
    }

    private renderList = ({match}: any)=>{
        return (<AgenciesList />);
    }

    private renderView = ({match, history}: any)=>{
        return (<Agency identifier={match.params.identifier} />);
    }

    private renderEdit = ({match, history}: any)=>{
        return (<EditAgencyForm history={history} identifier={match.params.identifier} />);
    }

    private renderCreate = ({match, history}: any)=>{
        return (<EditAgencyForm history={history} />);
    }
}
import * as React from 'react';
import * as Yup from 'yup';
import { Divider, List, Paper, ListSubheader, ListItem, ListItemSecondaryAction, ListItemText, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Formik,  Field } from 'formik';
import { FormikSuggestionTextField } from './FormikSuggestionTextField';
import { serviceLocator } from '../services';

interface Props{
    websiteKey: string,
}

interface State{
    menuAnchor: any;
    reviewsProviderMenuOpen: boolean;
    reviewsProviderMenuKey: string;
    reviewsGroup: { name: string, providers: Array<any> }|null;
    openDialog: string|null;
    loaded: boolean;
    providerSuggestions: Array<{name: string, identifier: string}>|null,
    loadingReviews: boolean
}

const dialogContentStyle: any = {
    minWidth: '300px'
}

const reviewService = serviceLocator.getReviewService();

export class ReviewsProvidersList extends React.Component<Props, State>{

    state: State = {
        menuAnchor: null,
        reviewsProviderMenuOpen: false,
        reviewsProviderMenuKey: '',
        reviewsGroup: null,
        openDialog: null,
        loaded: false,
        providerSuggestions: null,
        loadingReviews: false
    }

    private validProviderName = /^[a-z0-9-]+$/;

    private restoreScroll(){
        // fix
        setTimeout(()=>{
            document.body.style.overflow = "";
        },100);
    }

    componentDidMount = () => {
        this.loadReviewsGroup();
    }

    loadReviewsGroup = async () => {
        const reviewsGroup = await reviewService.getReviewsGroup(this.props.websiteKey);
        this.setState({ reviewsGroup, loaded: true });        
    }

    private handleNewClick = async (e: any) => {
        this.setState({ openDialog: 'new' });
        if(this.state.providerSuggestions==null){
            const { suggestions } = await reviewService.getProviderIdentifierSuggestions();
            this.setState({ providerSuggestions: suggestions });
        }
    }   

    handleProviderDelete = async (e: any) => {
        this.setState({reviewsProviderMenuOpen: false});
        await reviewService.removeProviderFromReviewsGroup(this.props.websiteKey, this.state.reviewsProviderMenuKey);
        this.loadReviewsGroup();
    }

    private handleProviderItemMoreClick = (e: any) => {
        const target = e.currentTarget;
        this.setState({ reviewsProviderMenuOpen: true, menuAnchor: target, reviewsProviderMenuKey: target.dataset.key });
    }


    private handleDialogClose = (e: any) => {
        this.setState({ openDialog: null });
    }

    handleAddProviderFormSubmit = async (values: any, { setSubmitting, resetForm }: any) =>{
        try{
            await reviewService.addProviderToReviewsGroup(this.props.websiteKey, values.identifier);
            setSubmitting(false);
            resetForm();
            this.setState({ openDialog: null });
            this.loadReviewsGroup();
        }
        catch{
            setSubmitting(false);
            alert('An error has ocurred');
        }
    }

    private handleMenuClose = (e: any) => {
        this.setState({ reviewsProviderMenuOpen: false });
    }

    private handleEnableClick = async (e: any) => {
        await reviewService.createReviewsGroup(this.props.websiteKey);
        this.loadReviewsGroup();
    }

    private handleLoadClick = async (e: any) => {
        this.setState({loadingReviews: true});
        try{
            await reviewService.loadReviewsGroup(this.props.websiteKey);
        }
        catch(e){
            alert('Failed to load reviews.');
        }
        this.setState({loadingReviews: false});
    }

    renderDialog(){
        return (
            <Formik
                initialValues={{ identifier: '' }}
                onSubmit={this.handleAddProviderFormSubmit}
                validationSchema={
                    Yup.object().shape({
                        identifier: Yup.string().required('Required.')
                    }).required()
                }>
                { props => {
                    const { handleSubmit, isSubmitting, submitForm, values } = props;
                    const identifierLower = (values.identifier||'').toLowerCase();
                    const options = (this.state.providerSuggestions||[])
                        .filter(x => x.identifier.toLowerCase().indexOf(identifierLower)!==-1||x.name.toLowerCase().indexOf(identifierLower)!==-1)
                        .sort((a,b)=> a.name > b.name ? 1 : -1)
                        .map(x=>({label:x.name, value:x.identifier}));

                    return (
                        <Dialog
                            onClose={this.handleDialogClose}
                            onExited={this.restoreScroll}
                            open={this.state.openDialog==='new'}>
                            <DialogTitle style={dialogContentStyle}>Add New Review Provider</DialogTitle>
                            <DialogContent style={dialogContentStyle}>
                                <form onSubmit={handleSubmit}>
                                    <Field debounce={15} margin="normal" name="identifier" label="Provider Identifier"
                                    suggestions={options} component={FormikSuggestionTextField} helperText="Provider (Google or Facebook) identifier." />
                                    <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{width:1, height:1, visibility:'hidden'}}>Create</button>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    disabled={isSubmitting}
                                    color="primary"
                                    type="submit"
                                    value="create"
                                    onClick={submitForm}>Add</Button>
                            </DialogActions>
                        </Dialog>
                                     
                    )
                }}
            </Formik>
        );
    }

    renderMenu() {
        return (
            <Menu
                key="reviews-group-menu"
                open={this.state.reviewsProviderMenuOpen}
                anchorEl={this.state.menuAnchor}
                onClose={this.handleMenuClose}
            >
                {/* <MenuItem>Remove</MenuItem> */}
                <MenuItem onClick={this.handleProviderDelete}>Remove</MenuItem>
            </Menu>
        );
    }

    render(){
        if(!this.state.loaded) return (
            <React.Fragment>
                <Paper>
                    <List component="nav" subheader={<ListSubheader>Reviews Providers</ListSubheader>}>
                        <Divider />
                    </List>
                </Paper>
                <div style={{ padding: '16px 0' }}>
                    <span> </span><Button size="small">...</Button>
                </div>
            </React.Fragment>
        );
        
        if(this.state.reviewsGroup==null) return (
            <React.Fragment>
                <Paper>
                    <List component="nav" subheader={<ListSubheader>Reviews Providers</ListSubheader>}>
                        <Divider />
                    </List>
                </Paper>
                <div style={{ padding: '16px 0' }}>
                    <span> </span><Button size="small" onClick={this.handleEnableClick}>Enable Reviews</Button>
                </div>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Paper>
                    <List component="nav" subheader={<ListSubheader>Reviews Providers</ListSubheader>}>
                        <Divider />
                        {this.state.reviewsGroup.providers.map(x => x.identifier).map((item, i) => {
                            return (
                                <ListItem key={item}
                                    divider={true}>
                                    <ListItemText primary={item} primaryTypographyProps={{style: {wordBreak: 'break-all'}}} />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={this.handleProviderItemMoreClick} data-key={item}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                </Paper>
                <div style={{ padding: '16px 0' }}>
                    <span> </span><Button size="small" onClick={this.handleNewClick}>Add Provider</Button>
                    <span> </span><Button size="small" disabled={this.state.loadingReviews} onClick={this.handleLoadClick}>Load Reviews</Button>
                </div>
                {this.renderDialog()}
                {this.renderMenu()}
            </React.Fragment>
        )
    }
}
import * as React from 'react';
import { withStyles, Typography } from '@material-ui/core';


import { MainPadding } from '../../components/MainPadding';
import { AppSection } from '../../components/AppSection';
import { RoleSection } from '../../components/RoleSection';
import { CardsDashboard } from './components/CardsDashboard';



const styles = {
    
};

export class HomeUnstyled extends React.Component<any,any>{
    
    public render(){
        return (<MainPadding>
            <AppSection section="home" />
            <div style={{maxWidth:'42em'}}>
                <Typography variant="h6">Welcome</Typography>
                <Typography component="p">To navigate, use the menu on left.</Typography>
            </div>
            <RoleSection role="admin">
                <br />
                <br />
                <CardsDashboard />
            </RoleSection>
        </MainPadding>)
    }
    
}

export const Home = withStyles(styles)(HomeUnstyled);
import * as React from 'react';
import * as Yup from 'yup';
import { DialogTitle, Dialog, DialogContent, Button, DialogActions, Typography } from '@material-ui/core';
import { FastField, Formik, Field } from 'formik';
import { DialogProps } from '@material-ui/core/Dialog';
import { VALID_SITE_NAME, SITE_NAME_HELPER } from './Websites';
import { FormikTextField } from '../FormikTextField';
import { FormikSelect } from '../FormikSelect';

const dialogContentStyle: any = {
  minWidth: '300px'
}

interface Props extends DialogProps {
  agency: string | null;
  createWebsite: (key: string, preConfigured: string, embeded: boolean) => Promise<void>;
  listPreConfigureds?: () => Promise<{ preConfigureds: Array<any>, noDefault: boolean }>;
};

interface State {
  preConfigureds: Array<{ name: string, type: 'custom' | 'embeded' }>;
  noDefault: boolean
}

export class CreateSiteDialog extends React.Component<Props, State>{

  state: State = { preConfigureds: [], noDefault: true };

  private handleNewSiteFormSubmit = async (values: { name: string, preConfigured: string }, { setSubmitting, resetForm }: any) => {
    try {
      const { name: siteName, preConfigured } = values;
      const { name: preConfiguredName, type } = JSON.parse(preConfigured || '{}');
      await this.props.createWebsite(siteName, preConfiguredName, type === 'embeded');
      setSubmitting(false);
      resetForm();
    }
    catch (e) {
      console.log(e);
      setSubmitting(false);
      alert('An error has ocurred');
    }
  }

  componentDidMount = async () => {
    if (this.props.listPreConfigureds) {
      const { preConfigureds, noDefault } = await this.props.listPreConfigureds();
      // debugger;
      this.setState({ preConfigureds, noDefault });
    }
  }

  render() {

    const { createWebsite, ...rest } = this.props;

    return (
      <Formik
        initialValues={{ name: '', preConfigured: '', embeded: false }}
        onSubmit={this.handleNewSiteFormSubmit}
        validationSchema={
          Yup.object().shape({
            name: Yup.string().matches(VALID_SITE_NAME, SITE_NAME_HELPER).required('Required.'),
            preConfigured: Yup.string()
          }).required()
        }>
        { props => {
          const { handleSubmit, isSubmitting, submitForm } = props;

          return (
            <Dialog
              {...rest}
            >
              <DialogTitle style={dialogContentStyle}>Create New Site</DialogTitle>
              <DialogContent style={dialogContentStyle}>
                {this.props.agency ? (
                  <form onSubmit={handleSubmit}>
                    <FastField autoFocus={true} margin="normal" name="name" label="Website Name" component={FormikTextField} />
                    { this.state.preConfigureds.length > 0 && (
                      <Field margin="normal" name="preConfigured" label="Template"
                        options={[...(this.state.noDefault ? [] : [{ label: "default", value: "" }]), ...this.state.preConfigureds.map(x => ({ label: `${x.name} ${x.type === 'embeded' ? '(embeded)' : ''}`, value: JSON.stringify(x) }))]}
                        component={FormikSelect}
                      />
                    )}
                    <button disabled={isSubmitting} type="submit" onClick={submitForm} style={{ width: 1, height: 1, visibility: 'hidden' }}>Create</button>
                  </form>
                ) : (<Typography>To create a website you must select an agency.</Typography>)}
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={isSubmitting || !this.props.agency}
                  color="primary"
                  type="submit"
                  value="create"
                  onClick={submitForm}>Create</Button>
              </DialogActions>
            </Dialog>
          )
        }}
      </Formik>
    );
  }
}
import { TextField } from '@material-ui/core';
import * as React from 'react';

export class SafeUrlGenerator extends React.Component<{}, { forwardTo: string }>{

  state = {
    forwardTo: ""
  };

  render() {
    return (<div>
      <TextField fullWidth={true} InputLabelProps={{shrink: true}} label="Forward To" onChange={(e) => {
        this.setState({ forwardTo: e.target.value });
      }} />
      <div style={{height: 8}}></div>
      <TextField fullWidth={true} InputLabelProps={{shrink: true}} label="Safe URL" value={this.state.forwardTo?`https://formscatcher.webdrvn.com/send-secure-message/${window.btoa(this.state.forwardTo)}`:''} />
    </div>);
  }
}
import * as React from 'react';
import { serviceLocator } from '../services';
import usePromise from './use-promise';
import { withStyles, ButtonBase, Dialog, DialogContent, DialogTitle, Popover, TextField, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const svgService = serviceLocator.getSvgService();
let svgPromise: any = svgService.getAll();

interface ItemProps {
  svg: string;
  name: string;
  classes: any;
  onClick: (e: any) => void;
}

const SvgItem = withStyles((theme) => {
  return {
    root: {
      width: '33%',
      padding: '0.5rem 1rem',
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        width: '25%',
      },
      [theme.breakpoints.up('md')]: {
        width: '20%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '10%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '7.14%',
      },
    },
    image: {
      position: 'relative',
      paddingBottom: '100%',
      '& > svg': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        fill: '#444',
        top: 0,
        left: 0
      }
    },
    title: {
      marginTop: '0.1rem',
      fontSize: '0.7rem',
      lineHeight: '0.8rem',
    }
  };
})(React.memo<ItemProps>((props: ItemProps) => {
  return (<ButtonBase data-name={props.name} className={props.classes.root} onClick={props.onClick}>
    <div
      className={props.classes.image}
      dangerouslySetInnerHTML={{ __html: props.svg }}>
    </div>
    <div
      className={props.classes.name}>
      {props.name.split('/').join(' > ')}
    </div>
  </ButtonBase>);
}, (prevProps, nextProps) => { return prevProps.name == nextProps.name; }));

interface Props {
  open: boolean;
  onClose: () => void;
  classes: any;
}

export const SvgPicker = withStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-around',
      alignContent: 'start'
    }
  };
})(React.memo<Props>((props: Props) => {

  const { value, loading } = usePromise<{ [key: string]: string }>(svgPromise);
  const [activeName, setActiveName] = React.useState<string>("");
  const [anchor, setAnchor] = React.useState(null);
  const [open, setOpen] = React.useState<boolean>(false);

  if (loading) return <div>Loading...</div>;

  const vals = value || {};

  const handleItemClick = (e: any) => {
    setActiveName(e.currentTarget.dataset.name);
    setAnchor(e.currentTarget);
    setOpen(true);
  }

  const handlePopoverClose = (e: any) => {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose} fullScreen={true}>
        <DialogTitle>
          <IconButton style={{ float: 'right' }} aria-label="close" onClick={props.onClose}>
            <Close />
          </IconButton>
                    SVG Icon Picker
                </DialogTitle>
        <DialogContent>
          <div className={props.classes.root}>
            {Object.keys(vals).map(key => (
              <SvgItem key={key} svg={vals[key]} name={key} onClick={handleItemClick} />
            ))}
          </div>
        </DialogContent>
      </Dialog>
      <Popover anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center', }}
        open={open} anchorEl={anchor} onClose={handlePopoverClose}>
        <div style={{ padding: '1rem' }}>
          <Typography display="block" variant="caption">{activeName}</Typography>
          <TextField multiline={true} value={vals[activeName] || 'empty'} rows={5} rowsMax={8} style={{ minWidth: '140px' }} />
        </div>
      </Popover>
    </React.Fragment>
  );

}, (prevProps, nextProps) => { return prevProps.open == nextProps.open; }));

import * as React from 'react';
import { TextField } from "@material-ui/core";
import { Debounce } from '../utils/debounce';

export class DebouncedTextField extends React.Component<any,any>{

    private changeDebounce: Debounce;

    static getDerivedStateFromProps(props: any, state: any){
        if(state.previousValue==null || state.previousValue != props.value){
            return { previousValue: props.value, value: props.value };
        }
        return null;
    }

    constructor(props: any){
        super(props);
        this.state = { value: props.value };
        this.changeDebounce = new Debounce(props.debounce||300);
    }

    handleChange = (e: any) => {
        e.persist();
        this.setState({value: e.target.value});
        this.changeDebounce.run(()=>{
            if(this.props.onChange){
                this.props.onChange(e);
            }
        });
    }

    handleBlur = (e: any) => {
        this.changeDebounce.runNow();
        if(this.props.onBlur){
            this.props.onBlur(e);
        }
    }

    handleKeyDown = (e: any) => {
        if(e.key === 'Enter'){
            this.changeDebounce.runNow();
        }
        if(this.props.onKeyDown){
            this.props.onKeyDown(e);
        }
    }

    public render(){

        return <TextField
            {...this.props}
            value={this.state.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
        />

    }
};
import * as React from 'react';
import { List } from './List';
import { Switch, Route } from 'react-router';
import { NotFound } from '../NotFound';
import { RoleSection } from '../../components/RoleSection';
import { NotAllowed } from '../NotAllowed';
import { AppSection } from '../../components/AppSection';
import { CreateOrUpdate } from './CreateOrUpdate';
import { serviceLocator } from '../../services';
import { MainPadding } from '../../components/MainPadding';
import { Update } from './Update';
import { RestrictedSection, Tests } from '../../components/RestrictedSection';

const uiService = serviceLocator.getUIService();

const restricTest = Tests.or([Tests.hasAnyAgency(),Tests.hasRole("admin")]);
export class QualityConfigs extends React.Component<any,any>{ 

    public render(){

        if(!uiService.getSelectedAgency()){
            return (<MainPadding><p>Please, select an agency.</p></MainPadding>);
        }
        
        return (<React.Fragment>
            <AppSection section="quality-check-config" />
            <RestrictedSection test={restricTest} render={(forbidden)=>(
                <Switch>
                    {forbidden && <Route path={"*"} component={NotAllowed} />}
                    <Route path={"/quality-check-configs/create/"} exact={true} component={this.renderCreate} />
                    <Route path={"/quality-check-configs/:identifier/edit/"} exact={true} component={this.renderEdit} />
                    <Route path={"/quality-check-configs/"} exact={true} render={this.renderList} />
                    <Route path={"/quality-check-configs/*"} component={NotFound} />
                </Switch>
            )} />
        </React.Fragment>)
    }

    private renderList = ({match}: any)=>{
        return (<List />);
    }

    private renderEdit = ({match}: any)=>{
        return (<Update qualityCheckConfigId={match.params.identifier} agency={uiService.getSelectedAgency()||''} />);
    }

    private renderCreate = ({match}: any)=>{
        return (<CreateOrUpdate config={null} parentConfig={null} agency={uiService.getSelectedAgency()||''} />);
    }
}
import * as React from 'react';
import { MainPadding } from '../components/MainPadding';
import { Typography } from '@material-ui/core';

export class NotFound extends React.Component<any,any>{
    
    public render(){
        return (<MainPadding>
            <Typography color="error">Page not found.</Typography>
        </MainPadding>)
    }
    
}
import * as React from 'react';
import { serviceLocator } from './../../services';
import { Deploy as DeployInner } from './../../components/Deploy';
import { Breadcumb } from '../../components/Breadcumb';
import { keyToDisplay } from './../../utils/string-utils';

const service = serviceLocator.getWebsiteService();
const credentialService = serviceLocator.getInfraCredentialService();
const uiService = serviceLocator.getUIService();


interface DeployProps {
    websiteKey: string;
    themeKey: string;
};

export class Deploy extends React.Component<DeployProps, {}>{
    render() {
        return (<DeployInner
            title={<Breadcumb items={[
                { to: `/websites/${this.props.themeKey}/`, label: `${keyToDisplay(this.props.themeKey)} Websites` },
                { to: `/websites/${this.props.themeKey}/${this.props.websiteKey}/`, label: `Website ${this.props.websiteKey}` },
                { label: `Deploy` }
            ]} />}
            websiteKey={this.props.websiteKey}
            getNote={service.getNote}
            setNote={service.setNote}
            createWebsiteBucket={service.createWebsiteBucket}
            postInfrastructureSetup={service.postInfrastructureSetup}
            postInfrastructuredomainOverride={service.postInfrastructuredomainOverride}
            getWebsiteInfrastructureData={service.getInfrastructureData}
            createWebsiteCloudFrontDistribution={service.createWebsiteCloudFrontDistribution}
            syncWebsiteBucket={(key, force) => service.syncWebsiteBucket(key, false, force)}
            credentialsProvider={() => (
                credentialService.listCredentials(uiService.getSelectedAgency())
                    .then((x) => Promise.resolve(x.credentials.map(cred => ({ label: cred.name, value: cred._id }))))
            )}
        />);
    }
}
import React from 'react';
import { IconButton, Button, InputLabel } from '@material-ui/core';
import { Add as IconAdd, Remove as RemoveIcon } from '@material-ui/icons';
import { BaseDynamic } from '../HoForm/BaseDynamic';
import { NormalizeStateContext, CrawlContext } from '../HoForm';

type LeafArrayDynamicField = {
    key: string,
    type: string,
    field: any,
    title: string,
    default: Array<any>,
    margin?: 'dense'|'normal'|'none',
    buttonLabel?: string
};

class LeafArrayDynamic extends BaseDynamic<LeafArrayDynamicField,{}> {

    normalizeState({state, field}: NormalizeStateContext<LeafArrayDynamicField>){
        let key = field.key;
        if(state[key]===undefined){
            state[key] = field.default || [];
        }
    }

    getType(){
        return 'leaf-array';
    }

    pushEmptyValue(){
        let context = this.props.context;
        let childField = Object.assign({}, Object.assign({}, this.props.context.node.field.field));
        childField.key = context.value.length;
        let childComponentProplessInstance = context.form.props.componentRegistry.getProplessInstance(childField.type);
        let valueCopy = context.value.slice(0);
        childComponentProplessInstance.normalizeState({state:valueCopy, field:childField, stateBuilder: context.form.stateBuilder, includes: context.form.props.includes});
        context.setValue(valueCopy);
    }

    getOnRequestDeleteHandler(index : number): ()=> void {
        return () => {
            let context = this.props.context;
            let copy = context.value.slice(0);
            copy.splice(index,1);
            context.setValue(copy);
        };
    }

    crawlComponent({form, node} : CrawlContext<LeafArrayDynamicField>): void{
        const value = this.getValueFromNode(node) || [];
        for (let childIndex = 0; childIndex < value.length; childIndex++) {
            let childNode = {
                state: value,
                field: Object.assign({}, node.field.field, { key: childIndex.toString() }),
                parent: node
            };
            form.crawlField(childNode);
        }
    }

    getOnItemChange(index: number): (value: any)=>void{
        return (value : any) => {
            let context = this.props.context;
            let copy = context.value.slice(0);
            copy[index] = value;
            context.setValue(copy);
        };
    }

    renderComponent(){
        
        let {context} = this.props;
        let {node, currentPath, parentPath} = context;
        let {field} = node;
        
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }

        const margin =  field.margin==='none'? '0px' : field.margin==='normal' ? '16px 0 8px 0' : '8px 0 4px 0';
        
        let arrayData = context.value;
        return (
        <div style={{margin:field.title!=null?margin:undefined}}>
            <InputLabel shrink={true} style={{display: 'block'}} >{field.title}</InputLabel>
            {arrayData.map((item: any, index: number)=>{
                let childNode = {
                    state: context.value,
                    field: Object.assign({}, field.field, { key:index.toString() }),
                    parent: context.node
                };
                return (
                    <div key={field.key + '-item-'+index} style={{ display:'flex', width:'100%' }}>
                        <div style={{flex: 1}}>
                        { context.form.renderField(childNode, this.getOnItemChange(index)) }
                        </div>
                        <div style={{flex: '0 0 auto', alignSelf: 'flex-end'}}>
                            <IconButton onClick={this.getOnRequestDeleteHandler(index)}><RemoveIcon fontSize="small" /></IconButton>
                        </div>
                    </div>
                );
            })}
            <Button style={{margin:field.title==null?margin:undefined}} onClick={ this.pushEmptyValue.bind(this) }><IconAdd />{field.buttonLabel?` ${field.buttonLabel}`:''}</Button>
        </div>);
    }
}

export default LeafArrayDynamic;
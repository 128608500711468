import * as React from 'react';
import { BaseForm } from './../../components/Websites/BaseForm';
import { serviceLocator } from '../../services';
import { Breadcumb } from '../../components/Breadcumb';
import { keyToDisplay } from '../../utils/string-utils';

interface Props {
	websiteKey: string;
	singleKey: string;
	themeKey: string;
}

interface State {
	data: any
}

export class SingleForm extends React.Component<Props, State>{

	state: State = {
		data: null
	}

	componentDidMount = async () => {
		const data = await serviceLocator.getWebsiteService().getSingle(this.props.websiteKey, this.props.singleKey);
		this.setState({ data });
	}

	render() {

		if (this.state.data == null) return (null);

		const { form, data } = this.state.data;

		const service = serviceLocator.getWebsiteService();
		const { singleKey, websiteKey, themeKey } = this.props;
		return <BaseForm
			themeKey={themeKey}
			debug={false}
			title={<Breadcumb paperStyle={false} items={[
				{ to: `/websites/${themeKey}`, label: `${keyToDisplay(themeKey)} Websites` },
				{ to: `/websites/${themeKey}/${websiteKey}/`, label: `Website ${this.props.websiteKey}` },
				{ label: `Single ${singleKey}` }
			]} />}
			websiteKey={websiteKey}
			getFormInitialData={() => service.getSingle(websiteKey, singleKey)}
			saveData={(data: any) => {
				return service.putSingle(websiteKey, singleKey, data)
			}}
			buildSite={() => service.buildWebsite(websiteKey)}
			getNote={() => service.getNote(websiteKey, `single-${singleKey}`)}
			saveNote={(note: string) => service.setNote(websiteKey, `single-${singleKey}`, note)}
		/>
	}
}
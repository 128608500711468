import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { serviceLocator } from '../../../services';
import { Link as LinkIcon } from '@material-ui/icons';

import StatusCard from './StatusCard';
const qualityCheckService = serviceLocator.getQualityCheckService();

const styles = {
    cardContent: {
        minHeight:'10em'
    }
};

interface State {
    pageSpeedTestFails: any;
    brokenLinksFail: any;
}

const STATUS_CARD_STYLE = {height: '100%'};

const FlexItem = (props: any)=>{
    return <div {...props} style={{
        position: 'relative',
        flex:1,
        padding: 16,
        maxWidth: 400,
        minWidth: 300
    }}></div>;
}

const LighhouseIcon = (props: any)=>{
    return <svg width="24" height="24" viewBox="0 0 24 24" { ...props } xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M22 1h-2v1h4v5l-2 2v13h2v2h-24v-2h2v-9h12v-4l-2-2v-5h4v-1h-2v-1h8v1zm-13 18h-3v4h3v-4zm5 0h-4v4h4v-4zm4 0h-3v4h3v-4zm-2-15h-2v2l1 1h1v-3zm3 0h-2v3h2v-3zm3 0h-2v3h1l1-1v-2z"/></svg>;
}

export class CardsDashboardUnstyled extends React.Component<any,State>{

    constructor(props: any){
        super(props);
        this.state = { pageSpeedTestFails: null, brokenLinksFail: null };
    }

    public async componentDidMount(){
        const failedWebsiteChecks = await qualityCheckService.listFailedWebsiteChecks()
        
        const pageSpeedTestFails = failedWebsiteChecks.filter(x => x.key.startsWith('PageSpeedTest'));
        const brokenLinksFail = failedWebsiteChecks.filter(x => x.key.startsWith('BrokenLinks'));

        this.setState({pageSpeedTestFails, brokenLinksFail});
    }

    render(){

        const { pageSpeedTestFails, brokenLinksFail } = this.state;

        return (<React.Fragment>
            <Typography variant="h6">
            Website Quality Cards</Typography>
            <div style={{display: 'flex', flexWrap:'wrap', margin: '0 -16px 0 -16px'}}>
                <FlexItem>
                    <StatusCard
                        cardContentProps={{ className: this.props.classes.cardContent }}
                        ok={brokenLinksFail==null? null : brokenLinksFail.length===0}
                        iconComponent={LinkIcon}
                        style={STATUS_CARD_STYLE}
                        title={`Links ${brokenLinksFail != null ? `(${brokenLinksFail.length} fails)` : '' }`}
                        description={<Typography component="p">Reports from website broken links</Typography>}
                    />
                </FlexItem>
                <FlexItem>
                    <StatusCard
                        cardContentProps={{className: this.props.classes.cardContent }}
                        ok={pageSpeedTestFails==null? null : pageSpeedTestFails.length===0}
                        iconComponent={LighhouseIcon}
                        style={STATUS_CARD_STYLE}
                        title={`Page Scores ${pageSpeedTestFails != null ? `(${pageSpeedTestFails.length} fails)` : '' }`}
                        description={<Typography component="p">Scores for Accessibility, Best Practices, Performance, PWA, SEO.</Typography>}
                    />
                </FlexItem>
            </div>
        </React.Fragment>)
    }
}

export const CardsDashboard = withStyles(styles)(CardsDashboardUnstyled);
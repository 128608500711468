import axios from 'axios';
import { AuthenticatedUserData, AuthenticationService } from './types';
import { EventEmitter } from 'events';

declare const addEventListener: any;

export class DefaultAuthenticationService implements AuthenticationService {

    eventEmitter: EventEmitter;

    private unauthorizedHandler: (()=>void)|null = null;
    private forbiddenHandler: (()=>void)|null = null;
    private parsedAuthenticatedUserData: AuthenticatedUserData|null = null;
    private parsedAuthenticatedUserDataToken: string|null = null;

    constructor(eventEmitter: EventEmitter){
        
        this.eventEmitter = eventEmitter;

        console.log('401/403 Interceptor installed.')
        axios.interceptors.response.use((response) => { // intercept the global error
            return response
        }, (error: any) => {
            if(error.response){
                if (error.response.status === 401) {
                    if(this.unauthorizedHandler!=null){
                        this.unauthorizedHandler();
                    }
                    else{
                        this.signOut();
                    }
                }
                if (error.response.status === 403) {
                    if(this.forbiddenHandler!=null){
                        this.forbiddenHandler();
                    }
                    else{
                        this.signOut();
                    }
                }
            }
            return Promise.reject(error);
        });

        const tryRefresh = () => {
            if(document.hasFocus() && this.isAuthenticated()){
                this.refresh();
            }
        }

        addEventListener('focus', tryRefresh);
        setInterval(tryRefresh, 2*60*1000);
    }

    public setUnathorizedHandler(handler: (()=>void)|null): void{
        this.unauthorizedHandler = handler;
    }

    public setForbiddenHandler(handler: (()=>void)|null): void{
        this.forbiddenHandler = handler;
    }
    
    public async authenticate(credentials: {username: string, password: string}): Promise<boolean>{
        const result = await axios.post('/api/user/authenticate', credentials);
        if(result.data.success){
            this.signOut();
            document.cookie = `jwt=${result.data.token};path=/;`;
            this.eventEmitter.emit('authenticated', this.parseJwt(result.data.token));
            return true;
        }
        return false;
    }

    private async refresh(): Promise<void>{
        const result = await axios.post('/api/user/refresh');
        if(result.data.success){
            this.signOut();
            document.cookie = `jwt=${result.data.token};path=/;`;
        }
    }

    public isAuthenticated(): boolean{
        const cookie = this.getCookie('jwt');
        return cookie != null && cookie!='';
    }

    private getCookie(name: string) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return (parts.pop() as any).split(";").shift();
    }

    public getAuthenticatedUserData(): AuthenticatedUserData|null {
        const token = this.getCookie('jwt');
        if(token==null||token==='') return null;
        if(this.parsedAuthenticatedUserDataToken!=token){
            this.parsedAuthenticatedUserData = this.parseJwt(token);
            this.parsedAuthenticatedUserDataToken = token;
        }
        return this.parsedAuthenticatedUserData as any;
    }

    private parseJwt (token: string): AuthenticatedUserData {
        var base64Url = token.split('.')[1];
        var base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(base64);
    };

    public signOut(): void{
        document.cookie = 'jwt=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
    }
}
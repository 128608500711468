import * as React from 'react';
import { Paper } from '@material-ui/core';
import { MainPadding } from '../../components/MainPadding';
import { Breadcumb } from '../../components/Breadcumb';
import { CreateOrUpdateFormProps, CreateOrUpdateForm } from './CreateOrUpdateForm';
import { withRouter, RouteComponentProps } from 'react-router';

type Props = CreateOrUpdateFormProps & RouteComponentProps;

export const CreateOrUpdate = withRouter (class CreateOrUpdateDumb extends React.Component<Props>{
    render(){
        return (<MainPadding style={{maxWidth: '42em'}}>
            <Breadcumb items={[
                { label:"Quality Check Config", to:`/quality-check-configs/` },
                { label: "New Config" }
            ]} /><br />

            <Paper style={{padding:16}}>
                <CreateOrUpdateForm {...this.props} onSave={()=>this.props.history.push('/quality-check-configs/')} />
            </Paper>
        </MainPadding>);
    }
})
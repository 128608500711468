import axios from 'axios';

export class AgencyService{
    public async listAgencies(active: boolean): Promise<{agencies: Array<any>}>{
        const result = await axios.get(`/api/agencies?active=${active}`);
        return result.data;        
    }

    public async getAgency(identifier: string): Promise<any>{
        const result = await axios.get(`/api/agencies/${encodeURIComponent(identifier)}`);
        return result.data;
    }

    public async createAgency(agency: any): Promise<void>{
        await axios.post(`/api/agencies/create/`, agency);
    }

    public async updateAgency(agency: any): Promise<void>{
        await axios.post(`/api/agencies/${agency.identifier}/update/`, agency);
    }

    public async addUser(identifier: string, user: any): Promise<void>{
        await axios.post(`/api/agencies/${identifier}/users/add`, { ...user, identifier });
    }

    public async removeUser(identifier: string, user: any): Promise<void>{
        await axios.post(`/api/agencies/${identifier}/users/remove`, { ...user, identifier });
    }
}
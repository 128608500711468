import * as React from 'react';
import { serviceLocator } from '../../services';
import { RawDataForm } from './../../components/Websites/RawDataForm';

const service = serviceLocator.getWebsiteService();

export const PageFormRaw = (props: {websiteKey: string, collectionKey: string, pageKey: string}) => {
    return (<RawDataForm
        mode="json"
        getData={async ()=>{
            const data = await service.getPage(props.websiteKey, props.collectionKey, props.pageKey)
            return JSON.stringify(data,null, '  ');
        }}
        saveData={(data: any)=>service.updatePage(props.websiteKey, props.collectionKey, props.pageKey, JSON.parse(data))}
        build={()=>service.buildWebsite(props.websiteKey)}
    />)
}

export const JsonLDRaw = (props: {websiteKey: string, itemKey: string}) => {
    return (<RawDataForm
        mode="json"
        getData={async ()=>{
            const data = await service.getJsonLD(props.websiteKey, props.itemKey)
            return JSON.stringify(data,null, '  ');
        }}
        saveData={(data: any)=>service.updateJsonLD(props.websiteKey, props.itemKey, JSON.parse(data))}
        build={()=>service.buildWebsite(props.websiteKey)}
    />)
}

export const HomePageFormRaw = (props: {websiteKey: string }) => {
    return (<RawDataForm
        mode="json"
        getData={async ()=>{
            const data = await service.getHomePage(props.websiteKey)
            return JSON.stringify(data,null, '  ');
        }}
        saveData={(data: any)=>service.updateHomePage(props.websiteKey, JSON.parse(data))}
        build={()=>service.buildWebsite(props.websiteKey)}
    />)
}

export const MainFormRaw = (props: {websiteKey: string}) => {
    return (<RawDataForm
        mode="json"
        getData={async ()=>{
            const data = await service.getFormData(props.websiteKey, 'content');
            return JSON.stringify(data,null, '  ');
        }}
        saveData={async (data: any) => {await service.postFormData(props.websiteKey, 'content', JSON.parse(data));} }
        build={()=>service.buildWebsite(props.websiteKey)}
    />)
}
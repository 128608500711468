import * as React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { serviceLocator } from '../services';

const authenticationService = serviceLocator.getAuthenticationService();
const agencyService = serviceLocator.getAgencyService();
const uiService = serviceLocator.getUIService();

interface State {
    agencies: (string[]|null)
}

let agenciesPromise: Promise<string[]>|null = null;

export class AgencyPicker extends React.Component<any, State> {

    state: State = {
        agencies: null
    }

    handleChange = (e: any) =>{
        const value = e.target.value;
        uiService.setSelectedAgency(value==='all'?'':value);
    }

    componentDidMount = async () =>{
        if(this.state.agencies==null){
            const authData = authenticationService.getAuthenticatedUserData();
            if(authData!=null&&authData.roles!=null&&authData.roles.indexOf('admin')!==-1){
                if(agenciesPromise==null){
                    agenciesPromise = agencyService.listAgencies(true).then(({agencies}) => Promise.resolve(agencies.map(x=>x.identifier)));
                }
                const agencies = await agenciesPromise;
                this.setState({agencies});
            }
        }
    }

    render(){
        const authData = authenticationService.getAuthenticatedUserData();
        if(authData==null) return (null);
        const admin = authData.roles.indexOf('admin')!==-1;
        const agenciesArr: string[] = this.state.agencies || authData.agencies || [];
        
        const agencies: Array<{text:string, value:string}> = agenciesArr.map(x =>({text:x, value:x}));
        const agenciesExtended = [ { text:admin?'all':'any', value:'all' }, ...agencies ];

        let selectedAgency = uiService.getSelectedAgency() || 'all';
        if(agenciesArr.indexOf(selectedAgency)===-1){
            selectedAgency = 'all';
        }

        return (
            <FormControl style={{minWidth: '140px'}}>
                <InputLabel htmlFor="selected-agency">Selected Agency</InputLabel>
                <Select onChange={this.handleChange} title="Selected Agency" id="selected-agency" name="selected-agency" value={selectedAgency}>
                    { agenciesExtended.map(x => <MenuItem key={x.value} value={x.value}>{x.text}</MenuItem>) }
                </Select>
            </FormControl>
        );
    }
}
import * as React from 'react';
import { serviceLocator } from './../../services';
import { Websites as WebsitesInner } from './../../components/Websites';
import { Breadcumb } from '../../components/Breadcumb';

const service = serviceLocator.getWebsiteService();
const uiService = serviceLocator.getUIService();
const authenticationService = serviceLocator.getAuthenticationService();

export class Websites extends React.Component<{}, {}>{

    render() {
        const agency = uiService.getSelectedAgency();
        return (
            <WebsitesInner
                showAgencyTags={agency == null || agency === ''}
                agency={agency}
                themeKey="prime-lending"
                title={<Breadcumb items={[
                    { label: "Prime Lending Websites" },
                ]} />}
                buildWebsite={service.buildWebsite}
                syncWebsite={(key: string) => service.syncWebsiteBucket(key, true)}
                listWebsites={(agency: string, status: string) => service.listWebsites(agency, 'prime-lending', status)}
                listWebsitesExtended={(agency) => service.listWebsitesExtended(agency, 'prime-lending')}
                renameWebsite={service.renameWebsite}
                deleteWebsite={service.deleteWebsite}
                createNewWebsite={service.createNewWebsite}
                cloneWebsite={service.cloneWebsite}
            />
        );
    }
}
import * as React from 'react';
import { ComponentProps, CrawlContext, NormalizeStateContext } from '../HoForm';
import { BaseDynamic } from '../HoForm';
import { TextField } from '@material-ui/core';
import { Lens } from '@material-ui/icons';
import { setValidationErrorIntoState, getValidationError } from '../HoForm/utils';

type ColorTextFieldDynamicField = {
    key: string, 
    type: string,
    default?: string,
    multiline?: boolean,
    tip?: string,
    title?: string,
    required?: boolean
}

const INPUT_LABEL_PROPS = { shrink: true };

type ColorTextFieldDynamicState = {
    
}

export class ColorTextFieldDynamic extends BaseDynamic<ColorTextFieldDynamicField,ColorTextFieldDynamicState> {

    constructor(props: ComponentProps<ColorTextFieldDynamicField>){
        super(props);
    }

    normalizeState({state, field}: NormalizeStateContext<ColorTextFieldDynamicField>){
        let key = field.key;
        if(state[key]==null){
            state[key] = field.default || '';
        }
    }

    getType(){
        return 'color-text-field';
    }

    handleChange = (e: any)=>{
        this.forceUpdate();
        this.props.context.setValue(e.target.value, 250);
    }

    crawlComponent({form, node} : CrawlContext<ColorTextFieldDynamicField>): void{
        const value = this.getValueFromNode(node);
        if(node.field.required){
            let validationError = '';
            if(node.field.required){
                const invalid = value==null||value==='';
                validationError += invalid?`The field is required.`:'';
            }
            setValidationErrorIntoState(node.state, form.buildDisplayPath(node), validationError);
        }        
    }

    renderComponent(){
        
        let {context} = this.props;
        let {node, nodePath, currentPath, parentPath} = context;
        let {field} = node;
                
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }
        
        let iconButtons = [];
        iconButtons.push(<Lens key="color" style={{ filter: 'drop-shadow( 0px 1px 2px rgba(0, 0, 0, .3))', fill: context.value }} />)
        
        const error = getValidationError(node.state, nodePath);

        return (
            <TextField
                id={`text-field-${field.key}`}
                onChange={ this.handleChange }
                error={ error!=null }
                value={context.value}
                fullWidth={true}
                InputLabelProps={INPUT_LABEL_PROPS}
                margin="dense"
                label={field.title}
                helperText={([ ...error?[error]:[], ...field.tip?[field.tip]:[] ]).join(' ')}
                InputProps={{
                    endAdornment: iconButtons
                }}
            />
        );
    }
}
import * as React from 'react';
import { Button, Paper, Typography, Avatar } from '@material-ui/core';
import { serviceLocator } from '../../services';
import { MainPadding } from '../../components/MainPadding';
import { withStyles } from '@material-ui/core/styles';
import { AppSection } from '../../components/AppSection';
import SaveIcon from '@material-ui/icons/Save';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import axios from 'axios';

const authenticationService = serviceLocator.getAuthenticationService();

export interface AgencyGeneralState {
  selectedFile: any;
  file: any;
}

interface AgencyGeneralProps extends WithSnackbarProps {
  classes: any;
  selectedAgency: string;
}

const styles = (theme: any) => ({
  imgPreview: {
    width: "100%",
    height: "100%",
    maxWidth: "300px",
    maxHeight: "300px",
    // objectFit: "cover",
  },
  buttonsWrapper: {
    display: "flex",
    // justifyContent: "space-between"
  }
});

const noImage ="https://trusteid.mioa.gov.mk/wp-content/plugins/uix-page-builder/uixpb_templates/images/UixPageBuilderTmpl/no-logo.png";

export class AgencyGeneralUnstyled extends React.Component<AgencyGeneralProps, AgencyGeneralState>{

  constructor(props: AgencyGeneralProps) {
    super(props);
    this.state = {
      selectedFile: noImage,
      file: null
    };
  }

  componentDidMount() {
    this.getLogo()
  }

  getLogo = async () => {
    const authUserData = authenticationService.getAuthenticatedUserData();
    const response = await axios.get(`/api/agencies/${this.props.selectedAgency}/logo/`);
    console.log('response', response)
    if(response.data?.success == false) {
      this.setState({
        selectedFile: noImage
      });
    } else {
      this.setState({
        selectedFile: "data:image\/png;base64, " + response.data
      });
    }
  }

  handleUploadClick = (event: any) => {
    console.log();
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    const that = this;
    reader.onloadend = function (e: any) {
      that.setState({
        selectedFile: [reader.result]
      });
    }.bind(this);
    console.log(url); // Would see a path?

    this.setState({
      selectedFile: event.target.files[0],
      file
    });
  };

  saveLogo = async () => {
    const authUserData = authenticationService.getAuthenticatedUserData();
    var file = this.state.file;
    const formData = new FormData();
    formData.append("logo", file);
    const response = await axios.post(`/api/agencies/${this.props.selectedAgency}/logo/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    console.log('response', response)
  };

  public render() {
    const authUserData = authenticationService.getAuthenticatedUserData();

    return (
      <MainPadding>
        <AppSection section="themePanel" />
        <Typography variant="h6">Agency General Settings</Typography><br />
        <Paper style={{ padding: "25px", paddingTop: "10px" }}>
          <p style={{ fontSize: "16px", fontWeight: "500" }}>
            Agency Logo
          </p>
          <div style={{ marginBottom: "30px" }}>

            {/* <Avatar alt={this.props.selectedAgency} src="/static/images/avatar/1.jpg" /> */}
            <img src={this.state.selectedFile} alt={this.props.selectedAgency} className={this.props.classes.imgPreview} />

            <div className={this.props.classes.buttonsWrapper}>
              <div>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  onChange={this.handleUploadClick}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="secondary" component="span">
                    Select File
                  </Button>
                </label>
              </div>

              <Button onClick={this.saveLogo} variant="contained" color="primary" style={{ marginLeft: "100px" }}>
                Save
              </Button>
            </div>
          </div>
        </Paper>
      </MainPadding>
    );
  }
}

export const AgencyGeneral = withSnackbar(withStyles(styles)(AgencyGeneralUnstyled));
export class Debounce{
  
    private timeout: any;
    private duration: number;
    private action: any;

    constructor(duration: number | null){
        this.duration = duration!=null?duration:300;
    }

    public run(action: ()=>void){
        this.action = action;
        this.cancelNext();
        this.timeout = setTimeout(action, this.duration);
    }

    public runNow(){
        if(this.action!=null) this.action();
        this.cancelNext();
    }

    public cancelNext(){
        if(this.timeout!=null){
            clearTimeout(this.timeout);
        }
    }
}
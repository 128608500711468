import * as React from 'react';
import { FormikTextField } from './FormikTextField';
import { Menu, MenuItem, ListItemText } from '@material-ui/core';

export class FormikSuggestionTextField extends React.Component<any,any>{

    state: {focused: boolean} = { focused: false };
    paperProps: any = {style:{maxHeight:'260px', overflowY:'scroll', marginTop:'30px'}};
    divRefEl: any;

    divRef = (ref: any) =>{
        this.divRefEl = ref;
    }

    handleSuggestionClick = (e: any) => {
        this.props.form.setFieldValue(this.props.field.name, e.currentTarget.dataset.value, true);
        this.forceUpdate();
    }

    public render(){
        const {
            field,
            onFocus,
            onChanged,
            suggestions,
            ...rest
        } = this.props;
        
        return (<React.Fragment>
            <FormikTextField
            field={{
                ...field,
                onChange:(e:any)=>{
                    if(onChanged){
                        onChanged(e);
                    }
                    field.onChange(e);
                },
                onBlur: (e: any)=>{
                    this.setState({focused:false});
                    field.onBlur(e);
                },
            }}
            onFocus={(e: any)=>{
                this.setState({focused:true});
                if(onFocus) onFocus(e);
            }}
            {...rest}
            />
            <div ref={this.divRef}></div>
            <Menu
                open={this.state.focused===true&&(this.props.suggestions||[]).length>0}
                anchorEl={this.divRefEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                disableAutoFocus={true}
                disableRestoreFocus={true}
                disableAutoFocusItem={true}
                disableEnforceFocus={true}
                autoFocus={false}
                PaperProps={this.paperProps}
            >
                { (suggestions||[]).map((s: any) =>(
                    <MenuItem key={s.value} data-value={s.value} onClick={this.handleSuggestionClick}>
                        <ListItemText primary={s.label||s.value} secondary={s.label?s.value:""} />
                    </MenuItem>
                )) }
            </Menu>
            </React.Fragment>
        )
    }
};
import * as React from 'react';
import { BaseDynamic, FieldBase, ComponentProps, CrawlContext } from '../_ho-forms-lib/src/HoForm';
import { TextField, InputAdornment, Grow } from '@material-ui/core';
import { PreviewThumbnail } from '../components/PreviewThumbnail';
import { setValidationErrorIntoState, getValidationError } from '../_ho-forms-lib/src/HoForm/utils';

interface FileUploadDynamicField extends FieldBase{
    title: string;
    tip?: string;
    required?: boolean;
}

interface YoutubePickDynamicState{

}

const INPUT_LABEL_PROPS = { shrink: true };

export class YoutubePickDynamic extends BaseDynamic<FileUploadDynamicField, YoutubePickDynamicState>{
    
    constructor(props: ComponentProps<FileUploadDynamicField>){
        super(props);
    }

    normalizeState({state, field}: {state: any, field: FileUploadDynamicField}){
        let key = field.key;
        if(state[key]==null){
            state[key] = field.default || '';
        }
    }

    getType(){
        return 'youtube-pick';
    }

    handleChange = (e: any)=>{
        this.forceUpdate();
        const cleanValue = e.target.value.replace(/^.+[?&]v=(.+)($|%).*/,'$1') || e.target.value;
        this.props.context.setValue(cleanValue, 250);
    }

    crawlComponent({form, node} : CrawlContext<FileUploadDynamicField>): void{
        if(node.field.required){
            const value = this.getValueFromNode(node);
            setValidationErrorIntoState(node.state, form.buildDisplayPath(node), !value?`The field is required.`:'');
        }
    }

    handlePreviewClick = (e: any) =>{
        e.preventDefault();
        if(this.context.value)
            this.setState({ previewVisible: true, anchor: e.currentTarget });
    };

    renderComponent(){
        
        let {context} = this.props;
        let {node, nodePath, currentPath, parentPath} = context;
        let {field} = node;
                
        if(!parentPath.startsWith(currentPath)){
            return (null);
        }
        
        const error = getValidationError(node.state, nodePath);

        return (
            <TextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Grow appear={true} in={true}><PreviewThumbnail
                                component="a"
                                href={`https://www.youtube.com/watch?v=${context.value}`}
                                target="_blank"
                                url={`https://img.youtube.com/vi/${context.value}/1.jpg`} />
                            </Grow>
                        </InputAdornment>
                    )
                }}
                id={`text-field-${field.key}`}
                onChange={ this.handleChange }
                error={ !!error }
                value={context.value}
                fullWidth={true}
                margin="dense"
                InputLabelProps={INPUT_LABEL_PROPS}
                label={field.title}
                helperText={([ ...error?[error]:[], ...field.tip?[field.tip]:[] ]).join(' ')}
            />
        );
    }
}
import * as React from 'react';
import { CreateOrUpdate } from './CreateOrUpdate';
import { serviceLocator } from '../../services';

interface Props {
    qualityCheckConfigId?: string;
    website?: string;
    agency: string;
}

interface State {
    config: any;
    parentConfig: any;
}

export class Update extends React.Component<Props,State>{

    state: State = {
        config: null,
        parentConfig: null
    }

    componentDidMount = async () =>{
        if(this.props.qualityCheckConfigId!=null){
            const config = (await serviceLocator.getQualityCheckService().getConfigById(this.props.qualityCheckConfigId));
            this.setState({config});
        }
        if(this.props.website!=null){
        }        
    }

    render(){
        if(this.state.config==null){
            return (null);
        }
        else{
            return <CreateOrUpdate agency={this.props.agency} config={this.state.config} parentConfig={this.state.parentConfig} />
        }
    }
}
import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, FormControl, Button } from '@material-ui/core';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { FormikTextField } from '../../FormikTextField';

interface Props {
    onAddOverrideDomain: (data: any)=>Promise<void>;
    open: boolean;
    onClose: any;
    domain: string;
    domainOverride?: string;
}

export class OverrideDomain extends React.PureComponent<Props> {

    state : { options: Array<{ label: string, value: string }> } = {
        options: []
    };

    private handleOverrideSubmit = async (values: any, { setSubmitting }: { setSubmitting: (value: boolean)=>void })=>{
        try{
            await this.props.onAddOverrideDomain(values);
            setSubmitting(false);
        }
        catch(e){
            throw e;
            setSubmitting(false);
        }
    }

    public render(){
        const { onAddOverrideDomain, open, domain, domainOverride, onClose } = this.props;
        let defaultDomain = domainOverride ? domainOverride : domain;
        return (
            <Dialog
                open={open} 
                onClose={onClose}
            >
                <DialogTitle id="form-dialog-title">Override Domain</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{ domainOverride: defaultDomain }}
                        onSubmit={this.handleOverrideSubmit}
                        validationSchema={Yup.object().shape({
                            domainOverride: Yup.string().matches(/\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/,'Invalid domain').required('Required')
                        })}
                    >
                    {props => {
                        const { isSubmitting, handleSubmit } = props;
                        return (<form onSubmit={handleSubmit}>
                            <FastField label="Override Domain" value={defaultDomain} name="domainOverride" component={FormikTextField} helperText="Override domain (domain.com or www.domain.com)" />
                            <FormControl margin={'normal'} fullWidth={true}>
                                <Button type="submit" disabled={isSubmitting} variant="contained" color="primary" fullWidth={true}>Submit</Button>
                            </FormControl>
                        </form>)
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}
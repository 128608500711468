import * as React from 'react';
import { WebsiteList } from '../WebsiteList';
import { IconButton, Menu, ListItem, ListItemText, ListItemSecondaryAction, Typography, Button, Dialog, DialogContent, DialogActions, DialogTitle, TextField, Chip } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Link } from "react-router-dom"
import { BatchBuildDialog } from './BatchBuildDialog';
import { MainPadding } from '../MainPadding';
import { RoleSection } from '../RoleSection';
import { AdminOrAgencyMemberSection } from '../AdminOrAgencyMemberSection';
import { AdminOrAgencyownerOrAgentSection } from '../AdminOrAgencyownerOrAgentSection';
import { RenameSiteDialog } from './RenameSiteDialog';
import { CreateSiteDialog } from './NewSiteDialog';
import { CloneSiteDialog } from './CloneSiteDialog';
import { InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';
import { serviceLocator } from '../../services';

const genericWebsiteService = serviceLocator.getWebsiteService();

declare const confirm: any;

interface WebsitesProps {
    title: React.ReactNode;
    agency: string;
    showAgencyTags: boolean;
    themeKey: string;
    buildWebsite: (key: string) => (Promise<{ error?: any }>);
    syncWebsite: (key: string) => Promise<void>;
    listWebsites: (agency: string, status: string) => Promise<Array<any>>;
    listWebsitesExtended: (agency: string) => Promise<Array<any>>;
    createNewWebsite: (key: string, agency: string, preConfigured: string, template: string, embeded: boolean) => Promise<void>;
    renameWebsite: (key: string, newKey: string) => Promise<void>;
    deleteWebsite: (key: string) => Promise<void>;
    cloneWebsite: (key: string, newKey: string) => Promise<void>;
    setWebsiteAsPreConfigured?: (key: string) => Promise<void>;
    listPreConfigureds?: () => Promise<any>;
}

interface WebsiteState {
    menuAnchor: any;
    menuOpen: boolean;
    menuSiteKey: string;
    websites: Array<{ key: string, globalKey: string, agency: string | null, status: string | null, preConfigured: boolean }>;
    openDialog: null | 'new' | 'batch',
    batchOperation: 'sync' | 'build',
    renameDialogKey: string | null,
    cloneDialogKey: string | null,
    websiteStatus: string,
}

export const SITE_NAME_HELPER = 'Sample: some-site';
export const VALID_SITE_NAME = /^[a-z0-9-]+$/;

export class Websites extends React.Component<WebsitesProps, WebsiteState>{

    constructor(props: any) {
        super(props);
        this.state = {
            batchOperation: 'build',
            menuAnchor: null,
            menuOpen: false,
            menuSiteKey: '',
            websites: [],
            openDialog: null,
            renameDialogKey: null,
            cloneDialogKey: null,
            websiteStatus: 'active'
        };
    }

    public componentDidMount() {
        this.loadSites('active');
    }

    loadSites = async (websiteStatus?: string) => {
        if (websiteStatus == undefined) {
            // websiteStatus = "all";
            websiteStatus = this.state.websiteStatus;
        }

        const websites = await this.props.listWebsites(this.props.agency, websiteStatus)
        this.setState({ websites });
    }

    handleStatusChange = async (event: any) => {
        console.log('handleStatusChange', event.target.value)
        this.setState({ websiteStatus: event.target.value });
        this.loadSites(event.target.value);
    };

    public render() {

        const { openDialog } = this.state;
        const selectedSite = (this.state.websites || []).find(x => x.key === this.state.menuSiteKey);

        // NEW SITE DIALOG
        const newSiteDialog = (<CreateSiteDialog
            onClose={this.handleDialogClose}
            onExited={this.restoreScroll}
            open={this.state.openDialog === 'new'}
            agency={this.props.agency}
            listPreConfigureds={this.props.listPreConfigureds}
            createWebsite={async (siteKey, preConfigured, embeded) => {
                await this.props.createNewWebsite(siteKey, this.props.agency, preConfigured, this.props.themeKey, embeded);
                this.setState({ openDialog: null });
                this.loadSites();
            }}
        />);

        // RENAME SITE DIALOG
        const renameSiteDialog = (<RenameSiteDialog
            onClose={this.handleRenameDialogClose}
            onExited={this.restoreScroll}
            open={this.state.renameDialogKey != null}
            renameDialogKey={this.state.renameDialogKey}
            renameWebsite={async (siteKey, newSiteKey) => {
                await this.props.renameWebsite(siteKey, newSiteKey);
                this.setState({ renameDialogKey: null });
                this.loadSites();
            }}
        />);

        // CLONE SITE DIALOG
        const cloneSiteDialog = (<CloneSiteDialog
            onClose={this.handleRenameDialogClose}
            onExited={this.restoreScroll}
            open={this.state.cloneDialogKey != null}
            cloneDialogKey={this.state.cloneDialogKey}
            cloneWebsite={async (siteKey, newSiteKey) => {
                await this.props.cloneWebsite(siteKey, newSiteKey);
                this.setState({ cloneDialogKey: null });
                this.loadSites();
            }}
        />);

        // LIST + DIALOGS
        return (
            <MainPadding>
                {(typeof this.props.title === 'string') ? <Typography variant="h6">{this.props.title}</Typography> : this.props.title}
                <br />

                <div style={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <AdminOrAgencyownerOrAgentSection>
                            <Button variant="contained" color="primary" onClick={this.handleNewClick}>New</Button>
                        </AdminOrAgencyownerOrAgentSection>
                        <RoleSection role="admin">
                            &nbsp; <Button variant="text" size="small" color="default" onClick={this.handleBatchBuildClick}>Batch Build</Button>
                            &nbsp; <Button variant="text" size="small" color="default" onClick={this.handleBatchSyncClick}>Batch Sync</Button>
                        </RoleSection>
                    </div>
                    <div>
                        <FormControl variant='outlined' style={{ minWidth: '120px', background: 'white' }}>
                            <InputLabel id="demo-simple-select-helper-label">Filter Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.websiteStatus}
                                label="Filter Status"
                                onChange={this.handleStatusChange}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={'active'}>Active</MenuItem>
                                <MenuItem value={'archived'}>Archived</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {newSiteDialog}
                {renameSiteDialog}
                {cloneSiteDialog}
                <BatchBuildDialog
                    syncWebsite={this.props.syncWebsite}
                    listWebsitesExtended={() => this.props.listWebsitesExtended(this.props.agency)}
                    buildWebsite={this.props.buildWebsite}
                    onClose={this.handleDialogClose}
                    open={openDialog === 'batch'}
                    operation={this.state.batchOperation} />

                <WebsiteList items={
                    this.state.websites.map((website, i) => {
                        const { key } = website;
                        return (
                            <ListItem key={key}
                                button={true}
                                component={this.getLink(key)}
                                divider={i < this.state.websites.length - 1}>
                                <ListItemText primary={<span>
                                    {key}
                                    {this.props.showAgencyTags && (<Chip style={{ marginLeft: "4px" }} size="small" label={website.agency || 'global'} />)}
                                    {website.preConfigured && (<Chip style={{ marginLeft: "4px" }} size="small" color="secondary" label="template" />)}
                                </span>} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={this.handleMoreClick} data-key={key}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })
                } />
                <Menu
                    open={this.state.menuOpen}
                    anchorEl={this.state.menuAnchor}
                    onClose={this.handleMenuClose}
                >
                    <MenuItem component={this.currentMenuLink} >Open</MenuItem>
                    <MenuItem component="a" onClick={this.hideMenu} href={`http://${(selectedSite != null ? selectedSite.globalKey : '').replace(/[.]com\/?$/, '')}.hugosites.webdrvn.com`} target="_blank">Preview</MenuItem>
                    <RoleSection role="admin">
                        {/* <MenuItem onClick={this.handleRenameCurrent}>Rename</MenuItem> */}
                        <MenuItem onClick={this.handleCloneCurrent}>Clone</MenuItem>
                        <MenuItem component={this.currentMenuDeployLink}>Deploy</MenuItem>
                        {
                            selectedSite?.status == "archived" ?
                            <MenuItem onClick={this.handleDeleteCurrent}>Delete</MenuItem>
                            :
                            <MenuItem onClick={this.handleArchiveCurrent}>Archive</MenuItem>
                        }
                        {this.props.setWebsiteAsPreConfigured && (<MenuItem onClick={this.handleSetAsPreConfigured}>Set as Pre-Configured</MenuItem>)}
                    </RoleSection>
                </Menu>
            </MainPadding>
        )
    }

    private handleBatchBuildClick = (e: any) => {
        this.setState({ openDialog: 'batch', batchOperation: 'build' });
    }

    private handleBatchSyncClick = (e: any) => {
        this.setState({ openDialog: 'batch', batchOperation: 'sync' });
    }

    private handleNewClick = (e: any) => {
        this.setState({ openDialog: 'new' });
    }

    private handleDialogClose = (e: any) => {
        this.setState({ openDialog: null });
    }

    private handleRenameCurrent = (e: any) => {
        this.setState({ renameDialogKey: this.state.menuSiteKey, menuOpen: false });
    }

    private handleCloneCurrent = (e: any) => {
        this.setState({ cloneDialogKey: this.state.menuSiteKey, menuOpen: false });
    }

    private handleDeleteCurrent = async (e: any) => {
        this.setState({ menuOpen: false });
        if (confirm('Are you sure you want to delete this website? This operation is only reversible by a sys-admin.')) {
            await this.props.deleteWebsite(this.state.menuSiteKey);
            await this.loadSites(this.state.websiteStatus);
        }
    }

    private handleArchiveCurrent = async (e: any) => {
        this.setState({ menuOpen: false });

        if (confirm('Are you sure to change status to Archived for this site?')) {
            await genericWebsiteService.putStatus(this.state.menuSiteKey, "archived");
            await this.loadSites(this.state.websiteStatus);
        }
    }

    private handleSetAsPreConfigured = async (e: any) => {
        this.setState({ menuOpen: false });
        if (confirm('Are you sure you want to mark this site as a Pre-Configured? This will enable site administrators to select it as a starting point to any new website.')) {
            if (this.props.setWebsiteAsPreConfigured != null) {
                await this.props.setWebsiteAsPreConfigured(this.state.menuSiteKey);
            }
            await this.loadSites(this.state.websiteStatus);
        }
    }

    private handleRenameDialogClose = (e: any) => {
        this.setState({ renameDialogKey: null });
    }

    private restoreScroll() {
        // fix
        setTimeout(() => {
            document.body.style.overflow = "";
        }, 100);
    }

    private hideMenu = () => {
        this.setState({ menuOpen: false });
    }

    private handleMoreClick = (e: any) => {
        const target = e.currentTarget;
        this.setState({ menuOpen: true, menuAnchor: target, menuSiteKey: target.dataset.key });
    }

    private handleMenuClose = (e: any) => {
        this.setState({ menuOpen: false });
    }

    private getLink = (site: string) => {
        return React.forwardRef((props: any, ref: any) => (<Link ref={ref} to={`/websites/${this.props.themeKey}/${site}/`} {...props} />));
    }

    private currentMenuLink = (props: any) => {
        return <Link to={`/websites/${this.props.themeKey}/${this.state.menuSiteKey}/`} {...props} />;
    }

    private currentMenuDeployLink = (props: any) => {
        return <Link to={`/websites/${this.props.themeKey}/${this.state.menuSiteKey}/deploy/`} {...props} />;
    }
}
import * as React from 'react';
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import FileManager, { FileManagerProps } from "./FileManager";
import { DialogProps } from '@material-ui/core/Dialog';

export class FileManagerDialog extends React.Component<FileManagerProps & DialogProps & { assets: boolean }, {}>{
  render() {

    const { getItems, deleteItem, uploadSingleFile, createFolder, onPick, getStaticUrl, assets, renamePath, transformImage, ...dialogProps } = this.props;

    return (<Dialog {...dialogProps} maxWidth="lg">
      <DialogTitle id="file-manager-dialog">Website {assets? "Assets": "Static Files"} Manager</DialogTitle>
      <DialogContent>
        <FileManager
          transformImage={transformImage}
          renamePath={renamePath}
          getItems={getItems}
          deleteItem={deleteItem}
          uploadSingleFile={uploadSingleFile}
          createFolder={createFolder}
          onPick={onPick}
          getStaticUrl={getStaticUrl}
        />
      </DialogContent>
    </Dialog>);
  }
}
import * as React from 'react';
import { List, Paper } from '@material-ui/core';

interface WebsiteListProps {
    items: any[];
}

export class WebsiteList extends React.Component<WebsiteListProps>{
    public render(){
        return (
        <Paper>
            <List component="nav">
                { this.props.items }
            </List>
        </Paper>
        );
    }
}
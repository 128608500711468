import * as React from 'react';
import { Route, Switch, matchPath } from "react-router-dom"
import { Websites } from './Websites';
import { MainForm } from './MainForm';
import { Deploy } from './Deploy';
import { PageForm, HomePageForm } from './PageForm';
import { Website } from './Website';
import { NotFound } from '../NotFound';
import { JsonLDForm } from './JsonLDForm';
import { AppSection } from '../../components/AppSection';
import { PageFormRaw, MainFormRaw, HomePageFormRaw, JsonLDRaw } from './PageFormRaw';
import { StaticFileStringEditor } from '../../components/Websites/StaticFileStringEditor';
 
export class SkeletonBootstrap extends React.Component<any,any>{

    public render(){
        
        return (<React.Fragment>
            <AppSection section="websites/skeleton-bootstrap-5-2" />
            <Switch>
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/deploy/"} exact={true} render={this.renderDeploy} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/main-form/raw"} exact={true} render={this.renderMainFormRaw} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/main-form/"} exact={true} render={this.renderMainForm} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/home/"} exact={true} render={this.renderHomePage} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/home/raw"} exact={true} render={this.renderHomePageRaw} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/collection/:collection/:page/raw"} exact={true} render={this.renderPageRaw} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/static-file-editor/*"} exact={false} render={this.renderStaticFileEditor} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/assets-file-editor/*"} exact={false} render={this.renderAssetsFileEditor} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/collection/:collection/:page/"} exact={true} render={this.renderPage} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/jsonld/:item"} exact={true} render={this.renderJsonLD} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/jsonld/:item/raw"} exact={true} render={this.renderJsonLDRaw} />
                <Route path={"/websites/skeleton-bootstrap-5-2/:site/"} exact={true} render={this.renderWebsite} />
                <Route path={"/websites/skeleton-bootstrap-5-2/"} exact={true} component={Websites} />
                <Route path={"/websites/skeleton-bootstrap-5-2/*"} component={NotFound} />
            </Switch>
        </React.Fragment>)
    }

    private renderPage = ({match}: any)=>{
        return (<PageForm websiteKey={match.params.site} collectionKey={match.params.collection} pageKey={match.params.page} />);
    }

    private renderHomePage = ({match}: any)=>{
        return (<HomePageForm websiteKey={match.params.site} />);
    }

    private renderHomePageRaw = ({match}: any)=>{
        return (<HomePageFormRaw websiteKey={match.params.site} />);
    }

    private renderStaticFileEditor = ({match}: any)=>{
        const path = match.url.split('/static-file-editor/').slice(-1)[0]
        return (<StaticFileStringEditor websiteKey={match.params.site} path={path} assets={false} />);
    }

    private renderAssetsFileEditor = ({match}: any)=>{
        const path = match.url.split('/assets-file-editor/').slice(-1)[0]
        return (<StaticFileStringEditor websiteKey={match.params.site} path={path} assets={true} />);
    }

    private renderPageRaw = ({match}: any)=>{
        return (<PageFormRaw websiteKey={match.params.site} collectionKey={match.params.collection} pageKey={match.params.page} />);
    }

    private renderWebsite = ({match}: any)=>{
        return (<Website websiteKey={match.params.site} />);
    }

    private renderJsonLD = ({match}: any)=>{
        return (<JsonLDForm websiteKey={match.params.site} itemKey={match.params.item} />);
    }

    private renderJsonLDRaw = ({match}: any)=>{
        return (<JsonLDRaw websiteKey={match.params.site} itemKey={match.params.item} />);
    }

    private renderMainForm = ({match}: any)=>{
        return (<MainForm websiteKey={match.params.site} />);
    }

    private renderMainFormRaw = ({match}: any)=>{
        return (<MainFormRaw websiteKey={match.params.site} />);
    }

    private renderDeploy = ({match}: any)=>{
        return (<Deploy websiteKey={match.params.site} />);
    }
}
import * as React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { serviceLocator } from '../../services';
import { MainPadding } from '../../components/MainPadding';
import { withStyles } from '@material-ui/core/styles';
import { AppSection } from '../../components/AppSection';
import SaveIcon from '@material-ui/icons/Save';
import _AceEditor from "react-ace";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import "ace-builds/src-noconflict/theme-monokai";

const AceEditor = _AceEditor as any;

declare const ace: any;

const themeService = serviceLocator.getThemeService();
const authenticationService = serviceLocator.getAuthenticationService();

export interface FooterState {
  block: "footer" | "header";
  html: string;
  style: string;
  script: string;
}
interface FooterProps extends WithSnackbarProps {
  selectedAgency: string;
}

const styles = (theme: any) => ({
});

export class FooterUnstyled extends React.Component<FooterProps, FooterState>{

  constructor(props: FooterProps) {
    super(props);
    this.state = {
      block: "footer",
      html: "",
      style: "",
      script: ""
    };

    ace.config.set('basePath', '/ace-builds/src-noconflict');
    ace.config.set('modePath', '/ace-builds/src-noconflict');
    ace.config.set('themePath', '/ace-builds/src-noconflict');

  }

  componentDidMount() {
    this.getFooterCods();
  }

  getFooterCods = async () => {
    const authUserData = authenticationService.getAuthenticatedUserData();
    
    if (this.props.selectedAgency) {
      const footer = await themeService.getThemeBlock(this.props.selectedAgency);
      console.log('footer', footer)
      this.setState({
        html: footer.html,
        style: footer.style,
        script: footer.script
      })
    }
  }

  handleHtmlChange = (value: string) => {
    this.setState({
      html: value
    })
  }

  handleStyleChange = (value: string) => {
    this.setState({
      style: value
    })
  }

  handleScriptChange = (value: string) => {
    this.setState({
      script: value
    })
  }

  saveChanges = () => {
    const authUserData = authenticationService.getAuthenticatedUserData();
    const params = {
      block: "footer",
      html: this.state.html,
      style: this.state.style,
      script: this.state.script,
      agency: this.props.selectedAgency
    }

    themeService.createOrUpdateTheme(params).then((res: any) => {
      console.log('res', res)
      this.props.enqueueSnackbar("Changes are stored successfully!");
    }).catch((err: any) => {
      this.props.enqueueSnackbar("Failed to save.");
    })
  }

  public render() {
    return (
      <MainPadding>
        <AppSection section="themePanel" />
        <Typography variant="h6">Website Footer</Typography><br />
        <Paper style={{ padding: "25px" }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button size="small" color='primary' startIcon={<SaveIcon />} variant='contained' onClick={this.saveChanges}>Save changes</Button>
          </div>

          <div style={{ marginBottom: "30px" }}>
            <Typography color="textSecondary" display="block" variant="caption" style={{ marginBottom: '4px' }}>HTML</Typography>
            <div style={{ border: 'solid 1px #eee' }}>
              <AceEditor
                mode={'html'}
                theme="monokai"
                tabSize={2}
                ref="reactAceComponent"
                showPrintMargin={false}
                maxLines={60}
                minLines={5}
                width="100%"
                fontSize={15}
                showGutter={true}
                onChange={this.handleHtmlChange}
                value={this.state.html}
                name={`code-name-html`}
                editorProps={{ $blockScrolling: true }}
                placeholder="HTML code here"
              />
            </div>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Typography color="textSecondary" display="block" variant="caption" style={{ marginBottom: '4px' }}>Stylesheet</Typography>
            <div style={{ border: 'solid 1px #eee' }}>
              <AceEditor
                mode={'css'}
                theme="monokai"
                tabSize={2}
                ref="reactAceComponent"
                showPrintMargin={false}
                maxLines={60}
                minLines={5}
                width="100%"
                fontSize={15}
                showGutter={true}
                onChange={this.handleStyleChange}
                value={this.state.style}
                name={`code-name-css`}
                editorProps={{ $blockScrolling: true }}
                placeholder="CSS style code here"
              />
            </div>
          </div>
          <div>
            <Typography color="textSecondary" display="block" variant="caption" style={{ marginBottom: '4px' }}>JavaScript</Typography>
            <div style={{ border: 'solid 1px #eee' }}>
              <AceEditor
                mode={'javascript'}
                theme="monokai"
                tabSize={2}
                ref="reactAceComponent"
                showPrintMargin={false}
                maxLines={60}
                minLines={5}
                width="100%"
                fontSize={15}
                showGutter={true}
                onChange={this.handleScriptChange}
                value={this.state.script}
                name={`code-name-js`}
                editorProps={{ $blockScrolling: true }}
                placeholder="JavaScript code here"
              />
            </div>
          </div>
        </Paper>

      </MainPadding>
    );
  }
}

export const Footer = withSnackbar(withStyles(styles)(FooterUnstyled));
import * as React from 'react';
import { pth } from "./../utils/pth";
import { TextField, InputAdornment, IconButton, FormControl, Typography, FormHelperText } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';

export class FormikFileUpload extends React.Component<any,any>{

    public state = { uploading: false };

    public render(){

        const {
            field, // { name, value, onChange, onBlur }
            form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            ...fProps
        } = this.props;
        
        const value = pth(field.name, values);
        const isTouched = pth(field.name,touched);
        const error = pth(field.name,errors);

        // if(error){
        //     console.log(field, error, isTouched);
        // }

        const { label, uploadFile, leftIconButtons, endAdornment, helperText, ...fPropsRest } = fProps;

        if(this.state.uploading){
            return (
                <TextField
                    margin="dense"
                    label={label}
                    fullWidth={true}
                    value={'Uploading...'}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={true}><ClearIcon fontSize="small" /></IconButton>
                        </InputAdornment>
                        ),
                    }}
                />
            ); 
        }

        if(value){
            
            // readonly

            delete field.onChange;

            return (
                <TextField
                    {...field}
                    {...fPropsRest}
                    margin="dense"
                    label={label}
                    fullWidth={true}
                    helperText={isTouched ? `${fProps.helperText||''} ${error||''}` : helperText}
                    error={isTouched ? (error!=null) : false}
                    value={value}
                    InputProps={{
                        readOnly: true,
                        startAdornment: (
                        <InputAdornment position="start">
                            <IconButton onClick={(e:any)=> setFieldValue(field.name, '')}><ClearIcon fontSize="small" /></IconButton>
                            { leftIconButtons }
                        </InputAdornment>
                        ),
                        endAdornment
                    }}
                    inputProps={{disabled:false}}
                />
            ); 
        }
        else{
            
            const handleChange = (e: any) =>{
                this.setState({uploading: true});
                uploadFile(e).then((x: string) =>{
                    this.setState({uploading: false});
                    setFieldValue(field.name, x);
                }, (uploadError: any)=>{
                    this.setState({uploading: false});
                });
            }
            return (
            <FormControl
                fullWidth={true}
                margin="dense"
            >
                <Typography variant="caption" color="textSecondary">{label}</Typography>
                <input
                    type="file"
                    {...field}
                    {...fPropsRest}
                    onChange={handleChange}
                />
                { (error!=null || helperText!=null) && <FormHelperText error={isTouched ? (error!=null) : false}>{`${fProps.helperText||''} ${error||''}`.trim()}</FormHelperText> }
            </FormControl>
            )
        }
    }
};